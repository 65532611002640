import gql from 'graphql-tag';
export const GetInquiryFormResponse = `query inquiryform(
    $inquiryformid: ID!
    ){
      inquiryform(
        inquiryformid: $inquiryformid
      ){
        inquiryformid
        formtype
        isfirstnameshow
        islastnameshow
        isemailshow
        isphoneshow
        name
        formurl
        formdata
        createdby
        updatedby
        locationid
        businessid
        status
        thanksmessage
        isshowthanksbutton
        thanksbuttontext
        thanksbuttontype
        thanksbuttonlink
        thanksbuttonfileurl
        callouttext
        calloutbody
        issendthanksemail
        issendthankssms
        thanksemailconfig
        thankssmsconfig
        selectedrows
        themecolor
        backgroundcolor
        textcolor
        calloutbuttontext
        calloutbtnposition
        textposition
        btnthemecolor
        btntextcolor
        workflowid
        termenable
        termdesc
        radioview
        checkboxview
        fieldtextcolor
        captchaenable
        isshowfooterlogo
        ispaid
        formprice
        deleted
        createdAt
        updatedAt
        formwidth
        fieldtextcolor
          inquiryformrespons{
            responsedata
          }
      }
  }`;

export const UploadImage = gql`mutation Uploadimg($images: [Upload!]) {
    uploadimg(images: $images) {
      images
    }
}`;

export const createInquiry = `mutation createinquiry(
  $responsedata: String,
  $inquiryformid: ID!,
  $locationid: ID!,
  $businessid: ID!,
  $status: String,
  $contactid: ID,
  $organizationid: ID,
  $paymentid: ID
){
  createinquiry(
        responsedata: $responsedata
        inquiryformid: $inquiryformid
        status: $status
        locationid: $locationid
        businessid: $businessid
        contactid: $contactid
        organizationid: $organizationid
        paymentid: $paymentid){
          inquiryid
        }
}`;

export const CreateInquiryContact = `mutation Createinquirycontact($inquiryformid: ID!, $responsedata: String!, $locationid: ID!, $businessid: ID!) {
  createinquirycontact(inquiryformid: $inquiryformid, responsedata: $responsedata, locationid: $locationid, businessid: $businessid) {
    contactid
    organizationid
  }
}`;

export const InquiryById = `query Inquiry($inquiryid: ID!) {
  inquiry(inquiryid: $inquiryid) {
    inquiryid
    inquiryformid
    responsedata
    createdby
    updatedby
    locationid
    businessid
    status
    deleted
    createdAt
    updatedAt
  }
}`;

export const updateInquiry = `mutation Updateinquiry($inquiryid: ID!, $inquiryformid: ID, $responsedata: String, $locationid: ID, $businessid: ID, $status: String, $contactid: ID, $organizationid: ID, $paymentid: ID) {
  updateinquiry(inquiryid: $inquiryid, inquiryformid: $inquiryformid, responsedata: $responsedata, locationid: $locationid, businessid: $businessid, status: $status, contactid: $contactid, organizationid: $organizationid, paymentid: $paymentid)
}`;

export const GetInquiryByFormId = `query Inquiriesbyform($inquiryformid: ID!) {
  inquiriesbyform(inquiryformid: $inquiryformid) {
    inquiryid
    inquiryformid
    responsedata
    createdby
    updatedby
    locationid
    businessid
    status
    deleted
    createdAt
    updatedAt
    contactid
    organizationid
    paymentid
    ispublish
  }
}`;

export const SendEditInquiryEmail = `mutation Sendeditinquiryemail($inquiryformid: ID!, $inquiryid: ID!, $contactid: ID, $organizationid: ID) {
  sendeditinquiryemail(inquiryformid: $inquiryformid, inquiryid: $inquiryid, contactid: $contactid, organizationid: $organizationid) {
    inquiryid
    inquiryformid
    responsedata
    createdby
    updatedby
    locationid
    businessid
    status
    deleted
    createdAt
    updatedAt
    contactid
    organizationid
    paymentid
  }
}`;

export const WorkflowAutomationsByLocation = `query workflowAutomationsByLocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!) {
  workflowAutomationsByLocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize) {
    workflowid
    name
  }
}`;