import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../config';

export default function BusinessFavicon({ name, logo }) {
  return (
    <Helmet>
      <title>{name}</title>
      <link rel="icon" type="image/x-icon" href={logo} />
      <meta property="og:title" content={name} />
      <meta property="og:image" content={config?.bixLogo} />
      <meta property="og:description" content={name} />
      <meta charset="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={config.BixPublicUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={name} />
      <meta property="quote" content={name} />
      <meta name="description" content={name} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="BiXpand" />
      <meta property="og:quote" content={name} />
      <meta property="og:hashtag" content="#bixpand" />
      <meta property="og:image" itemProp="image" content={config.bixLogo} />
      <meta property="og:image:secure_url" content={config.bixLogo} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={config.BixPublicUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={name} />
      <meta name="twitter:title" content={name} />
      <meta name="twitter:card" content={name} />
      <meta name="twitter:site" content={config.BixPublicUrl} />
      <meta name="twitter:creator" content="Bixpand" />
      <meta name="twitter:image" content={config.bixLogo} />
      <meta property="og:image:width" content="256" />
      <meta property="og:image:height" content="256" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:updated_time" content="1440432930" />
    </Helmet>
  );
}
