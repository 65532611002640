/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import useStyles from './Style';
import RichTextEditor from 'react-rte';
import Dropzone from 'react-dropzone';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import './History.css';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import config from '../../config';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { TicketStatus } from '../../common/const';
import moment from 'moment';
import { BrandSettingByLocation, Entity } from '../../Api Mutation/EntityApi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Autocomplete, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Link, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import { Attachment, Close, CloudUpload, Send } from '@mui/icons-material';
import TicketAttchments from './TicketAttchments';
import { UploadImage } from '../../Api Mutation/InquiryForm';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import BusinessFavicon from '../../common/BusinessFavicon';
import { Conversationlistbyticket, CreateConversation, Raiseticketbyticketid, Ticketstatushistory, UpdateTicket, Usersbytype } from '../../Api Mutation/TicketApi';
import { TimelineConnector, TimelineDot, TimelineItem, TimelineSeparator, Timeline } from '@mui/lab';

function TicketHistory() {
  const classes = useStyles();
  const rtEditor = useRef(null);
  const messagesEndRef = useRef(null);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const ticketId = params.get('tid');
  const contactId = params.get('cid');
  const locationId = params.get('lid');
  const businessId = params.get('bid');
  const userType = params.get('from');
  const [conversationList, setCoversationList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [files, setFiles] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [entityData, setEntityData] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [value, setValue] = useState('');
  const [dropOpen, setDropOpen] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [brandImage, setBrandImage] = useState('');
  const [favIcon, setFavIcon] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [locationBrandImage, setLocationBrandImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [associatedConnnectUser, setAssociatedConnnectUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [editorValue, setEditorValue] = useState(RichTextEditor?.createValueFromString(value || '', 'html'));

  const getEntityData = () => {
    if (locationId) {
      setLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: locationId },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
            setBusinessName(res?.data?.entity?.businessname || res?.data?.entity?.brandname);
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getEntityData();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const adminData = () => {
    setLoading(true);
    if (businessId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: businessId,
        },
      }).then((res) => {
        const result = res.data.entity;
        if (res?.errors?.[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          setLoading(false);
        }
        if (result) {
          setEntityData(result);
          setLoading(false);
        };
      });
    }
  };

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getConversation = (pageNumber) => {
    setLoading(true);
    if (ticketId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Conversationlistbyticket,
        variables: {
          ticketid: ticketId,
          pagenumber: pageNumber,
          pagesize: 6,
        },
      }).then((res) => {
        const result = res.data.conversationlistbyticket;
        if (res?.errors?.[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          setLoading(false);
          return;
        }
        if (result?.count > 0) {

          const mergeConversations = (oldList, newList) => {
            const combinedList = [...oldList];
            newList.forEach(newItem => {
              if (!oldList.some(item =>
                (item.fromadmin === newItem?.fromadmin) &&
                item.conversation.trim() === newItem?.conversation?.trim()
              )) {
                combinedList.push(newItem);
              }
            });
            return combinedList;
          };
          setCoversationList(prevList => mergeConversations(prevList, result?.rows));

          setHasMore(result.rows.length === 6);
          scrollToBottom();
        } else {
          setHasMore(false);
        }
        setLoading(false);
      });
    }
  };

  const renderPreview = (attachmentArr) => {
    const parsedArr = JSON.parse(attachmentArr);

    if (Array.isArray(parsedArr)) {
      return parsedArr.map((url, index) => {
        const fileType = url?.split('.').pop().toLowerCase();
        const fileName = url?.split('/')?.[5];


        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
          return (
            <div
              // className={classes.renderTag}
              style={{
                width: '100%',
                textAlign: 'center',
                alignContent: 'center',
                border: '1px solid #ccc9c9fa',
                marginBottom: 9,
                borderRadius: 4,
                padding: 2,
              }}
            >
              <center>
                <Link href={url} target="_blank"
                  // className={classes.fileText}
                  style={{
                    textAlign: 'center',
                    alignContent: 'center',
                    width: '100%',
                    textDecoration: 'none',
                    ":hover": {
                      textDecoration: 'none',
                    },
                  }}
                >{fileName}</Link>
              </center>
              <img
                key={index}
                src={url}
                alt={fileName || "Preview"}
                // className={classes.renderImg}
                style={{
                  width: '95%',
                  height: 'auto',
                  margin: '10px',
                  borderRadius: 5,
                  // [theme.breakpoints.up('lg')]: {
                  //   width: '50% !important',
                  // },
                }}
              />
            </div>
          );
        } else if (fileType === 'pdf') {
          return (
            <div className={classes.renderTag}>
              <center>
                <Link href={url} target="_blank"
                  className={classes.fileText}
                  style={{
                    textAlign: 'center',
                    alignContent: 'center',
                    width: '100%',
                    textDecoration: 'none',
                    ":hover": {
                      textDecoration: 'none',
                    },
                  }}
                >{fileName}</Link>
              </center>
              <embed
                key={index}
                src={url}
                type="application/pdf"
                width="90%"
                height="200px"
                className={classes.renderEmbed}
                style={{
                  marginLeft: '20px',
                  borderRadius: 5,
                }}
              />
            </div>
          );
        } else if (fileType === 'txt') {
          return (
            <a
              key={index}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              // className={classes.renderA}
              style={{
                display: 'block',
                margin: '5px',
                border: '1px solid #ccc4c4fa',
                borderRadius: 5,
                boxShadow: '0px 0px 1px 1px grey',
                width: '99%',
                textAlign: 'center',
                padding: '5px',
                textDecoration: 'none',
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              {fileName}
            </a>
          );
        } else {
          return (
            <a
              key={index}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              // className={classes.renderA}
              style={{
                display: 'block',
                margin: '5px',
                border: '1px solid #ccc4c4fa',
                borderRadius: 5,
                boxShadow: '0px 0px 1px 1px grey',
                width: '99%',
                textAlign: 'center',
                padding: '5px',
                textDecoration: 'none',
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              {fileName}
            </a>
          );
        }
      });
    }

    return null;
  };

  const getTicketData = () => {
    setLoading(true);
    if (ticketId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Raiseticketbyticketid,
        variables: {
          ticketid: ticketId,
        },
      }).then((res) => {
        const result = res.data.raiseticketbyticketid;
        if (res?.errors?.[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          setLoading(false);
        }
        if (result) {
          setTicketData(result);
          setAssociatedConnnectUser(result?.assignee);
          setLoading(false);
        };
      });
    }
  };

  const getHistory = () => {
    setLoading(true);
    if (ticketId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Ticketstatushistory,
        variables: {
          ticketid: ticketId,
        },
      }).then((res) => {
        const result = res?.data?.ticketstatushistory;
        if (result) {
          setHistoryList(result);
          setLoading(false);
        };
      });
    }
  };

  const createCoversation = () => {
    if (editorValue?._cache.html) {
      setLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: CreateConversation,
        variables: {
          ticketid: ticketId,
          contactid: contactId,
          locationid: locationId,
          businessid: businessId,
          conversation: editorValue?._cache.html,
          fromadmin: userType === 'true' ? true : false,
          attachments: files?.length > 0 ? JSON.stringify(files) : '',
        },
      }).then((res) => {
        const result = res?.data?.createConversation;
        if (res?.errors?.[0]?.message) {
          showNotification(res.errors[0].message, 'error');
          setLoading(false);
          setEditorValue(RichTextEditor?.createValueFromString('', 'html'));
        }
        if (result?.ticketid) {
          showNotification("Message Sent Successfully", 'success');
          setLoading(false);
          getConversation(page);
          scrollToBottom();
          setFiles([]);
          setEditorValue(RichTextEditor?.createValueFromString('', 'html'));
        };
      });
    } else {
      showNotification('Description is required', 'error');
    }
  };

  const httpLink = createUploadLink({
    uri: config.contactApi,
    headers: {
      authorization: config.staticToken || null,
      'Apollo-Require-Preflight': 'true',
    },
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: config.staticToken || null,
        'Apollo-Require-Preflight': 'true',
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (ticketId) {
        getConversation(1);
        getHistory();
        getTicketData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [ticketId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (locationId) {
        setLoading(true);
        postDataRequestPublic(config.baseURLApi, {
          query: Usersbytype,
          variables: {
            locationid: locationId,
            businessid: businessId,
          },
        }).then((json) => {
          if (json?.errors && json?.errors[0]?.message) {
            showNotification(json.errors[0].message, 'error');
          } else if (json?.data?.usersbytype) {
            setUserData(json.data.usersbytype);
          }
          setLoading(false);
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [locationId, businessId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (businessId) {
        adminData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [businessId]);

  return (
    <>
      {loading ? < LoadingPage /> : ''}
      <div>
        <Box style={{
          display: 'flex',
          alignItems: 'center',
          padding: 10,
          height: 80,
          boxShadow: '1px 0px 6px 1px gray',
        }}>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: 'black',
              marginLeft: 10,
            }}># {ticketData?.subject}</Typography>
        </Box>
        <Box
          className={classes.mainGrid}
          style={{
            marginTop: 11,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            gap: 10,
          }}
        >
          <Box
            className={classes.grid1}
            style={{
              width: '77%',
              backgroundColor: '#e2e4f1',
              borderRadius: 10,
              padding: 10,
              // [theme.breakpoints.down('md')]: {
              //   width: '96%',
              //   margin: 'auto',
              //   marginBottom: 10,
              // },
            }}
          >
            <div ref={messagesEndRef}
              // className={classes.msgBox} 
              style={{
                width: '100%',
                height: 355,
                marginBottom: 5,
                paddingRight: 8,
                overflow: 'auto',
              }}
              id="scrollableDiv">
              <InfiniteScroll
                dataLength={conversationList.length}
                useWindow={false}
                next={() => {
                  if (!loading && hasMore) {
                    getConversation(page + 1);
                    setPage(page + 1);
                  }
                }}
                hasMore={hasMore}
                loader={conversationList.length > 0 ? <p>Loading...</p> : ''}
                scrollableTarget="scrollableDiv"
              >
                {conversationList.map((detail, index) => (
                  <Box key={index}
                    // className={classes.msgWrapper}
                    style={{
                      width: '100%',
                    }}
                  >
                    {(detail?.fromadmin === true && userType === 'true') ? (
                      <Box
                        className={classes.replyWrapper}
                        style={{
                          color: 'white',
                          padding: 10,
                          width: '100%',
                          borderRadius: 6,
                          display: 'flex',
                          alignItems: 'baseline',
                          justifyContent: 'end',
                          gap: 7,
                        }}
                      >
                        <Box
                          className={classes.reply}
                          style={{
                            display: 'flex',
                            alignItems: 'start',
                            flexDirection: 'column',
                            marginRight: 0,
                            gap: 7,
                            backgroundColor: '#9ca8f6',
                            borderRadius: 6,
                            width: '50%',
                          }}
                        >
                          <Typography
                            className={classes.replyText}
                            style={{
                              color: 'white',
                              padding: 10,
                              width: '100%',
                              borderRadius: 6,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                            dangerouslySetInnerHTML={{ __html: detail?.conversation }}
                          />
                          <Box
                            // className={classes.attachmentTextBox}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              width: '96%',
                              margin: 'auto',
                            }}
                          >
                            {renderPreview(detail?.attachments)}
                          </Box>
                        </Box>
                        <Avatar
                          // className={classes.adminAvatar}
                          style={{
                            // color: '#7283f2',
                            color: '#9ca8f6',
                            backgroundColor: 'white',
                            fontWeight: 600,
                            fontSize: 17,
                            width: 35,
                            height: 35,
                          }}
                        >{`${handleUndefined(entityData?.firstname?.charAt(0) || '')}${handleUndefined(entityData?.lastname?.charAt(0) || '')}`}</Avatar>
                      </Box>
                    ) : (detail?.fromadmin === false && userType === 'false') ? (
                      <>
                        <Box
                          // className={classes.replyWrapper}
                          style={{
                            color: 'white',
                            padding: 10,
                            width: '100%',
                            borderRadius: 6,
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'end',
                            gap: 7,
                          }}
                        >
                          <Box
                            className={classes.reply}
                            style={{
                              display: 'flex',
                              alignItems: 'start',
                              flexDirection: 'column',
                              marginRight: 0,
                              gap: 7,
                              backgroundColor: '#9ca8f6',
                              borderRadius: 6,
                              width: '50%',
                            }}
                          >
                            <Typography
                              // className={classes.replyText}
                              style={{
                                color: 'white',
                                padding: 10,
                                width: '100%',
                                borderRadius: 6,
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                              dangerouslySetInnerHTML={{ __html: detail?.conversation }}
                            />
                            <Box
                              // className={classes.attachmentTextBox}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                width: '96%',
                                margin: 'auto',
                              }}
                            >
                              {renderPreview(detail?.attachments)}
                            </Box>
                          </Box>
                          <Avatar
                            // className={classes.adminAvatar}
                            style={{
                              // color: '#7283f2',
                              color: '#9ca8f6',
                              backgroundColor: 'white',
                              fontWeight: 600,
                              fontSize: 17,
                              width: 35,
                              height: 35,
                            }}
                          >{`${handleUndefined(ticketData?.firstname?.charAt(0) || '')}${handleUndefined(ticketData?.lastname?.charAt(0) || '')}`}</Avatar>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          // className={classes.msgStartWrapper}
                          style={{
                            color: 'white',
                            padding: 10,
                            width: '100%',
                            borderRadius: 6,
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'start',
                            gap: 7,
                          }}
                        >
                          <Avatar
                            // className={classes.userAvatar}
                            style={{
                              color: 'white',
                              // backgroundColor: '#7283f2',
                              backgroundColor: '#9ca8f6',
                              fontWeight: 600,
                              fontSize: 17,
                              width: 35,
                              height: 35,
                            }}
                          >{(userType === 'true') ? (`${handleUndefined(ticketData?.firstname?.charAt(0) || '')}${handleUndefined(ticketData?.lastname?.charAt(0) || '')}`) : (`${handleUndefined(entityData?.firstname?.charAt(0) || '')}${handleUndefined(entityData?.lastname?.charAt(0) || '')}`)}</Avatar>
                          <Box
                            className={classes.msg}
                            style={{
                              display: 'flex',
                              alignItems: 'start',
                              flexDirection: 'column',
                              marginRight: 0,
                              gap: 7,
                              backgroundColor: 'white',
                              borderRadius: 6,
                              color: 'black',
                              width: '50%',
                            }}
                          >
                            <Typography
                              // className={classes.msgText}
                              style={{
                                backgroundColor: 'white',
                                padding: 10,
                                width: '100%',
                                borderRadius: 6,
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                              dangerouslySetInnerHTML={{ __html: detail?.conversation }}
                            />
                            {handleUndefined(detail?.attachments) ? (
                              <Box
                                // className={classes.attachmentTextBox}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  width: '96%',
                                  margin: 'auto',
                                }}
                              >
                                {renderPreview(detail?.attachments)}
                              </Box>
                            ) : ''}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                ))}
              </InfiniteScroll>
            </div>
            <Box
              style={{
                backgroundColor: 'white',
                borderRadius: 10,
                border: '1px solid #ccc4c4fa',
              }}
            >
              <RichTextEditor
                value={editorValue}
                placeholder="Write a Email..."
                multiline
                id="body-text"
                type="string"
                ref={rtEditor}
                onChange={(values) => {
                  setEditorValue(values);
                  setValue(values.toString('html'));
                }}
                style={{ border: 'none' }}
                editorStyle={{ minHeight: '160px', fontSize: '14px', border: 'none' }}
              />
              <Box className={classes.featureBox} style={{
                display: 'flex',
                alignItems: 'center',
                gap: 3,
                marginRight: 10,
                margin: '2px 0px',
                justifyContent: 'space-between',
              }}>
                <div className={classes.alignContant} style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Tooltip title="Add Attachment">
                    <IconButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setDropOpen(true);
                        setFiles([]);
                      }}
                    >
                      <Attachment className={classes.themeIcon} style={{
                        color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
                      }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Send">
                    <IconButton className={classes.sendBtn}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#005eb2',
                        padding: 5,
                        marginRight: 7,
                        '&:hover': {
                          backgroundColor: '#004578 !important',
                          color: 'white !important',
                        },
                      }}
                      onClick={() => createCoversation()}>
                      <Send className={classes.sendIcon} style={{
                        color: 'white',
                        fontSize: 18,
                      }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
              {files?.length > 0 ? (
                <TicketAttchments
                  attchedFiles={files}
                  setAttachedFiles={setFiles}
                  isConversation
                />
              ) : ''}
              <Dialog open={dropOpen} onClose={() => setDropOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Attachment</DialogTitle>
                <DialogContent>
                  <Box className={classes.Dropzone} style={{
                    height: '150px',
                    marginBottom: '30px',
                    textAlign: 'center',
                    padding: '17px',
                    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
                    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
                    border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
                    // [theme.breakpoints.down('md')]: {
                    //   width: 250,
                    //   textAlign: 'center',
                    //   padding: '17px',
                    //   backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
                    //   color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
                    //   border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
                    //   marginBottom: 5,
                    // },
                  }}>
                    <Dropzone
                      disabled={fileLoader}
                      onDrop={async (acceptedFiles) => {
                        if (acceptedFiles.length > 0) {
                          const uploadedFileUrls = [];
                          for (const file of acceptedFiles) {
                            try {
                              setFileLoader(true);
                              const res = await client.mutate({
                                mutation: UploadImage,
                                variables: { images: file },
                              });
                              const fileUrl = res?.data?.uploadimg?.images;
                              if (fileUrl) {
                                uploadedFileUrls.push(fileUrl);
                                setFileLoader(false);
                              }
                            } catch (error) {
                              setFileLoader(false);
                              showNotification('Error uploading file', 'error');
                            }
                          }
                          if (uploadedFileUrls.length > 0) {
                            setFiles((prev) => [...prev, ...uploadedFileUrls]);
                            showNotification('Files uploaded successfully', 'success');
                            setDropOpen(false);
                          }
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <CloudUpload className={classes.placeholderIcon} />
                          <br />
                          {fileLoader ? (
                            <p style={{ marginTop: 10 }}>
                              Uploading...
                            </p>
                          ) : (<p style={{ marginTop: 10 }}>
                            Upload Picture
                          </p>)}
                        </div>
                      )}
                    </Dropzone>
                    <FormHelperText>
                      <Typography variant="body2">
                        <center>
                          {' '}
                          *File size should not
                          greater than 5MB!
                        </center>
                      </Typography>
                    </FormHelperText>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<Close />}
                    onClick={() => {
                      setDropOpen(false);
                    }}
                    color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
          <Box className={classes.grid2} style={{
            width: '20%',
            backgroundColor: '#e2e4f1',
            borderRadius: 10,
            padding: 10,
            // [theme.breakpoints.down('md')]: {
            //   width: '96%',
            //   margin: 'auto',
            // },
          }}>
            <Typography className={classes.requester}
              style={{
                fontSize: 20,
                fontWeight: 300,
                color: '#4A4A4A'
              }}
            ><strong>Requester</strong></Typography>
            <hr />
            <Box className={classes.detailWrapper} style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
              <Box>
                <Box className={classes.assignName} style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 3,
                  marginTop: '1rem',
                }}>
                  <Avatar className={classes.avatar}
                    style={{
                      backgroundColor: 'white',
                      // color: '#7283f2',
                      color: '#9ca8f6',
                      fontWeight: 600,
                    }}
                  >{handleUndefined(ticketData?.firstname)?.charAt(0)}</Avatar>
                  <Typography className={classes.userName}
                    style={{
                      borderBottom: '1px soild greay',
                      fontSize: 18,
                      color: '#4A4A4A'
                    }}
                  >{`${handleUndefined(ticketData?.firstname) || ''} ${handleUndefined(ticketData?.lastname) || ''}`}</Typography>
                </Box>
                <hr className={classes.nameHr} style={{
                  height: 1,
                  width: '60%',
                  backgroundColor: '#9ca8f6',
                  // [theme.breakpoints.down('md')]: {
                  //   width: '40%',
                  // },
                }} />
                <Box className={classes.detailBox} style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Typography className={classes.detailHead}
                    style={{
                      fontWeight: 500,
                      color: '#4e4e4e',
                      padding: 10,
                      paddingRight: 5,
                      fontSize: 16
                    }}
                  >
                    Email:
                  </Typography>
                  <Typography className={classes.detailText}
                    style={{
                      fontWeight: 400,
                      color: '#4e4e4e',
                      fontSize: 16,
                    }}
                  >
                    {ticketData?.primaryemail}
                  </Typography>
                </Box>
                <Box className={classes.detailBox}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography className={classes.detailHead}
                    style={{
                      fontWeight: 500,
                      color: '#4e4e4e',
                      padding: 10,
                      paddingRight: 5,
                      fontSize: 16,
                    }}
                  >
                    Requested At:
                  </Typography>
                  <Typography className={classes.detailText}
                    style={{
                      fontWeight: 400,
                      color: '#4e4e4e',
                      fontSize: 16,
                    }}
                  >
                    {moment(parseInt(ticketData?.createdAt, 10)).format('DD, MMMM, YYYY')}
                  </Typography>
                </Box>
                <Box className={classes.assigneeBox}
                  style={{
                    fontWeight: 400,
                    color: '#4e4e4e',
                    marginTop: 13,
                  }}
                >
                  <Typography color="primary" style={{ fontSize: 14, fontWeight: 500 }}>Assignee</Typography>
                  <hr className={classes.assigneeHr}
                    style={{
                      backgroundColor: '#0c7ee5',
                      height: '0.8px',
                    }}
                  />
                  <br />
                  <Autocomplete
                    fullWidth
                    disabled={userType === 'false'}
                    size="small"
                    id="assign-to-autocomplete"
                    value={userData?.length > 0 ? (userData?.find((u) => u?.userid === associatedConnnectUser)) : {}}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setAssociatedConnnectUser(newValue?.userid || '');
                        if (locationId && businessId) {
                          setLoading(true);
                          const assigneeJson = [{ id: newValue?.userid, name: `${newValue.firstname} ${newValue.lastname || ''}` }];
                          postDataRequestPublic(config.baseURLApi, {
                            query: UpdateTicket,
                            variables: {
                              locationid: locationId,
                              businessid: businessId,
                              updatedby: businessId,
                              ticketid: ticketId,
                              assignee: newValue ? JSON.stringify(assigneeJson) : '',
                            },
                          }).then((response) => {
                            if (response?.errors?.[0]?.message) {
                              showNotification(response.errors[0].message, 'error');
                              setLoading(false);
                            }
                            if (response?.data?.updateTicket) {
                              showNotification('Status Updated Successfully', 'success');
                              setLoading(false);
                            }
                          });
                        }
                      }
                    }}
                    options={userData}
                    getOptionLabel={(option) => `${handleUndefined(option?.firstname)} ${handleUndefined(option?.lastname)}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assign to"
                        variant="outlined"
                        sx={{
                          '& input': {
                            fontSize: 13
                          },
                          '& label': {
                            fontSize: 13
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <br />
              <Box
                style={{
                  backgroundColor: 'white',
                  margin: 5,
                  borderRadius: 10,
                  border: '1px solid #ccc4c4fa',
                  overflow: 'auto',
                  height: 250,
                  padding: '5px 3px',
                }}>
                <Timeline>
                  {historyList?.map((h) => (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <ListItemText
                        style={{
                          margin: 0,
                          marginLeft: 9,
                          marginTop: 3,
                          padding: '2px 0px',
                        }}
                        primary={<span style={{ fontSize: 14, color: '#4A4A4A' }}>{TicketStatus[h?.status]}</span>}
                        secondary={<span style={{ fontSize: 13 }}>{moment(h?.updatedAt?.split(' +')[0], 'YYYY-MM-DD HH:mm:ss').format('DD, MMMM, YYYY')}</span>}
                      />
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
      <BusinessFavicon
        name={businessName || "Bixpand"}
        logo={favIcon || locationBrandImage || brandImage || "https://storage.googleapis.com/bixpand-staging/WebsiteImages/favicon-16x16.png"}
      />
    </>
  )
}

export default TicketHistory