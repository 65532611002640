import React, { useState, useEffect } from 'react';
import {
  WhatsappShareButton,
  WhatsappIcon as WhatsappIconShare,
  TwitterShareButton,
  TwitterIcon as TwitterIconShare,
  FacebookShareButton,
  FacebookIcon as FacebookIconShare,
  EmailShareButton,
  EmailIcon as EmailIconShare,
  FacebookMessengerShareButton,
  FacebookMessengerIcon as FacebookMessengerIconShare,
  LinkedinShareButton,
  LinkedinIcon as LinkedinIconShare,
  RedditShareButton,
  RedditIcon as RedditIconShare,
  PocketShareButton,
  PocketIcon as PocketIconShare,
} from 'react-share';
import { Close, FileCopy, ShareOutlined as ShareOutlinedIcon } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PopupMessage from '../../PopupMessage/PopupMessage';
import { useGlobalState } from '../../../context/GlobalContext';
import { useEntitiesState } from '../../../context/EntityContext';
import { postDataRequest, handleUndefined } from '../../../common/commonfunctions';
import config from '../../../config';
import shareIcon from "../../../images/shareIcon.svg";
import useStyles from './style';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle as MuiDialogTitle, IconButton, Input, InputAdornment, Typography, styled } from '@mui/material';

export default function SocialShare(props) {
  const {
    Url, brandName, title, content, id, diff, event,
  } = props;
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [isLoading, setLoading] = useState(false);
  const globalContext = useGlobalState();
  const entityContext = useEntitiesState();
  const classes = useStyles();
  const publicPageURL = `${config.BixUrl}/reviews/${entityContext?.entities?.businessData
    && entityContext?.entities?.businessData[0]?.businessname}/${globalContext?.globalState?.currentLocation?.businessname}`;
  const saveReviewChangeLog = (text) => {
    if (props.handleShareReviewChangeLog && props.reviewId) {
      props.handleShareReviewChangeLog(text, props.reviewId);
    }
  };
  const hashtags = ['locallyowned', 'localbusiness', 'localbusinessowners', 'onlinereviews', 'reviewmanagement', 'reviews', 'expandyourbrand', 'expandyourbusiness', 'bixpand'];
  useEffect(() => {
    setImageUrl(Url);
  }, [Url]);

  const shareReviewPublicPage = () => {
    if (props?.inPublicPage === true) {
      const PUBLICPAGE_SHARE_REVIEW = `
            mutation
                increaseLikeOrShare(
                    $reviewid: ID!,
                    $type: String!,
                ){
                    increaseLikeOrShare(
                        reviewid: $reviewid,
                        type: $type, 
                    )
                }`;
      postDataRequest(config.reviewApi, {
        query: PUBLICPAGE_SHARE_REVIEW,
        variables: {
          reviewid: props.reviewId,
          type: 'share',
        },
      });
    }
  };

  const contentReport = () => {
    if (id) {
      const SHARE_COUNT_CONTENT = `
      mutation updateShareCountContentMarketing($contentmarketingnid: ID!, $sharecount: Int) {
        updateShareCountContentMarketing(contentmarketingnid: $contentmarketingnid, sharecount: $sharecount)
      }`;
      postDataRequest(config.baseURLApi, {
        query: SHARE_COUNT_CONTENT,
        variables: {
          contentmarketingnid: id,
          sharecount: 1,
        },
      });
    }
  };

  const shareReviewSanpshort = () => {
    if (props?.reviewBoard === true) {
      setLoading(true);
      const SHARE_REVIEW_SANPSSHORT = `
        mutation 
            generateAndSaveReviewSnapshot(
                $reviewid: ID!,
                ){
                    generateAndSaveReviewSnapshot(
                        reviewid: $reviewid
                    )
                }`;
      postDataRequest(config.reviewApi, {
        query: SHARE_REVIEW_SANPSSHORT,
        variables: {
          reviewid: props.reviewId,
        },
      }).then((res) => {
        setLoading(false);
        setImageUrl(res?.data?.generateAndSaveReviewSnapshot);
        setLoading(false);
      });
    }
    setOpenShareDialog(true);
  };

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }));

  const StyledTypo = styled(Typography)(({ theme }) => ({
    fontSize: 20,
    fontFamily: 'Open sans',
  }));

  const CopySpan = styled('span')(({ theme }) => ({
    fontSize: 12,
    fontFamily: 'Open sans',
    textTransform: 'capitalize',
    color: '#005eb2',
    fontWeight: 400,
  }));

  const CloseSpan = styled('span')(({ theme }) => ({
    fontFamily: 'Open sans',
    textTransform: 'capitalize',
    color: '#005eb2',
    fontWeight: 400,
  }));

  const DialogTitle = () => (
    <MuiDialogTitle
      disableTypography
      className={classes.rootDialogTitle}
    >
      <StyledBox>
        <StyledTypo variant="h6">Share</StyledTypo>

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setOpenShareDialog(false)}
        >
          <Close />
        </IconButton>
      </StyledBox>
    </MuiDialogTitle>
  );
  function CommonSocial() {
    return (
      <Dialog
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        size="xs"
      >
        <DialogTitle id="alert-dialog-title">Share</DialogTitle>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent dividers>
              <center>
                <FacebookShareButton
                  onClick={() => {
                    if (content && id) {
                      contentReport();
                    }
                    shareReviewPublicPage(props?.reviewId);
                    saveReviewChangeLog('Review shared on Facebook');
                  }}
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  quote={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  hashtag={
                    handleUndefined(brandName)
                      ? `#${brandName}`?.replace(/\s+/g, '')
                      : '#Bixpand'
                  }
                  className={classes.socialSvgContent}
                >
                  <FacebookIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                  onClick={() => {
                    if (content && id) {
                      contentReport();
                    }
                    shareReviewPublicPage(props?.reviewId);
                    saveReviewChangeLog(
                      'Review shared on Facebook Messenger',
                    );
                  }}
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  quote="Shared from BiXpand"
                  hashtag={
                    handleUndefined(brandName)
                      ? `#${brandName}`?.replace(/\s+/g, '')
                      : '#Bixpand'
                  }
                  className={classes.socialSvgContent}
                >
                  <FacebookMessengerIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </FacebookMessengerShareButton>

                <TwitterShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    if (content && id) {
                      contentReport();
                    }
                    shareReviewPublicPage(props?.reviewId);
                    saveReviewChangeLog('Review shared on Twitter');
                  }}
                  hashtags={
                    handleUndefined(brandName)
                      ? [
                        `${brandName}`?.replace(
                          /\s+/g,
                          '',
                        ), ...hashtags,
                      ]
                      : hashtags
                  }
                  className={classes.socialSvgContent}
                >
                  <TwitterIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </TwitterShareButton>

                <WhatsappShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    if (content && id) {
                      contentReport();
                    }
                    shareReviewPublicPage();
                    saveReviewChangeLog('Review shared on Whatsapp');
                  }}
                  separator=":: "
                  className={classes.socialSvgContent}
                >
                  <WhatsappIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    if (content && id) {
                      contentReport();
                    }
                    shareReviewPublicPage();
                    saveReviewChangeLog(
                      'Review shared on Linked In',
                    );
                  }}
                  quote={publicPageURL}
                  // separator=":: "
                  className={classes.socialSvgContent}
                >
                  <LinkedinIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </LinkedinShareButton>
                <RedditShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    if (content && id) {
                      contentReport();
                    }
                    shareReviewPublicPage();
                    saveReviewChangeLog('Review shared on Reddit');
                  }}
                  separator=":: "
                  className={classes.socialSvgContent}
                >
                  <RedditIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </RedditShareButton>
                <PocketShareButton
                  url={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  onClick={() => {
                    if (content && id) {
                      contentReport();
                    }
                    shareReviewPublicPage();
                    saveReviewChangeLog('Review shared on Pocket');
                  }}
                  separator=":: "
                  className={classes.socialSvgContent}
                >
                  <PocketIconShare
                    className={classes.socialIcon}
                    size={36}
                  />
                </PocketShareButton>
                <EmailShareButton
                  subject={handleUndefined(title) ? 'Appointment Booking URL' : 'BiX-public page'}
                  url={
                    new URL(
                      handleUndefined(imageUrl) || window.location.href,
                    )?.href
                  }
                  className={classes.socialSvgContent}
                >
                  <EmailIconShare
                    onClick={() => {
                      if (content && id) {
                        contentReport();
                      }
                    }}
                    className={classes.socialIcon}
                    size={36}
                  />
                </EmailShareButton>
              </center>
              <br />
              <CopyToClipboard
                text={
                  new URL(
                    imageUrl || window.location.href,
                  )?.href
                }
              >
                <Input
                  className={classes.field}
                  style={{ fontSize: '12px', fontFamily: 'Open sans' }}
                  id="standard-adornment-password"
                  type="text"
                  value={
                    new URL(
                      imageUrl || window.location.href,
                    )?.href
                  }
                  disabled
                  endAdornment={(
                    <InputAdornment position="end">
                      <Button
                        color="primary"
                        size="small"
                        startIcon={<FileCopy />}
                        onClick={() => PopupMessage('Link Copied')}
                      >
                        <CopySpan>Copy</CopySpan>
                      </Button>
                    </InputAdornment>
                  )}
                />
              </CopyToClipboard>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => setOpenShareDialog(false)}
            color="primary"
            autoFocus
          >
            <CloseSpan>Close</CloseSpan>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <>
       <IconButton
          float="left"
          size="small"
          color="primary"
          variant="outlined"
          disabled={diff <= 0}
          onClick={() => {
            shareReviewSanpshort();
          }}
        >
          {diff <= 0 ? <ShareOutlinedIcon /> :  event ? (

            <img  onClick={() => {
              shareReviewSanpshort();
            }} style={{ height: "22px" }} src={shareIcon} alt="eventimage" />
         
        ) : <ShareOutlinedIcon style={{ fontSize: '23px', color: '#005EB2' }} />}
        </IconButton>

      <CommonSocial />
    </>
  );
}

