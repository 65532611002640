import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppintmentPublic from "./pages/LeadCentral/Appointment/PublicPageAppointment/AppoinmentPublic";
import ContentPublicDomain from "./pages/Contact_Marketing/ContentPublicDomain";
import PublicInquiryForm from "./pages/LeadCentral/InquiryForm/PublicInquiryForm";
import PublicInquiryResponse from "./pages/LeadCentral/InquiryForm/PublicInquiryResponse";
import PublicEventWidget from "./pages/LeadCentral/Events/PublicEventWidget";
import Product from "./pages/ProductAndService/Component/Product";
import ProductDetails from "./pages/ProductAndService/Component/ProductDetails";
import PublicPreview from "./pages/NewsLetter/PublicPreview";
import AffiliatePublicPage from "./pages/Setting/AfilliateLink/AffiliatePublicPage";
import PublicNewsletterWidget from "./pages/NewsLetter/PublicNewsletterWidget";
import EventPublicURL from "./pages/LeadCentral/Events/component/EventPublicURL";
import BusinessDetails from "./pages/BusinessList/BusinessDetails";
import BusinessSearch from "./pages/BusinessList/BusinessSearch";
import AppintmentPublicUrl from "./pages/LeadCentral/Appointment/PublicPageAppointment/AppointmentURL";
import Appintment from "./pages/LeadCentral/Appointment/PublicPageAppointment/Appointment";
import Businesslist from "./pages/BusinessList/Businesslist";
import ContentWidget from "./pages/Contact_Marketing/PublicWidget";
import ContentPreview from "./pages/Contact_Marketing/PublicPreview";
import DocumentPublicPage from "./pages/Documents/DocumentPublicPage";
import AppointmentList from "./pages/LeadCentral/Appointment/PublicPageAppointmentList/AppointmentList";
import UnSubscribe from "./pages/UnSubscribe/RequestUnSubscribe";
import TicketRaisePublic from "./pages/TicketManagement/TicketRaisePublic";
import TicketHistory from "./pages/TicketManagement/TicketHistory";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/appointment-public/:user/:event"
            element={<AppintmentPublicUrl />}
          />
          <Route
            path="/appointment/:username/:shorturl"
            element={<Appintment />}
          />
          <Route
            path="/appointment/:user/:event/:eventId/:lid/:uid/:bid"
            element={<AppintmentPublic />}
          />
          <Route
            path="/al/:shorturl"
            element={<AppointmentList />}
          />
          <Route path="/cm/:content" element={<ContentPublicDomain />} />
          <Route
            path="/content-marketing/:content"
            element={<ContentPublicDomain />}
          />
          <Route
            path="/unsub"
            element={<UnSubscribe />}
          />
          <Route
            path="/if/:locationid/:businessid/:formid"
            element={<PublicInquiryForm />}
          />
          <Route
            path="/ifo/:formid"
            element={<PublicInquiryForm />}
          />
          <Route
            path="/inquiryform/:locationid/:businessid/:formid"
            element={<PublicInquiryForm />}
          />
          <Route
            path="/if/view/:formid"
            element={<PublicInquiryResponse />}
          />
          <Route
            path="/ep/:id/:locationid/:businessid"
            element={<EventPublicURL />}
          />
          <Route
            path="/event-public/:id/:locationid/:businessid"
            element={<EventPublicURL />}
          />
          <Route path="/cds/:documentid" element={<DocumentPublicPage />} />
          <Route path="/ew/:lid" element={<PublicEventWidget />} />
          <Route path="/event-widget/:lid" element={<PublicEventWidget />} />
          <Route path="/wp/:id/:lid" element={<PublicPreview />} />
          <Route path="/webpage/:id/:lid" element={<PublicPreview />} />
          <Route path="/p/:lid" element={<Product />} />
          <Route path="/product/:lid" element={<Product />} />
          <Route path="/pd/:pid/:lid/:uid" element={<ProductDetails />} />
          <Route
            path="/product-details/:pid/:lid/:uid"
            element={<ProductDetails />}
          />
          <Route path="/ac/:bid/:content" element={<AffiliatePublicPage />} />
          <Route
            path="/affiliatecontent/:bid/:content"
            element={<AffiliatePublicPage />}
          />
          <Route path="/nl/:lid" element={<PublicNewsletterWidget />} />
          <Route path="/c/:lid" element={<ContentWidget />} />
          <Route path="/cp/:id/:lid" element={<ContentPreview />} />
          <Route path="/bl/:lid" element={<Businesslist />} />
          <Route path="/bd/:oid/:lid/:uid" element={<BusinessDetails />} />
          <Route path="/s" element={<BusinessSearch />} />
          <Route path="/newsletter/:lid" element={<PublicNewsletterWidget />} />
          <Route path="/ticket-raise" element={<TicketRaisePublic />} />
          <Route path="/tico" element={<TicketHistory />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
