import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  profileName: {
    marginTop: 25,
    fontWeight: 'bold',
  },
  subheading: {
    marginTop: 16,
  },
  avatarImg: {
    marginTop: '0px !important',
  },
  profileImage: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: '#005eb2',
    background: 'lightgray',
    marginTop: theme.spacing(3),
    fontSize: 32,
    fontWeight: 600,
    alignItems: 'center',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: '#005EB2',
    marginTop: theme.spacing(3),
    fontSize: 32,
    fontWeight: 600,
    alignItems: 'center',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  box1: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
    },
  },
  button: {
    marginRight: '20px',
    borderRadius: '50px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    },
  },
  label: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'500 !important',
  },
  timeZoneText: {
    fontSize: 14,
    fontWeight: 400,
  },
  input: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'400 !important',
  },
  termDescription: {
    fontSize: '14px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'600 !important',
  },
   paymentBox:{
    marginLeft:40,
    [theme.breakpoints.down('sm')]:{
      marginLeft:0,
    },
  },
  guestBtn: {
    marginRight: '20px',
    background:'#fff',
    border:'1px solid #005eb2',
    width: '100%',
    borderRadius: '20px',
    padding: '8px',
    fontSize:'12px',
    height:'30px',
    color:'#005eb2',
    fontWeight: '500',
    fontFamily:'Open sans',
    cursor:'pointer',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    },
  },
  scheduleBtn: {
    marginRight: '20px',
    backgroundColor:'#005eb2',
    border:'none',
    width: '100%',
    borderRadius: '20px',
    padding: '8px',
    fontSize:'12px',
    height:'30px',
    color:'#fff',
    fontWeight: '500',
    fontFamily:'Open sans',
    cursor:'pointer',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    },
  },
  btnlink: {
    textDecoration: 'none',
  },
  cal: {
    width: '90%',
    height: 'auto',
    padding: 37,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      width: '100%',
      height: 'auto',
    },
  },
  SocialIcon: {
    margin: 5,
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  Calendar: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  CalendarDate: {
    width: '100%',
  },
  Button: {
    maxWidth: '20px',
    marginRight: 18,
  },
  ButtonPhone: {
    maxWidth: '20px',
    marginRight: 18,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  backMenu: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  time: {
    maxHeight: '350px',
    minHeight: '350px',
    maxWidth: '100%',
    minWidth: '40%',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  tBar: {
    marginTop: '30px',
  },
  BixPowerBy: {
    width: '200px',
    aspectRatio: 'auto',
  },
  paper: {
    position: 'relative',
    fontFamily:'Open sans',
    zIndex: 1,
    marginLeft: '8%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0%',
    },
  },
  filterSize: {
    maxWidth: '100%',
  },
  papers: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  evntTime: {
    fontSize: '12px !important',
  },
  timezoneSpan: {
    fontSize: '14px !important',
    fontWeight: 600,
  },
  details: {
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  Disconnect: {
    backgroundColor: '#42ba96',
    color: 'white',
  },
  mask: {
    position: 'absolute',
    top: '9px',
    right: '13%',
    padding: 8,
    transform: 'rotate(40deg)',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: 8,
    width: theme.spacing(15),
    height: theme.spacing(7),
  },
  brandImage: {
    width: '170px',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      aspectRatio: 'auto',
    },
  },
  brandImages: {
    width: '22%',
    aspectRatio: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
      aspectRatio: 'auto',
    },
  },
  heading: {
    alignItems: 'center',
    color: '#4A4A4A',
  },
  welcomePara: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#4a4a4a',
    lineHeight: '1.5',
  },
  calSectionTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#4a4a4a',
    margin: '0px',
    textTransform:'none',
    fontFamily:'Open sans',
  },
  headingLocation: {
    width: '300px',
    wordWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      wordWrap: 'break-word',
    },
  },
  locationText: {
    fontSize: 12,
    fontWeight: 400,
  },
  line: {
    textDecoration: 'line-through',
  },
  videoIcon: {
    marginBottom: -8,
  },
  unActiveLink: {
    marginTop: 50,
  },
  back: {
    border: '1px solid #005EB2',
  },
  root: {
    marginTop: '1%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2%',
    },
  },
  profile: {
    borderTop: '1px solid #005EB2',
    paddingTop: 40,
  },
  profileImg: {
    margin: '0 0 -7% 3%',
  },
  img: {
    border: '2px solid #fff',
    aspectRatio: 'auto',
    width: '15%',
    height: 'auto',
  },
  inActiveProfileImg: {
    margin: '0 0 -3% 3%',
  },
  inActiveimg: {
    border: '2px solid #fff',
    aspectRatio: 'auto',
    width: '15%',
    height: 'auto',
  },
  phoneField: {
    textalign: 'center',
    marginBottom: 23,
    marginRight: 55,
  },
}));
