import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  rootDialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  socialSvgContent: {
    borderRadius: '50%',
    top: 0,
    left: 0,
    margin: 1,
  },
  actionIcon: {
    color: '#005EB2',
  },
  label: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'500 !important',
  },
  input: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'400 !important',
  },
  termDescription: {
    fontSize: '14px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'600 !important',
  },
  BottomBut: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  socialIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    '&:hover': {
      fill: 'black',
      WebkitTransition: 'fill 170ms ease-in-out',
    },
  },
  margin: {
    marginBottom: 24,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  dateTimePicker:{
    width:'100%',
    '& .MuiInputLabel-root': {
      fontSize: '12px',
      fontFamily: 'Open sans',
      fontWeight: '500',
    },
    '& input': {
      fontSize: '12px',
      fontFamily: 'Open sans',
      fontWeight: '400',
      padding:8.5,
    },
  },
  weekDays: {
    fontFamily: 'Open sans,sans-sarif !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
  },
  componentHeading: {
    fontSize: 20,
    paddingTop: 4,
    fontFamily: 'Open sans',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  backArrow: {
    fontSize: '35px !important',
    fontFamily: 'Open sans',
  },
  frame: {
    height: '100vh',
    width: '100%',
    border: '3px solid',
    borderColor: '#6e6e6e',
    borderRadius: '10px',
    overflow: 'auto',
  },
  iframe: {
    height: '95vh',
    width: '100%',
  },
  framePreview: {
    border: '2px solid',
    borderTop: '20px solid',
    borderColor: '#6e6e6e',
    borderRadius: '10px',
  },
  dotRed: {
    background: 'rgb(237, 89, 74)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotYellow: {
    background: 'rgb(253, 216, 0)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotGreen: {
    background: 'rgb(90, 192, 90)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '15px 15px 15px',
    zIndex: '10',
    alignItems: 'center',
    backgroundColor: '#6e6e6e',
    padding: '10px',
    paddingLeft: '1.5rem',
  },
  backMenu: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  mainDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '30px 0',
  },
  chilDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '85%',
    marginBottom: '10px',
  },
  contentImg: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    aspectRatio: 'auto',
    marginRight: '30px',
  },
  contentImgs: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    backgroundColor: 'white',
    border: '3px solid #005EB2',
    aspectRatio: 'auto',
    marginRight: '30px',
  },
  button: {
    marginRight: '20px',
    borderRadius: '50px',
    color: 'white',
  },
  tabs: {
    minWidth: 200,
    borderBottom: '2px solid #e8e8e8',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0%',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '20px',
      fontSize: 10,
    },
  },
  tab: {
    minWidth: 110,
    fontSize: 14,
    fontFamily: 'Open sans',
    [theme.breakpoints.down('sm')]: {
      minWidth: 8,
      fontSize: 10,
    },
    '&:hover': {
      backgroundColor: '#f6f7ff',
      color: 'black',
    },
  },
  backButton: {
    height: 'fit-content',
    alignSelf: 'center',
  },
  nextButton: {
    marginLeft: 'auto',
    height: 'fit-content',
    alignSelf: 'center',
  },
  navPaddingLocation: {
    height: 'calc( 111vh - 82px )',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  score: {
    backgroundColor: 'white',
    fontSize: 14,
    color: '#005EB2',
    border: 'solid 2px #005EB2',
  },
  paper: (props) => ({
    position: 'relative',
    zIndex: 1,
    marginTop: '2%',
    width: `${props.formwidth}%`,
    margin: 'auto',
  }),
  cal: (props) => ({
    width: '100%',
    height: 'auto',
    padding: 37,
    justifyContent: 'center',
    background: props.background,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      width: '100%',
      height: 'auto',
    },
    border: '1px solid lightgrey',
    boxShadow: 'none',
  }),
  brandLogoSize: {
    width: theme.spacing(22),
    height: 'auto',
    aspectRatio: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(18),
      height: 'auto',
      aspectRatio: 'auto',
    },
  },
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 650,
    [theme.breakpoints.down('sm')]: {
      width: 380,
    },
  },
  card: (props) => ({
    background: props.background,
    marginTop: 20,
  }),
  inquiryBox: {
    width: '100%',
    height: 45,
    border: '1px dashed lightgrey',
    paddingLeft: 10,
    paddingTop: 14,
    marginBottom: 10,
  },
  selectedBox: {
    width: '100%',
    height: 45,
    border: '1px dashed #005EB2',
    paddingTop: 14,
    paddingLeft: 10,
    marginBottom: 10,
    background: '#005EB2',
    color: '#fff',
  },
  accordionDetails: {
    display: 'block',
  },
  field: {
    width: '100%',
    fontFamily: 'Open sans',
    border: '2px solid grey',
  },
  stateField: {
    width: '100%',
    fontSize: 12,
    fontFamily: 'Open sans',
    border: '2px solid grey',
  },
  elementBox: {
    display: 'flex',
    alignItems: 'center',
    fontFamily:'Open sans',
  },
  multilineElementBox: {
    display: 'flex',
    alignItems: 'center',
    fontFamily:'Open sans',
  },
  elementBoxDoc: {
    display: 'flex',
    alignItems: 'center',
    fontFamily:'Open sans',
    marginTop: '2%',
  },
  requiredField: {
    color: 'red',
    position: 'absolute',
    bottom: 24,
    right: 0,
  },
  required: {
    color: 'red',
    marginLeft: 4,
  },
  submitBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectrows: {
    float: 'right',
    marginRight: 15,
    marginBottom: 15,
  },
  scheduleBtn: {
    borderRadius: '50px',
    marginTop: 5,
  },
  Cover: (props) => ({
    background: props.background,
  }),
  callOutTextHead: (props) => ({
    color: props.textColor,
    textAlign: props.textPosition,
  }),
  LowWeightText: (props) => ({
    fontWeight: '400 !important',
    color: props.textColor,
    textAlign: props.textPosition,
  }),
  shape: (props) => ({
    backgroundColor: props.color,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  shapeBg: (props) => ({
    backgroundColor: props.background,
    display: 'flex',
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  btnShape: (props) => ({
    backgroundColor: props.btnColor,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  btnShapeText: (props) => ({
    backgroundColor: props.btnText,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  shapeText: (props) => ({
    backgroundColor: props.textColor,
    width: 40,
    height: 40,
    border: '2px solid',
    borderRadius: '50%',
  }),
  radiogroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    marginTop: 24,
  },
  previewImage: {
    width: '90%',
    height: 'Auto',
    aspectRatio: 'Auto',
  },
  popupCard: (props) => ({
    maxWidth: 350,
    backgroundColor: props.background,
    margin: 'auto',
    width: '50%',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
  }),
  paper1: {
    padding: theme.spacing(3),
    maxWidth: 1000,
    maxHeight: 500,
  },
  Dropzone: {
    width: '100%',
    textAlign: 'center',
    padding: '17px',
    backgroundColor: 'lightGray',
    color: 'gray',
    marginBottom: 2
  },
  images: {
    width: 150,
    aspectRatio: 'auto',
  },
  error: {
    marginLeft: 5,
    color: 'red',
    fontSize: 15,
  },
  labelBox: {
    paddingBottom: 5,
    borderBottom: '1px solid grey',
    marginBottom: 10,
  },
  placeholderIcon: {
    fontSize: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  UploadDrop: {
    height: '120px',
    marginBottom: '30px',
    textAlign: 'center',
    padding: '8px',
    backgroundColor: 'lightGray',
    color: 'gray',
    marginTop: '2%',
  },
  note: {
    textAlign: 'justify',
    marginTop: 5,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      textAlign: 'justify',
    },
  },
  docTitle: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  docs: {
    fontSize: 15,
  },
  contactheader: {
    display: 'flex',
    alignItems: 'center',
  },
  Paper: {
    backgroundColor: '#e8e8ee',
    color: '#000000',
    padding: '0px 0px 0px 0px',
    fontWeight: 500,
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e8e8ee',
    },
  },
}));


