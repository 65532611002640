export const EventListByURL = `query EventListByUrl($pageurl: String!) {
    eventListByUrl(pageurl: $pageurl) {
      businessid
      createdAt
      isshowfooterlogo
      createdUser {
        userid
        roleid
        firstname
        lastname
        phonenumber
        twiliophonenumber
        email
        role
        lastlogin
        deleted
        usertype
        businessid
        locationid
        defaultcontactview
        defaulttaskview
        defaultdealview
        defaulttaskviewid
        defaultfolderid
        createdAt
        avatar
      }
      createdby
      eventlist
      eventlistid
      events {
        eventid
        eventtype
        name
        location
        description
        eventbookingurl
        eventlink
        eventcolor
        eventdaterangtype
        scheduleday
        scheduletype
        eventstartdate
        eventenddate
        eventduration
        beforeeventbuffer
        aftereventbuffer
        isemailreminders
        isemailfollowup
        isshowphoneicon
        isshowwhatsappicon
        isshowiosicon
        isshowandroidicon
        createdby
        updatedby
        deleted
        locationid
        status
        timezone
        createdAt
        updatedAt
        totalbooking
        futurebooking
        todaybooking
        ispaidevent
        amount
        isshowuseravatar
        workflowid
        termenable
        termdesc
        captchaenable
        formdata
        themecolor
        backgroundcolor
        textcolor
        calloutbuttontext
        calloutbtnposition
        btnthemecolor
        btntextcolor
        shorturl
        isshowlogo
        isshowfooterlogo
        fieldtextcolor
        createdUser {
          userid
          roleid
          firstname
          lastname
          phonenumber
          twiliophonenumber
          email
          role
          lastlogin
          deleted
          usertype
          businessid
          locationid
          defaultcontactview
          defaulttaskview
          defaultdealview
          defaulttaskviewid
          defaultfolderid
          createdAt
          avatar
        }
      }
      isdefault
      locationid
      name
      pageurl
      updatedAt
      updatedby
      userid
    }
  }`;