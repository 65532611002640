/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography, Tooltip, IconButton, Box, Link, CircularProgress, Paper, Link as UiLink,
  Dialog, DialogTitle, DialogContent, styled, createTheme, ThemeProvider, 
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { handleUndefined, postDataRequestPublic } from '../../../common/commonfunctions';
import config from '../../../config';
import useStyles from './styles';
import './inquiry.css';
import { GetInquiryByFormId, GetInquiryFormResponse, SendEditInquiryEmail } from '../../../Api Mutation/InquiryForm';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import { useGlobalState } from '../../../context/GlobalContext';

export default function PublicInquiryResponse() {
  const getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          padding: '5px 5px 5px 5px',
        },
      },
      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight: '100% !important',
        },
      },
      MuiTableCell: {
        head: {
          padding: '5px 5px 5px 5px',
        },
      },
    },
  });
  const classes = useStyles();
  const {
    formid,
  } = useParams([]);
  const globalContext = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [contactColumn, setContactColumn] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [modelOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (handleUndefined(formid)) {
      setLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: GetInquiryFormResponse,
        variables: {
          inquiryformid: formid,
        },
      }).then((res) => {
        if (res) {
          setPropertyData(JSON.parse(res?.data?.inquiryform?.formdata));
        }
      }).catch(() => setLoading(false));

      postDataRequestPublic(config.baseURLApi, {
        query: GetInquiryByFormId,
        variables: {
          inquiryformid: formid,
        },
      }).then((res) => {
        if (res) {
          const resData = res?.data?.inquiriesbyform;
          const newRes = [];
          resData?.filter((r) => r.ispublish).map((result) => {
            const newData = JSON.parse(result?.responsedata);
            const resp = [];
            newData.map((d) => {
              const resObj = {};
              resObj.custom_name = d?.custom_name;
              resObj.name = d?.name;
              if (d?.name?.split('_')?.[1] !== 'Country' && d?.name?.split('_')?.[1] !== 'State'
              && d?.name?.split('_')?.[1] !== 'Zip Code' && d?.name?.split('_')?.[1] !== 'Address1' && d?.name?.split('_')?.[1] !== 'Address2'
              && d?.name?.split('_')?.[1] !== 'City') {
                resObj.value = d?.value;
              } else if (d?.name?.split('_')?.[1] === 'Country') {
                setLoading(true);
                postDataRequestPublic(config.baseURLApi, {
                  query: '{countries{countryid,name}}',
                })
                  .then((response) => {
                    const val = response?.data?.countries?.find(
                      (r) => r.countryid === d?.value,
                    ).name;
                    resObj.value = val;
                    setTimeout(() => {
                      setLoading(false);
                    }, [1000]);
                  });
              } else if (d?.name?.split('_')?.[2] === 'text') {
                resObj.value = d?.value;
              } else if (d?.name?.split('_')?.[1] === 'State') {
                setLoading(true);
                postDataRequestPublic(config.baseURLApi, {
                  query: `{state(stateid:${d?.value}){name}}`,
                })
                  .then((response) => {
                    resObj.value = response?.data?.state?.name;
                    setTimeout(() => {
                      setLoading(false);
                    }, [1000]);
                  });
              } else {
                resObj.value = d?.value;
              }
              resp.push(resObj);
            });
            const finalObj = {
              inquiryformid: result?.inquiryformid,
              inquiryid: result?.inquiryid,
              contactid: result?.contactid,
              organizationid: result?.organizationid,
              response: resp,
              editLink: `${globalContext?.globalState?.currentLocation?.subdomain
                ? `https://${globalContext?.globalState?.currentLocation?.subdomain}${config.subDomainUrl}/if/${result?.locationid}/${result?.businessid}/${formid}?inquiryid=${result?.inquiryid}`
                : `${config.BixUrl}/if/${result?.locationid}/${result?.businessid}/${formid}?inquiryid=${result?.inquiryid}`}`,
            };
            newRes.push(finalObj);
          });
          setResponseData(newRes);
          setTimeout(() => {
            setLoading(false);
          }, [4000]);
        }
      }).catch(() => setLoading(false));
    }
  }, [formid]);

  const hoursColumn = [
    {
      label: 'Day',
      name: 'day',
      options: {
        textAlign: 'center',
        padding: '1px 1px 1px 1px',
        overflowWrap: 'break-word',
        filter: false,
      },
    },
    {
      label: 'Start Time',
      name: 'starttime',
      options: {
        filter: false,
        textAlign: 'center',
        padding: '1px 1px 1px 1px',
        overflowWrap: 'break-word',
      },
    },
    {
      label: 'End Time',
      name: 'endtime',
      options: {
        textAlign: 'center',
        padding: '1px 1px 1px 1px',
        overflowWrap: 'break-word',
        filter: false,
      },
    },
  ];

  
  const renderTooltipLabel = (label, name) => (
    <Tooltip title={name} classes={{ tooltip: classes.customTooltip }}>
      <span>{label}</span>
    </Tooltip>
  );

  useEffect(() => {
    const column = [{
      label: 'inquiryformid',
      name: 'inquiryformid',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'inquiryid',
      name: 'inquiryid',
      options: {
        filter: false,
        display: 'excluded',
      },
    }, {
      label: 'contactid',
      name: 'contactid',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'organizationid',
      name: 'organizationid',
      options: {
        filter: false,
        display: 'excluded',
      },
    }];

    if (propertyData.length > 0) {
      propertyData.map((property) => {
        if (property?.Page1) {
          for (let i = 1; i <= Object.keys(property)?.length; i++) {
            if (property[`Page${i}`] && property[`Page${i}`].length > 0) {
              property[`Page${i}`].map((prop) => {
                if (prop.objecttype !== 'Designing') {
                  const obj = {};
                  if (['Address1', 'Address2', 'Country', 'State', 'City', 'Zip Code'].includes(prop.propertyname)) {
                    const label = (`${prop.objecttype} ${prop?.name}`)?.charAt(0)?.toUpperCase() + (`${prop.objecttype} ${prop?.name}`)?.slice(1);
                    const name = `${prop.objecttype} ${prop?.name}`;
                    obj.label = renderTooltipLabel(label, name);
                    obj.name = `${prop.objecttype}_${prop?.name}`;
                    obj.options = {
                      textAlign: 'center',
                      padding: '1px 1px 1px 1px',
                      overflowWrap: 'break-word',
                      display: false,
                    };
                  } else {
                    if (prop.propertyname === 'Upload Document') {
                      obj.label = renderTooltipLabel(prop?.value?.substring(0, 25), prop?.value);
                      obj.name = prop?.value;
                    } else {
                      obj.label = renderTooltipLabel((prop?.name?.charAt(0)?.toUpperCase()
                    + prop?.name?.slice(1))?.substring(0, 25), prop?.name);
                      obj.name = prop?.name;
                      obj.dbfield = prop?.dbfieldname;
                    }

                    obj.options = {
                      textAlign: 'center',
                      padding: '1px 1px 1px 1px',
                      overflowWrap: 'break-word',
                      filter: !((property.propertyname === 'Upload Document' || property.propertyname === 'availablehours')),
                      customBodyRender: (res) => {
                        if (typeof res === 'object') {
                          if (res.length && res.length > 0) {
                            if (typeof res[0] === 'string') {
                              return (
                                <span>{res.toString()}</span>
                              );
                            }
                            return (
                              <UiLink
                                onClick={() => {
                                  setModalOpen(true);
                                  setDisplayData(res);
                                }}
                              >
                                Click here to view available hours
                              </UiLink>
                            );
                          } if (res?.title) {
                            return (
                              <UiLink
                                onClick={() => {
                                  setModalOpen(true);
                                  setDisplayData(res);
                                }}
                              >
                                {res?.title}
                              </UiLink>
                            );
                          }
                        } else if (typeof res === 'boolean') {
                          return (
                            <span>{res.toString()}</span>
                          );
                        } else if (res?.includes('http')) {
                          return (
                            <Link href={res} target="_blank" download>
                              {res}
                            </Link>
                          );
                        } else {
                          return (
                            <span>{res}</span>
                          );
                        }
                        return '';
                      },
                      display: false,
                    };
                  }

                  if (obj.dbfield) {
                    if (['firstname', 'primaryemail', 'phonenumber', 'name', 'orgemail', 'orgphonenumber'].includes(obj.dbfield)) {
                      obj.options = {
                        display: true,
                      };
                    }
                  } else if (['First Name', 'Email', 'Phone Number', 'Organization Name', 'Organization Email', 'Organization Phone Number'].includes(obj.name)) {
                    obj.options = {
                      display: true,
                    };
                  }

                  column.push(obj);
                }
              });
            }
          }
        } else if (property.objecttype !== 'Designing') {
          const obj = {};
          if (['Address1', 'Address2', 'Country', 'State', 'City', 'Zip Code'].includes(property.propertyname)) {
            const label = (`${property.objecttype} ${property?.name}`)?.charAt(0)?.toUpperCase() + (`${property.objecttype} ${property?.name}`)?.slice(1);
            const name = `${property.objecttype} ${property?.name}`;
            obj.label = renderTooltipLabel(label, name);
            obj.name = `${property.objecttype}_${property?.name}`;
            obj.options = {
              textAlign: 'center',
              padding: '1px 1px 1px 1px',
              overflowWrap: 'break-word',
              display: false,
            };
          } else {
            if (property.propertyname === 'Upload Document') {
              obj.label = renderTooltipLabel(property?.value?.substring(0, 25), property?.value);
              obj.name = property?.value;
            } else {
              obj.label = renderTooltipLabel((property?.name?.charAt(0)?.toUpperCase()
            + property?.name?.slice(1))?.substring(0, 25), property?.name);
              obj.name = property?.name;
              obj.dbfield = property?.dbfieldname;
            }

            obj.options = {
              filter: !((property.propertyname === 'Upload Document' || property.propertyname === 'availablehours')),
              textAlign: 'center',
              padding: '1px 1px 1px 1px',
              overflowWrap: 'break-word',
              customBodyRender: (res) => {
                if (typeof res === 'object') {
                  if (res.length && res.length > 0) {
                    if (typeof res[0] === 'string') {
                      return (
                        <span>{res.toString()}</span>
                      );
                    }
                    return (
                      <UiLink
                        onClick={() => {
                          setModalOpen(true);
                          setDisplayData(res);
                        }}
                      >
                        Click here to view available hours
                      </UiLink>
                    );
                  } if (res?.title) {
                    return (
                      <UiLink
                        onClick={() => {
                          setModalOpen(true);
                          setDisplayData(res);
                        }}
                      >
                        {res?.title}
                      </UiLink>
                    );
                  }
                } else if (typeof res === 'boolean') {
                  return (
                    <span>{res.toString()}</span>
                  );
                } else if (res?.includes('http')) {
                  return (
                    <Link href={res} target="_blank" download>
                      {res}
                    </Link>
                  );
                } else {
                  return (
                    <span>{res}</span>
                  );
                }
                return '';
              },
              display: false,
            };
          }

          if (obj.dbfield) {
            if (['firstname', 'primaryemail', 'phonenumber', 'name', 'orgemail', 'orgphonenumber'].includes(obj.dbfield)) {
              obj.options = {
                display: true,
              };
            }
          } else if (['First Name', 'Email', 'Phone Number', 'Organization Name', 'Organization Email', 'Organization Phone Number'].includes(obj.name)) {
            obj.options = {
              display: true,
            };
          }

          column.push(obj);
        }
      });
    }

    column.push({
      label: 'Actions',
      name: 'editLink',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (res, rowData) => {
          if (res) {
            return (
              <>
                <Tooltip title="Edit Details">
                  <IconButton
                    color="primary"
                    size="medium"
                    className={classes.actionIcon}
                    component="span"
                    onClick={() => {
                      setLoading(true);
                      postDataRequestPublic(config.baseURLApi, {
                        query: SendEditInquiryEmail,
                        variables: {
                          inquiryformid: rowData?.rowData[0],
                          inquiryid: rowData?.rowData[1],
                          contactid: rowData?.rowData[2],
                          organizationid: rowData?.rowData[3],
                        },
                      }).then((newRes) => {
                        if (newRes?.data?.sendeditinquiryemail) {
                          showNotification('Edit details email sent successfully.', 'success');
                          setTimeout(() => {
                            setLoading(false);
                          }, [1000]);
                        }
                      }).catch(() => setLoading(false));
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
          return '';
        },
      },
    });

    setContactColumn(column);

    const res = [];

    if (responseData && responseData.length > 0) {
      responseData.map((response) => {
        const resObj = {};
        resObj.inquiryformid = response.inquiryformid;
        resObj.inquiryid = response.inquiryid;
        resObj.contactid = response.contactid;
        resObj.organizationid = response.organizationid;
        resObj.editLink = response.editLink;
          response?.response.map((resObje) => {
            if (resObje?.name?.split('_')?.[1]) {
              if (['Address1', 'Address2', 'Country', 'State', 'City', 'Zip Code'].includes(resObje?.name?.split('_')?.[1])) {
                resObj[resObje?.name] = resObje?.value;
              } else {
                resObj[resObje?.name?.split('_')?.[1]] = resObje?.value;
              }
            } else {
              resObj[resObje?.name] = resObje?.value;
            }
          });
          res.push(resObj);
      });
    }
    setContactData(res);
  }, [propertyData, responseData]);

  const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: '#e8e8ee',
    color: '#000000',
    padding: '0px 0px 0px 0px',
    fontWeight: 500,
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e8e8ee',
    },
  }));

  const CloseBtn = styled(IconButton)(({ theme}) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }))

  return (
    <div style={{ padding: 24}}>
      <ThemeProvider theme={getMuiTheme()}>
        {loading ? <center><CircularProgress /></center> : (
          <MUIDataTable
            title={(
              <div>
                <Box className={classes.contactheader}>
                  <Typography variant="h6">
                    Contacts
                  </Typography>
                </Box>
              </div>
            )}
            columns={contactColumn}
            data={contactData}
            rowsPerPage
            className="responsive-table"
            options={{
              sort: true,
              fixedHeader: true,
              tableBodyHeight: '62vh',
              filter: true,
              print: false,
              search: true,
              pagination: true,
              download: false,
              responsive: 'standard',
              resizableColumns: true,
              draggableColumns: {
                enabled: true,
              },
              selectableRows: 'none',
            }}
          />
        )}
      </ThemeProvider>
      <Dialog
        open={modelOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <StyledPaper className={classes.Paper} size="small">
          <DialogTitle id="form-dialog-title">
            {displayData?.title || 'Available Hours'}
          </DialogTitle>
          <CloseBtn
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setDisplayData([]);
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </CloseBtn>
        </StyledPaper>
        <br />
        <DialogContent>
          {displayData.length > 0 ? (
            <ThemeProvider theme={getMuiTheme()}>
              {loading ? <center><CircularProgress /></center> : (
                <MUIDataTable
                  title={(
                    <div>
                      <Box className={classes.contactheader}>
                        <Typography variant="h6">
                          Available Hours
                        </Typography>
                      </Box>
                    </div>
                      )}
                  columns={hoursColumn}
                  data={displayData}
                  rowsPerPage
                  options={{
                    sort: true,
                    fixedHeader: true,
                    filter: false,
                    print: false,
                    search: true,
                    pagination: true,
                    download: false,
                    responsive: 'standard',
                    resizableColumns: true,
                    draggableColumns: {
                      enabled: true,
                    },
                    selectableRows: 'none',
                  }}
                />
              )}
            </ThemeProvider>
          ) : (
            <table style={{ width: '100%', border: '1px solid lightgrey' }}>
              <tr style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                <th style={{ border: '1px solid lightgrey' }}>Title</th>
                <th style={{ border: '1px solid lightgrey' }}>Description</th>
                <th style={{ border: '1px solid lightgrey' }}>Document</th>
              </tr>
              <tr style={{ textAlign: 'center', border: '1px solid lightgrey' }}>
                <td style={{ border: '1px solid lightgrey' }}>
                  {displayData?.title}
                </td>
                <td style={{ border: '1px solid lightgrey' }}>
                  {displayData?.description}
                </td>
                <td style={{ border: '1px solid lightgrey' }}>
                  <Link href={displayData?.documentLink} target="_blank" download>
                    Click here
                  </Link>
                </td>
              </tr>
            </table>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
