/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form } from 'react-final-form';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import {
  Component, Country, State, timeFormat,
} from '../../../common/const';
import {
  emailValidate, phoneCountryDialCodeValidate,
} from '../../../common/FormValidation';
import { handleUndefined, postDataRequestPublic } from '../../../common/commonfunctions';
import config from '../../../config';
import useStyles from './styles';
import './inquiry.css';
import './styles.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers';
import {
  Box, Button, Checkbox, Divider,
  FormControl, FormControlLabel, FormGroup, Grid,
  InputLabel, MenuItem, Radio, RadioGroup, Rating,
  Select, TextField, Typography, styled, Chip, Link,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import { UploadImage } from '../../../Api Mutation/InquiryForm';
import Dropzone from 'react-dropzone';
import DescriptionIcon from '@mui/icons-material/Description';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackupIcon from '@mui/icons-material/Backup';


const header = {
  authorization: config.staticToken || null,
  'Apollo-Require-Preflight': 'true',
}

const httpLink = createUploadLink({
  uri: config.contactApi,
  headers: header,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: header,
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
});

const FormGenerator = ({
  content, onFormSubmit, rows, btnName, colorCode,
  backgroundColorCode,
  foreColor,
  fontColorCode,
  btnPos,
  formWidth,
  btnThemeColor,
  btnTextColor,
  termDesc,
  enableTerm,
  radioPos,
  checkBoxPos,
  enableCaptcha,
  visible,
  setVisible,
  suggestContact,
  contact,
  setContact,
  formData,
  isAppointment,
  isAppointmentPublic,
  defaultCountryPhoneCode,
  eventType,
}) => {
  const classes = useStyles({
    color: colorCode,
    background: backgroundColorCode,
    textColor: foreColor,
    btnPosition: btnPos,
    formwidth: formWidth,
    btnColor: btnThemeColor,
    btnText: btnTextColor,
    customTextField: {
      '& input::placeholder': {
        fontSize: '20px',
      },
    },
  });

  const StyledButton = styled(Button)(() => ({
    backgroundColor: btnThemeColor,
    color: btnTextColor,
    textTransform: 'capitalize',
    fontSize: '12px',
    fontFamily: 'Open sans',
    fontWeight: 500,
  }));

  const PreviewButton = styled(Button)(() => ({
    float: 'left',
    backgroundColor: btnThemeColor,
    marginRight: 10,
    color: btnTextColor,
    textTransform: 'capitalize',
    fontSize: '12px',
    fontFamily: 'Open sans',
    fontWeight: 500,
  }));

  const StyledBox = styled(Box)(() => ({
    textAlign: btnPos,
    paddingTop: '10px',
  }));

  const CheckBoxTypo = styled(Typography)(() => ({
    fontFamily: 'Open sans',
    fontWeight: 600,
    fontSize: 14,
  }));

  const LabelTypo = styled(Typography)(() => ({
    fontFamily: 'Open sans',
  }));

  const LabelBox = styled(Typography)(() => ({
    paddingBottom: 5,
    borderBottom: '1px solid grey',
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 500,
    fontfamily: 'Open Sans',
  }));

  const ScheduleButton = styled(Button)(() => ({
    backgroundColor: btnThemeColor,
    color: btnTextColor,
    textTransform: 'capitalize',
    fontSize: '12px',
    fontFamily: 'Open sans',
    fontWeight: 500,
    width: '100%',
    borderRadius: 15,
  }));

  const GuestButton = styled(Button)(() => ({
    textTransform: 'capitalize',
    fontSize: '12px',
    fontFamily: 'Open sans',
    fontWeight: 500,
    width: '100%',
    borderRadius: 15,
    marginTop: 10,
    marginBottom: 15,
  }));

  const dobObj = content?.find((con) => con?.name?.toLowerCase()?.includes('dob'));
  const [dob, setDOB] = useState(
    dobObj?.objecttype ? {
      [`${dobObj?.objecttype}_${dobObj?.name}`]: moment(new Date()).format('YYYY-MM-DD'),
    } : '',
  );
  const startDateObj = content?.find((con) => con?.name?.toLowerCase()?.includes('business start'));
  const [businessStartDate, setBusinessStartDate] = useState(
    startDateObj?.objecttype ? {
      [`${startDateObj?.objecttype}_${startDateObj?.name}`]: moment(new Date()).format('YYYY-MM-DD'),
    } : '',
  );
  const birthDateObj = content?.find((con) => con?.name?.toLowerCase()?.includes('birth'));
  const [dateOfBirth, setDateOfBirth] = useState(
    birthDateObj?.objecttype ? {
      [`${birthDateObj?.objecttype}_${birthDateObj?.name}`]: moment(new Date()).format('YYYY-MM-DD'),
    } : '',
  );
  const [activeTabId, setActiveTabId] = useState(1);
  const [steps, setSteps] = useState([{
    label: 1,
  }]);
  const [countryArr, setCountryArr] = useState([]);
  const countryFilterArr = eventType === 'multi-page'
    ? content[0][`Page${activeTabId}`]?.filter((con) => con?.name?.toLowerCase()?.includes('country'))
    : content?.filter((con) => con?.name?.toLowerCase()?.includes('country'));
  const conCountryObj = countryFilterArr?.find((con) => con?.objecttype?.includes('contact'));
  const [contactCountryid, setContactCountryId] = useState({ [`${conCountryObj?.objecttype ? conCountryObj?.objecttype : ''}${conCountryObj?.name ? `_${conCountryObj?.name}` : ''}`]: Country.USA });
  const orgCountryObj = countryFilterArr?.find((con) => con?.objecttype?.includes('organization'));
  const [orgCountryid, setOrgCountryId] = useState({ [`${orgCountryObj?.objecttype ? orgCountryObj?.objecttype : ''}${orgCountryObj?.name ? `_${orgCountryObj?.name}` : ''}`]: Country.USA });
  const [contactStateArr, setContactStateArr] = useState([]);
  const [orgStateArr, setOrgStateArr] = useState([]);
  const stateFilterArr = eventType === 'multi-page'
    ? content[0][`Page${activeTabId}`]?.filter((con) => con?.name?.toLowerCase()?.includes('state'))
    : content?.filter((con) => con?.name?.toLowerCase()?.includes('state'));
  const conStateObj = stateFilterArr?.find((con) => con?.objecttype?.includes('contact'));
  const [contactStateid, setContactStateId] = useState({ [`${conStateObj?.objecttype ? conStateObj?.objecttype : ''}${conStateObj?.name ? `_${conStateObj?.name}` : ''}`]: State.Alabama });
  const orgStateObj = stateFilterArr?.find((con) => con?.objecttype?.includes('organization'));
  const [orgStateid, setOrgStateId] = useState({ [`${orgStateObj?.objecttype ? orgStateObj?.objecttype : ''}${orgStateObj?.name ? `_${orgStateObj?.name}` : ''}`]: State.Alabama });
  const [dropdownSel, setDropDownSel] = useState('');
  const [multiConArr, setMultiConArr] = useState([]);
  const [multiOrgArr, setMultiOrgArr] = useState([]);
  const [updatedVal, setUpdatedVal] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const [images, setImages] = useState([]);
  const [businessLogo, setBusinessLogo] = useState([]);
  const [day, setDays] = React.useState({
    SUN: false,
    MON: true,
    TUE: true,
    WED: true,
    THU: true,
    FRI: true,
    SAT: false,
  });
  const [sunTimeStart, setSunTimeStart] = useState(dayjs(moment().format('YYYY-MM-DD 09:00:00 Z')));
  const [monTimeStart, setMonTimeStart] = useState(dayjs(moment().format('YYYY-MM-DD 09:00:00 Z')));
  const [tueTimeStart, setTueTimeStart] = useState(dayjs(moment().format('YYYY-MM-DD 09:00:00 Z')));
  const [wenTimeStart, setWenTimeStart] = useState(dayjs(moment().format('YYYY-MM-DD 09:00:00 Z')));
  const [thuTimeStart, setThuTimeStart] = useState(dayjs(moment().format('YYYY-MM-DD 09:00:00 Z')));
  const [friTimeStart, setFriTimeStart] = useState(dayjs(moment().format('YYYY-MM-DD 09:00:00 Z')));
  const [satTimeStart, setSatTimeStart] = useState(dayjs(moment().format('YYYY-MM-DD 09:00:00 Z')));
  const [sunTimeEnd, setSunTimeEnd] = useState(dayjs(moment().format('YYYY-MM-DD 17:00:00 Z')));
  const [monTimeEnd, setMonTimeEnd] = useState(dayjs(moment().format('YYYY-MM-DD 17:00:00 Z')));
  const [tueTimeEnd, setTueTimeEnd] = useState(dayjs(moment().format('YYYY-MM-DD 17:00:00 Z')));
  const [wenTimeEnd, setWenTimeEnd] = useState(dayjs(moment().format('YYYY-MM-DD 17:00:00 Z')));
  const [thuTimeEnd, setThuTimeEnd] = useState(dayjs(moment().format('YYYY-MM-DD 17:00:00 Z')));
  const [friTimeEnd, setFriTimeEnd] = useState(dayjs(moment().format('YYYY-MM-DD 17:00:00 Z')));
  const [satTimeEnd, setSatTimeEnd] = useState(dayjs(moment().format('YYYY-MM-DD 17:00:00 Z')));
  const {
    SUN, MON, TUE, WED, THU, FRI, SAT,
  } = day;

  const getMuiTheme = (elem) => createTheme({
    overrides: {
      Mui: {
        focused: {
          background: 'red',
          marginTop: elem.name.length >= 94 ? 24 : 0,
          fontSize: 12,
          color: fontColorCode,
          fontFamily: 'open sans',
        },
      },
    },
  });

  const handleChangeTime = (event) => {
    setDays({ ...day, [event.target.name]: event.target.checked });
  };

  const handlePrev = () => {
    if (activeTabId > 0) {
      setActiveTabId(activeTabId - 1);
    }
  };

  useEffect(() => {
    if (eventType === 'multi-page') {
      if (content && content[0]) {
        const arr = Object.keys(content[0]);
        if (arr.length > 0) {
          const newStep = [...steps];
          // eslint-disable-next-line no-plusplus
          for (let i = 2; i <= arr.length; i++) {
            const step = {};
            step.label = i;
            newStep.push(step);
            setSteps(newStep);
          }
        }
      }
    }
  }, [eventType]);

  useEffect(() => {
    postDataRequestPublic(config.baseURLApi, {
      query: '{countries{countryid,name}}',
    })
      .then((res) => {
        setCountryArr(res.data.countries);
      });
  }, []);

  useEffect(() => {
    postDataRequestPublic(config.baseURLApi, {
      query: 'query statebycountry($countryId:Int!){statesbycountry(countryid:$countryId){stateid,name,code}}',
      variables: {
        countryId: parseInt(Object.keys(contactCountryid)?.[0] !== ''
          && contactCountryid[Object.keys(contactCountryid)]
          ? contactCountryid[Object.keys(contactCountryid)]
          : Country.USA, 10),
      },
    })
      .then((res) => {
        setContactStateArr(res?.data?.statesbycountry);
      });
  }, [contactCountryid]);

  useEffect(() => {
    postDataRequestPublic(config.baseURLApi, {
      query: 'query statebycountry($countryId:Int!){statesbycountry(countryid:$countryId){stateid,name,code}}',
      variables: {
        countryId: parseInt(Object.keys(orgCountryid)?.[0] !== ''
          && orgCountryid[Object.keys(orgCountryid)]
          ? orgCountryid[Object.keys(orgCountryid)] : Country.USA, 10),
      },
    })
      .then((res) => {
        setOrgStateArr(res?.data?.statesbycountry);
      });
  }, [orgCountryid]);

  const getElement = (elem) => {
    switch (Component[elem.fieldtype]) {
      case 'Textbox':
        return (
          <FormControl             style={{ color: fontColorCode, foreColor: fontColorCode }}
          fullWidth size="small" variant="outlined">
            <Box style={{ color: fontColorCode }} className={classes.elementBox}>
              <TextField
                className={classes.field}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                  style: { fontSize: 12, color: fontColorCode, backgroundColor: colorCode }
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  style: { fontSize: 12, color: fontColorCode, foreColor: fontColorCode },
                }}
                fullWidth
                style={{ background: colorCode, color: fontColorCode, foreColor: fontColorCode }}
                size="small"
                variant="outlined"
                type="text"
                label={elem?.name}
                value={(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : ''}
                onChange={(e) => {
                  const data = {};
                  const val = [...updatedVal];
                  data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
        case 'Multiline':
          return (
            <FormControl fullWidth size="small" variant="outlined">
              <Box className={classes.elementBox}>
                <ThemeProvider theme={getMuiTheme(elem)}>
                  <TextField
                    className={classes.field}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                      style: {
                        fontSize: 12,
                        whiteSpace: 'break-spaces',
                      }
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                      style: { fontSize: 12, color: fontColorCode },
                    }}
                    style={{ background: colorCode, color: fontColorCode }}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label={elem?.name}
                    multiline
                    minRows={5}
                    value={
                      updatedVal.find(
                        (val) =>
                          Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`
                      )?.[`${elem?.objecttype}_${elem?.name}`] || ''
                    }
                    onChange={(e) => {
                      const data = {};
                      const val = [...updatedVal];
                      data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                      val.forEach((v) => {
                        if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                          const index = val.indexOf(v);
                          val.splice(index, 1);
                        }
                      });
                      val.push(data);
                      setUpdatedVal(val);
                    }}
                  />
                </ThemeProvider>
              </Box>
              {elem?.isrequired && (
                <Typography className={classes.requiredField} variant="body1">
                  *
                </Typography>
              )}
            </FormControl>
          );
        
      case 'Single checkbox':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox required={elem?.isrequired} checked={updatedVal?.length > 0 && updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]} />}
                  label={(
                    <Box className={classes.elementBox}>
                      <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
                      {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
                    </Box>
                  )}
                  onChange={(e) => {
                    const data = {};
                    const val = [...updatedVal];
                    data[`${elem?.objecttype}_${elem?.name}`] = e.target.checked;
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(data);
                    setUpdatedVal(val);
                  }}
                />
              </FormGroup>
            </Box>
          </FormControl>
        );
      case 'Multiple checkbox':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
              {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
            </Box>
            <Box className={classes.elementBox}>
              <FormGroup
                style={{ flexDirection: checkBoxPos === 'horizontal' ? 'row' : 'column' }}
              >
                {elem?.options?.length > 0 && elem?.options?.map((el) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={updatedVal?.length > 0
                          && updatedVal?.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]?.length > 0
                          && updatedVal?.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]?.find((res) => res === el.label)}
                      />
                    )}
                    sx={{ fontFamily: 'Open sans', fontSize: '14px' }}
                    label={<CheckBoxTypo>{el.label}</CheckBoxTypo>}
                    value={el.label}
                    onChange={(e) => {
                      const data = {};
                      const val = [...updatedVal];
                      if (elem?.objecttype === 'contact') {
                        const arr = [...multiConArr];
                        if (e.target.checked) {
                          arr.push(e.target.value);
                        } else {
                          const index = arr.findIndex((mui) => mui === e.target.value);
                          if (index > -1) {
                            arr.splice(index, 1);
                          }
                        }
                        setMultiConArr(arr);
                        data[`${elem?.objecttype}_${elem?.name}`] = arr;
                      } else {
                        const arr = [...multiOrgArr];
                        if (e.target.checked) {
                          arr.push(e.target.value);
                        } else {
                          const index = arr.findIndex((mui) => mui === e.target.value);
                          if (index > -1) {
                            arr.splice(index, 1);
                          }
                        }
                        setMultiOrgArr(arr);
                        data[`${elem?.objecttype}_${elem?.name}`] = arr;
                      }

                      val.map((v) => {
                        if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                          const index = val.indexOf(v);
                          val.splice(index, 1);
                        }
                      });
                      val.push(data);
                      setUpdatedVal(val);
                      setIsRequired(false);
                    }}
                  />
                ))}
                {elem?.isrequired && isRequired && multiConArr.length === 0 && elem?.objecttype === 'contact' && (<Typography className={classes.required} variant="body1">Please select any one</Typography>)}
                {elem?.isrequired && isRequired && multiOrgArr.length === 0 && elem?.objecttype === 'organization' && (<Typography className={classes.required} variant="body1">Please select any one</Typography>)}
              </FormGroup>
            </Box>
          </FormControl>
        );
      case 'Dropdown':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              {((elem?.name?.toLowerCase()?.includes('country') && countryArr?.length > 0)
                || (elem?.name?.toLowerCase()?.includes('state')
                  && elem?.objecttype?.includes('contact') && contactStateArr?.length > 0)
                || (elem?.name?.toLowerCase()?.includes('state') && elem?.objecttype?.includes('organization')
                  && orgStateArr?.length > 0) || (elem?.options?.length > 0)) ? (
                <>
                  <InputLabel id={`${elem?.objecttype}_${elem?.name}`} style={{ background: colorCode }} sx={{ fontSize: '12px', fontFamily: 'open sans', fontWeight: 500 }}>{elem?.name}
                  </InputLabel>
                  <Select
                    labelId={`${elem?.objecttype}_${elem?.name}`}
                    id="demo-simple-select"
                    label={elem?.name}
                    sx={{
                      fontSize: 12,
                      fontFamily: 'Open sans',
                      color: 'black'
                    }}
                    fullWidth
                    style={{ background: colorCode, color: fontColorCode }}
                    required={elem?.isrequired}
                    value={
                      updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]
                        ? updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]
                        : elem?.name?.toLowerCase()?.includes('country')
                          ? Country.USA
                          : elem?.name?.toLowerCase()?.includes('state')
                            ? State.Alabama
                            : dropdownSel[`${elem?.objecttype}_${elem?.name}`]
                    }
                    onChange={(e) => {
                      const obj = {};
                      const val = [...updatedVal];
                      if (elem?.name?.toLowerCase()?.includes('country')) {
                        if (elem?.objecttype?.includes('contact')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setContactCountryId(obj);
                        }
                        if (elem?.objecttype?.includes('organization')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setOrgCountryId(obj);
                        }
                      } else if (elem?.name?.toLowerCase()?.includes('state')) {
                        if (elem?.objecttype?.includes('contact')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setContactStateId(obj);
                        }
                        if (elem?.objecttype?.includes('organization')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setOrgStateId(obj);
                        }
                      }
                      obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                      setDropDownSel(obj);
                      val.map((v) => {
                        if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                          const index = val.indexOf(v);
                          val.splice(index, 1);
                        }
                      });
                      val.push(obj);
                      setUpdatedVal(val);
                    }}
                  >
                    {elem?.name?.toLowerCase()?.includes('country') && (
                      countryArr.length > 0 && countryArr.map((country) => (
                        <MenuItem value={country.countryid} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{country.name}</MenuItem>
                      ))
                    )}
                    {elem?.name?.toLowerCase()?.includes('state') && elem?.objecttype?.includes('contact') && (
                      contactStateArr.length > 0 && contactStateArr.map((state) => (
                        <MenuItem value={state.stateid} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{state.name}</MenuItem>
                      ))
                    )}
                    {elem?.name?.toLowerCase()?.includes('state') && elem?.objecttype?.includes('organization') && (
                      orgStateArr.length > 0 && orgStateArr.map((state) => (
                        <MenuItem value={state.stateid} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{state.name}</MenuItem>
                      ))
                    )}
                    {elem?.options?.length > 0 && elem?.options?.map((el) => (
                      <MenuItem value={el.label}>{el.label}</MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <TextField
                  className={classes.field}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                    style: { fontSize: 12, color: fontColorCode, backgroundColor: colorCode }
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                    style: { fontSize: 12, color: fontColorCode, foreColor: fontColorCode },
                  }}
                  fullWidth
                  size="small"
                  style={{ background: colorCode, color: fontColorCode, foreColor: fontColorCode }}
                  variant="outlined"
                  type="text"
                  label={elem?.name}
                  value={(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}_text`)?.[`${elem?.objecttype}_${elem?.name}_text`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}_text`)?.[`${elem?.objecttype}_${elem?.name}_text`]) : ''}
                  onChange={(e) => {
                    const obj = {};
                    const val = [...updatedVal];
                    if (elem?.name?.toLowerCase()?.includes('country')) {
                      if (elem?.objecttype?.includes('contact')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setContactCountryId(obj);
                      }
                      if (elem?.objecttype?.includes('organization')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setOrgCountryId(obj);
                      }
                    } else if (elem?.name?.toLowerCase()?.includes('state')) {
                      if (elem?.objecttype?.includes('contact')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setContactStateId(obj);
                      }
                      if (elem?.objecttype?.includes('organization')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setOrgStateId(obj);
                      }
                    }
                    obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                    setDropDownSel(obj);
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}_text`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(obj);
                    setUpdatedVal(val);
                  }}
                />
              )}
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Radio':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <FormControl>
                <Box className={classes.elementBox}>
                  <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
                  {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
                </Box>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  style={{ flexDirection: radioPos === 'horizontal' ? 'row' : 'column'  }}
                  name={`${elem?.objecttype}_${elem?.name}`}
                  onChange={(e) => {
                    const data = {};
                    const val = [...updatedVal];
                    data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(data);
                    setUpdatedVal(val);
                  }}
                >
                  {elem?.options?.length > 0 && elem?.options?.map((el) => (
                    <FormControlLabel
                      value={el.label}
                      control={<Radio required={elem?.isrequired} />}
                      label={<CheckBoxTypo>{el.label}</CheckBoxTypo>}
                      checked={updatedVal?.length > 0 && updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`] === el.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </FormControl>
        );
      case 'Date picker':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                InputLabelProps={{ style: { fontSize: 12, color: fontColorCode } }}
                style={{ background: colorCode, color: colorCode }}
                  className={classes.dateTimePicker}
                  value={
                    new Date(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`])
                      ? new Date(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`])
                      : elem?.name?.toLowerCase()?.includes('dob')
                        ? (dob[`${elem?.objecttype}_${elem?.name}`] ? new Date(dob[`${elem?.objecttype}_${elem?.name}`]) : null)
                        : elem?.name?.toLowerCase()?.includes('birth')
                          ? (dateOfBirth[`${elem?.objecttype}_${elem?.name}`] ? new Date(dateOfBirth[`${elem?.objecttype}_${elem?.name}`]) : null)
                          : elem?.name?.toLowerCase()?.includes('business start')
                            ? (businessStartDate[`${elem?.objecttype}_${elem?.name}`] ? new Date(businessStartDate[`${elem?.objecttype}_${elem?.name}`]) : null)
                            : null
                  }
                  label={elem?.name}
                  format="yyyy/MM/dd"
                  ampm={false}
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const obj = {};
                    const val = [...updatedVal];
                    if (elem?.name?.toLowerCase()?.includes('dob')) {
                      obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                      setDOB(obj);
                    } else if (elem?.name?.toLowerCase()?.includes('birth')) {
                      obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                      setDateOfBirth(obj);
                    } else if (elem?.name?.toLowerCase()?.includes('business start')) {
                      obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                      setBusinessStartDate(obj);
                    }
                    obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(obj);
                    setUpdatedVal(val);
                  }}
                />
              </LocalizationProvider>
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Number':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <TextField
                className={classes.field}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                  style: { fontSize: 12, color: fontColorCode },
                }}
                style={{ background: colorCode, color: colorCode }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                size="small"
                variant="outlined"
                fullWidth
                type="number"
                label={elem?.name}
                value={(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : ''}
                onChange={(e) => {
                  const data = {};
                  const val = [...updatedVal];
                  data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Phone number':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <TextField
                className={classes.field}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                  style: { fontSize: 12, color: fontColorCode },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  style: { fontSize: 12, color: fontColorCode },
                }}
                style={{ background: colorCode, color: colorCode }}
                fullWidth
                size="small"
                variant="outlined"
                type="number"
                label={elem?.name}
                value={(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : ''}
                onChange={(e) => {
                  const data = {};
                  const val = [...updatedVal];
                  data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Rating':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
              {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
            </Box>
            <Rating
              name={`${elem?.objecttype}_${elem?.name}`}
              value={(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : ''}
              precision={0.1}
              onChange={(e) => {
                const data = {};
                const val = [...updatedVal];
                data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                val.map((v) => {
                  if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                    const index = val.indexOf(v);
                    val.splice(index, 1);
                  }
                });
                val.push(data);
                setUpdatedVal(val);
                setIsRequired(false);
              }}
            />
            {elem?.isrequired && isRequired && elem?.objecttype === 'contact' && (<Typography className={classes.required} variant="body1">This field is required</Typography>)}
            {elem?.isrequired && isRequired && elem?.objecttype === 'organization' && (<Typography className={classes.required} variant="body1">This field is required</Typography>)}
          </FormControl>
        );
      case 'Paragraph':
        return (
          <Box className={classes.elementBox}>
            <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
          </Box>
        );
      case 'Divider':
        return (
          <Divider />
        );
      case 'Label':
        return (
          <Box>
            <LabelTypo dangerouslySetInnerHTML={{ __html: elem?.value }} />
          </Box>
        );
      case 'Image':
        if (elem.propertyname === 'Business Logo') {
          setBusinessLogo(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]);
        }
        if (elem.propertyname === 'Images') {
          setImages(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]);
        }
        return (
          <FormControl fullWidth size="small" variant="outlined">
            {elem.objecttype === 'organization'
              ? (
                <>
                  <Box>
                    <LabelBox>{`Upload ${elem?.name}`}</LabelBox>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box className="mb-4">
                        <Box className={classes.Dropzone}>
                          <Dropzone
                            accept="image/*"
                            onDrop={async (acceptedFiles) => {
                              if (elem.propertyname === 'Business Logo') {
                                setBusinessLogo(acceptedFiles);
                              } else {
                                setImages(acceptedFiles);
                              }
                              const res = await client.mutate({
                                mutation: UploadImage,
                                variables: {
                                  images: acceptedFiles,
                                },
                              });
                              if (res?.data?.uploadimg?.images) {
                                const data = {};
                                const val = [...updatedVal];
                                data[`${elem?.objecttype}_${elem?.name}`] = res?.data?.uploadimg?.images;
                                val.map((v) => {
                                  if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                                    const index = val.indexOf(v);
                                    val.splice(index, 1);
                                  }
                                });
                                val.push(data);
                                setUpdatedVal(val);
                                showNotification('Image uploaded successfully', 'success');
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <BackupIcon style={{ fontSize: 50 }} />
                                <br />
                                <p style={{ fontSize: 14 }}>
                                  Upload Picture
                                  {elem?.isrequired ? <label className={classes.error}>*</label> : ''}
                                </p>
                              </div>
                            )}
                          </Dropzone>
                          <center>
                            <Typography variant="body2" style={{ fontSize: 14, fontFamily: 'Open sans' }}>
                              {' '}
                              File size should not
                              greater than 3MB!
                            </Typography>
                          </center>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      {elem.propertyname === 'Business Logo' && (typeof businessLogo !== 'string'
                        ? businessLogo?.length > 0 && [...businessLogo]?.map((f) => (
                          <center>
                            <img src={URL.createObjectURL(f)} alt="preview" className={classes.images} />
                          </center>
                        ))
                        : (
                          <center>
                            <img src={businessLogo || ''} alt="preview" className={classes.images} />
                          </center>
                        ))}
                      {elem.propertyname === 'Images' && (typeof images !== 'string'
                        ? images?.length > 0 && [...images]?.map((f) => (
                          <center>
                            <img src={URL.createObjectURL(f)} alt="preview" className={classes.images} />
                          </center>
                        ))
                        : (
                          <center>
                            <img src={images || ''} alt="preview" className={classes.images} />
                          </center>
                        ))}
                    </Grid>
                  </Grid>
                </>
              )
              : (
                elem.value && (
                  <center>
                    <img src={elem.value} alt="preview" className={classes.previewImage} />
                  </center>
                )
              )}
          </FormControl>
        );
      case 'Section':
        return (
          <>
            <Box className={classes.elementBox}>
              <Typography variant="h6" style={{ fontFamily: 'Open sans' }}>{elem?.value}</Typography>
            </Box>
            <Divider />
          </>
        );
      case 'availablehours': {
        return (
          <Box className={classes.elementBox}>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={12}>
                <Box>
                  <Typography variant="body1" style={{ fontFamily: 'Open sans' }}>
                    Working Hours
                  </Typography>
                  <Typography variant="caption" style={{ fontFamily: 'Open sans' }}>
                    Add Working hours of your business place
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={5} md={3} sm={3} xs={12}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={SUN} onChange={handleChangeTime} name="SUN" />}
                  label={<Typography className={classes.weekDays}>SUN</Typography>}
                />
              </Grid>
              <Grid item lg={6} md={9} sm={9} xs={12}>
                {day?.SUN === true ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex">
                      <MobileTimePicker
                        ampm={false}
                        label="Start Time"
                        className='my-mobile-time-picker'
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        defaultValue={sunTimeStart}
                        onChange={(newValue) => setSunTimeStart(newValue)}
                      />
                      &nbsp;
                      <MobileTimePicker
                        ampm={false}
                        label="End Time"
                        className='my-mobile-time-picker'
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        defaultValue={sunTimeEnd}
                        onChange={(newValue) => setSunTimeEnd(newValue)}
                      />
                    </Box>
                  </LocalizationProvider>
                )
                  : (
                    <CheckBoxTypo className={classes.timeText} variant="body1">
                      Unavailable
                    </CheckBoxTypo>
                  )}
              </Grid>
              <Grid item lg={5} md={3} sm={3} xs={12}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={MON} onChange={handleChangeTime} name="MON" />}
                  label={<Typography className={classes.weekDays}>MON</Typography>}
                />
              </Grid>
              <Grid item lg={6} md={9} sm={9} xs={12}>
                {day?.MON === true ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex">
                      <MobileTimePicker
                        ampm={false}
                        label="Start Time"
                        className='my-mobile-time-picker'
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        defaultValue={monTimeStart}
                        onChange={(date) => setMonTimeStart(date)}
                      />
                      &nbsp;
                      <MobileTimePicker
                        ampm={false}
                        label="End Time"
                        className='my-mobile-time-picker'
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        defaultValue={monTimeEnd}
                        onChange={(newValue) => setMonTimeEnd(newValue)}
                      />
                    </Box>
                  </LocalizationProvider>
                )
                  : (
                    <CheckBoxTypo className={classes.timeText} variant="body1">
                      Unavailable
                    </CheckBoxTypo>
                  )}
              </Grid>
              <Grid item lg={5} md={3} sm={3} xs={12}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={TUE} onChange={handleChangeTime} name="TUE" />}
                  label={<Typography className={classes.weekDays}>TUE</Typography>}
                />
              </Grid>
              <Grid item lg={6} md={9} sm={9} xs={12}>
                {day?.TUE === true ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex">
                      <MobileTimePicker
                        ampm={false}
                        className='my-mobile-time-picker'
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        label="Start Time"
                        defaultValue={tueTimeStart}
                        onChange={(newValue) => setTueTimeStart(newValue)}
                      />
                      &nbsp;
                      <MobileTimePicker
                        ampm={false}
                        className='my-mobile-time-picker'
                        label="End Time"
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        defaultValue={tueTimeEnd}
                        onChange={(newValue) => setTueTimeEnd(newValue)}
                      />
                    </Box>
                  </LocalizationProvider>
                )
                  : (
                    <CheckBoxTypo className={classes.timeText} variant="body1">
                      Unavailable
                    </CheckBoxTypo>
                  )}
              </Grid>
              <Grid item lg={5} md={3} sm={3} xs={12}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={WED} onChange={handleChangeTime} name="WED" />}
                  label={<Typography className={classes.weekDays}>WED</Typography>}
                />
              </Grid>
              <Grid item lg={6} md={9} sm={9} xs={12}>
                {day?.WED === true ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex">
                      <MobileTimePicker
                        ampm={false}
                        label="Start Time"
                        className='my-mobile-time-picker'
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        defaultValue={wenTimeStart}
                        onChange={(newValue) => setWenTimeStart(newValue)}
                      />
                      &nbsp;
                      <MobileTimePicker
                        ampm={false}
                        label="End Time"
                        className='my-mobile-time-picker'
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        defaultValue={wenTimeEnd}
                        onChange={(newValue) => setWenTimeEnd(newValue)}
                      />
                    </Box>
                  </LocalizationProvider>
                )
                  : (
                    <CheckBoxTypo className={classes.timeText} variant="body1">
                      Unavailable
                    </CheckBoxTypo>
                  )}
              </Grid>
              <Grid item lg={5} md={3} sm={3} xs={12}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={THU} onChange={handleChangeTime} name="THU" />}
                  label={<Typography className={classes.weekDays}>THU</Typography>}
                />
              </Grid>
              <Grid item lg={6} md={9} sm={9} xs={12}>
                {day?.THU === true ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex">
                      <MobileTimePicker
                        ampm={false}
                        className='my-mobile-time-picker'
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        label="Start Time"
                        defaultValue={thuTimeStart}
                        onChange={(newValue) => setThuTimeStart(newValue)}
                      />
                      &nbsp;
                      <MobileTimePicker
                        ampm={false}
                        className='my-mobile-time-picker'
                        label="End Time"
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        defaultValue={thuTimeEnd}
                        onChange={(newValue) => setThuTimeEnd(newValue)}
                      />
                    </Box>
                  </LocalizationProvider>
                )
                  : (
                    <CheckBoxTypo className={classes.timeText} variant="body1">
                      Unavailable
                    </CheckBoxTypo>
                  )}
              </Grid>
              <Grid item lg={5} md={3} sm={3} xs={12}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={FRI} onChange={handleChangeTime} name="FRI" />}
                  label={<Typography className={classes.weekDays}>FRI</Typography>}
                />
              </Grid>
              <Grid item lg={6} md={9} sm={9} xs={12}>
                {day?.FRI === true ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex">
                      <MobileTimePicker
                        ampm={false}
                        label="Start Time"
                        className='my-mobile-time-picker'
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        defaultValue={friTimeStart}
                        onChange={(newValue) => setFriTimeStart(newValue)}
                      />
                      &nbsp;
                      <MobileTimePicker
                        ampm={false}
                        label="End Time"
                        className='my-mobile-time-picker'
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        defaultValue={friTimeEnd}
                        onChange={(newValue) => setFriTimeEnd(newValue)}
                      />
                    </Box>
                  </LocalizationProvider>
                )
                  : (
                    <CheckBoxTypo className={classes.timeText} variant="body1" style={{ fontFamily: 'Open sans' }}>
                      Unavailable
                    </CheckBoxTypo>
                  )}
              </Grid>
              <Grid item lg={5} md={3} sm={3} xs={12}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={SAT} onChange={handleChangeTime} name="SAT" />}
                  label={<Typography className={classes.weekDays}>SAT</Typography>}
                />
              </Grid>
              <Grid item lg={6} md={9} sm={9} xs={12}>
                {day?.SAT === true ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex">
                      <MobileTimePicker
                        ampm={false}
                        className='my-mobile-time-picker'
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        label="Start Time"
                        defaultValue={satTimeStart}
                        onChange={(newValue) => setSatTimeStart(newValue)}
                      />
                      &nbsp;
                      <MobileTimePicker
                        ampm={false}
                        className='my-mobile-time-picker'
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        label="End Time"
                        defaultValue={satTimeEnd}
                        onChange={(newValue) => setSatTimeEnd(newValue)}
                      />
                    </Box>
                  </LocalizationProvider>
                )
                  : (
                    <CheckBoxTypo className={classes.timeText} variant="body1" style={{ fontFamily: 'Open sans' }}>
                      Unavailable
                    </CheckBoxTypo>
                  )}
              </Grid>
            </Grid>
          </Box>
        );
      }
      case 'Docs': {
        const docs = updatedVal?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) || updatedVal?.find((v) => v[elem?.value]);
        const documents = docs?.[`${elem?.objecttype}_${elem?.name}`]?.documentLink || docs?.[elem?.value]?.documentLink;
        const documentfile = documents?.split('/');
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <Typography variant="h6" style={{ fontFamily: 'Open sans' }}>{elem?.value}</Typography>
            </Box>
            <Divider />
            <Box className={classes.elementBoxDoc}>
              <TextField
                className={classes.field}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                fullWidth
                size="small"
                variant="outlined"
                type="text"
                label="Title"
                value={(updatedVal.find(
                  (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                )?.[`${elem?.objecttype}_${elem?.name}`]?.title)
                  ? (updatedVal.find(
                    (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                  )?.[`${elem?.objecttype}_${elem?.name}`]?.title)
                  : updatedVal.find(
                    (val) => Object.keys(val)[0] === elem?.value,
                  )?.[elem?.value]?.title
                    ? updatedVal.find(
                      (val) => Object.keys(val)[0] === elem?.value,
                    )?.[elem?.value]?.title
                    : ''}
                onChange={(e) => {
                  const val = [...updatedVal];
                  const data = val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) ? val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) : val?.find((v) => v[elem?.value]) ? val?.find((v) => v[elem?.value]) : {};
                  const docObj = data[`${elem?.objecttype}_${elem?.name}`] ? data[`${elem?.objecttype}_${elem?.name}`] : data[elem?.value] ? data[elem?.value] : {};
                  docObj.title = e.target.value;
                  if (data[elem?.value]) {
                    delete data[elem?.value];
                  }
                  data[`${elem?.objecttype}_${elem?.name}`] = docObj;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    } else if (Object.keys(v)[0] === elem.value) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            <Box className={classes.elementBoxDoc}>
              <TextField
                className={classes.field}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                fullWidth
                size="small"
                variant="outlined"
                type="text"
                label="Description"
                value={(updatedVal.find(
                  (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                )?.[`${elem?.objecttype}_${elem?.name}`]?.description)
                  ? (updatedVal.find(
                    (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                  )?.[`${elem?.objecttype}_${elem?.name}`]?.description)
                  : updatedVal.find(
                    (val) => Object.keys(val)[0] === elem?.value,
                  )?.[elem?.value]?.description
                    ? updatedVal.find(
                      (val) => Object.keys(val)[0] === elem?.value,
                    )?.[elem?.value]?.description
                    : ''}
                onChange={(e) => {
                  const val = [...updatedVal];
                  const data = val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) ? val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) : val?.find((v) => v[elem?.value]) ? val?.find((v) => v[elem?.value]) : {};
                  const docObj = data[`${elem?.objecttype}_${elem?.name}`] ? data[`${elem?.objecttype}_${elem?.name}`] : data[elem?.value] ? data[elem?.value] : {};
                  docObj.description = e.target.value;
                  if (data[elem?.value]) {
                    delete data[elem?.value];
                  }
                  data[`${elem?.objecttype}_${elem?.name}`] = docObj;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    } else if (Object.keys(v)[0] === elem.value) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            <Box className={classes.UploadDrop}>
              <Dropzone
                onDrop={async (acceptedFiles) => {
                  const res = await client.mutate({
                    mutation: UploadImage,
                    variables: {
                      images: acceptedFiles,
                    },
                  });
                  if (res?.data?.uploadimg?.images) {
                    const val = [...updatedVal];
                    const data = val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) ? val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) : val?.find((v) => v[elem?.value]) ? val?.find((v) => v[elem?.value]) : {};
                    const docObj = data[`${elem?.objecttype}_${elem?.name}`] ? data[`${elem?.objecttype}_${elem?.name}`] : data[elem?.value] ? data[elem?.value] : {};
                    docObj.documentLink = res?.data?.uploadimg?.images;
                    if (data[elem?.value]) {
                      delete data[elem?.value];
                    }
                    data[`${elem?.objecttype}_${elem?.name}`] = docObj;
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      } else if (Object.keys(v)[0] === elem.value) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(data);
                    setUpdatedVal(val);
                    showNotification('Image uploaded successfully', 'success');
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon style={{ fontSize: 50 }} />
                    <Typography style={{ fontSize: 12 }}>
                      Select Files
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      *File size should not
                      greater than 5MB!
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {documents && (
                <Link href={documents} target="_blank" download>
                  <br />
                  <Box className={classes.note}>
                    <Typography variant="body2" className={classes.docTitle}>
                      <DescriptionIcon style={{ fontSize: 15 }} />
                      &nbsp;&nbsp;
                      {documentfile[documentfile?.length - 1]}
                    </Typography>
                  </Box>
                </Link>
              )}
            </Box>
          </FormControl>
        );
      }
      default:
        return '';
    }
  };

  const getSelectedValue = (values, obj) => {
    if (Object.keys(obj).length > 0) {
      values[Object.keys(obj)] = obj[Object.keys(obj)];
    }
    return values;
  };

  useEffect(() => {
    if (formData && formData.length > 0) {
      const val = [...updatedVal];
      formData.map((form) => {
        const data = {};
        data[form.name] = form.value;
        val.map((v) => {
          if (Object.keys(v)[0] === form.name) {
            const index = val.indexOf(v);
            val.splice(index, 1);
          }
        });
        val.push(data);
        setUpdatedVal(val);
      });
    }
  }, [formData]);

  const validateSubmit = (values, formType) => {
    let isValid = true;
    const errors = [];
    getSelectedValue(values, dob);
    getSelectedValue(values, dateOfBirth);
    getSelectedValue(values, businessStartDate);
    getSelectedValue(values, contactCountryid);
    getSelectedValue(values, contactStateid);
    getSelectedValue(values, orgCountryid);
    getSelectedValue(values, orgStateid);

    updatedVal.map((val) => {
      getSelectedValue(values, val);
    });

    const data = [];
    const keys = Object.keys(values);
    if (keys.length > 0) {
      keys.map((key) => {
        if (key) {
          data.push({
            name: key,
            custom_name: key,
            value: values[key],
            dbfieldName: content?.find((con) => con.name === key?.split('_')?.[1])?.dbfieldname,
          });
        }
      });
    }

    if (formType === 'multi-page') {
      data.map((d) => {
        if (content.length > 0) {
          for (let i = 1; i < activeTabId; i++) {
            if (content[0][`Page${i}`]?.find((con) => con.name === d.name?.split('_')?.[1])?.dbfieldname) {
              d.dbfieldName = content[0][`Page${i}`]?.find((con) => con.name === d.name?.split('_')?.[1])?.dbfieldname
            }
          }
        }
      });
    }

    const arr = formType === 'multi-page' ? content[0][`Page${activeTabId}`] : content;
    if (arr.length > 0) {
      arr.map((con) => {
        if (Component[con.fieldtype] === 'Multiple checkbox' && con.isrequired) {
          keys.map((key) => {
            if (key !== `${con.objecttype}_${con.name}`) {
              if (!(values[`${con.objecttype}_${con.name}`] && values[`${con.objecttype}_${con.name}`].length > 0)) {
                isValid = false;
                setIsRequired(true);
              } else {
                isValid = true;
              }
            }
          });
        }

        if (Component[con.fieldtype] === 'Rating' && con.isrequired) {
          keys.map((key) => {
            if (key !== `${con.objecttype}_${con.name}`) {
              if (!(values[`${con.objecttype}_${con.name}`])) {
                isValid = false;
                setIsRequired(true);
              } else {
                isValid = true;
              }
            }
          });
        }

        if (Component[con.fieldtype] === 'Textbox' && con.isrequired) {
          if (!(values[`${con?.objecttype}_${con?.name}`])) {
            isValid = false;
            errors.push(`${con?.name} is required`);
          } else if (con?.name?.toLowerCase() === 'email') {
            if (!emailValidate(values[`${con.objecttype}_${con.name}`])) {
              isValid = true;
            } else {
              errors.push(emailValidate(values[`${con.objecttype}_${con.name}`]));
            }
          }
        } else if (Component[con?.fieldtype] === 'Textbox' && con?.name?.toLowerCase() === 'email') {
          if (!emailValidate(values[`${con.objecttype}_${con.name}`])) {
            isValid = true;
          } else {
            errors.push(emailValidate(values[`${con.objecttype}_${con.name}`]));
          }
        }

        if (Component[con.fieldtype] === 'Multiline' && con.isrequired) {
          if (!(values[`${con.objecttype}_${con.name}`])) {
            isValid = false;
            errors.push(`${con.name} is required`);
          } else {
            isValid = true;
          }
        }

        if (Component[con.fieldtype] === 'Phone number' && con.isrequired) {
          if (!(values[`${con.objecttype}_${con.name}`])) {
            isValid = false;

            errors.push(`${con.name} is required`);
          } else if (!phoneCountryDialCodeValidate(values[`${con.objecttype}_${con.name}`])) {
            isValid = true;
          } else if ((values[`${con.objecttype}_${con.name}`]).length === 10) {
            isValid = true;
            const findData = data.find((e) => e.custom_name === `${con.objecttype}_${con.name}`);
            findData.value = `${handleUndefined(defaultCountryPhoneCode)}${values[`${con.objecttype}_${con.name}`]}`;
          } else {
            isValid = false;
            errors.push('Kindly input a valid phone number containing a minimum of 10 digits.');
          }
        } else if (Component[con.fieldtype] === 'Phone number') {
          if (!phoneCountryDialCodeValidate(values[`${con.objecttype}_${con.name}`])) {
            isValid = true;
          } else if ((values[`${con.objecttype}_${con.name}`]).length === 10) {
            isValid = true;
            const findData = data.find((e) => e.custom_name === `${con.objecttype}_${con.name}`);
            findData.value = `${handleUndefined(defaultCountryPhoneCode)}${values[`${con.objecttype}_${con.name}`]}`;
          } else {
            isValid = false;
            errors.push('Kindly input a valid phone number containing a minimum of 10 digits.');
          }
        }

        if (Component[con.fieldtype] === 'Docs') {
          if (!values[`${con.objecttype}_${con.name}`]?.title && values[`${con.objecttype}_${con.name}`]?.documentLink) {
            isValid = false;
            errors.push('Document title is required');
          } else {
            if (data && data.find((d) => d.name === `${con.objecttype}_${con.name}`)) {
              data.find((d) => d.name === `${con.objecttype}_${con.name}`).name = con.value;
            } else if (data && data.find((d) => d.name === con.value)) {
              data.find((d) => d.name === con.value).custom_name = `${con.objecttype}_${con.name}`;
            }
            isValid = true;
          }
        }

        if (Component[con.fieldtype] === 'Number' && con.isrequired) {
          if (!(values[`${con.objecttype}_${con.name}`])) {
            isValid = false;
            errors.push(`${con.name} is required`);
          } else {
            isValid = true;
          }
        }

        if (con.propertyname === 'Business Logo' && con.isrequired) {
          if (!(values[`${con.objecttype}_${con.name}`])) {
            isValid = false;
            errors.push(`${con.name} is required`);
          } else {
            isValid = true;
          }
        }

        if (con.propertyname === 'Images' && con.isrequired) {
          if (!(values[`${con.objecttype}_${con.name}`])) {
            isValid = false;
            errors.push(`${con.name} is required`);
          } else {
            isValid = true;
          }
        }
      });
    }

    errors.map((err) => {
      showNotification(err, 'error');
    });

    const timeavailabilityArray = [];
    if (!(moment(sunTimeStart?.$d).format('YYYY-MMM-DD HH:mm:ss A') >= moment(sunTimeEnd?.$d).format('YYYY-MMM-DD HH:mm:ss A'))
      && !(moment(monTimeStart?.$d).format('YYYY-MMM-DD HH:mm:ss A') >= moment(monTimeEnd?.$d).format('YYYY-MMM-DD HH:mm:ss A'))
      && !(moment(tueTimeStart?.$d).format('YYYY-MMM-DD HH:mm:ss A') >= moment(tueTimeEnd?.$d).format('YYYY-MMM-DD HH:mm:ss A'))
      && !(moment(wenTimeStart?.$d).format('YYYY-MMM-DD HH:mm:ss A') >= moment(wenTimeEnd?.$d).format('YYYY-MMM-DD HH:mm:ss A'))
      && !(moment(thuTimeStart?.$d).format('YYYY-MMM-DD HH:mm:ss A') >= moment(thuTimeEnd?.$d).format('YYYY-MMM-DD HH:mm:ss A'))
      && !(moment(friTimeStart?.$d).format('YYYY-MMM-DD HH:mm:ss A') >= moment(friTimeEnd?.$d).format('YYYY-MMM-DD HH:mm:ss A'))
      && !(moment(satTimeStart?.$d).format('YYYY-MMM-DD HH:mm:ss A') >= moment(satTimeEnd?.$d).format('YYYY-MMM-DD HH:mm:ss A'))
    ) {
      if (day?.SUN === true) timeavailabilityArray.push({ day: 'SUN', starttime: moment(sunTimeStart?.$d).format(timeFormat?.time), endtime: moment(sunTimeEnd?.$d).format(timeFormat?.time) });
      if (day?.MON === true) timeavailabilityArray.push({ day: 'MON', starttime: moment(monTimeStart?.$d).format(timeFormat?.time), endtime: moment(monTimeEnd?.$d).format(timeFormat?.time) });
      if (day?.TUE === true) timeavailabilityArray.push({ day: 'TUE', starttime: moment(tueTimeStart?.$d).format(timeFormat?.time), endtime: moment(tueTimeEnd?.$d).format(timeFormat?.time) });
      if (day?.WED === true) timeavailabilityArray.push({ day: 'WED', starttime: moment(wenTimeStart?.$d).format(timeFormat?.time), endtime: moment(wenTimeEnd?.$d).format(timeFormat?.time) });
      if (day?.THU === true) timeavailabilityArray.push({ day: 'THU', starttime: moment(thuTimeStart?.$d).format(timeFormat?.time), endtime: moment(thuTimeEnd?.$d).format(timeFormat?.time) });
      if (day?.FRI === true) timeavailabilityArray.push({ day: 'FRI', starttime: moment(friTimeStart?.$d).format(timeFormat?.time), endtime: moment(friTimeEnd?.$d).format(timeFormat?.time) });
      if (day?.SAT === true) timeavailabilityArray.push({ day: 'SAT', starttime: moment(satTimeStart?.$d).format(timeFormat?.time), endtime: moment(satTimeEnd?.$d).format(timeFormat?.time) });
    }

    const timeavailability = arr.find((con) => Component[con.fieldtype] === 'availablehours');
    if (timeavailability) {
      data.push({
        name: `${timeavailability?.objecttype}_${timeavailability?.name}`,
        custom_name: `${timeavailability?.objecttype}_${timeavailability?.name}`,
        value: timeavailabilityArray,
      });
    }

    if (onFormSubmit && isValid && errors.length === 0 && formType === 'single-page') {
      setUpdatedVal([]);
      onFormSubmit(data);
    } else if (isValid && errors.length === 0 && activeTabId < steps.length && formType === 'multi-page') {
      setActiveTabId(activeTabId + 1);
    } else if (onFormSubmit && isValid && errors.length === 0 && formType === 'multi-page') {
      setUpdatedVal([]);
      onFormSubmit(data);
    }
  };

  const onSubmitData = async (values) => {
    validateSubmit(values, eventType);
  };

  const onMultiSubmitData = (values) => {
    validateSubmit(values, eventType);
  };

  return (
    eventType === 'single-page'
      ? (
        <Form
          onSubmit={onSubmitData}
          render={({
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} className={isAppointmentPublic ? 'pl-4 pt-2 pr-4' : 'pl-4 pt-2 pr-4'}>
                {content?.length > 0 && content.map((con) => (
                  con.objecttype !== 'Designing' ? (
                    <Grid
                      item
                      lg={(
                        Component[con.fieldtype] === 'Image'
                        || Component[con.fieldtype] === 'availablehours')
                        ? 12
                        : rows}
                      md={(
                        Component[con.fieldtype] === 'Image'
                        || Component[con.fieldtype] === 'availablehours')
                        ? 12
                        : rows}
                      xs={12}
                      sm={12}
                      className="mt-2"
                      style={{ backgroundColor: backgroundColorCode }}
                    >
                      {getElement(con)}
                    </Grid>
                  ) : (
                    <Grid item lg={12} xs={12} md={12} sm={12} className="mt-2">
                      {getElement(con)}
                    </Grid>
                  )
                ))}
                <Grid item xs={12}>
                  {visible === true
                    ? (
                      <Autocomplete
                        multiple
                        id="tags-filled"
                        value={contact}
                        onChange={(event, newValue) => { setContact(newValue); }}
                        options={suggestContact}
                        freeSolo
                        renderTags={(values, getTagProps) => values.map(
                          (option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ),
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label="Guest Email(s)"
                            placeholder="Add email and press enter"
                          />
                        )}
                      />
                    )
                    : ''}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {enableTerm && (
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: termDesc }} className={classes.termDescription} />
                  )}
                  <StyledBox>
                    {isAppointment
                      ? (
                        <>
                          <GuestButton
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.guestBtn}
                            onClick={() => {
                              if (setVisible) {
                                setVisible(true);
                              }
                            }}
                          >
                            Add Guests
                          </GuestButton>
                          <ScheduleButton
                            size="small"
                            variant="contained"
                            type="submit"
                            onClick={() => { }}
                          >
                            {btnName}
                          </ScheduleButton>
                        </>
                      )
                      : (
                        <StyledButton
                          size="small"
                          variant="contained"
                          type="submit"
                          onClick={() => { }}
                        >
                          <span>{btnName}</span>
                        </StyledButton>
                      )}
                  </StyledBox>
                </Grid>
              </Grid>
            </form>
          )}
        />
      ) : (
        <Form
          onSubmit={onMultiSubmitData}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} className={isAppointmentPublic ? 'pl-4 pt-2' : 'pl-4 pt-2 pr-4'}>
                {content?.length > 0 && content.map((con) => (
                  con[`Page${activeTabId}`] && con[`Page${activeTabId}`].length > 0
                    ? con[`Page${activeTabId}`].map((fi) => (
                      fi.objecttype !== 'Designing' ? (
                        <Grid
                          item
                          lg={
                            (Component[fi.fieldtype] === 'Image' || Component[fi.fieldtype] === 'availablehours')
                              ? 12
                              : rows
                          }
                          md={
                            (Component[fi.fieldtype] === 'Image' || Component[fi.fieldtype] === 'availablehours')
                              ? 12
                              : rows
                          }
                          xs={12}
                          sm={12}
                          className="pl-4 pt-2 pr-4"
                          style={{ backgroundColor: backgroundColorCode }}
                        >
                          <Box className={classes.elementBox}>
                            {getElement(fi)}
                          </Box>
                        </Grid>
                      ) : (
                        <Grid item md={12} xs={12} sm={12} className="pl-4 pt-2 pr-4">
                          <Grid container spacing={2}>
                            <Grid item xs={11} md={11} sm={11}>
                              {getElement(fi)}
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    ))
                    : null
                ))}
                <Grid item md={12} xs={12} sm={12}>
                  {enableTerm && activeTabId === steps.length && (
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: termDesc }} />
                  )}
                  <StyledBox>
                    {activeTabId > 1 && (
                      <PreviewButton
                        className={classes.prevBtn}
                        onClick={handlePrev}
                      >
                        Prev
                      </PreviewButton>
                    )}
                    {activeTabId >= 1 && activeTabId < steps.length
                      && (
                        <PreviewButton
                          className={classes.nextBtn}
                          type="submit"
                          onClick={() => { }}
                        >
                          Next
                        </PreviewButton>
                      )}
                    {activeTabId === steps.length && (
                      <PreviewButton
                        type="submit"
                        className={classes.nextBtn}
                        onClick={() => { }}
                      >
                        {btnName}
                      </PreviewButton>
                    )}
                  </StyledBox>
                </Grid>
              </Grid>
            </form>
          )}
        />
      )
  );
};
export default FormGenerator;
