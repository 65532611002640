/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { ContentMarketingbylocation, ContentMarketingSettingsByLocationId, AddActivityContentMarketing } from '../../Api Mutation/Campaign';
import config from '../../config';
import { dummyimage, dateFormatBrowser, ContentMarketingStatus } from '../../common/const';
import { Entity } from '../../Api Mutation/EntityApi';
import useStyle from './Style';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { postDataRequestPublic } from '../../common/commonfunctions';
import { useTheme } from '@mui/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Link, MobileStepper, Typography, styled } from '@mui/material';
import PoweredByLogo from '../../common/PoweredByLogo';
import './widgetStyle.css';

export default function PublicNewsletterWidget() {
  const classes = useStyle();
  const [titleData, setTitleData] = useState();
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [newsLetterData, setNewsLetterData] = useState([]);
  const [entityData, setEntityData] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [whiteLabelStatus, setWhiteLabelStatus] = useState();
  const [subDomain, setSubDomain] = useState();
  const theme = useTheme();
  const [count, setCount] = useState();
  const maxSteps = Math.ceil(count?.count / 5);
  const {
    lid,
  } = useParams([]);

  const EventName = styled(Typography)(({ theme }) => ({
    fontSize: ' 1.25rem ',
    fontFamily: 'Open Sans ',
    fontWeight: '600 ',
    color: '#005eb2',
    textDecoration: 'none',
  }));
  const EventDate = styled(Typography)(({ theme }) => ({
    fontSize: ' 12px ',
    fontFamily: 'Open Sans ',
    fontWeight: '400 ',
    color: '#005eb2',
    textDecoration: 'none',
  }));
  const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: ' 0.8125rem ',
    fontFamily: 'Open Sans ',
    fontWeight: '500 ',
    color: '#4A4A4A',
    textTransform: 'capitalize',
  }));

  const getNewsLetterByData = (props) => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: ContentMarketingbylocation,
        variables: {
          locationid: lid,
          pageNumber: props?.pageNumber + 1,
          pageSize: 5,
          ispublish: true,
        },
      }).then((res) => {
        const data = res?.data?.contentMarketingbylocation;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data) {
          setNewsLetterData(data?.rows);
          setCount(data);
        }
      });
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fetchData = async (prop) => {
    if (prop?.id) {
      const CREATE_ACTIVITYNEWSLETTER = AddActivityContentMarketing;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITYNEWSLETTER,
        variables: {
          contentmarketingnid: prop?.id,
          webpageclickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getEntityData = (locationId) => {
    postDataRequestPublic(config.baseURLApi, {
      query: Entity,
      variables: {
        entityid: locationId,
      },
    }).then((response) => {
      if (response?.data?.entity?.whitelabeltype) {
        setWhiteLabelStatus(response?.data?.entity?.whitelabeltype);
        setSubDomain(response?.data?.entity?.whitelabeldomain);
      }
    });
  };

  const getWidgetSetting = () => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: ContentMarketingSettingsByLocationId,
        variables: { locationid: lid },
      }).then((res) => {
        const data = res?.data?.contentMarketingSettingsByLocationId;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data) {
          setTitleData(data);
          setEnablePublicLogo(data?.isshowfooterlogo);
          getEntityData(data?.locationid)
        }
      });
    }
  };

  useEffect(() => {
    getNewsLetterByData({
      pageNumber: activeStep,
    });
  }, [lid, activeStep]);

  useEffect(() => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: lid,
        },
      }).then((result) => {
        const data = result?.data?.entity;
        if (data) {
          setEntityData(data);
        }
      });
    }
    getWidgetSetting();
  }, [lid]);

  return (
    <>
      <Box className={classes.Widget}>
        <Box className={titleData?.contentposition === 'center' ? classes.WidgetLayout : classes.WidgetLayoutAlign}>
          <Box className="widgetPageTitle" color="primary" dangerouslySetInnerHTML={{ __html: titleData?.pagetitle }} />
          <Box className="widgetTitleText" color="primary" dangerouslySetInnerHTML={{ __html: titleData?.subtitle }} />
          <Box className="widgetTitleText" color="primary" dangerouslySetInnerHTML={{ __html: titleData?.subscriptiontitle }} />
        </Box>
        <Box className={classes.contentsText}>
          {newsLetterData && newsLetterData?.map((i) => (
            i?.status !== ContentMarketingStatus?.Draft ? (<Link
              className={classes.link}
              target="_blank"
              href={whiteLabelStatus === 'full' && subDomain && entityData?.subdomain
                ? `https://${entityData?.subdomain}.${subDomain}/cp/${i?.contentmarketingnid}/${lid}`
                : entityData?.subdomain
                ? `https://${entityData?.subdomain}${config.subDomainUrl}/cp/${i?.contentmarketingnid}/${lid}`
                : entityData?.subdomain === null
                  ? `${config.BixUrl}/cp/${i?.contentmarketingnid}/${lid}`
                  : `${config.BixUrl}/cp/${i?.contentmarketingnid}/${lid}`}
              onClick={() => {
                fetchData({
                  id: i?.contentmarketingnid,
                  click: {
                    timestamp: new Date(),
                    url: whiteLabelStatus === 'full' && subDomain && entityData?.subdomain
                      ? `https://${entityData?.subdomain}.${subDomain}/cp/${i?.contentmarketingnid}`
                      : entityData?.subdomain
                      ? `https://${entityData?.subdomain}${config.subDomainUrl}/cp/${i?.contentmarketingnid}`
                      : entityData?.subdomain === null
                        ? `${config.BixUrl}/cp/${i?.contentmarketingnid}`
                        : `${config.BixUrl}/cp/${i?.contentmarketingnid}`,
                    name: i?.name,
                  },
                });
              }}
            >
              <Box className={classes.contents}>
                <img className={classes.img} src={i?.listpageimage ? i?.listpageimage : dummyimage} alt="demo" />
                <Box className={classes.contentsText}>
                  <EventName variant="h6">
                    {i?.name}
                  </EventName>
                  <EventDate variant="body2">
                    {moment(i?.createdAt, dateFormatBrowser).format('ddd, MMM DD, YYYY')}
                  </EventDate>
                </Box>
              </Box>
            </Link>) : ''
          ))}
        </Box>
      </Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        sx={{ fontFamily: 'Open sans' }}
        variant="text"
        activeStep={activeStep}
        nextButton={(
          <StyledButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </StyledButton>
        )}
        backButton={(
          <StyledButton size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </StyledButton>
        )}
      />
      <center>
        <PoweredByLogo enablePublicLogo={enablePublicLogo} id={entityData?.entityid}/>
      </center>
    </>
  );
}
