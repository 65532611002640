import React from "react";
import { Grid } from "@mui/material";
import "./Search.css";

export default function BusinessSearch({ setSearchText, searchText, getInitialData, activeStep }) {
  return (
    <>
      <div class="container">
        <div class="full-search-2 Reveal-search Reveal-search-radius box-style mb-4">
          <div class="Reveal-search-content">
          <Grid container spacing={1}>
            <Grid style={{ background: "none" }} item xs={12} md={3} sm={4}>
                <div class="form-group">
                  <div class="input-with-icon">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Keywords..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <i class="theme-cl ti-search"></i>
                  </div>
                </div>
              </Grid>
              <Grid style={{ background: "none" }} item xs={12} md={3} sm={4}>
                <div class="form-group">
                  <div class="input-with-icon">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Location..."
                    />
                    <i class="theme-cl ti-target"></i>
                  </div>
                </div>
              </Grid>
              <Grid style={{ background: "none" }} item xs={12} md={3} sm={4}>
                <div class="form-group">
                  <div class="input-with-icon">
                    <select
                      id="list-category"
                      class="form-control select2-hidden-accessible"
                      data-select2-id="list-category"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="" data-select2-id="2">
                        &nbsp;
                      </option>
                      <option value="1">Spa &amp; Bars</option>
                      <option value="2">Restaurants</option>
                      <option value="3">Hotels</option>
                      <option value="4">Educations</option>
                      <option value="5">Business</option>
                      <option value="6">Retail &amp; Shops</option>
                      <option value="7">Garage &amp; Services</option>
                    </select>
                    <span
                      class="select2 select2-container select2-container--default"
                      dir="ltr"
                      data-select2-id="1"
                      style={{ width: "267px" }}
                    >
                      <span class="selection">
                        <span
                          class="select2-selection select2-selection--single"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabindex="0"
                          aria-labelledby="select2-list-category-container"
                        >
                          <span
                            class="select2-selection__rendered"
                            id="select2-list-category-container"
                            role="textbox"
                            aria-readonly="true"
                          >
                            <span class="select2-selection__placeholder">
                              Choose Category
                            </span>
                          </span>
                          <span
                            class="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation"></b>
                          </span>
                        </span>
                      </span>
                      <span class="dropdown-wrapper" aria-hidden="true"></span>
                    </span>
                    <i class="theme-cl ti-briefcase"></i>
                  </div>
                </div>
              </Grid>
              <Grid style={{ background: "none" }} item xs={12} md={3} sm={4}>
                <div onClick={() => getInitialData({
                   pagenumber: activeStep,
                })} class="form-group">
                  <a href class="btn search-btn">
                    Search
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
