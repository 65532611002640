/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { postDataRequestPublic, handleUndefined, ValidateSubdomain } from '../../common/commonfunctions';
import { ContentMarketingbyshorturl, AddActivityContentMarketing } from '../../Api Mutation/Campaign';
import { Entitybysubdomain } from '../../Api Mutation/EntityApi';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import config from '../../config';
import PoweredByLogo from '../../common/PoweredByLogo';
import ErrorPage from '../../components/Error/ErrorPage';

export default function PublicContectDomain(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const {
    content,
  } = useParams([]);
  const SubDomainValidate = ValidateSubdomain();
  const [emailTemplateHtml, setEmailTemlateHtml] = useState();
  const [contentMarketingId, setContentMarketingId] = useState('');
  const [startDate, setStartDate] = useState(moment().format());
  const [locationId, setLocationId] = useState();
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const ref = useRef(null);
  const { onClickOutside } = props;
  const host = window.location.hostname;
  const getHostName = host.split('.');


  const getDomainByData = () => {
    if (host !== config.domain || host !== config.stagingDomain) {
      const DOMAIN = Entitybysubdomain;
      postDataRequestPublic(config.baseURLApi, {
        query: DOMAIN,
        variables: {
          subdomain: getHostName[0]
        },
      }).then((res) => {
        if (res?.data?.entitybysubdomain) {
          setLocationId(res?.data.entitybysubdomain?.entityid);
        }
      });
    }
  };

  useEffect(() => {
    if (!SubDomainValidate) {
      getDomainByData();
    }
  }, []);

  const fetchData = async (prop) => {
    if (handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id)) {
      const CREATE_ACTIVITYCONTECTMARKETING = AddActivityContentMarketing;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITYCONTECTMARKETING,
        variables: {
          contentmarketingnid: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id),
          openeddata: prop?.opendata,
          clickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getDataBytemplate = async () => {
    setStartDate(moment().format());
    if (locationId) {
      await postDataRequestPublic(config.baseURLApi, {
        query: ContentMarketingbyshorturl,
        variables: {
          shorturl: content,
          locationid: locationId,
        },
      }).then(async (res) => {
        if (res?.errors && res?.errors[0]?.message) {
          await showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.contentMarketingbyshorturl) {
          await setEnablePublicLogo(res?.data?.contentMarketingbyshorturl?.isshowfooterlogo);
          await setContentMarketingId(res?.data?.contentMarketingbyshorturl?.contentmarketingnid);
          await localStorage.setItem('cid', res?.data?.contentMarketingbyshorturl?.contentmarketingnid);
          await setEmailTemlateHtml(res?.data?.contentMarketingbyshorturl?.contenttemplate);
        }
        await setDataLoading(false);
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside && onClickOutside();
      } else if (e?.target?.parentNode?.localName === 'a' && e?.target?.parentNode?.href) {
        const clickedData = {
          timestamp: new Date(),
          url: e?.target?.parentNode?.href,
          name: e?.target?.parentNode?.innerText,
        };
        fetchData({
          id: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId),
          click: clickedData,
        });
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    getDataBytemplate();
  }, [locationId]);

  useEffect(() => {
    window.onbeforeunload = async (event) => {
      const message = 'Are you sure to exit this page ?';
      const e = event || window.event;
      await e.preventDefault();
      if (e) {
        e.returnValue = message;
      }
      return fetchData({
        opendata: JSON.stringify({
          timestamp: startDate,
          timespent: moment(new Date())?.diff(startDate, 'seconds'),
        }),
      });
    };
  }, []);

  return (
    <>
      {!SubDomainValidate ?
        (<div>
          {dataLoading ? <LoadingPage /> : ''}
          <center>
            <div ref={ref} dangerouslySetInnerHTML={{ __html: emailTemplateHtml }} />
          </center>
          <PoweredByLogo enablePublicLogo={enablePublicLogo} id={locationId} />
        </div>)
        : <ErrorPage />
      }
    </>
  );
}
