/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useStyle from "./style";
import { Box } from "@mui/material";
import { brand } from "./const";
import { postDataRequestPublic } from "../common/commonfunctions";
import { Entity, BrandSettingByLocation } from "../Api Mutation/EntityApi";
import config from "../config";

export default function PoweredBy({ id }) {
  const classes = useStyle();
  const [image, setImage] = useState();
  const [brandImage, setBrandImage] = useState();
  const [website, setWebsite] = useState();
  const [locationLogo, setLocationLogo] = useState('');
  const [locationSite, setLocationWebsite] = useState('');

  const getEntityData = () => {
    if (id) {
      const ENTITYDATA = Entity;
      postDataRequestPublic(config.baseURLApi, {
        query: ENTITYDATA,
        variables: {
          entityid: id,
        },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationLogo(res?.data?.entity?.logourl);
          setLocationWebsite(res?.data?.entity?.website);
          if (res?.data?.entity?.parententityid) {
            postDataRequestPublic(config.baseURLApi, {
              query: ENTITYDATA,
              variables: {
                entityid: res?.data?.entity?.parententityid,
              },
            }).then((respon) => {
              if (respon?.data?.entity) {
                setImage(respon?.data?.entity?.publiclogotype);
                setWebsite(respon?.data?.entity?.website);
              }
            });
            postDataRequestPublic(config.baseURLApi, {
              query: BrandSettingByLocation,
              variables: {
                locationid: res?.data?.entity?.parententityid,
              },
            }).then((resp) =>
              setBrandImage(resp?.data?.brandSettingByLocation?.logourl)
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    getEntityData();
  }, [id]);

  return (
    <>
    {image === brand?.noLogo ? (
      <Box />
    ) : (
      <div>
        <center>
          <a
            style={{ textDecoration: 'none', pointerEvents: brandImage === null ? 'none' : '' }}
            rel="noopener noreferrer"
            href={image === brand?.bixpand ? config.bixpand : brandImage === null ? '' : (image === brand?.business ? locationSite || website : '')}
            target="_blank"
          >
            <Box
              marginTop={3}
              marginBottom={1}
            >
              <img
                className={classes.bixpoweredBy}
                src={
              image === brand?.bixpand ? config.poweredBy
                : image === brand?.business || brand?.fullWhiteLabel || brand?.halfWhiteLabel
                ? locationLogo || brandImage
                  : ''
            }
                alt=""
              />
            </Box>
          </a>
        </center>
      </div>
    )}
  </>
  );
}
