export const eventBookByUser = `
query eventbookbyuser($userid: ID!, $orderby: String, $status: String, $locationid: ID, $listtype: Int){
 eventbookbyuser(userid:$userid, orderby:$orderby, status: $status, locationid:$locationid, listtype:$listtype){
   bookingid,bookingdate,contactid,guestemail,starttime,endtime,status,meetingduration,meetingprepare,eventid,createdby,updatedby,
   deleted,issentreminder,locationid,timezone,createdAt,updatedAt,cancellationreason,canceledby,changereason,status,
   createdUser{userid,firstname,lastname,phonenumber,email},assignedusersdata{userid,firstname,lastname,phonenumber,email}
   event{eventid,eventtype,name,location,description,scheduleday,scheduletype,eventstartdate,eventenddate,eventduration,isemailreminders}
   contact{contactid,primaryemail,firstname,lastname, status}
 }}`;

export const createEvents = `mutation createevent($eventtype: String!,$fieldtextcolor: String, $name: String!, $createdby: ID!, $location: String, $description: String, $eventbookingurl: String, $eventlink: String, $eventdaterangtype: Int, $eventcolor: String, $scheduleday: Int, $scheduletype: Int, $eventstartdate: String, $eventenddate: String, $eventduration: Int, $beforeeventbuffer: Int, $aftereventbuffer: Int, $isemailreminders: Boolean, $isemailfollowup: Boolean, $isshowphoneicon: Boolean, $isshowwhatsappicon: Boolean, $isshowiosicon: Boolean, $isshowandroidicon: Boolean, $timezone: String, $status: String, $ispaidevent: Boolean, $locationid: ID, $amount: Int, $isshowuseravatar: Boolean, $workflowid: ID, $termenable: Boolean, $captchaenable: Boolean, $termdesc: String, $formdata: String, $themecolor: String, $backgroundcolor: String, $textcolor: String, $calloutbtnposition: String, $calloutbuttontext: String, $btnthemecolor: String, $btntextcolor: String, $username: String) {
  createevent(eventtype: $eventtype, name: $name,,  fieldtextcolor: $fieldtextcolor, createdby: $createdby, location: $location, description: $description, eventbookingurl: $eventbookingurl, eventlink: $eventlink, eventdaterangtype: $eventdaterangtype, eventcolor: $eventcolor, scheduleday: $scheduleday, scheduletype: $scheduletype, eventstartdate: $eventstartdate, eventenddate: $eventenddate, eventduration: $eventduration, beforeeventbuffer: $beforeeventbuffer, aftereventbuffer: $aftereventbuffer, isemailreminders: $isemailreminders, isemailfollowup: $isemailfollowup, isshowphoneicon: $isshowphoneicon, isshowwhatsappicon: $isshowwhatsappicon, isshowiosicon: $isshowiosicon, isshowandroidicon: $isshowandroidicon, timezone: $timezone, status: $status, ispaidevent: $ispaidevent, locationid: $locationid, amount: $amount, isshowuseravatar: $isshowuseravatar, workflowid: $workflowid, termenable: $termenable, captchaenable: $captchaenable, termdesc: $termdesc, formdata: $formdata, themecolor: $themecolor, backgroundcolor: $backgroundcolor, textcolor: $textcolor, calloutbtnposition: $calloutbtnposition, calloutbuttontext: $calloutbuttontext, btnthemecolor: $btnthemecolor, btntextcolor: $btntextcolor, username: $username) {
    eventid
    eventtype
    name
    location
    description
    eventbookingurl
    eventlink
    eventcolor
    eventdaterangtype
    scheduleday
    scheduletype
    eventstartdate
    eventenddate
    eventduration
    beforeeventbuffer
    aftereventbuffer
    isemailreminders
    isemailfollowup
    isshowphoneicon
    isshowwhatsappicon
    isshowiosicon
    isshowandroidicon
    createdby
    updatedby
    deleted
    locationid
    status
    timezone
    createdUser {
      userid
      firstname
      lastname
      phonenumber
      email
    }
    createdAt
    updatedAt
    totalbooking
    futurebooking
    todaybooking
    ispaidevent
    amount
    isshowuseravatar
    workflowid
    termenable
    termdesc
    captchaenable
    formdata
    themecolor
    backgroundcolor
    textcolor
    fieldtextcolor
    calloutbuttontext
    calloutbtnposition
    btnthemecolor
    btntextcolor
    shorturl
  }
}`;

export const editEvent = `mutation editevent($eventid: ID!, $fieldtextcolor: String, $updatedby: ID!, $eventtype: String, $name: String, $location: String, $description: String, $eventbookingurl: String, $eventlink: String, $eventcolor: String, $eventdaterangtype: Int, $scheduleday: Int, $scheduletype: Int, $eventstartdate: String, $eventenddate: String, $eventduration: Int, $beforeeventbuffer: Int, $aftereventbuffer: Int, $status: String, $timezone: String, $isemailfollowup: Boolean, $isemailreminders: Boolean, $isshowphoneicon: Boolean, $isshowandroidicon: Boolean, $ispaidevent: Boolean, $isshowiosicon: Boolean, $isshowwhatsappicon: Boolean, $amount: Int, $isshowuseravatar: Boolean, $workflowid: ID, $username: String, $termdesc: String, $termenable: Boolean, $captchaenable: Boolean, $formdata: String, $themecolor: String, $backgroundcolor: String, $textcolor: String, $calloutbuttontext: String, $calloutbtnposition: String, $btnthemecolor: String, $btntextcolor: String) {
  editevent(eventid: $eventid, updatedby: $updatedby, eventtype: $eventtype,fieldtextcolor: $fieldtextcolor, name: $name, location: $location, description: $description, eventbookingurl: $eventbookingurl, eventlink: $eventlink, eventcolor: $eventcolor, eventdaterangtype: $eventdaterangtype, scheduleday: $scheduleday, scheduletype: $scheduletype, eventstartdate: $eventstartdate, eventenddate: $eventenddate, eventduration: $eventduration, beforeeventbuffer: $beforeeventbuffer, aftereventbuffer: $aftereventbuffer, status: $status, timezone: $timezone, isemailfollowup: $isemailfollowup, isemailreminders: $isemailreminders, isshowphoneicon: $isshowphoneicon, isshowandroidicon: $isshowandroidicon, ispaidevent: $ispaidevent, isshowiosicon: $isshowiosicon, isshowwhatsappicon: $isshowwhatsappicon, amount: $amount, isshowuseravatar: $isshowuseravatar, workflowid: $workflowid, username: $username, termdesc: $termdesc, termenable: $termenable, captchaenable: $captchaenable, formdata: $formdata, themecolor: $themecolor, backgroundcolor: $backgroundcolor, textcolor: $textcolor, calloutbuttontext: $calloutbuttontext, calloutbtnposition: $calloutbtnposition, btnthemecolor: $btnthemecolor, btntextcolor: $btntextcolor)
}`;

export const editBooking = `
mutation
editbooking(
  $bookingid: ID!
  $bookingdate: String
  $contactid: ID
  $timezone: String
  $guestemail: [String!]
  $starttime: String
  $status: Int
  $endtime: String
  $meetingduration: Int
  $issubmit: Boolean
  $meetingprepare: String
  $eventid: ID
  $paymentid: ID
  $organizationid: ID
  $responsedata: String
  $updatedby: ID!
){
  editbooking(
    bookingid:$bookingid
    timezone: $timezone
    bookingdate:$bookingdate
    contactid:$contactid
    starttime:$starttime
    endtime:$endtime
    status: $status
    guestemail:$guestemail
    issubmit:$issubmit
    meetingduration:$meetingduration
    meetingprepare:$meetingprepare
    eventid:$eventid
    paymentid:$paymentid
    organizationid:$organizationid
    responsedata:$responsedata
    updatedby:$updatedby
      )
    }`;

export const eventBookingData = `
query eventbookingdata($bookingid: ID!){
 eventbookingdata(bookingid:$bookingid){
   bookingid,bookingdate,contactid,guestemail,starttime,endtime,meetingduration,meetingprepare,eventid,createdby,updatedby,
   deleted,issentreminder,locationid,timezone,createdAt,updatedAt,cancellationreason,canceledby,changereason,status, organizationid, responsedata,
   createdUser{userid,firstname,lastname,phonenumber,email}
   event{eventid,eventtype,name,location,description,eventbookingurl,scheduleday,scheduletype,eventstartdate,eventenddate,eventduration,isemailreminders}
   contact{contactid,primaryemail,firstname,lastname}
 }}`;

export const eventsAvailableTime = `query Eventsavailabletime($eventid: ID!, $day: String, $timezone: String) {
  eventsavailabletime(eventid: $eventid, day: $day, timezone: $timezone) {
    errormessage {
      googleerror
      outlookerror
    }
    timeSlot {
      starttime
      endtime
    }
  }
}`;

export const eventByData = `
  query eventdata($eventid: ID!){
   eventdata(eventid:$eventid){
     eventid,eventtype,name,location,description,eventbookingurl,eventlink,eventcolor,eventdaterangtype,scheduleday,scheduletype,status,
     eventstartdate,eventenddate,eventduration,beforeeventbuffer,aftereventbuffer,isemailreminders,isemailfollowup, radioview,checkboxview,
     createdby,updatedby,deleted,locationid,createdAt,updatedAt,createdUser{ userid,firstname,lastname,phonenumber,email},
     isshowphoneicon,isshowwhatsappicon,isshowiosicon, fieldtextcolor, isshowandroidicon,ispaidevent,amount,isshowuseravatar,termenable,termdesc,captchaenable,
     formdata, themecolor, backgroundcolor,textcolor, calloutbuttontext,calloutbtnposition,btnthemecolor,btntextcolor,isshowlogo,isshowfooterlogo,platform,islinkvisible
   }}`;

export const bookEvent = `
mutation
bookevent(
  $bookingdate: String!
  $meetingduration: Int
  $guestemail: [String!]
  $timezone: String
  $eventid: ID!
  $status: Int
  $starttime: String
  $endtime: String
  $issubmit: Boolean
  $contactid: ID
  $locationid: ID
  $createdby: ID!
){
  bookevent(
    bookingdate:$bookingdate
    contactid: $contactid
    timezone:$timezone
    guestemail:$guestemail
    issubmit:$issubmit
    starttime: $starttime
    endtime: $endtime
    status: $status
    meetingduration:$meetingduration
    eventid:$eventid
    locationid:$locationid
    createdby:$createdby
  ){
    bookingid,bookingdate,status,starttime,endtime,contactid,meetingduration,eventid,createdby,updatedby,deleted,locationid,createdAt,updatedAt,
            }
    }`;

export const UrlByCode = `
query urlbycode($sorturlcode: String!){
     urlbycode(sorturlcode:$sorturlcode){
         fullurl,
     }
 }`;

export const CreateContactOrg = `mutation Createcontactorg($eventid: ID!, $responsedata: String!, $locationid: ID!, $businessid: ID!) {
  createcontactorg(eventid: $eventid, responsedata: $responsedata, locationid: $locationid, businessid: $businessid) {
    contactid
    organizationid
  }
}`;

export const Eventbyshorturl = `query eventbyshorturl($shorturl: String!) {
  eventbyshorturl(shorturl: $shorturl) {
    eventid
    eventtype
    name
    location
    description
    eventbookingurl
    eventlink
    eventcolor
    eventdaterangtype
    scheduleday
    scheduletype
    eventstartdate
    eventenddate
    eventduration
    beforeeventbuffer
    aftereventbuffer
    isemailreminders
    isemailfollowup
    isshowphoneicon
    isshowwhatsappicon
    isshowiosicon
    isshowandroidicon
    createdby
    updatedby
    radioview
    checkboxview
    deleted
    locationid
    status
    timezone
    createdUser {
      userid
      firstname
      lastname
      phonenumber
      email
      businessid
      locationid
    }
    createdAt
    updatedAt
    totalbooking
    futurebooking
    todaybooking
    ispaidevent
    amount
    isshowuseravatar
    workflowid
    termenable
    termdesc
    captchaenable
    formdata
    themecolor
    backgroundcolor
    textcolor
    calloutbuttontext
    calloutbtnposition
    btnthemecolor
    btntextcolor
    shorturl
    isshowlogo
  }
}`;
