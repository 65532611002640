/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import UseStyles from './Style';
import './style.css';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import config from '../../config';
import { BrandSettingByLocation, Entity } from '../../Api Mutation/EntityApi';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { CheckCircle } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import TicketFormGenerator from './TicketFormGenerator';
import BusinessFavicon from '../../common/BusinessFavicon';
import { RaiseTicket, TicketFormByLocation } from '../../Api Mutation/TicketApi';

const TicketRaisePublic = () => {
  const classes = UseStyles();
  const [ticketSubmit, setTicketSubmit] = useState('');
  const [brandImage, setBrandImage] = useState('');
  const [favIcon, setFavIcon] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [locationBrandImage, setLocationBrandImage] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [content, setContent] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [value, setValue] = useState();
  const [editorValue, setEditorValue] = useState(RichTextEditor?.createValueFromString(value || '', 'html'));
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const locationId = params.get('lid');
  const fromType = params.get('from');
  const businessId = params.get('bid');

  const entityData = () => {
    if (locationId) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: locationId },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
            setBusinessName(res?.data?.entity?.businessname || res?.data?.entity?.brandname);
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setDataLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      entityData();
    }
    return () => {
      isMounted = false;
    };
  }, [locationId]);

  const getInitialData = () => {
    if (locationId) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: TicketFormByLocation,
        variables: {
          locationid: locationId,
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          setDataLoading(false);
        } else if (res?.data?.ticketFormByLocation?.fields) {
          const data = JSON.parse(res?.data?.ticketFormByLocation?.fields);
          if (data && (data?.length > 0)) {
            const typeOptions = data?.filter((d) => d?.dbfieldname === 'type')[0]?.options;
            const priorityOptions = data?.filter((d) => d?.dbfieldname === 'priority')[0]?.options;
            const topicOptions = data?.filter((d) => d?.dbfieldname === 'topic')[0]?.options;
            const typeListOptions = typeof typeOptions === 'string' ? JSON.parse(typeOptions) : typeOptions;
            const priorityListOptions = typeof priorityOptions === 'string' ? JSON.parse(priorityOptions) : priorityOptions;
            const topicListOptions = typeof topicOptions === 'string' ? JSON.parse(topicOptions) : topicOptions;
            setContent(data);
            setTypeList(typeListOptions);
            setPriorityList(priorityListOptions);
            setTopicList(topicListOptions);
            setDataLoading(false);
          }
        } else {
          setContent(content);
          setDataLoading(false);
        }
        setDataLoading(false);
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (locationId) {
        getInitialData();
        localStorage.removeItem('ticketFirstName');
        localStorage.removeItem('ticketLastName');
      }
    }
    return () => {
      isMounted = false;
    };
  }, [locationId]);

  const onFormSubmit = (data) => {
    if (data) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: RaiseTicket,
        variables: {
          locationid: locationId,
          businessid: businessId,
          fromadmin: fromType === ('true') ? true : false,
          responsedata: JSON.stringify(data),
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          localStorage.removeItem('ticketFirstName');
          localStorage.removeItem('ticketLastName');
          localStorage.removeItem('ticketPhoneNumber');
          setDataLoading(false);
        } else if (res?.data?.raiseTicket) {
          localStorage.removeItem('ticketFirstName');
          localStorage.removeItem('ticketLastName');
          localStorage.removeItem('ticketPhoneNumber');
          showNotification('Ticket submitted successfully.', 'success');
          setDataLoading(false);
          setTicketSubmit(res?.data?.raiseTicket?.ticketid);
        }
        setDataLoading(false);
      });
    }
  };

  return (
    <>
      {dataLoading ? <LoadingPage /> : ''}
      <div className={classes.boxContainer}>
        {ticketSubmit ? (
          <Paper className={classes.ticketThanksTitle} elevation={3}>
            <CheckCircle className={classes.submitIcon} />
            <hr className={classes.hrStyle} />
            <Typography className={classes.thanksMsg}>
              <span>Thank you!</span>
              Your ticket has been successfully submitted.
              You will receive a confirmation email shortly.
              <span className={classes.mainSpan}>Our team will review your concern and get back to you soon.</span>
            </Typography>
          </Paper>
        ) :
          (<Paper className={classes.ticketTitle} elevation={3}>
            <Box className={classes.title}>
              <Typography className={classes.titleText}>Raise Your Ticket</Typography>
            </Box>
            <hr className={classes.hrStyle} />
            <Box>
              <TicketFormGenerator
                content={content}
                onFormSubmit={onFormSubmit}
                rows={12}
                eventType="single-page"
                subject={subject}
                setSubject={setSubject}
                description={description}
                setDescription={setDescription}
                isFromTicket
                priorityList={priorityList}
                typeList={typeList}
                topicList={topicList}
                editorValue={editorValue}
                setValue={setValue}
                setEditorValue={setEditorValue}
                btnName="Raise Ticket"
                locationId={locationId}
              />
            </Box>
          </Paper>)}
      </div>
      <BusinessFavicon
        name={businessName || "Bixpand"}
        logo={favIcon || locationBrandImage || brandImage || "https://storage.googleapis.com/bixpand-staging/WebsiteImages/favicon-16x16.png"}
      />
    </>
  );
};

export default TicketRaisePublic;
