/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable no-useless-catch */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Roles } from './const';
import config from '../config';

const enablesRolesAndAccessRoleIds = [Roles.BusinessManager, Roles.AgencyAdmin, Roles.AgencyManager,
  Roles.LocationUser, Roles.LocationManager, Roles.BusinessAdmin];
const errorCodes = [401, 403];

const header = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  businessid: JSON.parse(localStorage?.getItem('business_Id')) || null,
  locationid: JSON.parse(localStorage?.getItem('location_Id')) || null,
  'Apollo-Require-Preflight': 'true',
};

const publicHeaders = {
  'Content-Type': 'application/json',
  Authorization: config?.staticToken,
  'Apollo-Require-Preflight': 'true',
};

const basicHeaders = {
  'Content-Type': 'application/json',
  'Apollo-Require-Preflight': 'true',
};
export const useFetch = (url, params) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);
        const response = await axios({
          url,
          method: 'post',
          headers: basicHeaders,
          data: params,
        });
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [url, params]);
  return { loading, data };
};
export const checkAccess = (userPermissions, menu, moduleName, permission, userRoleId) => {
  if (enablesRolesAndAccessRoleIds?.includes(userRoleId)) {
    return userPermissions?.includes(
      menu && menu?.find((name) => name.name === moduleName)?.permissions.find((name) => (
        name.name === permission
      ))?.permissionid,
    );
  }
  return true;
};
export const dataRequest = async function (url, params) {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: header,
      data: params,
    });
    return response.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status) && localStorage.getItem('token')) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  } finally {
    // console.log('API executed successfully');
  }
};

export const postDataRequestImageUpload = async function (url, data) {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: header,
      data,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 && localStorage.getItem('token')) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  } finally {
    // console.log('API executed successfully');
  }
};
export const postDataRequestPublic = async function (url, variables) {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: publicHeaders,
      data: variables,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 && localStorage.getItem('token')) {
      localStorage.clear();
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  } finally {
    // console.log('API executed successfully');
  }
};
export const deleteDataRequest = async function (url, variables) {
  try {
    const response = await axios({
      url,
      method: 'delete',
      headers: header,
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status) && localStorage.getItem('token')) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  } finally {
    // console.log('API executed successfully');
  }
};

export const putDataRequest = async function (url, variables) {
  try {
    const response = await axios({
      url,
      method: 'put',
      headers: header,
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status) && localStorage.getItem('token')) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  } finally {
    // console.log('API executed successfully');
  }
};

export const postDataRequest = async function (url, variables) {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: header,
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status) && localStorage.getItem('token')) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  } finally {
  }
};
export const getDataRequest = async function (url, variables) {
  try {
    const response = await axios({
      url,
      method: 'get',
      headers: header,
      data: variables,
    });
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    // console.log('API executed successfully');
  }
};

export const getDataRequestPublic = async function (url, variables) {
  try {
    const response = await axios({
      url,
      method: 'get',
      headers: publicHeaders,
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (error?.response?.status === 403 && localStorage.getItem('token')) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const handleUndefined = (message) => (
  (message
            && (message !== 'undefined' && message !== 'null' ? message : ''))
        || ''
);
export const handleValidWebsite = (url) => (
  (url
            && (!!(url !== 'undefined'
                && url !== 'null'
                && (url.startsWith('https://') || url.startsWith('http://')))))
        || ''
);
export const handleValidEmail = (email) => (
  (email
            && (!!(email !== 'undefined'
                && email !== 'null'
                && (/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
                  email,
                )))))
        || ''
);

export function ValidateSubdomain() {
  const domainUrl = new URL(window?.location?.href);
  const parsedDomainUrl = domainUrl?.hostname;
  const domainWithoutWWW = parsedDomainUrl?.replace('www.', '');
  const subdomainValue = domainWithoutWWW?.split('.')[0];

  return subdomainValue === 'app' || subdomainValue === 'dev' || subdomainValue === 'localhost';
}
