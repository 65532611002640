import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  bixpoweredBy: {
    width: '140px',
    aspectRatio: 'auto',
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: '140px',
      aspectRatio: 'auto',
      objectFit: 'contain',
    },
  },
}));