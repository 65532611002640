/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { postDataRequestPublic, handleUndefined } from '../../../common/commonfunctions';
import { AddActivityAffiliateLink, AffiliateLinkByShorturl } from '../../../Api Mutation/AffiliateApi';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import config from '../../../config';
import PoweredBy from '../../../common/PoweredBy';

export default function AffiliatePublicPage(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const {
    content, bid,
  } = useParams([]);
  const [emailTemplateHtml, setEmailTemlateHtml] = useState();
  const [contentMarketingId, setContentMarketingId] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState(moment().format());
  const [locationId, setLocationId] = useState('');
  const ref = useRef(null);
  const { onClickOutside } = props;

  const fetchData = async (prop) => {
    if (handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id)) {
      const CREATE_ACTIVITY = AddActivityAffiliateLink;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITY,
        variables: {
          affiliateid: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId) || handleUndefined(prop?.id),
          openeddata: prop?.opendata,
          clickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getDataBytemplate = async () => {
    if (bid) {
      await postDataRequestPublic(config.baseURLApi, {
        query: AffiliateLinkByShorturl,
        variables: {
          shorturl: content,
          ownerid: bid,
        },
      }).then(async (res) => {
        if (res?.errors && res?.errors[0]?.message) {
          await showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.affiliateLinkbyshorturl) {
          await setContentMarketingId(res?.data?.affiliateLinkbyshorturl?.affiliateid);
          await localStorage.setItem('cid', res?.data?.affiliateLinkbyshorturl?.affiliateid);
          await setEmailTemlateHtml(res?.data?.affiliateLinkbyshorturl?.contenttemplate);
          setLocationId(res?.data?.affiliateLinkbyshorturl?.locationid);
        }
        await setDataLoading(false);
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside && onClickOutside();
      } else if (e?.target?.parentNode?.localName === 'a') {
        if (e?.target?.parentNode?.localName === 'a' && e?.target?.parentNode?.href) {
          const clickedData = {
            timestamp: new Date(),
            url: e?.target?.parentNode?.href,
            name: e?.target?.parentNode?.innerText,
          };
          e.preventDefault();
          if (e?.target?.parentNode?.href?.includes(config.BixPublicUrl)) {
            window.open(`${config.BixPublicUrl}/register?bid=${bid}`);
          }
          fetchData({
            id: handleUndefined(localStorage.getItem('cid')) || handleUndefined(contentMarketingId),
            click: clickedData,
          });
        }
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    fetchData({
      opendata: JSON.stringify({
        timestamp: startDate,
      }),
    });
  }, []);

  useEffect(() => {
    getDataBytemplate();
  }, [bid]);

  return (
    <div>
      {dataLoading ? <LoadingPage /> : ''}
      <center>
        <div ref={ref} dangerouslySetInnerHTML={{ __html: emailTemplateHtml }} />
      </center>
      <PoweredBy id={locationId} />
    </div>
  );
}
