import gql from 'graphql-tag';

export const Businesseventsbylocation = `query Businesseventsbylocation($locationid: ID!,$pagenumber: Int, $pagesize: Int) {
    businesseventsbylocation(locationid: $locationid, pagenumber: $pagenumber, pagesize: $pagesize) {
      createdAt
      createdby
      deleted
      description
      documents
      eventbookingurl
      eventenddate
      eventid
      eventimage
      eventprice
      eventstartdate
      formdata
      hostimage
      hostname
      isemailfollowup
      firstemailreminders
      secondemailreminders
      thirdemailreminders
      ispaid
      isshowandroidicon
      isshowiosicon
      isshowphoneicon
      isshowwhatsappicon
      issendemail
      issendsms
      location
      locationid
      name
      status
      totalattendee
      updatedAt
      updatedby
      timezone
      tags
      count
    }
}`;

export const Cancelbusinessevent = `mutation Cancelbusinessevent(
  $eventid: ID!,
  $status: String!,
  $emailconfig: String!
  $updatedby: ID!) {
  cancelbusinessevent(
    eventid: $eventid,
    status: $status
    emailconfig: $emailconfig
    updatedby: $updatedby
  ) 
}`;

export const Deletebusinessevent = `mutation Deletebusinessevent(
  $eventid: ID!
) {
  deletebusinessevent(
    eventid: $eventid
  ) 
}`;

export const Businesseventbooking = `mutation Businesseventbooking($eventid: ID!, $responsedata: String, $contactid: ID, $organizationid: ID, $locationid: ID, $businessid: ID, $paymentid: ID, $timezone: String) {
  businesseventbooking(eventid: $eventid, responsedata: $responsedata, contactid: $contactid, organizationid: $organizationid, locationid: $locationid, businessid: $businessid, paymentid: $paymentid, timezone: $timezone) {
    bookingid
    eventid
    responsedata
    createdby
    updatedby
    locationid
    businessid
    contactid
    organizationid
    deleted
    createdAt
    updatedAt
    message
  }
}`;

export const businesseventreport = `query businesseventreport($eventid: ID!){
  businesseventreport(eventid: $eventid){
    eventid,
    name,
    location,
    hostname,
    updatedAt,
    reportuser{
      contactid,
      organizationid,
      fullname,
      email,
      phonenumber,
      ipaddress,
      city,
      state,
      country,
      updatedAt
    }
  }
}`;

export const BusinessEventData = `query businesseventdata($eventid: ID!){
businesseventdata(eventid: $eventid){
  createdAt
  createdby
  deleted
  description
  documents
  eventbookingurl
  eventenddate
  eventid
  eventimage
  eventprice
  eventstartdate
  formdata
  hostimage
  hostname
  isemailfollowup
  firstemailreminders
  secondemailreminders
  thirdemailreminders
  ispaid
  isshowandroidicon
  isshowiosicon
  isshowphoneicon
  isshowwhatsappicon
  issendemail
  issendsms
  location
  locationid
  name
  radioview
  checkboxview
  status
  totalattendee
  updatedAt
  updatedby
  timezone
  tags
  count
  themecolor
backgroundcolor
textcolor
calloutbuttontext
calloutbtnposition
btnthemecolor
btntextcolor
ispublish
workflowid
termenable
fieldtextcolor
termdesc
formwidth
captchaenable
isshowfooterlogo
}
}`;

export const Createcontactorganization = `mutation Createcontactorganization($eventid: ID!, $responsedata: String!, $locationid: ID!, $businessid: ID!) {
  createcontactorganization(eventid: $eventid, responsedata: $responsedata, locationid: $locationid, businessid: $businessid) {
    contactid
    organizationid
  }
}
`;

export const Createbusinessevent = gql`
 mutation Createbusinessevent($name: String!, $createdby: ID!, $location: String,  $fieldtextcolor: String,
  $description: String, $eventimage: [Upload!], $documents: [Upload], $hostname: String, $hostimage: String, $formdata: String, $eventstartdate: String, $eventbookingurl: String, $ispaid: Boolean, $eventenddate: String, $eventprice: Int, $firstemailreminders: Boolean,$secondemailreminders: Boolean,$thirdemailreminders: Boolean, $isemailfollowup: Boolean, $isshowphoneicon: Boolean, $isshowwhatsappicon: Boolean, $isshowiosicon: Boolean, $isshowandroidicon: Boolean, $issendemail: Boolean, $issendsms: Boolean, $emailconfig: String, $smsconfig: String, $status: String, $locationid: ID, $timezone: String, $tags: String) {
    createbusinessevent(name: $name, createdby: $createdby, location: $location, fieldtextcolor: $fieldtextcolor, description: $description, eventimage: $eventimage, documents: $documents, hostname: $hostname, hostimage: $hostimage, formdata: $formdata, eventstartdate: $eventstartdate, eventbookingurl: $eventbookingurl, ispaid: $ispaid, eventenddate: $eventenddate, eventprice: $eventprice,firstemailreminders: $firstemailreminders,secondemailreminders: $secondemailreminders,thirdemailreminders: $thirdemailreminders, isemailfollowup: $isemailfollowup, isshowphoneicon: $isshowphoneicon, isshowwhatsappicon: $isshowwhatsappicon, isshowiosicon: $isshowiosicon, isshowandroidicon: $isshowandroidicon, issendemail: $issendemail, issendsms: $issendsms, emailconfig: $emailconfig, smsconfig: $smsconfig, status: $status, locationid: $locationid, timezone: $timezone,tags: $tags) {
      eventid
      name
      location
      description
      eventbookingurl
      eventstartdate
      eventenddate
      firstemailreminders
      secondemailreminders
      thirdemailreminders
      isemailfollowup
      isshowphoneicon
      isshowwhatsappicon
      isshowiosicon
      isshowandroidicon
      createdby
      updatedby
      deleted
      locationid
      status
      createdAt
      updatedAt
      totalattendee
      hostname
      hostimage
      eventimage
      documents
      formdata
      fieldtextcolor
      ispaid
      eventprice
      issendemail
      issendsms
      timezone
      tags
      count
    }
}`;

export const Editbusinessevent = gql`
  mutation Editbusinessevent($eventid: ID!, $updatedby: ID!, $fieldtextcolor: String, $name: String, $location: String, $description: String, $eventimage: [Upload!], $documents: [Upload], $hostname: String, $hostimage: String, $formdata: String, $eventstartdate: String, $eventenddate: String, $eventbookingurl: String, $ispaid: Boolean, $eventprice: Int,  $firstemailreminders: Boolean,$secondemailreminders: Boolean,$thirdemailreminders: Boolean, $isshowphoneicon: Boolean, $isemailfollowup: Boolean, $isshowwhatsappicon: Boolean, $isshowiosicon: Boolean, $isshowandroidicon: Boolean, $issendemail: Boolean, $issendsms: Boolean, $emailconfig: String, $smsconfig: String, $status: String, $locationid: ID, $timezone:String, $tags: String) {
    editbusinessevent(eventid: $eventid, updatedby: $updatedby, fieldtextcolor: $fieldtextcolor,name: $name, location: $location, description: $description, eventimage: $eventimage, documents: $documents, hostname: $hostname, hostimage: $hostimage, formdata: $formdata, eventstartdate: $eventstartdate, eventenddate: $eventenddate, eventbookingurl: $eventbookingurl, ispaid: $ispaid, eventprice: $eventprice, firstemailreminders: $firstemailreminders,secondemailreminders: $secondemailreminders,thirdemailreminders: $thirdemailreminders, isshowphoneicon: $isshowphoneicon, isemailfollowup: $isemailfollowup, isshowwhatsappicon: $isshowwhatsappicon, isshowiosicon: $isshowiosicon, isshowandroidicon: $isshowandroidicon, issendemail: $issendemail, issendsms: $issendsms, emailconfig: $emailconfig, smsconfig: $smsconfig, status: $status, locationid: $locationid, timezone: $timezone, tags: $tags)
  }
`;

export const CreateOrUpdatebusinessEventSettings = `mutation CreateOrUpdatebusinessEventSettings($pagetitle: String, $subtitle: String, $innersubtitle: String, $contentposition: String, $status: Int, $businessid: ID!, $locationid: ID!, $createdby: ID!) {
  createOrUpdatebusinessEventSettings(pagetitle: $pagetitle, subtitle: $subtitle, innersubtitle: $innersubtitle, contentposition: $contentposition, status: $status, businessid: $businessid, locationid: $locationid, createdby: $createdby) {
    businessEventsettingsid
    pagetitle
    subtitle
    innersubtitle
    contentposition
    status
    businessid
    locationid
    createdby
    updatedby
  }
}`;

export const BusinessEventSettingsByLocationId = `query BusinessEventSettingsByLocationId($locationid: ID!) {
  businessEventSettingsByLocationId(locationid: $locationid) {
    businessEventsettingsid
    pagetitle
    subtitle
    innersubtitle
    contentposition
    status
    businessid
    locationid
    createdby
    updatedby
    isshowfooterlogo
  }
}`;
