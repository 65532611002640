import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Button, Grid, TextField } from '@mui/material';
import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  required, maxString, composeValidators, mustBeString,
} from '../../../common/FormValidation';
import { handleUndefined, postDataRequestPublic } from '../../../common/commonfunctions';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import useStyle from './StylesPaymentMethod';
import config from '../../../config';
// import PaymentContactInfo from './PaymentContactInfo';
import Widget from '../../../components/Widget/Widget';

export default function Stripe({
  amount, setPaymentId, setActiveTabId, appointment, contactData, event, organizationData,
  userId, businessId, locationId, eventId,product,setThankYou,objectId, formId, inquiryform,
}) {
  const classes = useStyle();
  const elements = useElements();
  const [disable, setDisable] = useState(false);
  const bId = JSON.parse(localStorage.getItem('business_Id'));
  const uId = JSON.parse(localStorage.getItem('userid'));
  const lId = JSON.parse(localStorage.getItem('location_Id'));
  const stripe = useStripe();

  const handleSubmitofStripe = async () => {
    setDisable(true);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    }).then((res) => {
      if (res?.paymentMethod?.id) {
        postDataRequestPublic(`${config.payment}v1/payment-settings/create-strip-payment`, {
          paymentid: res?.paymentMethod?.id,
          businessid: businessId || bId,
          userid: userId || uId,
          locationid: locationId || lId,
          contactid: contactData?.contactid,
          organizationid: organizationData?.organizationid,
          objectid: objectId || eventId || formId,
          email: contactData?.primaryemail ? contactData?.primaryemail : organizationData?.orgemail,
          payername: `${handleUndefined(contactData?.firstname)} ${handleUndefined(contactData?.lastname)} ${handleUndefined(organizationData?.name)}`,
          currency: 'usd',
          status: 'active',
          totalamount: amount,
          type: appointment ? 'APPOINTMENT' : product ? 'PRODUCT' : event ? 'EVENT' : inquiryform ? 'INQUIRYFORM' :  'OTHER',
          serviceprovider: 'STRIPE',
          description: appointment ? 'APPOINTMENT' : product || (event ? 'EVENT' : inquiryform ? 'INQUIRYFORM' : 'OTHER'),
        }).then((data) => {
          if (data?.data) {
            if (data?.data?.status !== 'failed') {
              if (setPaymentId) {
                setPaymentId(data?.data?.businesspaymentid);
              }
              if (setThankYou) {
                setThankYou(true);
              }
              if (setActiveTabId) {
                setActiveTabId(3);
              }
              showNotification('Payment Successfully Done', 'success');
            } else if (data?.data?.errormessage !== null) {
              showNotification(data?.data?.errormessage, 'error');
            } else {
              showNotification('Something went wrong, Please retry..', 'error');
            }
          }
          setDisable(false);
        }).catch((err) => {
          showNotification(err?.response?.data?.message, 'error');
        });
      }
    });
  };
  return (
    <div>
      <Widget>
        <Form
          title="Card Settings"
          onSubmit={handleSubmitofStripe}
          render={({
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    className={classes.fieldofpayment}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      }
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      }
                    }}
                    required
                    name="name"
                    variant="outlined"
                    size="small"
                    validate={composeValidators(required, mustBeString, maxString(20))}
                    type="text"
                    label="Name on Card"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <div
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                    id="card-element"
                  >
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '14px',
                            color: '#32325d',
                            fontWeight:400,
                            fontFamily:
                                                            'Open sans, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                            '::placeholder': {
                              color: '#a0aec0',
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <hr />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disable}
                    startIcon={<CreditCardIcon />}
                    className={classes.cardSaveButton}
                  >
                    Pay
                  </Button>
                </Grid>
              </Grid>
              <br />
            </form>
          )}
        />

      </Widget>
    </div>
  );
}
