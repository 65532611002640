import React, { useEffect, useState } from 'react';
import {
  Box, Button, Paper, Typography, styled,
} from '@mui/material';
import moment from 'moment';
import { postDataRequestPublic } from '../../common/commonfunctions';
import config from '../../config';
import { Entity } from '../../Api Mutation/EntityApi';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { SubscriptionStatus } from '../../common/const';

const UnSubscribe = () => {
  const search = window?.location?.search;
  const params = new URLSearchParams(search);
  const locationId = params.get('l');
  const emailId = params.get('e');
  const campaignrunId = params.get('em');
  const toEmail = params.get('to');
  const fromEmail = params.get('from');
  const [dataLoading, setDataLoading] = useState(false);
  const [website, setWebSite] = useState('');
  const [thankYouMsg, setThankYouMessage] = useState(false);

  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    border: '1px solid lightgrey',
    padding: '20px 0px 40px 20px',
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    marginTop: '5% !important',
    width: '38%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  }));

  useEffect(() => {
    if (locationId) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: locationId },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setWebSite(res?.data?.entity?.website);
          }
          setDataLoading(false);
        });
    }
  }, [locationId]);

  const unSubscribe = () => {
    setDataLoading(true);
    postDataRequestPublic(config.baseURLApi, {
      query: `mutation Updatecontactbyemail($locationid: ID!, $emailid: ID, $primaryemail: String!, $subscriptionstatus: Int, $emailsubscriptiondate: String, $emailunsubscriptiondate: String, $campaignrunid: ID) {
            updatecontactbyemail(locationid: $locationid, emailid: $emailid, primaryemail: $primaryemail, subscriptionstatus: $subscriptionstatus, emailsubscriptiondate: $emailsubscriptiondate, emailunsubscriptiondate: $emailunsubscriptiondate, campaignrunid: $campaignrunid)
          }`,
      variables: {
        locationid: locationId,
        primaryemail: toEmail,
        subscriptionstatus: SubscriptionStatus.UnSubscribe,
        emailunsubscriptiondate: moment(Date.now()).utc().format(),
        emailid: emailId,
        campaignrunid: campaignrunId,
      },
    })
      .then((res) => {
        if (res?.data?.updatecontactbyemail) {
          setThankYouMessage(true);
        }
        setDataLoading(false);
      });
  };


  return (
    <>
      {dataLoading ? <LoadingPage /> : ''}
      <StyledBox>
      {thankYouMsg
          ? (
            <StyledPaper elevation={6}>
              <center>
                <Typography variant="h6">We are sad to see you go!</Typography>
                <Typography variant="body1">You have been unsubscribed and will no longer here from us.</Typography>
              </center>
            </StyledPaper>
          )
          : 
        (<StyledPaper elevation={6}>
          <center>
            <Typography variant="h6">Unsubscribe Form</Typography>
          </center>
          <Box style={{ marginTop: '1rem' }}>
            <Typography variant="body2">{`You are receiving this email from ${fromEmail}`}</Typography>
            <Typography variant="body2" color="primary">{toEmail}</Typography>
          </Box>
          <Box style={{ marginTop: '1rem' }}>
            <Typography variant="body2">{`Unsubscribe to all emails from ${fromEmail}`}</Typography>
          </Box>
          <Box style={{ marginTop: '3rem' }}>
            <Button
              onClick={() => unSubscribe()}
              size="small"
              variant="contained"
              style={{
                color: '#000',
                background: '#f8f8f8',
                cursor: 'pointer',
                border: '1px solid #005eb2',
                boxShadow: '2px 3px 2px 2px rgba(0,94,178,0.3), 0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                borderRadius: 12,
                fontWeight: 400,
              }}
            >
              UNSUBSCRIBE
            </Button>
            <Button
              onClick={() => {
                window.open(website, '_blank');
              }}
              size="small"
              variant="contained"
              style={{
                color: '#000',
                background: '#f8f8f8',
                cursor: 'pointer',
                border: '1px solid #005eb2',
                boxShadow: '2px 3px 2px 2px rgba(0,94,178,0.3), 0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                borderRadius: 12,
                marginLeft: '0.5rem',
                fontWeight: 400,
              }}
            >
              CANCEL
            </Button>
          </Box>
        </StyledPaper>)
      }
      </StyledBox>
    </>
  );
};

export default UnSubscribe;
