import React from 'react';
import useStyle from "./Style";
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';

function TicketAttchments({ attchedFiles, setAttachedFiles, updateValues, setUpdatedValues, isConversation }) {
    const classes = useStyle();

    const renderPreview = (url) => {
        const fileType = url.split('.').pop().toLowerCase();
        const fileName = url?.split('/')?.[5];

        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
            return (
                <div className={classes.renderTag}>
                    <center>
                        <Link href={url} target="_blank" className={classes.fileText}>{fileName}</Link>
                    </center>
                    <img
                        src={url}
                        alt={fileName || "Preview"}
                        className={classes.renderAttachImg}
                    />
                </div>
            );
        } else if (fileType === 'pdf') {
            return (
                <div className={classes.renderTag}>
                    <center>
                        <Link href={url} target="_blank" className={classes.fileText}>{fileName}</Link>
                    </center>
                    <embed
                        src={url}
                        type="application/pdf"
                        className={classes.renderAttachEmbed}
                    />
                </div>
            );
        } else if (fileType === 'txt') {
            return (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.renderAttachmentText}
                >
                    {fileName}
                </a>
            );
        } else {
            return (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.renderAttachmentText}
                >
                    {fileName}
                </a>
            );
        }
    };

    const handleRemove = (index) => {
        const updatedFiles = attchedFiles.filter((_, i) => i !== index);
        if (isConversation) {
            setAttachedFiles(updatedFiles);
        } else {
            const ticketAttachmentObject = updateValues.find(item => item.ticket_attachment);
            if (ticketAttachmentObject) {
                let attachmentsArray = JSON.parse(ticketAttachmentObject.ticket_attachment);
                attachmentsArray = attachmentsArray.filter((_, i) => i !== index);
                ticketAttachmentObject.ticket_attachment = JSON.stringify(attachmentsArray);
            }
            setAttachedFiles(updatedFiles);
            setUpdatedValues([...updateValues]);
        }
    };

    return (
        <div className={classes.attachments}>
            <Typography className={classes.attachmentHeading}>Attachments</Typography>
            <br />
            <Grid container spacing={3}>
                {attchedFiles?.map((url, index) => (
                    <Grid item lg={3} md={4} sm={4} xs={12} key={index} className={classes.renderItem}>
                        <Box className={classes.renderBox}>
                            {renderPreview(url)}
                            <IconButton className={classes.removeBtn} onClick={() => { handleRemove(index) }}>
                                <CancelOutlined />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default TicketAttchments;
