/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useStyle from './PublicEventWidgetStyle';
import { useTheme } from '@mui/styles';
import { Entity } from '../../../Api Mutation/EntityApi';
import { postDataRequestPublic } from '../../../common/commonfunctions';
import { BusinessEventSettingsByLocationId, Businesseventsbylocation } from '../../../Api Mutation/Event';
import config from '../../../config';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import { Box, Button, Link, MobileStepper, Typography, styled } from '@mui/material';
import { dummyimage } from '../../../common/const';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import PoweredByLogo from '../../../common/PoweredByLogo';

export default function PublicEventWidget() {
  const classes = useStyle();
  const [dataLoading, setDataLoading] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [titleData, setTitleData] = useState();
  const [eventData, setEventData] = useState([]);
  const [whiteLabelStatus, setWhiteLabelStatus] = useState();
  const [subDomain, setSubDomain] = useState();
  const [entityData, setEntityData] = useState();
  const {
    lid,
  } = useParams([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();

  const PageTitle = styled(Typography)(({ theme }) => ({
    fontSize: ' 1.25rem ',
    fontFamily: 'Open Sans ',
    fontWeight: '600 ',
    color: '#005eb2 ',
  }));
  const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: ' 14px ',
    fontFamily: 'Open Sans ',
    fontWeight: '600 ',
    color: '#005eb2 ',
  }));
  const InnerSubTitle = styled(Typography)(({ theme }) => ({
    fontSize: ' 12px ',
    fontFamily: 'Open Sans ',
    fontWeight: '400 ',
    color: '#005eb2 ',
  }));
  const Eventname = styled(Typography)(({ theme }) => ({
    fontSize: ' 1.25rem ',
    fontFamily: 'Open Sans ',
    fontWeight: '600 ',
  }));
  const EventDate = styled(Typography)(({ theme }) => ({
    fontSize: ' 12px ',
    fontFamily: 'Open Sans ',
    fontWeight: '400 ',
  }));
  const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: ' 0.8125rem ',
    fontFamily: 'Open Sans ',
    fontWeight: '500 ',
    color: '#4A4A4A',
    textTransform: 'capitalize',
  }));

  const maxSteps = eventData.length > 0 ? Math.ceil(eventData[0]?.count / 5) : 0;
  const rowsPerPage = 5;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getBusinessEventData = (props) => {
    if (lid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Businesseventsbylocation,
        variables: {
          locationid: lid,
          pagenumber: props.pageNumber + 1,
          pagesize: props.pageSize,
        },
      }).then((res) => {
        setEventData(res?.data?.businesseventsbylocation);
        setDataLoading(false);
      });
    }
  };

  const getWidgetSetting = () => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: BusinessEventSettingsByLocationId,
        variables: { locationid: lid },
      }).then((res) => {
        const data = res?.data?.businessEventSettingsByLocationId;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data) {
          setTitleData(data);
          setEnablePublicLogo(data?.isshowfooterlogo);
        }
      });
    }
  };

  useEffect(() => {
    getBusinessEventData({
      pageNumber: activeStep,
      pageSize: rowsPerPage,
    });
  }, [activeStep, lid, rowsPerPage]);

  useEffect(() => {
    getWidgetSetting();
  }, [lid]);

  useEffect(() => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: lid,
        },
      }).then((result) => {
        const data = result?.data?.entity;
        if (data) {
          setEntityData(data);
          setWhiteLabelStatus(data?.whitelabeltype);
          setSubDomain(data?.whitelabeldomain);
        }
      });
    }
    getWidgetSetting();
  }, [lid]);

  return (
    <>
      {dataLoading && <LoadingPage />}
      <Box className={classes.Widget}>
        <Box className={titleData?.contentposition === 'center' ? classes.WidgetLayout : classes.WidgetLayoutAlign}>
          <PageTitle variant="h6" color="primary">
            {titleData?.pagetitle}
          </PageTitle>
          <SubTitle variant="body1" color="primary">
            {titleData?.subtitle}
          </SubTitle>
          <InnerSubTitle variant="body2" color="primary">
            {titleData?.innersubtitle}
          </InnerSubTitle>
        </Box>
        <Box className={classes.contentsText}>
          {eventData && eventData.map((i) => (
            <Box className={classes.contents}>
              <Box>
                <Link
                  href={whiteLabelStatus === 'full' && subDomain && entityData?.subdomain
                    ? `https://${entityData?.subdomain}.${subDomain}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}`
                    : `https://${entityData?.subdomain}${config.subDomainUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}`
                    || `${config.BixUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}` || i?.eventbookingurl
                  }
                  target="_blank"
                >
                  <img className={classes.img} src={i?.eventimage ? i?.eventimage : dummyimage} alt="demo" />
                </Link>
              </Box>
              <Box className={classes.contentsText}>
                <Link
                  href={
                    whiteLabelStatus === 'full' &&
                      subDomain &&
                      entityData?.subdomain
                      ? `https://${entityData?.subdomain}.${subDomain}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}`
                      : `https://${entityData?.subdomain}${config.subDomainUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}` ||
                      `${config.BixUrl}/ep/${i?.eventid}/${lid}/${entityData?.parententityid}` ||
                      i?.eventbookingurl
                  }
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <Eventname variant="h6">
                    {i?.name}
                  </Eventname>
                </Link>
                <EventDate variant="body2">
                  {moment(parseInt(i?.eventstartdate, 10)).format('dddd, MMMM DD,YYYY HH:mm A z')}
                  {' '}
                  To
                  {' '}
                  {moment(parseInt(i?.eventenddate, 10)).format('dddd, MMMM DD,YYYY HH:mm A z')}
                </EventDate>
              </Box>
            </Box>
          ))}
          <MobileStepper
            steps={maxSteps}
            style={{ fontFamily: 'Open sans' }}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={(
              <StyledButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </StyledButton>
            )}
            backButton={(
              <StyledButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </StyledButton>
            )}
          />
          <center>
            <PoweredByLogo enablePublicLogo={enablePublicLogo} id={entityData?.entityid} />
          </center>
        </Box>
      </Box>
    </>
  );
}
