import {
  ApolloClient, HttpLink, InMemoryCache, split,
} from '@apollo/client';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import config from './config';

const backendUrls = {
  http: config.subscription,
  ws: config.subscriptionio,
};

const { http, ws } = backendUrls;

const loadUser = () => localStorage.getItem('token');

const loggedUser = loadUser();

const wsLink = new GraphQLWsLink(
  createClient({
    url: ws,
    connectionParams: () => ({
      Authorization: loggedUser,
    }),
  }),
);

const httpLink = new HttpLink({
  uri: http,
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default client;
