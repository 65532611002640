export const NewsletterEmailByLocationId = `query newsletterEmailByLocationId($locationid: ID!, $pageNumber: Int!, $pageSize: Int!, $status: Int, $startdate: String, $enddate: String, $searchtext: String, $ispublish: Boolean) {
    newsletterEmailByLocationId(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize, status: $status, startdate: $startdate, enddate: $enddate, searchtext: $searchtext, ispublish: $ispublish) {
      count
      rows {
        newsletteremailid
        name
        category
        author
        listpagetitle
        listpageimage
        subject
        fromname
        fromemail
        emailhtml
        webpagehtml
        emaildesign
        newslettersettingsid
        ownerid
        locationid
        status
        ispublish
        createdby
        updatedby
        createdAt
        updatedAt
        webpageopeneddata
        webpageclickeddata
        webpagesharecount
        shorturl
        businessid
        senditnow
        scheduledatetime
        serviceproviderid
        serviceprovider
      }
    }
  }`;

export const CreateNewsletterEmail = `mutation createNewsletterEmail($ownerid: ID!, $locationid: ID!, $createdby: ID!, $businessid: ID!, $author: String, $name: String, $listpagetitle: String, $listpageimage: String, $subject: String, $fromname: String, $fromemail: String, $emailhtml: String, $webpagehtml: String, $emaildesign: String, $webpagedesign: String, $newslettersettingsid: ID, $status: Int, $webpageopeneddata: String, $webpageclickeddata: String, $webpagesharecount: Int, $senditnow: Boolean, $scheduledatetime: String, $serviceproviderid: ID, $serviceprovider: Int) {
    createNewsletterEmail(ownerid: $ownerid, locationid: $locationid, createdby: $createdby, businessid: $businessid, author: $author, name: $name, listpagetitle: $listpagetitle, listpageimage: $listpageimage, subject: $subject, fromname: $fromname, fromemail: $fromemail, emailhtml: $emailhtml, webpagehtml: $webpagehtml, emaildesign: $emaildesign, webpagedesign: $webpagedesign, newslettersettingsid: $newslettersettingsid, status: $status, webpageopeneddata: $webpageopeneddata, webpageclickeddata: $webpageclickeddata, webpagesharecount: $webpagesharecount, senditnow: $senditnow, scheduledatetime: $scheduledatetime, serviceproviderid: $serviceproviderid, serviceprovider: $serviceprovider) {
      newsletteremailid
      name
      category
      author
      listpagetitle
      listpageimage
      subject
      fromname
      fromemail
      emailhtml
      webpagehtml
      emaildesign
      newslettersettingsid
      ownerid
      locationid
      status
      createdby
      updatedby
      createdAt
      updatedAt
      webpageopeneddata
      webpageclickeddata
      webpagesharecount
      shorturl
      businessid
      senditnow
      scheduledatetime
      serviceproviderid
      serviceprovider
    }
  }`;

export const EditNewsletterEmail = `mutation editNewsletterEmail($newsletteremailid: ID!, $updatedby: ID!, $name: String, $author: String, $listpagetitle: String, $listpageimage: String, $pageimage: Upload, $subject: String, $fromname: String, $fromemail: String, $emailhtml: String, $webpagehtml: String, $emaildesign: String, $webpagedesign: String, $newslettersettingsid: ID, $ownerid: ID, $locationid: ID, $status: Int, $webpageopeneddata: String, $webpageclickeddata: String, $webpagesharecount: Int, $businessid: ID, $senditnow: Boolean, $scheduledatetime: String, $serviceproviderid: ID, $serviceprovider: Int) {
  editNewsletterEmail(newsletteremailid: $newsletteremailid, updatedby: $updatedby, name: $name, author: $author, listpagetitle: $listpagetitle, listpageimage: $listpageimage, pageimage: $pageimage, subject: $subject, fromname: $fromname, fromemail: $fromemail, emailhtml: $emailhtml, webpagehtml: $webpagehtml, emaildesign: $emaildesign, webpagedesign: $webpagedesign, newslettersettingsid: $newslettersettingsid, ownerid: $ownerid, locationid: $locationid, status: $status, webpageopeneddata: $webpageopeneddata, webpageclickeddata: $webpageclickeddata, webpagesharecount: $webpagesharecount, businessid: $businessid, senditnow: $senditnow, scheduledatetime: $scheduledatetime, serviceproviderid: $serviceproviderid, serviceprovider: $serviceprovider)
}`;

export const Newslettersubscribedcontacts = `query newslettersubscribedcontacts($locationid: ID!, $pagenumber: Int!, $pagesize: Int!, $searchtext: String, $sortbycolumn: String, $sortorder: String) {
  newslettersubscribedcontacts(locationid: $locationid, pagenumber: $pagenumber, pagesize: $pagesize, searchtext: $searchtext, sortbycolumn: $sortbycolumn, sortorder: $sortorder) {
    count
    totalallcontact
    rows {
      contactid
      primaryemail
      fullname
      firstname
      lastname
      phonenumber
      tags
      source
      subscriptionstatus
      emailsubscriptiondate
      emailunsubscriptiondate
      lifecyclestage
      status
      locationid
      createdby
      updatedby
      organizationid
      ownerid
      createdAt
      updatedAt
    }
  }
}`;

export const NewsletterEmail = `query newsletterEmail($newsletteremailid: ID!) {
  newsletterEmail(newsletteremailid: $newsletteremailid) {
    newsletteremailid
    name
    category
    author
    listpagetitle
    listpageimage
    subject
    fromname
    fromemail
    emailhtml
    webpagehtml
    emaildesign
    isshowfooterlogo
    webpagedesign
    newslettersettingsid
    ownerid
    locationid
    status
    createdby
    updatedby
    createdAt
    updatedAt
    webpageopeneddata
    webpageclickeddata
    webpagesharecount
    shorturl
    businessid
    senditnow
    scheduledatetime
    serviceproviderid
    serviceprovider
  }
}`;
export const CreateOrUpdateNewsletterSettings = `mutation createOrUpdateNewsletterSettings($ownerid: ID!, $businessid: ID!, $locationid: ID!, $createdby: ID!, $pagetitle: String, $subtitle: String, $contentposition: String, $isshowsubscribeform: Boolean, $lifecyclestage: Int, $subscriptiontitle: String, $status: Int) {
  createOrUpdateNewsletterSettings(ownerid: $ownerid, businessid: $businessid, locationid: $locationid, createdby: $createdby, pagetitle: $pagetitle, subtitle: $subtitle, contentposition: $contentposition, isshowsubscribeform: $isshowsubscribeform, lifecyclestage: $lifecyclestage, subscriptiontitle: $subscriptiontitle, status: $status) {
    newslettersettingsid
    pagetitle
    subtitle
    contentposition
    isshowsubscribeform
    lifecyclestage
    subscriptiontitle
    ownerid
    status
    businessid
    locationid
    createdby
    updatedby
  }
}`;

export const DeleteNewsletterEmail = `mutation deleteNewsletterEmail($newsletteremailid: ID!) {
  deleteNewsletterEmail(newsletteremailid: $newsletteremailid)
}`;

export const ReplicateNewsletterEmail = `mutation replicateNewsletterEmail($newsletteremailid: ID!) {
  replicateNewsletterEmail(newsletteremailid: $newsletteremailid) {
    newsletteremailid
    name
    category
    author
    listpagetitle
    listpageimage
    subject
    fromname
    fromemail
    emailhtml
    webpagehtml
    emaildesign
    webpagedesign
    newslettersettingsid
    ownerid
    locationid
    status
    createdby
    updatedby
    createdAt
    updatedAt
    webpageopeneddata
    webpageclickeddata
    webpagesharecount
    shorturl
    businessid
    senditnow
    scheduledatetime
    serviceproviderid
    serviceprovider
  }
}`;

export const UniqueNewsletterEmailUrlReportById = `query uniqueNewsletterEmailUrlReportById($newsletteremailid: ID!, $url: String!) {
  uniqueNewsletterEmailUrlReportById(newsletteremailid: $newsletteremailid, url: $url) {
    url
    totalcount
    uniquecount
    customerid
    datetime
    contact {
      contactid
      firstname
      phonenumber
      primaryemail    }
  }
}`;

export const NewsletterEmailUrlReportById = `query newsletterEmailUrlReportById($newsletteremailid: ID!, $url: String!) {
  newsletterEmailUrlReportById(newsletteremailid: $newsletteremailid, url: $url) {
    url
    totalcount
    uniquecount
    customerid
    datetime
    contact {
      contactid
      firstname
      phonenumber
      primaryemail
    }
  }
}`;

export const CampaignRunsbyNewsletterEmail = `query campaignRunsbyNewsletterEmail($newsletteremailid: ID!, $pagenumber: Int!, $pagesize: Int!, $isdelivered: Boolean, $isopened: Boolean, $isclicked: Boolean, $isbounced: Boolean, $isunsubscribed: Boolean, $iserror: Boolean) {
  campaignRunsbyNewsletterEmail(newsletteremailid: $newsletteremailid, pagenumber: $pagenumber, pagesize: $pagesize, isdelivered: $isdelivered, isopened: $isopened, isclicked: $isclicked, isbounced: $isbounced, isunsubscribed: $isunsubscribed, iserror: $iserror) {
    count
    rows {
      campaignrunid
      sendto
      sgemailid
      opened
      status
      openeddate
      delivereddate
      clickeddate
      bounceddate
      unsubscribeddate
      scheduleat
      senttime
      errormessage
      customerid
      locationid
      createdby
      updatedby
      emailcampaignid
      campaignrunstatus
      campaign {
        campaignid
        name
        communicationtypeid
        subject
        body
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        reviewtypeid
        createdAt
        updatedAt
        campaigntype
      }
      serviceproviderid
      serviceprovider
      threadid
      openeddata
      clickeddata
      opencount
      clickcount
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
      }
      newsletteremailid
      emailmarketing {
        emailcampaignid
        name
        fromname
        fromemail
        subject
        emailtemplate
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        createdAt
        updatedAt
        templatedesign
        totaldelivered
        totalopened
        totalclicked
        totalbounced
        totalunsubscribed
        serviceproviderid
        serviceprovider
        totalsentemail
      }
    }
  }
}`;

export const NewsletterEmailReportData = `query newsletterEmailReportData($newsletteremailid: ID!) {
  newsletterEmailReportData(newsletteremailid: $newsletteremailid) {
    totalerror
    totalsuccess
    totalsentemail
    total
    errorpercentage
    successpercentage
    lastopeneddate
    lastclickeddate
    scheduleat
    totalclicked
    totaldelivered
    totalbounced
    totalopened
    totalunsubscribed
    campaignid
    name
    communicationtypeid
    subject
    body
    customers
    senditnow
    scheduledatetime
    graphdata
    locationid
    createdby
    updatedby
    status
    reviewtypeid
    createdAt
    updatedAt
    customerid
    newsletteremailid
    communicationtypename
    reviewtypename
    emailtemplate
    fromemail
    fromname
    serviceproviderid
    serviceprovider
    threadid
    openeddata
    clickeddata
  }
}`;

export const NewsletterEmailClickReportById = `query newsletterEmailClickReportById($newsletteremailid: ID!) {
  newsletterEmailClickReportById(newsletteremailid: $newsletteremailid) {
    url
    totalcount
    uniquecount
    customerid
    datetime
    contact {
      contactid
      firstname
      lastname
      phonenumber
      primaryemail
    }
  }
}`;

export const NewsletterSettingsByLocationId = `query newsletterSettingsByLocationId($locationid: ID!) {
  newsletterSettingsByLocationId(locationid: $locationid) {
    newslettersettingsid
    pagetitle
    subtitle
    contentposition
    isshowsubscribeform
    lifecyclestage
    subscriptiontitle
    ownerid
    status
    businessid
    locationid
    createdby
    updatedby
    isshowfooterlogo
  }
}`;

export const AddActivityNewsletterEmail = `mutation addActivityNewsletterEmail($newsletteremailid: ID!, $webpageopeneddata: String, $webpageclickeddata: String) {
  addActivityNewsletterEmail(newsletteremailid: $newsletteremailid, webpageopeneddata: $webpageopeneddata, webpageclickeddata: $webpageclickeddata)
}`;

export const NewsletterEmailUrlReportsById = `query newsletterEmailUrlReportsById($newsletteremailid: ID!) {
  newsletterEmailUrlReportsById(newsletteremailid: $newsletteremailid) {
    count
    url
  }
}`;
