/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Step,
  StepLabel,
  useMediaQuery,
  Stepper,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import TimezoneSelect from "react-timezone-select";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PublicIcon from "@mui/icons-material/Public";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import VideocamIcon from "@mui/icons-material/Videocam";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "./appointment.css";
import {
  BrandSettingByLocation,
  Entity,
} from "../../../../Api Mutation/EntityApi";
import { Userprofilebyuser } from "../../../../Api Mutation/Login";
import Payment from "../../../../pages/Setting/PaymentConnection/Payment";
import {
  handleUndefined,
  postDataRequestPublic,
} from "../../../../common/commonfunctions";
import {
  ActiveAppointmentStatus,
} from "../../../../common/const";
import config from "../../../../config";
import useStyle from "./style";
import { LoadingPage } from "../../../../pages/LoadingPage/LoadingPage";
import {
  editBooking,
  eventBookingData,
  eventsAvailableTime,
  eventByData,
  bookEvent,
  CreateContactOrg,
} from "../../../../Api Mutation/Appointment";
import FormGenerator from "../../InquiryForm/FormGenerator";
import { showNotification } from "../../../../components/Notification/ToastifyNotifications";
import BusinessFavicon from "../../../../common/BusinessFavicon";
import PoweredByLogo from "../../../../common/PoweredByLogo";

export default function AppintmentPublic() {
  const classes = useStyle();
  const [activeTabId, setActiveTabId] = useState(0);
  const matches = useMediaQuery("(max-width:600px)");
  const { eventId, lid, uid, bid } = useParams([]);
  const history = useNavigate();
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const bookid = params.get("bookid");
  const user = params.get("user");
  const contactid = params.get("contactId");
  const steps = ["Date & Time", "Payment", "Details", "Confrim"];
  const suggestContact = [];
  const [value, setValue] = useState(Date.now());
  const [dataLoading, setDataLoading] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [bookingId, setBookingId] = useState();
  const [eventData, setEventData] = useState();
  const [bookingDate, setBookingDate] = useState();
  const [profileData, setProfileData] = useState();
  const [fontColorCode, setFontColorCode] = useState('black');
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [favIcon, setFavicon] = useState(null);
  const [contact, setContact] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [contactId, setContactId] = useState();
  const [contactData, setContactData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [brandImage, setBrandImage] = useState();
  const [defaultCountryPhoneCode, setDefaultCountryPhoneCode] = useState('1');
  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [termDesc, setTermDesc] = useState();
  const [enableTerm, setEnableTerm] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(false);
  const [content, setContent] = useState([]);
  const [btnName, setBtnName] = useState("Schedule Event");
  const [colorCode, setColorCode] = useState("#005eb2");
  const [backgroundColorCode, setBackgroundColorCode] = useState("#fff");
  const [foreColor, setForeColor] = useState("#000");
  const [btnPos, setBtnPos] = useState("center");
  const [btnThemeColor, setBtnThemeColor] = useState("#005eb2");
  const [btnTextColor, setBtnTextColor] = useState("#fff");
  const [submittedData, setData] = useState("");
  const [onClick, setClick] = useState(false);

  const MeetingTypo = styled(Typography)(({ theme }) => ({
    alignItems: "center",
    fontFamily: "Open sans",
    fontWeight: 400,
    color: "#005eb2",
    fontSize: "12px",
  }));
  const HeadingTypo = styled(Typography)(({ theme }) => ({
    alignItems: "center",
    fontFamily: "Open sans",
    fontWeight: 600,
  }));
  const NameTypo = styled(Typography)(({ theme }) => ({
    alignItems: "center",
    fontFamily: "Open sans",
    fontWeight: 600,
    fontSize: 20,
  }));

  const TimeTypo = styled(Typography)(({ theme }) => ({
    alignItems: "center",
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
  }));

  const WelcomeTypo = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "600",
    color: "#4a4a4a",
    lineHeight: "1.5",
    fontFamily: "Open sans",
  }));

  const MonthTypo = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontFamily: "Open sans",
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  }));

  const ConfirmTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
  }));

  const EventDateTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Open sans",
    fontSize: "1rem",
  }));

  const ConfirmBtn = styled(Button)(({ theme }) => ({
    fontFamily: "Open sans",
    backgroundColor: "#005eb2",
    textTransform: "capitalize",
    fontWeight: 400,
  }));

  const TimeBtn = styled(Button)(({ theme }) => ({
    fontFamily: "Open sans",
    fontSize: 13,
  }));

  const eventBookByData = () => {
    if (bookid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: eventBookingData,
        variables: {
          bookingid: bookid,
        },
      }).then((res) => {
        setDataLoading(false);
        setEventData(res?.data?.eventbookingdata?.event);
        setBookingId(res?.data?.eventbookingdata?.bookingid);
        setBookingDate(res?.data?.eventbookingdata?.bookingdate);
        setContactId(res?.data?.eventbookingdata?.contact?.contactid);
        setStartTime(res?.data?.eventbookingdata?.starttime);
        setFontColorCode(res?.data?.eventbookingdata?.fieldtextcolor);
        setEndTime(res?.data?.eventbookingdata?.endtime);
        setContact(res?.data?.eventbookingdata?.guestemail);
        if (res?.data?.eventbookingdata?.responsedata) {
          setData(JSON.parse(res?.data?.eventbookingdata?.responsedata));
        }
        setDataLoading(false);
      });
    }
  };

  const getEventTime = async (props) => {
    if (handleUndefined(eventId)) {
      setDataLoading(true);
      await postDataRequestPublic(config.baseURLApi, {
        query: eventsAvailableTime,
        variables: {
          eventid: handleUndefined(eventId),
          day: moment(props?.day || value).format("YYYY-MM-DD"),
          timezone:
            props?.timeZone ||
            timeZone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }).then((res) => {
        if (res?.data?.eventsavailabletime) {
          setDataLoading(false);
          const { timeSlot } = res?.data?.eventsavailabletime;
          setTimeData(timeSlot);
          setDataLoading(false);
        }
      });
    }
  };

  const getEventData = () => {
    setDataLoading(true);
    if (handleUndefined(eventId)) {
      postDataRequestPublic(config.baseURLApi, {
        query: eventByData,
        variables: {
          eventid: handleUndefined(eventId),
        },
      }).then((res) => {
        setDataLoading(false);
        setEventData(res?.data?.eventdata);
        setTermDesc(res?.data?.eventdata?.termdesc);
        setEnableTerm(res?.data?.eventdata?.termenable);
        setFontColorCode(res?.data?.eventdata?.fieldtextcolor);
        setEnablePublicLogo(res?.data?.eventdata?.isshowfooterlogo);
        setContent(JSON.parse(res?.data?.eventdata?.formdata));
        setBtnName(res?.data?.eventdata?.calloutbuttontext);
        setColorCode(res?.data?.eventdata?.themecolor);
        setBackgroundColorCode(res?.data?.eventdata?.backgroundcolor);
        setForeColor(res?.data?.eventdata?.textcolor);
        if (res?.data?.eventdata?.btnthemecolor) {
          setBtnThemeColor(res?.data?.eventdata?.btnthemecolor);
          setBtnTextColor(res?.data?.eventdata?.btntextcolor);
        }
        setBtnPos(res?.data?.eventdata?.calloutbtnposition);
        setDataLoading(false);
      });
    }
    postDataRequestPublic(config.baseURLApi, {
      query: Entity,
      variables: { entityid: lid },
    })
      .then((res) => {
        if (res?.data?.entity) {
          setLocationBrandImage(res?.data?.entity?.logourl);
          setFavicon(res?.data?.entity?.favicon);
          if(res?.data?.entity?.defaultphonecountrycode){
            setDefaultCountryPhoneCode(res?.data?.entity?.defaultphonecountrycode);
          }
        }
        setDataLoading(false);
      })
      .catch(() => {
        setDataLoading(false);
      });
    if (handleUndefined(bid)) {
      postDataRequestPublic(config.baseURLApi, {
        query: BrandSettingByLocation,
        variables: {
          locationid: handleUndefined(bid),
        },
      }).then((res) =>
        setBrandImage(res?.data?.brandSettingByLocation?.logourl)
      );
    }
    if (uid) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Userprofilebyuser,
        variables: {
          userId: uid,
        },
      }).then((res) => {
        setProfileData(res?.data?.userprofilebyuser);
      });
    }
  };

  useEffect(() => {
    getEventTime();
  }, [eventId]);

  useEffect(() => {
    if (bookid) {
      eventBookByData();
    }
    if (eventId) {
      getEventData();
    }
  }, [eventId, bid, bookid]);

  const getContact = (cid) => {
    if (cid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
            query contact($contactid: ID!){
              contact(contactid:$contactid){
                contactid,primaryemail,firstname,lastname,contactavatar,phonenumber,lifecyclestage,locationid,
                linkedin,createdby,updatedby,jobid,ownerid,createdAt,updatedAt,istargetlist,ispininchat,organization{
                  organizationid,name,orgwebsite
                }
          }}
          `,
        variables: {
          contactid: cid,
        },
      }).then((res) => {
        if (res?.data?.contact) {
          setContactData(res?.data?.contact);
        }
      });
    }
  };

  const getOrganization = (oid) => {
    if (oid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
                  query organization($organizationid: ID!){
                    organization(organizationid: $organizationid){
                          organizationid,name,locationid,orgphonenumber,orgwebsite,orgtwitter,orgfacebook,orgstatus,categories,employeesize,
                          yearsinbusiness,revenue,businessstartdate,typeofentity,orgmap,orglinkedin,orggmb,orgemail,orgyoutubeurl,
                          address{addressid,street1,street2,city,county,stateid,countryid,zip,country{countryid,name},state{stateid,name}},orgyelp,orgtripadvisor,
                          createdby,updatedby,phonecountryid,createdAt,updatedAt,orgattribute1,orgattribute2,orgattribute3,orgattribute4,
                      }
                  }`,
        variables: {
          organizationid: oid,
        },
      }).then((res) => {
        if (res?.data?.organization) {
          setOrganizationData(res?.data?.organization);
        }
      });
    }
  };

  useEffect(() => {
    getContact(contactid);
  }, [contactid]);

  const handleActiveTabs = (tab) => {
    if (tab < activeTabId) {
      setActiveTabId(tab);
    } else if (activeTabId === 0) {
      setActiveTabId(tab);
      setDataLoading(true);
      if (bookid || bookingId) {
        postDataRequestPublic(config.baseURLApi, {
          query: editBooking,
          variables: {
            bookingid: bookid || bookingId,
            bookingdate: bookingDate || moment(value).format("YYYY-MM-DD"),
            starttime: `${moment(
              `${moment(value).format("YYYY-MM-DD")}${" "}${startTime}`,
              "YYYY-MM-DD hh:mm A"
            ).format("YYYY-MM-DDTHH:mm:ss")}`,
            endtime: `${moment(
              `${moment(value).format("YYYY-MM-DD")}${" "}${endTime}`,
              "YYYY-MM-DD hh:mm A"
            ).format("YYYY-MM-DDTHH:mm:ss")}`,
            meetingduration: eventData?.eventduration,
            eventid: eventId,
            timezone:
              timeZone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
            locationid: lid,
            updatedby: bookid ? (user ? uid : contactId) : uid,
          },
        }).then((res) => {
          if (res?.data?.editbooking) {
            setDataLoading(false);
          }
        });
      } else {
        postDataRequestPublic(config.baseURLApi, {
          query: bookEvent,
          variables: {
            bookingdate: moment(value).format("YYYY-MM-DD"),
            meetingduration: eventData?.eventduration,
            starttime: `${moment(
              `${moment(value).format("YYYY-MM-DD")}${" "}${startTime}`,
              "YYYY-MM-DD hh:mm A"
            ).format("YYYY-MM-DDTHH:mm:ss")}`,
            endtime: eventData?.eventduration === 1440 ? `${moment(value)
              .add(1440, 'minutes').format('YYYY-MM-DDTHH:mm:ss')}` : `${moment(`${moment(value).format('YYYY-MM-DD')}${' '}${endTime}`,
              'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')}`,
            eventid: eventId,
            timezone:
              timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            locationid: lid,
            createdby: uid,
          },
        }).then((res) => {
          if (res?.data?.bookevent) {
            setDataLoading(false);
            setBookingId(res?.data?.bookevent?.bookingid);
            setBookingDate(res?.data?.bookevent?.bookingdate);
            setDataLoading(false);
          }
        });
      }
    } else if (activeTabId === 1) {
      setActiveTabId(tab);
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: CreateContactOrg,
        variables: {
          responsedata: JSON.stringify(submittedData),
          eventid: eventId,
          locationid: lid,
          businessid: bid,
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, "error");
        } else if (res?.data?.createcontactorg) {
          const result = res?.data?.createcontactorg;
          if (result) {
            if (result.contactid) {
              getContact(result.contactid);
            } else if (result.organizationid) {
              getOrganization(result.organizationid);
            }
            postDataRequestPublic(config.baseURLApi, {
              query: editBooking,
              variables: {
                eventid: eventId,
                bookingdate: moment(value).format("YYYY-MM-DD"),
                bookingid: bookingId || bookid,
                starttime: `${moment(
                  `${moment(value).format("YYYY-MM-DD")}${" "}${startTime}`,
                  "YYYY-MM-DD hh:mm A"
                ).format("YYYY-MM-DDTHH:mm:ss")}`,
                endtime: `${moment(
                  `${moment(value).format("YYYY-MM-DD")}${" "}${endTime}`,
                  "YYYY-MM-DD hh:mm A"
                ).format("YYYY-MM-DDTHH:mm:ss")}`,
                timezone:
                  timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
                contactid: result?.contactid,
                issubmit: eventData.ispaidevent !== true,
                isrescheduled: !!bookid,
                paymentid: paymentId || null,
                guestemail: contact,
                organizationid: result?.organizationid,
                responsedata: JSON.stringify(submittedData),
                updatedby: bookid ? (user ? uid : contactId) : uid,
              },
            }).then((res) => {
              if (res?.data?.editbooking) {
                setDataLoading(false);
                if (eventData.ispaidevent !== true) {
                  setActiveTabId(3);
                  showNotification("Data updated successfully", "success");
                }
                setDataLoading(false);
              }
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    if (paymentId) {
      postDataRequestPublic(config.baseURLApi, {
        query: editBooking,
        variables: {
          eventid: eventId,
          bookingdate: moment(value).format("YYYY-MM-DD"),
          bookingid: bookingId || bookid,
          issubmit: true,
          paymentid: paymentId,
          updatedby: bookid ? (user ? uid : contactId) : uid,
        },
      }).then((res) => {
        if (res?.data?.editbooking) {
          setDataLoading(false);
          showNotification("Data updated successfully", "success");
          setDataLoading(false);
        }
      });
    }
  }, [paymentId]);

  const onFormSubmit = (data) => {
    setClick(true);
    setData(data);
  };

  useEffect(() => {
    if (submittedData && onClick) {
      if (eventData.ispaidevent === true) {
        activeTabId === 2
          ? handleActiveTabs(2)
          : handleActiveTabs(activeTabId + 1);
      } else {
        handleActiveTabs(activeTabId + 1);
      }
    }
  }, [submittedData, onClick]);

  useEffect(() => {
    if (contactData) {
      if (content?.length > 0) {
        const arr = [];
        content?.map((con) => {
          const obj = {};
          if (con.objecttype !== 'Designing') {
            obj.name = `${con?.objecttype}_${con?.name}`;
            obj.custom_name = `${con?.objecttype}_${con?.name}`;
            obj.dbfieldname = con?.dbfieldname;
            if (contactData && contactData[con?.dbfieldname]) {
              obj.value = contactData[con?.dbfieldname];
            } else if (contactData && contactData.organization
              && contactData?.organization[con?.dbfieldname]) {
              obj.value = contactData?.organization[con?.dbfieldname];
            }
            arr.push(obj);
          }
        });
        setData(arr);
      }
    }
  }, [contactData, content]);

  return (
    <>
        <BusinessFavicon 
          name={eventData?.name}
          logo={favIcon || locationBrandImage || brandImage}
        />
      <Stepper
        style={{ display: "none" }}
        classes={{ root: classes.paddingStepLabel }}
        activeStep={activeTabId}
        className={classes.stepper}
        alternativeLabel={matches}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              classes={{
                label: classes.stepLabel,
              }}
              StepIconProps={{
                classes: {
                  root: classes.stepIcon,
                  completed: classes.completed,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {dataLoading ? <LoadingPage /> : <></>}
      {eventData?.status === ActiveAppointmentStatus?.inactive ? (
        <div className={classes.root}>
        <div className={classes.paper}>
          <Paper className={classes.cal} elevation={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {locationBrandImage && eventData?.isshowlogo === true ? (
                  <>
                    <center>
                      <img
                        className={classes.brandImage}
                        src={locationBrandImage}
                        alt="brand logo"
                      />
                    </center>
                  </>
                ) : (
                  brandImage && eventData?.isshowlogo === true ? (
                    <center>
                      <img
                        className={classes.brandImage}
                        src={brandImage}
                        alt="brand logo"
                      />
                    </center>
                  ) : ''
                )}
                {eventData?.isshowuseravatar === true ? (
                  <div className={classes.inActiveProfileImg}>
                    <Avatar
                      className={classes.inActiveimg}
                      style={{ width: "6%", height: "6%" }}
                      alt={profileData?.firstname}
                      src={profileData && profileData?.avatar}
                    />
                  </div>
                ) : ''}
                <Box className={classes.profile}>
                  <center>
                    <Typography variant="h6">
                      {eventData?.createdUser?.firstname}
                      {' '}
                      {eventData?.createdUser?.lastname}
                      {' '}
                      is out of office and not taking any further meeting.
                    </Typography>
                  </center>
                </Box>
              </Grid>
            </Grid>
            <PoweredByLogo enablePublicLogo={enablePublicLogo} id={lid}/>
          </Paper>
        </div>
      </div>
      ) : (
        <>
          <div className={classes.root}>
            {activeTabId === 0 && (
              <>
                <div className={classes.paper}>
                  <Paper className={classes.cal} elevation={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        {locationBrandImage && eventData?.isshowlogo === true ? (
                          <>
                            <center>
                              <img
                                className={classes.brandImage}
                                src={locationBrandImage}
                                alt="brand logo"
                              />
                            </center>
                          </>
                        ) : brandImage && eventData?.isshowlogo === true ? (
                          <center>
                            <img
                              className={classes.brandImage}
                              src={brandImage}
                              alt="brand logo"
                            />
                          </center>
                        ) : (
                          ""
                        )}
                        {eventData?.isshowuseravatar === true ? (
                          <div className={classes.profileImg}>
                            <Avatar
                              className={classes.img}
                              style={{ width: "13%", height: "13%" }}
                              alt={profileData?.firstname}
                              src={profileData && profileData?.avatar}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <Box className={classes.profile}>
                          <br className={classes.brTag} />
                          <MeetingTypo variant="body2" color="primary">
                            Meeting With
                          </MeetingTypo>
                          <HeadingTypo variant="h6">
                            {eventData?.createdUser?.firstname}{" "}
                            {eventData?.createdUser?.lastname}
                          </HeadingTypo>
                          <Box className={classes.SocialIcon}>
                            {profileData?.phonenumber &&
                            eventData?.isshowphoneicon === true ? (
                              <IconButton
                                className={classes.Button}
                                size="small"
                                href={`tel:${profileData?.phonenumber}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <PhoneIcon
                                  style={{ fontSize: 25, color: "#072cfe" }}
                                />
                              </IconButton>
                            ) : (
                              ""
                            )}
                            {profileData?.phonenumber &&
                            eventData?.isshowwhatsappicon === true ? (
                              <IconButton
                                className={classes.Button}
                                size="small"
                                href={`${`https://api.whatsapp.com/send?phone=${handleUndefined(
                                  profileData?.phonenumber
                                )}`}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <WhatsAppIcon
                                  style={{ fontSize: 25, color: "#3cd4a0" }}
                                />
                              </IconButton>
                            ) : (
                              ""
                            )}
                            {profileData?.phonenumber &&
                            eventData?.isshowiosicon === true ? (
                              <IconButton
                                className={classes.ButtonPhone}
                                size="small"
                                href={`sms:${profileData?.phonenumber}&amp;body=`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <AppleIcon
                                  style={{ fontSize: 25, color: "#607d8b" }}
                                />
                              </IconButton>
                            ) : (
                              ""
                            )}
                            {profileData?.phonenumber &&
                            eventData?.isshowandroidicon === true ? (
                              <IconButton
                                className={classes.ButtonPhone}
                                size="small"
                                href={`sms:${profileData?.phonenumber}?body=`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <AndroidIcon
                                  style={{ fontSize: 25, color: "#ffc260" }}
                                />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </Box>
                          <br />
                          <NameTypo variant="h1">{eventData?.name}</NameTypo>
                          <br />
                          <TimeTypo variant="body1">
                            <Box display="flex">
                              <AccessTimeIcon />
                              &nbsp;
                              {eventData?.eventduration === 1440 ? 'Full Day' : `${eventData?.eventduration} Min`}
                            </Box>
                          </TimeTypo>
                          <br />
                          {eventData?.ispaidevent === true && (
                            <TimeTypo variant="body1">
                              <Box display="flex">
                                <MonetizationOnIcon />
                                &nbsp; ${eventData?.amount}
                              </Box>
                            </TimeTypo>
                          )}
                          <br />
                          <TimeTypo variant="body1">
                            <Box display="flex">
                              <VideocamIcon />
                              &nbsp;
                              <div className={classes.headingLocation}>
                                {eventData?.islinkvisible
                                    ? eventData?.location
                                    : eventData?.platform}
                              </div>
                            </Box>
                          </TimeTypo>
                          <br />
                          <WelcomeTypo
                            variant="body1"
                            dangerouslySetInnerHTML={{
                              __html: eventData?.description,
                            }}
                          />
                        </Box>
                      </Grid>

                      <Divider orientation="vertical" flexItem />
                      <Grid item xs={12} md={6}>
                        <Box className={classes.Calendar}>
                          <Box className={classes.details}>
                            <h1 className={classes.calSectionTitle}>
                              Select a Date & Time
                            </h1>
                            <br />
                            <TimezoneSelect
                              value={timeZone}
                              onChange={(e) => {
                                setTimeZone(e?.value);
                                getEventTime({
                                  timeZone: e?.value,
                                });
                              }}
                            />
                            <br />
                          
                          </Box>
                          <Grid container>
                            <Grid item lg={8} md={10} sm={12} xs={12} style={{ textAlign: 'center' }}>
                            <Calendar
                              classNames={classes.CalendarDate}
                              onChange={(date) => {
                                getEventTime({
                                  day: date,
                                });
                                setValue(date);
                              }}
                              select
                              date={value}
                              color="#3d91ff"
                              value={value}
                              minDate={moment().toDate()}
                            />
                            </Grid>
                            <Grid item lg={4} md={2} sm={12} xs={12}>
                            <Box className={classes.time}>
                            {value ? (
                              <>
                                <Box className={classes.tBar}>
                                  {timeData && timeData?.length === 0 ? (
                                    <MonthTypo variant="body1">
                                      No Time available
                                    </MonthTypo>
                                  ) : (
                                    <>
                                      <MonthTypo variant="body1">
                                        {moment(value).format("MMMM Do YYYY")}
                                      </MonthTypo>
                                      <br />
                                      {timeData?.length >= 0 &&
                                        timeData?.map((item) => (
                                          <Grid container spacing={1}>
                                            <Grid
                                              item
                                              xs={12}
                                              md={
                                                startTime === item?.starttime
                                                  ? 12
                                                  : 12
                                              }
                                            >
                                              <TimeBtn
                                                onClick={() => {
                                                  setStartTime(item?.starttime);
                                                  setEndTime(item?.endtime);
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                              >
                                                 {eventData?.eventduration === 1440 ? 'Full Day' : `${item?.starttime}
                                                ${' '}
                                                -
                                                ${' '}
                                                ${item?.endtime}`}
                                              </TimeBtn>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={
                                                startTime === item?.starttime
                                                  ? 12
                                                  : 12
                                              }
                                            >
                                              {startTime === item?.starttime ? (
                                                <ConfirmBtn
                                                  onClick={() => {
                                                    if (
                                                      eventData.ispaidevent ===
                                                      true
                                                    ) {
                                                      activeTabId === 2
                                                        ? handleActiveTabs(2)
                                                        : handleActiveTabs(
                                                            activeTabId + 1
                                                          );
                                                    } else {
                                                      handleActiveTabs(
                                                        activeTabId + 1
                                                      );
                                                    }
                                                  }}
                                                  fullWidth
                                                  variant="contained"
                                                  color="primary"
                                                >
                                                  Confirm
                                                </ConfirmBtn>
                                              ) : (
                                                ""
                                              )}
                                            </Grid>
                                          </Grid>
                                        ))}
                                    </>
                                  )}
                                </Box>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                            </Grid>
                          </Grid>
                        
                        </Box>
                      </Grid>
                    </Grid>
                    <PoweredByLogo enablePublicLogo={enablePublicLogo} id={lid}/>
                  </Paper>
                </div>
              </>
            )}
            {activeTabId === 2 && eventData?.ispaidevent === true && (
              <>
                <div className={classes.paper}>
                  <Paper className={classes.cal} elevation={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={5}>
                        <Box className={classes.backMenu}>
                          {activeTabId !== 0 && (
                            <Link
                              href
                              onClick={() => {
                                activeTabId === 0
                                  ? handleActiveTabs(0)
                                  : handleActiveTabs(activeTabId - 1);
                              }}
                            >
                              <Tooltip title="Back">
                                <IconButton aria-label="back" size="medium">
                                  <ArrowBackIosIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}
                          <HeadingTypo variant="h6">
                            {eventData?.createdUser?.firstname}{" "}
                            {eventData?.createdUser?.lastname}
                          </HeadingTypo>
                        </Box>
                        <br />

                        {locationBrandImage ? (
                          <img
                            className={classes.brandImage}
                            src={locationBrandImage}
                            alt="brand logo"
                          />
                        ) : brandImage ? (
                          <img
                            className={classes.brandImage}
                            src={brandImage}
                            alt="brand logo"
                          />
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                        <HeadingTypo variant="h6">
                          {eventData?.name}
                        </HeadingTypo>
                        <br />
                        <TimeTypo variant="body1">
                          <Box display="flex" alignItems="center">
                            <AccessTimeIcon />
                            &nbsp;
                            {eventData?.eventduration === 1440 ? 'Full Day' : `${eventData?.eventduration} Min`}
                          </Box>
                        </TimeTypo>
                        <br />
                        {eventData?.ispaidevent === true && (
                          <HeadingTypo variant="body1">
                            <Box display="flex">
                              <MonetizationOnIcon />
                              &nbsp; ${eventData?.amount}
                            </Box>
                          </HeadingTypo>
                        )}
                        <br />
                        <HeadingTypo variant="body1">
                          <Box display="flex">
                            <VideocamIcon />
                            &nbsp;
                            <div className={classes.headingLocation}>
                              {eventData?.islinkvisible
                                    ? eventData?.location
                                    : eventData?.platform}
                            </div>
                          </Box>
                        </HeadingTypo>
                        <br />
                        <HeadingTypo variant="body1">
                          <Box display="flex" alignItems="center">
                            <CalendarTodayIcon />
                            {eventData?.eventduration === 1440 ? 'Full Day' : `${moment(startTime, config.timeformate).format(
                              config.timeformate
                            )}
                            -
                            ${moment(endTime, config.timeformate).format(
                              config.timeformate
                            )}`}
                            &nbsp;&nbsp;
                            
                            , &nbsp;
                            {moment(value).format("MMM Do YYYY") || bookingDate}
                            &nbsp;
                          </Box>
                        </HeadingTypo>
                        <br />
                        <HeadingTypo variant="body1">
                          <Box display="flex" alignItems="center">
                            <PublicIcon />
                            &nbsp;&nbsp;
                            {timeZone}
                          </Box>
                        </HeadingTypo>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid xs={12} md={6}>
                        <center>
                          <Payment
                            appointment
                            userId={uid}
                            businessId={bid}
                            locationId={lid}
                            contactData={contactData}
                            organizationData={organizationData}
                            objectId={bookid || bookingId}
                            amount={eventData?.amount}
                            setPaymentId={setPaymentId}
                            setActiveTabId={setActiveTabId}
                            handleActiveTabs={handleActiveTabs}
                          />
                        </center>
                      </Grid>
                    </Grid>
                    <PoweredByLogo enablePublicLogo={enablePublicLogo} id={lid}/>
                  </Paper>
                </div>
              </>
            )}
            {activeTabId === 1 && (
              <>
                <div className={classes.paper}>
                  <Paper className={classes.cal} elevation={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={5}>
                        <Box className={classes.backMenu}>
                          {activeTabId !== 0 && (
                            <Link
                              href
                              onClick={() => {
                                activeTabId === 0
                                  ? handleActiveTabs(0)
                                  : handleActiveTabs(activeTabId - 1);
                              }}
                            >
                              <Tooltip title="Back">
                                <IconButton aria-label="back" size="medium">
                                  <ArrowBackIosIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}
                          <HeadingTypo variant="h6">
                            {eventData?.createdUser?.firstname}{" "}
                            {eventData?.createdUser?.lastname}
                          </HeadingTypo>
                        </Box>
                        <br />
                        {locationBrandImage ? (
                          <img
                            className={classes.brandImage}
                            src={locationBrandImage}
                            alt="brand logo"
                          />
                        ) : brandImage ? (
                          <img
                            className={classes.brandImage}
                            src={brandImage}
                            alt="brand logo"
                          />
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                        <HeadingTypo variant="h6">
                          {eventData?.name}
                        </HeadingTypo>
                        <br />
                        <TimeTypo variant="body1">
                          <Box display="flex" alignItems="center">
                            <AccessTimeIcon />
                            &nbsp;
                            {eventData?.eventduration === 1440 ? 'Full Day' : `${eventData?.eventduration} Min`}
                          </Box>
                        </TimeTypo>
                        <br />
                        {eventData?.ispaidevent === true && (
                          <TimeTypo variant="body1">
                            <Box display="flex">
                              <MonetizationOnIcon />
                              &nbsp; ${eventData?.amount}
                            </Box>
                          </TimeTypo>
                        )}
                        <br />
                        <TimeTypo variant="body1">
                          <Box display="flex">
                            <VideocamIcon />
                            &nbsp;
                            <div className={classes.headingLocation}>
                              {eventData?.islinkvisible
                                    ? eventData?.location
                                    : eventData?.platform}
                            </div>
                          </Box>
                        </TimeTypo>
                        <br />
                        <TimeTypo variant="body1">
                          <Box display="flex" alignItems="center">
                            <CalendarTodayIcon />
                            {eventData?.eventduration === 1440 ? 'Full Day' : `${moment(startTime, config.timeformate).format(
                              config.timeformate
                            )}
                            -
                            ${moment(endTime, config.timeformate).format(
                              config.timeformate
                            )}`}
                            , &nbsp;
                            {moment(value).format("MMM Do YYYY") || bookingDate}
                            &nbsp;
                          </Box>
                        </TimeTypo>
                        <br />
                        <TimeTypo variant="body1">
                          <Box display="flex" alignItems="center">
                            <PublicIcon />
                            &nbsp;&nbsp;
                            {timeZone}
                          </Box>
                        </TimeTypo>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <br />
                      <Grid item xs={12} md={6} style={{ marginLeft: 20 }}>
                        <FormGenerator
                          onFormSubmit={onFormSubmit}
                          defaultCountryPhoneCode={defaultCountryPhoneCode}
                          content={content}
                          rows={6}
                          btnName={btnName}
                          colorCode={colorCode}
                          fontColorCode={fontColorCode}
                          backgroundColorCode={backgroundColorCode}
                          foreColor={foreColor}
                          btnPos={btnPos}
                          btnThemeColor={btnThemeColor}
                          btnTextColor={btnTextColor}
                          termDesc={termDesc}
                          enableTerm={enableTerm}
                          visible={visible}
                          setVisible={setVisible}
                          suggestContact={suggestContact}
                          contact={contact}
                          setContact={setContact}
                          formData={submittedData}
                          eventType="single-page"
                          isAppointment
                          isAppointmentPublic
                        />
                      </Grid>
                    </Grid>
                    <PoweredByLogo enablePublicLogo={enablePublicLogo} id={lid}/>
                  </Paper>
                </div>
              </>
            )}
            {activeTabId === 3 && (
              <>
                <div className={classes.paper}>
                  <Paper className={classes.cal} elevation={6}>
                    <center>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignContent="center"
                      >
                        <Grid item xs={12}>
                          {locationBrandImage ? (
                            <img
                              className={classes.brandImage}
                              src={locationBrandImage}
                              alt="brand logo"
                            />
                          ) : brandImage ? (
                            <img
                              className={classes.brandImage}
                              src={brandImage}
                              alt="brand logo"
                            />
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <ConfirmTypo variant="h6">Confirmed</ConfirmTypo>
                        </Grid>
                        <Grid item xs={12}>
                          <ConfirmTypo variant="body1">
                            You are scheduled with{" "}
                            {eventData?.createdUser?.firstname}{" "}
                            {eventData?.createdUser?.lastname}.
                          </ConfirmTypo>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider variant="middle" />
                        </Grid>
                        <Grid item xs={12}>
                          <ConfirmTypo variant="h6">
                            {eventData?.name}
                          </ConfirmTypo>
                        </Grid>
                        <Grid item xs={12}>
                          <ConfirmTypo variant="body1">
                            <Box display="inline-flex" alignItems="center">
                              <CalendarTodayIcon />
                              &nbsp;
                              <EventDateTypo variant="body2">
                              {eventData?.eventduration === 1440 ? 'Full Day' : `${moment(startTime, config.timeformate).format(config.timeformate)}
                             
                             -
                          
                             ${moment(endTime, config.timeformate).format(config.timeformate)}`}
                                , &nbsp;
                                {moment(value).format("MMM Do YYYY") ||
                                  bookingDate}
                              </EventDateTypo>
                            </Box>
                          </ConfirmTypo>
                        </Grid>
                        {eventData?.ispaidevent === true && (
                          <Grid item xs={12}>
                            <HeadingTypo variant="body1">
                              <Box display="inline-flex" alignItems="center">
                                <MonetizationOnIcon />
                                &nbsp; ${eventData?.amount}
                              </Box>
                            </HeadingTypo>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <ConfirmTypo variant="body1">
                            <Box display="inline-flex" alignItems="center">
                              <PublicIcon />
                              &nbsp;&nbsp;
                              {timeZone}
                            </Box>
                          </ConfirmTypo>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <EventDateTypo
                              className={classes.headingLocation}
                              variant="body2"
                              alignItems="center"
                            >
                              <VideocamIcon className={classes.videoIcon} />
                              &nbsp;
                              {eventData?.islinkvisible
                                    ? eventData?.location
                                    : eventData?.platform}
                            </EventDateTypo>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <ConfirmTypo variant="body1">
                            A calendar invitation has been sent to your email
                            address.
                          </ConfirmTypo>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider variant="middle" />
                        </Grid>
                      </Grid>
                    </center>
                    <PoweredByLogo enablePublicLogo={enablePublicLogo} id={lid}/>
                  </Paper>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
