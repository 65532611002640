import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  body: {
    height: '100%',
    backgroundColor: 'white',
  },
  WidgetLayout: {
    textAlign: 'center',
    padding: 16,
  },
  WidgetLayoutAlign: {
    textAlign: 'left',
    padding: 16,
  },
  contents: {
    display: 'flex',
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'Column',
    },
  },
  contentsText: {
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 1,
    },
  },
  link:{
    textDecoration:'none !important',
    '&:hover': {
      textDecoration:'underline !important',
    },
  },
  Widget: {
    margin: 'auto',
    backgroundColor: '#ffffff',
  },
  img: {
    minWidth: 250,
    maxWidth: 250,
    minHeight: 150,
    maxHeight: 150,
    objectFit: 'contain',
  },
}));
