import React, { useState } from 'react';
import useStyle from './styles';
import { Grid, Typography, Box, Button, TextField, FormHelperText, styled } from '@mui/material';
import { composeValidators, emailValidate, maxString, required } from '../../common/FormValidation';
import { emailValidation } from '../../common/const';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PhoneNoWithCountryCode from '../Phonenumber/PhoneWithCountryCodeSolo';
import { handleUndefined } from '../../common/commonfunctions';

export default function ContactInfo({ createContact, productData }) {
  const classes = useStyle();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePurchase = () => {
    createContact(firstName, lastName, phoneNumber, email)
  }

  const StyledTypo = styled(Typography)(({ theme }) => ({
    fontFamily: 'Open sans',
    fontSize: '14px',
    fontWeight: 600,
  }));

  const StyledBtn = styled(Button)(({ theme }) => ({
    fontFamily: 'Open sans',
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: 400,
    backgroundColor: '#005eb2',
  }));

  return (
    <div>
      <StyledTypo variant="body1">
        Contact information
      </StyledTypo>
      <br />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            className={classes.field}
            InputLabelProps={{
              classes: {
                root: classes.label,
              }
            }}
            InputProps={{
              classes: {
                input: classes.input,
              }
            }}
            fullWidth
            required
            size="small"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            validate={composeValidators(required, maxString(20))}
            variant="outlined"
            type="text"
            label="First Name"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            className={classes.field}
            InputLabelProps={{
              classes: {
                root: classes.label,
              }
            }}
            InputProps={{
              classes: {
                input: classes.input,
              }
            }}
            fullWidth
            value={lastName}
            size="small"
            onChange={(e) => setLastName(e.target.value)}
            validate={maxString(20)}
            variant="outlined"
            type="text"
            label="Last Name"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            className={classes.field}
            InputLabelProps={{
              classes: {
                root: classes.label,
              }
            }}
            InputProps={{
              classes: {
                input: classes.input,
              }
            }}
            fullWidth
            required
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            validate={composeValidators(required, emailValidate)}
            variant="outlined"
            error={email && !emailValidation?.test(email)}
            type="email"
            label="Email"
          />
          <FormHelperText
            className={classes.price}
            error={email && !emailValidation?.test(email)}
          >
            <Typography variant="body2">{email && !emailValidation?.test(email) ? 'Invalid Email' : ''}</Typography>
          </FormHelperText>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className={classes.phoneNumber}>
            <PhoneNoWithCountryCode
              setPhoneNumber={setPhoneNumber}
              phoneNumber={handleUndefined(
                phoneNumber,
              )}
              className={classes.phoneField}
            />
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          {productData?.termenable && (
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: productData?.termdesc }} className={classes.termDescription} />
          )}
          <br />
          <StyledBtn
            fullWidth
            startIcon={<MonetizationOnIcon />}
            onClick={handlePurchase}
            color="primary"
            size="small"
            variant="contained"
          >
            Purchase
          </StyledBtn>
        </Grid>
      </Grid>
    </div>
  );
}
