import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  WidgetLayout: {
    textAlign: 'center',
  },
  WidgetLayoutAlign: {
    textAlign: 'left',
    padding: 10,
  },
  contents: {
    display: 'flex',
    padding: 10,
    [theme.breakpoints.down('sm')]:{
      flexDirection:'column',
    },
  },
  contentsText: {
    padding: 10,
    [theme.breakpoints.down('sm')]:{
      padding:0,
    },
  },
  Widget: {
    margin: 'auto',
    background: '#fff',
  },
  img: {
    width: 250,
    height: 150,
    marginRight: 10,
    aspectRatio: 'auto',
    objectFit: 'fill',
    border: '1px solid black',
  },
}));
