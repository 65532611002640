/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Organization } from "../../Api Mutation/OrganizationApi";
import config from "../../config";
import PoweredBy from "../../common/PoweredBy";
import { postDataRequestPublic } from "../../common/commonfunctions";
import moment from "moment";
import { showNotification } from "../../components/Notification/ToastifyNotifications";
import { Grid } from "@mui/material";
import "./businessdetails.css";

export default function BusinessDetails() {
  const { oid, lid, uid } = useParams([]);
  const [business, setBusiness] = useState();

  const getInitialData = () => {
    postDataRequestPublic(config.baseURLApi, {
      query: Organization,
      variables: {
        organizationid: oid,
      },
    }).then((response) => {
      if (response?.data?.organization) {
        setBusiness(response?.data?.organization);
      } else if (response?.errors[0]?.message) {
        showNotification(
          response?.errors && response?.errors[0]?.message,
          "error"
        );
      }
    });
  };

  useEffect(() => {
    getInitialData();
  }, [lid, uid, oid]);
  return (
    <>
      <section
        class="page-title-banner"
        style={{
          backgroundImage: `url(${
            config?.businessBanner
          })`,
        }}
      >
        <div class="container">
          <div class="row m-0 align-items-end detail-swap">
            <div class="tr-list-wrap">
              <div class="tr-list-detail">
                <div class="tr-list-thumb">
                  <img
                    src={business?.businesslogo ? business?.businesslogo : config?.businessListLogo}
                    class="img-responsive"
                    alt=""
                  />
                </div>
                <div class="tr-list-info">
                  {/* <div class="cate-gorio">
                    <a href="#">Restaurant</a>
                  </div> */}
                  <h4 class="veryfied-list">{business?.name}</h4>
                  {business?.orgemail ? (
                    <p style={{ color: "#ffffff" }}>
                      <i class="ti-email"></i>
                      {business?.orgemail}
                    </p>
                  ) : (
                    ""
                  )}
                  {business?.orgphonenumber ? (
                    <p style={{ color: "#ffffff" }}>
                      <i class="ti-mobile"></i>
                      {business?.orgphonenumber}
                    </p>
                  ) : (
                    ""
                  )}
                  {business?.address?.street1 ? (
                    <p style={{ color: "#ffffff" }}>
                      <i class="ti-location-pin"></i>
                      {business?.address?.street1},{business?.address?.street2},
                      {business?.address?.city},{business?.address?.state?.name}
                      ,{business?.address?.country?.name},
                      {business?.address?.zip}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <div class="tr-list-ratting">
                    <div class="ratting-group">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star"></i>
                      <a href="#" class="overall-reviews">
                        (42 Reviews)
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="gray">
        <div>
          <Grid container spacing={2} justifyContent="center">
            <Grid style={{ background: "none" }} item xs={12} md={6} sm={4}>
              {business?.shortdesc || business?.longdesc ? (
                <div class="Reveal-block-wrap">
                  <div class="Reveal-block-header">
                    <h4 class="block-title">Description</h4>
                  </div>
                  <div class="Reveal-block-body">
                    <p>{business?.shortdesc}</p>
                    <p>{business?.longdesc}</p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* <div class="Reveal-block-wrap">
                <div class="Reveal-block-header">
                  <h4 class="block-title">Ameneties</h4>
                </div>

                <div class="Reveal-block-body">
                  <ul class="avl-features third">
                    <li>Air Conditioning</li>
                    <li>Swimming Pool</li>
                    <li>Central Heating</li>
                    <li>Laundry Room</li>
                    <li>Gym</li>
                    <li>Alarm</li>
                    <li>Window Covering</li>
                    <li>Internet</li>
                    <li>Pets Allow</li>
                    <li>Free WiFi</li>
                    <li>Car Parking</li>
                    <li>Spa &amp; Massage</li>
                  </ul>
                </div>
              </div> */}
              {/* <div class="Reveal-block-wrap">
                <div class="Reveal-block-header">
                  <h4 class="block-title">Location</h4>
                </div>

                <div class="Reveal-block-body">
                  <div class="map-container">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54875.24055089478!2d76.7508579533216!3d30.726761932228868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fedb2d3da9405%3A0xaecad931f1a39595!2sICICI%20Bank%20Sector%2034%2C%20Chandigarh%20-%20Branch%20%26%20ATM!5e0!3m2!1sen!2sin!4v1611821871299!5m2!1sen!2sin"
                      width="100%"
                      height="400"
                      frameborder="0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                </div>
              </div> */}
              {/* <div class="Reveal-block-wrap">
                <div class="Reveal-block-header">
                  <h4 class="block-title">Item Pricing</h4>
                </div>

                <div class="Reveal-block-body p-0">
                  <ul class="item-pricing">
                    <li>
                      Neapolitan Pizza<span>$75</span>
                    </li>
                    <li>
                      Sicilian Pizza<span>$62</span>
                    </li>
                    <li>
                      Detroit Pizza<span>$59</span>
                    </li>
                    <li>
                      Black Bean Burgers<span>$29</span>
                    </li>
                    <li>
                      Turkey Burgers<span>$41</span>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div class="list-single-main-item fl-wrap">
                <div class="list-single-main-item-title fl-wrap">
                  <h3>
                    Item Reviews - <span> 3 </span>
                  </h3>
                </div>
                <div class="reviews-comments-wrap">
                  <div class="reviews-comments-item">
                    <div class="review-comments-avatar">
                      <img
                        src="https://themezhub.net/reveal-live/reveal/assets/img/user-1.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="reviews-comments-item-text">
                      <h4>
                        <a href="#">Josaph Manrty</a>
                        <span class="reviews-comments-item-date">
                          <i class="ti-calendar theme-cl"></i>27 Oct 2019
                        </span>
                      </h4>

                      <div class="listing-rating high" data-starrating2="5">
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <span class="review-count">4.9</span>{" "}
                      </div>
                      <div class="clearfix"></div>
                      <p>
                        " Commodo est luctus eget. Proin in nunc laoreet justo
                        volutpat blandit enim. Sem felis, ullamcorper vel
                        aliquam non, varius eget justo. Duis quis nunc tellus
                        sollicitudin mauris. "
                      </p>
                      <div class="pull-left reviews-reaction">
                        <a href="#" class="comment-like active">
                          <i class="ti-thumb-up"></i> 12
                        </a>
                        <a href="#" class="comment-dislike active">
                          <i class="ti-thumb-down"></i> 1
                        </a>
                        <a href="#" class="comment-love active">
                          <i class="ti-heart"></i> 07
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="reviews-comments-item">
                    <div class="review-comments-avatar">
                      <img
                        src="https://themezhub.net/reveal-live/reveal/assets/img/user-2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="reviews-comments-item-text">
                      <h4>
                        <a href="#">Rita Chawla</a>
                        <span class="reviews-comments-item-date">
                          <i class="ti-calendar theme-cl"></i>2 Nov May 2019
                        </span>
                      </h4>

                      <div class="listing-rating mid" data-starrating2="5">
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star"></i>
                        <span class="review-count">3.7</span>{" "}
                      </div>
                      <div class="clearfix"></div>
                      <p>
                        " Commodo est luctus eget. Proin in nunc laoreet justo
                        volutpat blandit enim. Sem felis, ullamcorper vel
                        aliquam non, varius eget justo. Duis quis nunc tellus
                        sollicitudin mauris. "
                      </p>
                      <div class="pull-left reviews-reaction">
                        <a href="#" class="comment-like active">
                          <i class="ti-thumb-up"></i> 12
                        </a>
                        <a href="#" class="comment-dislike active">
                          <i class="ti-thumb-down"></i> 1
                        </a>
                        <a href="#" class="comment-love active">
                          <i class="ti-heart"></i> 07
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="reviews-comments-item">
                    <div class="review-comments-avatar">
                      <img
                        src="https://themezhub.net/reveal-live/reveal/assets/img/user-3.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="reviews-comments-item-text">
                      <h4>
                        <a href="#">Adam Wilsom</a>
                        <span class="reviews-comments-item-date">
                          <i class="ti-calendar theme-cl"></i>10 Nov 2019
                        </span>
                      </h4>

                      <div class="listing-rating good" data-starrating2="5">
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star active"></i>
                        <i class="ti-star"></i>{" "}
                        <span class="review-count">4.2</span>{" "}
                      </div>
                      <div class="clearfix"></div>
                      <p>
                        " Commodo est luctus eget. Proin in nunc laoreet justo
                        volutpat blandit enim. Sem felis, ullamcorper vel
                        aliquam non, varius eget justo. Duis quis nunc tellus
                        sollicitudin mauris. "
                      </p>
                      <div class="pull-left reviews-reaction">
                        <a href="#" class="comment-like active">
                          <i class="ti-thumb-up"></i> 12
                        </a>
                        <a href="#" class="comment-dislike active">
                          <i class="ti-thumb-down"></i> 1
                        </a>
                        <a href="#" class="comment-love active">
                          <i class="ti-heart"></i> 07
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div class="Reveal-block-wrap">
                <div class="Reveal-block-header">
                  <h4 class="block-title">Add Review</h4>
                </div>

                <div class="Reveal-block-body">
                  <div class="giv-averg-rate">
                    <div class="row">
                      <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>Service?</label>
                            <div class="rate-stars">
                              <input type="checkbox" id="st1" value="1" />
                              <label for="st1"></label>
                              <input type="checkbox" id="st2" value="2" />
                              <label for="st2"></label>
                              <input type="checkbox" id="st3" value="3" />
                              <label for="st3"></label>
                              <input type="checkbox" id="st4" value="4" />
                              <label for="st4"></label>
                              <input type="checkbox" id="st5" value="5" />
                              <label for="st5"></label>
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>Value for Money?</label>
                            <div class="rate-stars">
                              <input type="checkbox" id="vst1" value="1" />
                              <label for="vst1"></label>
                              <input type="checkbox" id="vst2" value="2" />
                              <label for="vst2"></label>
                              <input type="checkbox" id="vst3" value="3" />
                              <label for="vst3"></label>
                              <input type="checkbox" id="vst4" value="4" />
                              <label for="vst4"></label>
                              <input type="checkbox" id="vst5" value="5" />
                              <label for="vst5"></label>
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>Cleanliness?</label>
                            <div class="rate-stars">
                              <input type="checkbox" id="cst1" value="1" />
                              <label for="cst1"></label>
                              <input type="checkbox" id="cst2" value="2" />
                              <label for="cst2"></label>
                              <input type="checkbox" id="cst3" value="3" />
                              <label for="cst3"></label>
                              <input type="checkbox" id="cst4" value="4" />
                              <label for="cst4"></label>
                              <input type="checkbox" id="cst5" value="5" />
                              <label for="cst5"></label>
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6 col-sm-12">
                            <label>Location?</label>
                            <div class="rate-stars">
                              <input type="checkbox" id="lst1" value="1" />
                              <label for="lst1"></label>
                              <input type="checkbox" id="lst2" value="2" />
                              <label for="lst2"></label>
                              <input type="checkbox" id="lst3" value="3" />
                              <label for="lst3"></label>
                              <input type="checkbox" id="lst4" value="4" />
                              <label for="lst4"></label>
                              <input type="checkbox" id="lst5" value="5" />
                              <label for="lst5"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="avg-total-pilx">
                          <h4 class="high">4.9</h4>
                          <span>Average Ratting</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="review-form-box form-submit">
                    <form>
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Name</label>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Your Name"
                            />
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Email</label>
                            <input
                              class="form-control"
                              type="email"
                              placeholder="Your Email"
                            />
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <label>Review</label>
                            <textarea
                              class="form-control ht-140"
                              placeholder="Review"
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <button type="submit" class="btn btn-theme">
                              Submit Review
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> */}
            </Grid>
            <Grid style={{ background: "none" }} item xs={12} md={5} sm={4}>
              {/* <div class="verified-list mb-4">
                <i class="ti-check"></i>Verified Listing
              </div> */}

              <div class="page-sidebar">
                <div class="Reveal-side-widget form-submit">
                  {business?.contact?.firstname ? (
                    <div class="Reveal-Reveal-side-widget-header dark green">
                      <div class="Reveal-thumb-photo">
                        {business?.contact?.contactavatar ? (
                        <img src={business?.contact?.contactavatar} alt="img" />
                        ) : (
                          <span class="contact">
                             {business?.contact?.firstname?.charAt(0)}
                          </span>
                        )}
                      </div>
                      <div class="Reveal-thumb-details">
                        <h4>
                          {business?.contact?.firstname}{" "}
                          {business?.contact?.lastname}
                        </h4>
                        <span>{business?.contact?.primaryemail}</span>
                        <br />
                        <span>{business?.contact?.phonenumber}</span>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div class="Reveal-Reveal-side-widget-body">
                    <div class="form-group">
                      <label>Full Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Email"
                      />
                    </div>
                    <div class="form-group">
                      <label>Message</label>
                      <textarea
                        class="form-control"
                        placeholder="Send Message to author..."
                      ></textarea>
                    </div>
                    <button class="btn btn-theme full-width">
                      Send Message
                    </button>
                  </div> */}
                </div>

                {/* <div class="coupon bg-white rounded mb-3 d-flex justify-content-between">
                  <div class="kiri p-3">
                    <div class="icon-container ">
                      <div class="icon-container_box">
                        <img
                          src="https://themezhub.net/reveal-live/reveal/assets/img/pizza.pn"
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="tengah py-3 d-flex w-100 justify-content-start">
                    <div>
                      <span class="badge badge-success">Valid</span>
                      <h3 class="coupon-lead">Red Chilly Pizza</h3>
                      <p class="text-muted mb-0">Order 2 &amp; Save 30% Off</p>
                    </div>
                  </div>
                  <div class="kanan">
                    <div class="info m-3 d-flex align-items-center">
                      <div class="w-100">
                        <div class="block">
                          <span class="time font-weight-light">
                            <span>19 days</span>
                          </span>
                        </div>
                        <a href="#" class="btn btn-sm btn-theme">
                          show
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div class="Reveal-overview-rating">
                  <div class="Reveal-overview-rating-box">
                    <span class="Reveal-overview-rating-box-total">4.2</span>
                  </div>

                  <div class="Reveal-rating-bars">
                    <div class="Reveal-Reveal-rating-bars-item">
                      <span class="Reveal-Reveal-rating-bars-name">
                        Service
                      </span>
                      <span class="Reveal-Reveal-rating-bars-inner">
                        <span
                          class="Reveal-Reveal-rating-bars-rating high"
                          data-rating="4.7"
                        >
                          <span
                            class="Reveal-Reveal-rating-bars-rating-inner"
                            style={{ width: "85%" }}
                          ></span>
                        </span>
                        <strong>4.7</strong>
                      </span>
                    </div>
                    <div class="Reveal-Reveal-rating-bars-item">
                      <span class="Reveal-Reveal-rating-bars-name">
                        Value for Money
                      </span>
                      <span class="Reveal-Reveal-rating-bars-inner">
                        <span
                          class="Reveal-Reveal-rating-bars-rating good"
                          data-rating="3.9"
                        >
                          <span
                            class="Reveal-Reveal-rating-bars-rating-inner"
                            style={{ width: "75%" }}
                          ></span>
                        </span>
                        <strong>3.9</strong>
                      </span>
                    </div>
                    <div class="Reveal-Reveal-rating-bars-item">
                      <span class="Reveal-Reveal-rating-bars-name">
                        Location
                      </span>
                      <span class="Reveal-Reveal-rating-bars-inner">
                        <span
                          class="Reveal-Reveal-rating-bars-rating mid"
                          data-rating="3.2"
                        >
                          <span
                            class="Reveal-Reveal-rating-bars-rating-inner"
                            style={{ width: "52.2%" }}
                          ></span>
                        </span>
                        <strong>3.2</strong>
                      </span>
                    </div>
                    <div class="Reveal-Reveal-rating-bars-item">
                      <span class="Reveal-Reveal-rating-bars-name">
                        Cleanliness
                      </span>
                      <span class="Reveal-Reveal-rating-bars-inner">
                        <span
                          class="Reveal-Reveal-rating-bars-rating poor"
                          data-rating="2.0"
                        >
                          <span
                            class="Reveal-Reveal-rating-bars-rating-inner"
                            style={{ width: "20%" }}
                          ></span>
                        </span>
                        <strong>2.0</strong>
                      </span>
                    </div>
                  </div>
                </div> */}
               
                <div class="imp-boxes">
                  <div class="imp-boxes-single">
                    {business?.orgphonenumber ? (
                      <>
                        <div class="imp-boxes-single-icon">
                          <img
                            src="https://themezhub.net/reveal-live/reveal/assets/img/phone.svg"
                            width="25"
                            alt=""
                          />
                        </div>
                        <div class="imp-boxes-single-content">
                          {business?.orgphonenumber}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {business?.orgemail ? (
                    <div class="imp-boxes-single">
                      <div class="imp-boxes-single-icon">
                        <img
                          src="https://themezhub.net/reveal-live/reveal/assets/img/mail.svg"
                          width="25"
                          alt=""
                        />
                      </div>
                      <div class="imp-boxes-single-content">
                        {business?.orgemail}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {business?.orgfacebook ||
                  business?.orgtwitter ||
                  business?.orglinkedin ? (
                    <div class="imp-boxes-single">
                      <div class="imp-boxes-single-icon">
                        <img
                          src="https://themezhub.net/reveal-live/reveal/assets/img/share.svg"
                          width="25"
                          alt=""
                        />
                      </div>
                      <div class="imp-boxes-single-content">
                        <ul>
                          {business?.orgfacebook ? (
                            <li>
                              <a
                                href={business?.orgfacebook}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i class="ti-facebook"></i>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {business?.orgtwitter ? (
                            <li>
                              <a
                                href={business?.orgtwitter}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i class="ti-twitter"></i>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {business?.orglinkedin ? (
                            <li>
                              <a
                                href={business?.orglinkedin}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i class="ti-linkedin"></i>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div class="tr-single-box">
                  <div class="tr-single-header">
                    <h4>
                      <i class="ti-bar-chart"></i> Statics Info
                    </h4>
                  </div>

                  <div class="Reveal-other-body">
                    <ul class="Reveal-service half">
                      <li>
                        <div class="Reveal-service-icon">
                          <a href="#">
                            <div class="Reveal-icon-box-round">
                              <i class="ti-star"></i>
                            </div>
                            <div class="Reveal-icon-box-text">4.5 Rating</div>
                          </a>
                        </div>
                      </li>

                      <li>
                        <div class="Reveal-service-icon">
                          <a href="#">
                            <div class="Reveal-icon-box-round">
                              <i class="ti-bookmark"></i>
                            </div>
                            <div class="Reveal-icon-box-text">20 Bookmark</div>
                          </a>
                        </div>
                      </li>

                      <li>
                        <div class="Reveal-service-icon">
                          <a href="#">
                            <div class="Reveal-icon-box-round">
                              <i class="ti-eye"></i>
                            </div>
                            <div class="Reveal-icon-box-text">785 View</div>
                          </a>
                        </div>
                      </li>

                      <li>
                        <div class="Reveal-service-icon">
                          <a href="#">
                            <div class="Reveal-icon-box-round">
                              <i class="ti-share"></i>
                            </div>
                            <div class="Reveal-icon-box-text">110 Share</div>
                          </a>
                        </div>
                      </li>

                      <li>
                        <div class="Reveal-service-icon">
                          <a href="#">
                            <div class="Reveal-icon-box-round">
                              <i class="ti-comment-alt"></i>
                            </div>
                            <div class="Reveal-icon-box-text">22 comments</div>
                          </a>
                        </div>
                      </li>

                      <li>
                        <div class="Reveal-service-icon">
                          <a href="#">
                            <div class="Reveal-icon-box-round">
                              <i class="ti-heart"></i>
                            </div>
                            <div class="Reveal-icon-box-text">20 Likes</div>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {business?.orgphonenumber ||
                business?.address?.street1 ||
                business?.orgemail ||
                business?.orgwebsite ? (
                  <div class="tr-single-box">
                    <div class="tr-single-header">
                      <h4>
                        <i class="ti-direction"></i> Listing Info
                      </h4>
                    </div>

                    <div class="Reveal-other-body">
                      <ul class="Reveal-service">
                        {business?.address?.street1 ? (
                          <li>
                            <div class="Reveal-service-icon">
                              <a href="#">
                                <div class="Reveal-icon-box-round">
                                  <i class="ti-location-pin"></i>
                                </div>
                                <div class="Reveal-icon-box-text">
                                  {business?.address?.street1},
                                  {business?.address?.street2},
                                  {business?.address?.city}, <br />
                                  {business?.address?.state?.name},
                                  {business?.address?.country?.name},
                                  {business?.address?.zip}
                                </div>
                              </a>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {business?.orgphonenumber ? (
                          <li>
                            <div class="Reveal-service-icon">
                              <a href="#">
                                <div class="Reveal-icon-box-round">
                                  <i class="ti-mobile"></i>
                                </div>
                                <div class="Reveal-icon-box-text">
                                  {business?.orgphonenumber}
                                </div>
                              </a>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {business?.orgemail ? (
                          <li>
                            <div class="Reveal-service-icon">
                              <a href="#">
                                <div class="Reveal-icon-box-round">
                                  <i class="ti-email"></i>
                                </div>
                                <div class="Reveal-icon-box-text">
                                  {business?.orgemail}
                                </div>
                              </a>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {business?.orgwebsite ? (
                          <li>
                            <div class="Reveal-service-icon">
                              <a
                                href={business?.orgwebsite}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div class="Reveal-icon-box-round">
                                  <i class="ti-world"></i>
                                </div>
                                <div class="Reveal-icon-box-text">
                                  {business?.orgwebsite}
                                </div>
                              </a>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                 {business?.availablehours?.length > 0 ? (
                  <div class="Reveal-side-widget">
                    <div class="Reveal-Reveal-side-widget-header dark red">
                      <div class="Reveal-thumb-details">
                        <h4>Opening Time</h4>
                        <span>8:00 AM To 6:00 PM</span>
                      </div>
                      <div class="opening-status">Closed</div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="Reveal-other-body">
                      <ul class="listing-hour-day">
                        {business?.availablehours &&
                          business?.availablehours?.map((i) => (
                            <li>
                              <span class="listing-hour-day">{i?.day}</span>
                              <span class="listing-hour-time">
                                {moment(i?.starttime, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}{" "}
                                -{" "}
                                {moment(i?.endtime, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )} 
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <PoweredBy id={lid} />
    </>
  );
}
