import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    margin: theme.spacing(3),
    width: theme.spacing(50),
    [theme.breakpoints.down('sm')]:{
      width:'auto',
    }
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  subMenu: {
    flexGrow: 1,
    position: 'relative',
    padding: '1rem',
    textAlign: 'center',
    margin: theme.spacing(3),
    width: theme.spacing(18),
  },
  subMenuSelect: {
    flexGrow: 1,
    position: 'relative',
    padding: '1rem',
    textAlign: 'center',
    boxShadow: '5px 5px',
    margin: theme.spacing(3),
    width: theme.spacing(18),
  },
  methodName:{
    fontSize:'14px !important',
    fontFamily:'Open sans !important',
    fontWeight:'400 !important',
  },
  label: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'500 !important',
  },
  input: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'400 !important',
  },
  paypalIcon: {
    width: theme.spacing(3),
  },
  fieldofpayment: {
    width: '100%',
  },
}));
