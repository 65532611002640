/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
const baseURLApi = process.env.REACT_APP_BASEURL;
const authApi = process.env.REACT_APP_AUTHAPI;
const entityApi = process.env.REACT_APP_ENTITYAPI;
const reviewApi = process.env.REACT_APP_REVIEWMANAGEMENTAPI;
const campaignApi = process.env.REACT_APP_CAMPAIGNMANAGEMENTAPI;
const supportApi = process.env.REACT_APP_SUPPORT;
const BixUrl = process.env.REACT_APP_BIXURL;
const bixpand = process.env.REACT_APP_BIXPAND;
const BixPublicUrl = process.env.REACT_APP_BIXPUBLICURL;
const subDomainUrl = process.env.REACT_APP_SUBDOMAIN;
const domain = process.env.REACT_APP_DOMAIN;
const stagingDomain = process.env.REACT_APP_STAGING_DOMAIN;
const bixLogo = process.env.REACT_APP_ICON;
const searchGooglePlace = process.env.REACT_APP_SEARCHGOOGLEPLACE;
const getPlaceDetails = process.env.REACT_APP_GETPLACEDETAILS;
const getAuthenticationUrl = process.env.REACT_APP_GETAUTHENTICATIONURL;
const reviewboard = process.env.REACT_APP_REVIEWBOARD;
const payment = process.env.REACT_APP_PAYMENT;
const searchFBPageFromName = process.env.REACT_APP_SEARCHFBPAGEFROMNAME;
const getFBPages = process.env.REACT_APP_GETFBPAGES;
const googleMapApi = process.env.REACT_APP_GOOGLEAPI;
const facebookReviewImageShareUrl = process.env.REACT_APP_FACEBOOK_REVIEW_POST_URL;
const widgets = process.env.REACT_APP_WIDGETS;
const dateFilter = process.env.REACT_APP_DATE_FILTER;
const bucketUrl = process.env.REACT_APP_GOOGLE_BUCKET_URL;
const help = process.env.REACT_APP_HELP;
const url = process.env.REACT_APP_URL;
const privacyUrl = process.env.REACT_APP_PRIVACY;
const termsUrl = process.env.REACT_APP_TERMS;
const contactEmail = process.env.REACT_APP_CONTACTEMAIL;
const staticToken = process.env.REACT_APP_STATIC_TOKEN;
const facebookapi = process.env.REACT_APP_FACEBOOKAPI;
const TextCampaign = process.env.REACT_APP_TEXT_CAMPAIGN;
const RatingTextCampaign = process.env.REACT_APP_RATING_TEXT_CAMPAIGN;
const RatingEmailCampaign = process.env.REACT_APP_RATING_EMAIL_CAMPAIGN;
const EmailCampaign = process.env.REACT_APP_EMAIL_CAMPAIGN;
const contactApi = process.env.REACT_APP_CONTACTAPI;
const reviewTypeId = process.env.REACT_APP_REVIEWTYPEID;
const bixpandFavicon = process.env.REACT_APP_BIXPAND_FAVICON;
const paymentKey = process.env.REACT_APP_PAYMENTKEY;
const HelpAdvisors = process.env.REACT_APP_AUTHAPI;
const ContactDashboard = process.env.REACT_APP_CONTACTAPI;
const chatApi = process.env.REACT_APP_CHATAPI;
const chatUrl = process.env.REACT_APP_CHATURL;
const timeformate = process.env.REACT_APP_TIME_FORMATE;
const callApi = process.env.REACT_APP_CALLAPI;
const leadApi = process.env.REACT_APP_LEADAPI;
const planId = process.env.REACT_APP_PLAN_ID;
const Twilio = process.env.REACT_APP_TWILIO_NUMBER;
const VjNumber = process.env.REACT_APP_VJ_NUMBER;
const PaymentTwilioNumber = process.env.REACT_APP_PAYMENT_TWILIO_NUMBER;
const socketApi = process.env.REACT_APP_SOCKETAPI;
const contactIo = process.env.REACT_APP_SOCKETAPI_CONTACT;
const subscription = process.env.REACT_APP_SUBSCRIPTION;
const subscriptionio = process.env.REACT_APP_SUBSCRIPTION_SOCKET;
const supportMeetingURL = process.env.REACT_APP_SUPPORT_MEETING_URL;
const tawkTo = process.env.REACT_APP_TAWKTO;
const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const poweredBy = process.env.REACT_APP_POWEREDBY;
const businessListLogo = process.env.REACT_APP_BUSINESS_LIST;
const businessBanner = process.env.REACT_APP_BUSINESS_BANNER;


export default {
  baseURLApi,
  authApi,
  subscription,
  subscriptionio,
  remote: process.env.REACT_APP_AUTHAPI,
  entityApi,
  campaignApi,
  isBackend: process.env.REACT_APP_BACKEND,
  reviewApi,
  supportApi,
  payment,
  searchGooglePlace,
  getPlaceDetails,
  getAuthenticationUrl,
  searchFBPageFromName,
  getFBPages,
  reviewboard,
  googleMapApi,
  widgets,
  facebookReviewImageShareUrl,
  dateFilter,
  bucketUrl,
  BixUrl,
  BixPublicUrl,
  help,
  url,
  privacyUrl,
  termsUrl,
  facebookapi,
  staticToken,
  contactEmail,
  TextCampaign,
  RatingTextCampaign,
  RatingEmailCampaign,
  EmailCampaign,
  contactApi,
  reviewTypeId,
  paymentKey,
  HelpAdvisors,
  ContactDashboard,
  chatApi,
  chatUrl,
  timeformate,
  callApi,
  bixpand,
  leadApi,
  planId,
  Twilio,
  VjNumber,
  PaymentTwilioNumber,
  socketApi,
  contactIo,
  supportMeetingURL,
  tawkTo,
  bixpandFavicon,
  bixLogo,
  paypalClientId,
  subDomainUrl,
  stagingDomain,
  poweredBy,
  businessListLogo,
  businessBanner,
  domain,
};
