import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  fieldofpayment: {
    width: '100%',
  },
  label: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'500 !important',
  },
  input: {
    fontSize: '12px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'400 !important',
  },
  termDescription: {
    fontSize: '14px !important' ,
    fontFamily: 'Open sans !important' ,
    fontWeight:'600 !important',
  },
}));
