import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { postDataRequestPublic } from '../../../../common/commonfunctions';
import config from '../../../../config';
import { UrlByCode } from '../../../../Api Mutation/Appointment';

export default function AppintmentPublicUrl() {
  const { user, event } = useParams();
  useEffect(() => {
    postDataRequestPublic(config.url, {
      query: UrlByCode,
      variables: {
        sorturlcode: window.location.href,
      },
    }).then((res) => {
      if (res?.data?.urlbycode?.fullurl) {
        window.open(res?.data?.urlbycode?.fullurl, '_self');
      }
    });
  }, [user, event]);
  return <div />;
}
