/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import {ContentMarketing, AddActivityContentMarketing} from '../../Api Mutation/Campaign'
import config from '../../config';
import { Entity } from '../../Api Mutation/EntityApi';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { Typography } from '@mui/material';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import PoweredBy from '../../common/PoweredBy';

export default function PublicPreview(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const [contentMarketingData, setContentMarketingData] = useState();
  const [locationData ,setLocationData] = useState();
  const [startDate, setStartDate] = useState(moment().format());
  const ref = useRef(null);
  const { onClickOutside } = props;
  const {
    id, lid,
  } = useParams([]);

  const fetchData = async (prop) => {
    if (handleUndefined(id) || handleUndefined(prop?.id)) {
      const CREATE_ACTIVITY = AddActivityContentMarketing;
      await postDataRequestPublic(config.baseURLApi, {
        query: CREATE_ACTIVITY,
        variables: {
          contentmarketingnid: handleUndefined(id) || handleUndefined(prop?.id),
          webpageopeneddata: prop?.opendata,
          webpageclickeddata: JSON.stringify(prop?.click) ? JSON.stringify(prop?.click) : null,
        },
      });
    }
  };

  const getLocationByData = () => {
    if (lid) {
      const ENTITYDATA = Entity;
      postDataRequestPublic(config.baseURLApi, {
        query: ENTITYDATA,
        variables: { entityid: lid },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationData(res?.data?.entity);
        }
      });
    }
  };

  useEffect(() => {
    getLocationByData();
  }, [lid]);


  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside && onClickOutside();
      } else if (e?.target?.parentNode?.localName === 'a') {
          const clickedData = {
            timestamp: new Date(),
            url: e?.target?.parentNode?.href,
            name: e?.target?.parentNode?.innerText,
          };
          fetchData({
            id,
            click: clickedData,
          });
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    fetchData({
      opendata: JSON.stringify({
        timestamp: startDate,
        timespent: 0,
      }),
    });
  }, []);

  useEffect(() => {
    window.onbeforeunload = async (event) => {
      const message = 'Are you sure to exit this page ?';
      const e = event || window.event;
      await e.preventDefault();
      if (e) {
        e.returnValue = message;
      }
      return fetchData({
        opendata: JSON.stringify({
          timestamp: startDate,
          timespent: moment(new Date())?.diff(startDate, 'seconds'),
        }),
      });
    };
  }, [id]);

  const getData = () => {
    if (id) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: ContentMarketing,
        variables: {
          contentmarketingnid: id,
        },
      }).then((res) => {
        const data = res?.data?.contentMarketing;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (data){
          setContentMarketingData(data);
        }
        setDataLoading(false);
      });
    }
  };

  useEffect(() => {
    setStartDate(moment().format());
    getData();
  }, [id]);
  return (
    <div>
      <center>
        <Typography
          variant="h6"
        >
          {contentMarketingData?.name}
        </Typography>
        {dataLoading ? <LoadingPage /> : (
          <div
            dangerouslySetInnerHTML={{
              __html: contentMarketingData?.contenttemplate,
            }}
          />
        )}
      </center>
      <PoweredBy id={locationData?.entityid}/>
    </div>
  );
}
