import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  widgetWrapper: {
    display: 'flex',
    minHeight: '100%',
  },
  inheritHeight: {
    minHeight: 'auto',
  },
  widgetHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widgetRoot: {
    // boxShadow: theme.customShadows.widget,
  },
  widgetBody: {
    height: (props) => (props.fullHeight ? '100%' : 'inherit'),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  noPadding: {
    padding: 0,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  title: {
    fontFamily: 'poppins',
    fontSize: '20px',
    fontWeight: '600 !important',
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'rgba(255, 255, 255, 0.35)',
    },
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.23)',
  },
}));
