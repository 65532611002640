import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box, Grid, Typography, Avatar as AvatarMui, Badge, Paper, Button,
} from "@mui/material";
import { ArrowForwardIos } from '@mui/icons-material';
import { handleUndefined, postDataRequestPublic } from '../../../../common/commonfunctions';
import config from '../../../../config';
import { EventListByURL } from '../../../../Api Mutation/EventList';
import { LoadingPage } from '../../../LoadingPage/LoadingPage';
import PoweredByLogo  from '../../../../common/PoweredByLogo';
import useStyles from '../PublicPageAppointment/style';

const AppointmentList = () => {
    const classes = useStyles();
    const { shorturl } = useParams([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [profile, setProfile] = useState('');
    const [eventData, setEventData] = useState([]);
    const [showLogo, setShowLogo] = useState(false);
    const [businessId, setBusinessId] = useState('');

    useEffect(() => {
        if (shorturl) {
            setDataLoading(true);
            postDataRequestPublic(config.baseURLApi, {
                query: EventListByURL,
                variables: {
                    pageurl: `${shorturl}`,
                },
            }).then((res) => {
                const data = res?.data?.eventListByUrl;
                setShowLogo(data?.isshowfooterlogo);
                setBusinessId(data?.locationid);
                setProfile(data?.createdUser);
                setEventData(data?.events);
            });
            setDataLoading(false);
        }
    }, [shorturl]);

    return (
        <>
            {dataLoading ? (<LoadingPage />) : ''}
            {profile && eventData.length > 0 && (
                <>
                    <center>
                        <Box>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <AvatarMui
                                    style={{
                                        width: 80,
                                        height: 80,
                                        color: '#005eb2',
                                        background: 'lightgray',
                                        marginTop: 20,
                                        fontSize: 32,
                                        fontWeight: 600,
                                        alignItems: 'center',
                                        aspectRatio: 'auto',
                                    }}
                                    src={profile?.avatar}
                                >
                                    {handleUndefined(profile?.firstname?.charAt(0))}
                                </AvatarMui>
                            </Badge>
                            <Typography variant="h5" style={{ marginTop: 25, fontWeight: 'bold' }}>{`${profile?.firstname ? profile?.firstname : ''} ${profile?.lastname ? profile?.lastname : ''}`}</Typography>
                            <Typography variant="body2" style={{ marginTop: 16 }}>Book a call with me:</Typography>
                        </Box>
                    </center>
                    <Box style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <Grid container spacing={3} style={{ marginTop: 25 }} alignItems="center" justifyContent="center">
                            {eventData && eventData.map((item) => (
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Paper
                                        className={classes.paperCard}
                                        elevation={3}
                                        style={{
                                            background: 'white',
                                            borderBottom: '7px solid green',
                                        }}
                                    >
                                        <Box className="p-3">
                                            <Typography variant="h6">{item?.name}</Typography>
                                            <Box style={{ overflow: 'auto', height: 250, marginTop: 5 }}>
                                                <Typography
                                                    variant="body1"
                                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                                />
                                            </Box>
                                            <Box style={{ marginTop: 20 }}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    endIcon={<ArrowForwardIos />}
                                                    size="medium"
                                                    target="_blank"
                                                    href={item?.eventbookingurl}
                                                >
                                                    Book Now
                                                    {' '}
                                                    {item.ispaidevent ? `For $${item.amount}` : ''}
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <center>
                        <PoweredByLogo enablePublicLogo={showLogo} id={businessId} />
                    </center>
                </>
            )}
        </>
    );
};

export default AppointmentList;
