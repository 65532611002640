import gql from 'graphql-tag';

export const ContactDocument = `
query contactDocument($documentid: ID!){
 contactDocument(documentid:$documentid){
  documentid
  title
  description
  documents
  status
  createdby
  updatedby
  ownerUser {
    firstname
    lastname
  }
  deleted
  comments {
    commentid
    description
    parentid
    createdAt
    updatedAt
  }
  contactid
  createdAt
  updatedAt
  emailbody
 }}`;

export const CustomPropertyListByBusiness = `query customPropertyListByBusiness(
    $businessid: ID!
    $objecttype: [String!]
){
        customPropertyListByBusiness(
                businessid: $businessid
                objecttype: $objecttype
){
        name,value,type,table,
}}`;

export const CustomPropertyList = `query CustomPropertiesBySection($businessid: ID!) {
  customPropertiesBySection(businessid: $businessid) {
    fieldtype
    isrequired
    name
    objecttype
    propertyid
    options{
      label
      internal
    }
    propertyname
  }
}`;

export const CustomPropertyListByBusinessForImport = `query customPropertyListByBusinessForImport($businessid: ID!, $objecttype: [String!]) {
        customPropertyListByBusinessForImport(businessid: $businessid, objecttype: $objecttype) {
          name
          value
          type
          table
        }
      }`;

export const Verifyphonenumber = `
      query verifyphonenumber($phonenumber: String!,$contactid: ID!){
        verifyphonenumber(phonenumber: $phonenumber, contactid: $contactid)
      }`;

export const CreateContactEmail = gql`
mutation createContactEmail($to: String!, $fromemail: String!, $subject: String!, $status: Int!, $contactid: ID!, $ownerid: ID!, $senditnow: Boolean!, $createdby: ID!, $cc: String, $bcc: String, $fromname: String, $emailbody: String, $emailattachments: [Upload!], $taskreminderdate: String, $scheduledatetime: String, $emailtype: Int, $emailreferenceid: String, $servicesprovider: Int, $parentemailid: ID, $threadid: String, $attachmentsurls: [AttachmentsurlsInput!]) {
  createContactEmail(to: $to, fromemail: $fromemail, subject: $subject, status: $status, contactid: $contactid, ownerid: $ownerid, senditnow: $senditnow, createdby: $createdby, cc: $cc, bcc: $bcc, fromname: $fromname, emailbody: $emailbody, emailattachments: $emailattachments, taskreminderdate: $taskreminderdate, scheduledatetime: $scheduledatetime, emailtype: $emailtype, emailreferenceid: $emailreferenceid, servicesprovider: $servicesprovider, parentemailid: $parentemailid, threadid: $threadid, attachmentsurls: $attachmentsurls) {
    emailid
    to
    cc
    bcc
    fromemail
    fromname
    subject
    emailbody
    emailattachments
    status
    scheduledatetime
    contactid
    ownerid
    threadid
    messageid
    emailreferenceid
    servicesprovider
    emailtype
    parentemailid
    senditnow
    createdby
    updatedby
    deleted
    activityhistory
    emailread
    createdAt
    updatedAt
    replies {
      emailid
      to
      cc
      bcc
      fromemail
      fromname
      subject
      emailbody
      emailattachments
      status
      scheduledatetime
      contactid
      ownerid
      threadid
      messageid
      emailreferenceid
      servicesprovider
      emailtype
      parentemailid
      senditnow
      createdby
      updatedby
      deleted
      activityhistory
      emailread
      createdAt
      updatedAt
    }
    report {
      emailreportid
      messageid
      senttime
      delivereddate
      openeddate
      clickeddate
      bounceddate
      unsubscribeddate
      contactid
      locationid
      emailid
      status
      createdby
      updatedby
      deleted
      createdAt
      updatedAt
    }
  }
}`;

export const CreateContactByEmail = `
mutation createContactEmail($to: String!, $fromemail: String!, $subject: String!, $status: Int!, $contactid: ID!, $ownerid: ID!, $senditnow: Boolean!, $createdby: ID!, $cc: String, $bcc: String, $fromname: String, $emailbody: String, $emailattachments: [Upload!], $taskreminderdate: String, $scheduledatetime: String, $emailtype: Int, $emailreferenceid: String, $servicesprovider: Int, $parentemailid: ID, $threadid: String, $attachmentsurls: [AttachmentsurlsInput!]) {
  createContactEmail(to: $to, fromemail: $fromemail, subject: $subject, status: $status, contactid: $contactid, ownerid: $ownerid, senditnow: $senditnow, createdby: $createdby, cc: $cc, bcc: $bcc, fromname: $fromname, emailbody: $emailbody, emailattachments: $emailattachments, taskreminderdate: $taskreminderdate, scheduledatetime: $scheduledatetime, emailtype: $emailtype, emailreferenceid: $emailreferenceid, servicesprovider: $servicesprovider, parentemailid: $parentemailid, threadid: $threadid, attachmentsurls: $attachmentsurls) {
    emailid
    to
    cc
    bcc
    fromemail
    fromname
    subject
    emailbody
    emailattachments
    status
    scheduledatetime
    contactid
    ownerid
    threadid
    messageid
    emailreferenceid
    servicesprovider
    emailtype
    parentemailid
    senditnow
    createdby
    updatedby
    deleted
    activityhistory
    emailread
    createdAt
    updatedAt
    replies {
      emailid
      to
      cc
      bcc
      fromemail
      fromname
      subject
      emailbody
      emailattachments
      status
      scheduledatetime
      contactid
      ownerid
      threadid
      messageid
      emailreferenceid
      servicesprovider
      emailtype
      parentemailid
      senditnow
      createdby
      updatedby
      deleted
      activityhistory
      emailread
      createdAt
      updatedAt
    }
    report {
      emailreportid
      messageid
      senttime
      delivereddate
      openeddate
      clickeddate
      bounceddate
      unsubscribeddate
      contactid
      locationid
      emailid
      status
      createdby
      updatedby
      deleted
      createdAt
      updatedAt
    }
  }
}`;

export const EditContactEmail = gql` 
mutation editContactEmail($emailid: ID!, $updatedby: ID!, $to: String, $cc: String, $threadid: String, $bcc: String, $emailreferenceid: String, $fromemail: String, $fromname: String, $subject: String, $emailbody: String, $emailattachments: [Upload!], $status: Int, $taskreminderdate: String, $scheduledatetime: String, $contactid: ID, $ownerid: ID, $servicesprovider: Int, $senditnow: Boolean, $attachmentsurls: [AttachmentsurlsInput!], $emailtype: Int, $parentemailid: ID) {
  editContactEmail(emailid: $emailid, updatedby: $updatedby, to: $to, cc: $cc, threadid: $threadid, bcc: $bcc, emailreferenceid: $emailreferenceid, fromemail: $fromemail, fromname: $fromname, subject: $subject, emailbody: $emailbody, emailattachments: $emailattachments, status: $status, taskreminderdate: $taskreminderdate, scheduledatetime: $scheduledatetime, contactid: $contactid, ownerid: $ownerid, servicesprovider: $servicesprovider, senditnow: $senditnow, attachmentsurls: $attachmentsurls, emailtype: $emailtype, parentemailid: $parentemailid)
}`;

export const ContactEmail = `
            query contactEmail($emailid: ID!){
                contactEmail(emailid:$emailid){
                    emailid,to,cc,bcc,fromemail,fromname,subject,emailbody,emailattachments,status,scheduledatetime,contactid,
                    replies{emailid,to,cc, threadid,fromemail,fromname,subject, emailbody, emailattachments},senditnow,servicesprovider,createdby,updatedby,
                    report{emailreportid,senttime,delivereddate,openeddate,clickeddate,bounceddate,unsubscribeddate},
                    deleted,createdAt,updatedAt,
             }}`;

export const Emailsettingsbyuser = `
query emailsettingsbyuser($ownerid: ID!,$isemail: Boolean){
  emailsettingsbyuser(ownerid:$ownerid, isemail:$isemail){
    emailsettingid,accountid,refreshtoken,accesstoken,historyid,useremail,accesstokenexpirydate,status,servicesprovider,createdby,updatedby,
    deleted,locationid,ownerid,iscalendar,isemail,iscontact,isdefaultemail,createdAt,updatedAt,
 }}`;
export const Employeesizeyearsrevenuecategories = `query employeesizeyearsrevenuecategories($locationid: ID) {
        employeesizeyearsrevenuecategories(locationid: $locationid) {
          employeesize
          revenue
          yearsinbusiness
          categories
          typeofentity
          businessstartdate
        }
      }
      
      `;

export const SectionsByBusinessWithProperty = `query sectionsByBusinessWithProperty($businessid:ID!,$objecttype: String){
  sectionsByBusinessWithProperty(businessid: $businessid, objecttype:$objecttype){
    sectionid,name,description,locationid,objecttype,iscommon,allowtoeditdelete,deleted,businessid,createdby,
    updatedby,propertiescount,properties{propertyid,name,label,sectionid,fieldtype,options,dbfieldname,description,locationid,
    objecttype,iscommon,allowtoeditdelete,isrequired,isshowinform,deleted,businessid,createdby,updatedby,createdUser{userid,firstname,lastname,phonenumber,email},
    createdAt,updatedAt}createdUser{userid,firstname,lastname,phonenumber,email},createdAt,updatedAt,
    properties{propertyid,name,label,sectionid,fieldtype,options,dbfieldname,description,locationid,objecttype,iscommon,allowtoeditdelete,
    isrequired,isshowinform,deleted,businessid,createdby,updatedby,createdAt,updatedAt}
  }}`;

export const ContactlistByLocation = `
query contactlistbylocation($locationid:ID!,$pagenumber:Int!,$pagesize:Int!,$searchtext:String){
  contactlistbylocation(locationid:$locationid,pagenumber:$pagenumber,pagesize:$pagesize,searchtext:$searchtext){
    count 
    rows{ 
      contactid
       primaryemail
       firstname
       lastname
       fullname
       phonenumber
       lifecyclestage
       organizationname
       organizationid
      } 
    }
  }`;

export const SavedFiltersByUser = `query savedfiltersbyuser($ownerid: ID!, $locationid: ID!, $type: Int) {
  savedfiltersbyuser(ownerid: $ownerid, locationid: $locationid, type: $type) {
    filterid
    name
    description
    businessid
    table
    type
    query
    createdby
    updatedby
    locationid
    deleted
  }
}`;

export const PinFilter = `mutation pinFilter($filterid: ID!, $locationid: ID!, $type: Int, $isforallrecords: Boolean, $ownerid: ID, $createdby: ID, $businessid: ID) {
  pinFilter(filterid: $filterid, locationid: $locationid, type: $type, isforallrecords: $isforallrecords, ownerid: $ownerid, createdby: $createdby, businessid: $businessid)
}`;

export const Editsavedfilter = `mutation editsavedfilter($filterid: ID!, $name: String!, $table: String!, $locationid: ID!, $description: String, $businessid: ID, $type: Int, $query: String, $ownerid: ID, $updatedby: ID) {
  editsavedfilter(filterid: $filterid, name: $name, table: $table, locationid: $locationid, description: $description, businessid: $businessid, type: $type, query: $query, ownerid: $ownerid, updatedby: $updatedby)
}`;

export const TeamsByBusiness = `query teamsbybusiness($businessid: ID!) {
  teamsbybusiness(businessid: $businessid) {
    teamid
    name
    memberlist {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    createdUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    businessid
    locationid
    createdby
    updatedby
    createdAt
    updatedAt
  }
}`;

export const UserListByBusinessAndLocation = `query userListByBusinessAndLocation($businessid: ID!, $locationid: ID!, $includeusersid: [ID]) {
  userListByBusinessAndLocation(businessid: $businessid, locationid: $locationid, includeusersid: $includeusersid) {
    userid
    roleid
    firstname
    lastname
    phonenumber
    email
    role
    lastlogin
    deleted
    usertype
    businessid
    locationid
    defaultcontactview
  }
}`;

export const ContactEmailsByContact = `query contactEmailsByContact($contactid: ID!) {
  contactEmailsByContact(contactid: $contactid) {
    emailid
    to
    cc
    bcc
    fromemail
    fromname
    subject
    emailbody
    emailattachments
    status
    scheduledatetime
    contactid
    ownerid
    threadid
    messageid
    emailreferenceid
    servicesprovider
    emailtype
    parentemailid
    senditnow
    createdby
    updatedby
    deleted
    activityhistory
    emailread
    createdAt
    updatedAt
    replies {
      emailid
      to
      cc
      bcc
      fromemail
      fromname
      subject
      emailbody
      emailattachments
      status
      scheduledatetime
      contactid
      ownerid
      threadid
      messageid
      emailreferenceid
      servicesprovider
      emailtype
      parentemailid
      senditnow
      createdby
      updatedby
      deleted
      activityhistory
      emailread
      createdAt
      updatedAt
    }
    report {
      emailreportid
      messageid
      senttime
      delivereddate
      openeddate
      clickeddate
      bounceddate
      unsubscribeddate
      contactid
      locationid
      emailid
      status
      createdby
      updatedby
      deleted
      createdAt
      updatedAt
    }
  }
}`;

export const ContactDataById = `
query contact($contactid: ID!){
  contact(contactid:$contactid){
    contactid,primaryemail,firstname,lastname,contactavatar,phonenumber,lifecyclestage,locationid,
    createdby,updatedby,jobid,ownerid,createdAt,updatedAt,istargetlist,ispininchat,issmsblock,
    linkedin, facebook, twitter, jobid
}}`;

export const CreateContactTask = `mutation createContactTask($taskname: String!, $taskdescription: String!, $createdby: ID!, $tasktype: Int, $priority: Int, $duedate: String, $remindertype: Int, $reminderdate: String, $ownerid: ID, $associatedconnnect: [ID!], $status: Int, $assigneduser: [ID!], $contactid: ID, $timezone: String, $eventtiming: Int, $iscreateevent: Boolean) {
  createContactTask(taskname: $taskname, taskdescription: $taskdescription, createdby: $createdby, tasktype: $tasktype, priority: $priority, duedate: $duedate, remindertype: $remindertype, reminderdate: $reminderdate, ownerid: $ownerid, associatedconnnect: $associatedconnnect, status: $status, assigneduser: $assigneduser, contactid: $contactid, timezone: $timezone, eventtiming: $eventtiming, iscreateevent: $iscreateevent) {
    taskid
    taskname
    taskdescription
    tasktype
    priority
    duedate
    remindertype
    reminderdate
    assigneduser
    status
    associatedconnnect
    ownerid
    createdby
    updatedby
    deleted
    isarchived
    contactid
    comments {
      commentid
      description
      parentid
      type
      status
      createdby
      updatedby
      deleted
      contactid
      createdAt
      updatedAt
    }
    assignedusers {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    createdUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    updatedUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    associatedconnnects {
      contactid
      primaryemail
      secondaryemail
      firstname
      lastname
      contactavatar
      officephonenumber
      phonenumber
      homephonenumber
      lifecyclestage
      locationid
      createdby
      updatedby
      deleted
      jobid
      tags
      ownerid
      organizationid
      source
      twitter
      createdAt
      updatedAt
      phonecountryid
      linkedin
      facebook
      status
      openemail
      opentask
      opennote
      opendocument
      istargetlist
      ispininchat
      lasteventdate
      issmsblock
      leadscore
      teams
      notecount
      taskcount
      emailcount
      dealscount
      documentcount
      emailstatus
      phonenostatus
      phoneverifydata
    }
    createdAt
    updatedAt
    timezone
    eventtiming
  }
}`;

export const CreateContactNote = `mutation createContactNote($notesubject: String!, $createdby: ID!, $notedescription: String, $assingedtocontact: [ID!], $parententityid: ID, $entitytype: Int, $ownerid: ID, $contactid: ID) {
  createContactNote(notesubject: $notesubject, createdby: $createdby, notedescription: $notedescription, assingedtocontact: $assingedtocontact, parententityid: $parententityid, entitytype: $entitytype, ownerid: $ownerid, contactid: $contactid) {
    contactnoteid
    notesubject
    notedescription
    assingedtocontact
    parententityid
    entitytype
    ownerid
    createdby
    updatedby
    deleted
    contactid
    comments {
      commentid
      description
      parentid
      type
      status
      createdby
      updatedby
      deleted
      contactid
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    readstatus
  }
}`;

export const ContactNotesByContact = `query contactNotesByContact($contactid: ID!, $orderby: String) {
  contactNotesByContact(contactid: $contactid, orderby: $orderby) {
    contactnoteid
    notesubject
    notedescription
    assingedtocontact
    parententityid
    entitytype
    ownerid
    createdby
    updatedby
    deleted
    contactid
    comments {
      commentid
      description
      parentid
      type
      status
      createdby
      updatedby
      deleted
      contactid
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    readstatus
  }
}`;

export const EditContactNote = `mutation editContactNote($contactnoteid: ID!, $notesubject: String!, $updatedby: ID!, $notedescription: String, $assingedtocontact: [ID!], $parententityid: ID, $entitytype: Int, $ownerid: ID, $contactid: ID) {
  editContactNote(contactnoteid: $contactnoteid, notesubject: $notesubject, updatedby: $updatedby, notedescription: $notedescription, assingedtocontact: $assingedtocontact, parententityid: $parententityid, entitytype: $entitytype, ownerid: $ownerid, contactid: $contactid)
}`;

export const ContactNote = `query contactNote($contactnoteid: ID!) {
  contactNote(contactnoteid: $contactnoteid) {
    contactnoteid
    notesubject
    notedescription
    assingedtocontact
    parententityid
    entitytype
    ownerid
    createdby
    updatedby
    deleted
    contactid
    comments {
      commentid
      description
      parentid
      type
      status
      createdby
      updatedby
      deleted
      contactid
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    readstatus
  }
}`;

export const ContactTasksByContact = `query contactTasksByContact($contactid: ID!, $searchtext: String, $status: Int, $orderby: String) {
  contactTasksByContact(contactid: $contactid, searchtext: $searchtext, status: $status, orderby: $orderby) {
    taskid
    taskname
    taskdescription
    tasktype
    priority
    duedate
    remindertype
    reminderdate
    assigneduser
    status
    associatedconnnect
    ownerid
    createdby
    updatedby
    deleted
    isarchived
    contactid
    comments {
      commentid
      description
      parentid
      type
      status
      createdby
      updatedby
      deleted
      contactid
      createdAt
      updatedAt
    }
    assignedusers {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    createdUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    updatedUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    associatedconnnects {
      contactid
      primaryemail
      firstname
      lastname
      phonenumber
      lifecyclestage
    }
    createdAt
    updatedAt
    timezone
    eventtiming
  }
}`;

export const ContactTask = `query contactTask($taskid: ID!) {
  contactTask(taskid: $taskid) {
    taskid
    taskname
    taskdescription
    tasktype
    priority
    duedate
    remindertype
    reminderdate
    assigneduser
    status
    associatedconnnect
    ownerid
    createdby
    updatedby
    deleted
    isarchived
    contactid
    comments {
      commentid
      description
      parentid
      type
      status
      createdby
      updatedby
      deleted
      contactid
      createdAt
      updatedAt
    }
    assignedusers {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    createdUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    updatedUser {
      userid
      roleid
      firstname
      lastname
      phonenumber
      email
      role
      lastlogin
      deleted
      usertype
      businessid
      locationid
      defaultcontactview
    }
    associatedconnnects {
      contactid
      primaryemail
      firstname
      lastname
      phonenumber
      lifecyclestage
      locationid
      organization {
        name
        organizationid
      }
    }
    createdAt
    updatedAt
    timezone
    eventtiming
  }
}`;

export const ContactAddressByContactid = `query contactAddressByContactid($addresstype: Int!, $organizationid: ID, $contactid: ID) {
  contactAddressByContactid(addresstype: $addresstype, organizationid: $organizationid, contactid: $contactid) {
    addressid
    street1
    street2
    city
    county
    stateid
    countryid
    zip
    createdby
    updatedby
    locationid
    organizationid
    addresstype
    country {
      countryid
      name
      code
      flag
      phonecode
      createdby
      updatedby
    }
    state {
      stateid
      name
      code
      createdby
      updatedby
    }
  }
}`;

export const CreateOrUpdateContactAddress = `mutation createOrUpdateContactAddress($street1: String, $street2: String, $city: String, $county: String, $stateid: Int, $state: String, $addresstype: Int, $countryid: Int, $zip: String, $createdby: ID, $contactid: ID, $organizationid: ID, $locationid: ID) {
  createOrUpdateContactAddress(street1: $street1, street2: $street2, city: $city, county: $county, stateid: $stateid, state: $state, addresstype: $addresstype, countryid: $countryid, zip: $zip, createdby: $createdby, contactid: $contactid, organizationid: $organizationid, locationid: $locationid) {
    addressid,street1,street2,city,county,stateid,countryid,addresstype,zip,country{countryid},state{stateid,name}
  }
}`;

export const Organizationbylocation = `query organizationbylocation($locationid: ID!, $pagenumber: Int!, $pagesize: Int!, $searchtext: String) {
  organizationbylocation(locationid: $locationid, pagenumber: $pagenumber, pagesize: $pagesize, searchtext: $searchtext) {
    count
    rows {
      organizationid
      name
    }
  }
}`;

export const ContactFormSettingsByLocation = `query contactformsettingsbylocation($locationId: ID!){contactformsettingsbylocation(locationid: $locationId){
  formid
  name
  formtype
  formbody
  callouttext
  featureimageurl
  thanksmessage
  popupshowcount
  status
  activedate
  impressions
}}`;

export const Contactformsettingreport = `
query contactformsettingreport($formid: ID!) {
  contactformsettingreport(formid: $formid) {
    formid
    formtype
    featureimageurl
    callouttext
    calloutbody
    calloutbuttontext
    themecolor
    backgroundcolor
    textcolor
    calloutbuttontype
    calloutbuttonlink
    formbody
    name
    calloutimageposition
    calloutbuttonfileurl
    isshowfiledselection
    triggeronload
    isfixedposition
    triggerposition
    status
    activedate
    isfirstnameshow
    islastnameshow
    isemailshow
    isphoneshow
    formsubmitbuttontext
    contactlifecyclestage
    thanksmessage
    isshowthanksbutton
    thanksbuttontext
    thanksbuttontype
    thanksbuttonlink
    thanksbuttonfileurl
    triggeronhalfpagecroll
    triggeronexitintent
    triggerafterelapsedtime
    triggertime
    popupshowcount
    impressions
    locationid
    createdby
    updatedby
    deleted
    createdAt
    updatedAt
    issendthanksemail
    issendthankssms
    thanksemailconfig
    thankssmsconfig
    openeddata
    clickeddata
    owneruser {
      userid
      firstname
      lastname
      phonenumber
      email
    }
    formcontacts {
      contactid
      formcontactdata {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
        lifecyclestage
        locationid
        jobid
        ownerid
      }
    }
  }
}`;

export const CreateUrl = `mutation
createurl(
  $fullurl: String!
  $sorturlcode: String
  $deleted: Boolean
  $userid: ID
){
  createurl(
    fullurl: $fullurl
    sorturlcode: $sorturlcode
    deleted: $deleted
    userid: $userid
      ){urlid,fullurl,sorturlcode,userid,createdAt,updatedAt}
    }`;

export const OrganizationByLocationWithPagination = `
query organizationbylocationwithpagination($pagenumber: Int,$pagesize: Int,$locationid: ID!){
 organizationbylocationwithpagination(pagenumber: $pagenumber,pagesize: $pagesize,locationid:$locationid){
   count,rows{organizationid,name,locationid,orgphonenumber,orgemail,categories,employeesize,yearsinbusiness,revenue,businessstartdate,typeofentity,contactcount,
     orgmap, address {addressid,street1,street2,city,county,stateid,countryid,zip,country{countryid,name},state {stateid,name},}
   orgwebsite,orgtwitter,orgfacebook,orgstatus,orglinkedin,orggmb,orgyelp,orgtripadvisor,createdby,updatedby,phonecountryid,createdAt,updatedAt,orgyoutubeurl}}}`;

export const createOrGetContact = `
   mutation 
   createOrGetContact(
     $contactid: ID
     $primaryemail: String
     $secondaryemail: String
     $firstname: String!
     $lastname: String
     $locationid: ID!
     $website: String
     $organizationname: String
     $jobid: String,
     $phonenumber: String
     $lifecyclestage: Int!
     $linkedin: String
     $source: [String!]
     $ownerid: ID
     $createdby: ID
     $status: Int
     $productid: ID
     $paymentid: ID
   ){ 
     createOrGetContact(
       contactid: $contactid
       primaryemail: $primaryemail
       secondaryemail: $secondaryemail
       firstname: $firstname
       lastname: $lastname
       locationid: $locationid
       source: $source,
       website: $website
       organizationname: $organizationname
       linkedin: $linkedin
       jobid: $jobid,
       phonenumber: $phonenumber
       lifecyclestage: $lifecyclestage
       ownerid: $ownerid
       createdby: $createdby
       status: $status
       productid: $productid
       paymentid: $paymentid
               ){
         contactid,primaryemail,firstname,lastname,phonenumber,lifecyclestage,locationid,
         createdby,updatedby,ownerid,source,createdAt,updatedAt,status,jobid,linkedin
               }
       }`;

export const UpdateContact = `
mutation
updateContact(
        $contactid: ID!
        $primaryemail: String
        $secondaryemail: String
        $firstname: String
        $lastname: String
        $locationid: ID
        $officephonenumber: String
        $phonenumber: String
        $lifecyclestage: Int
        $jobid: String
        $tags: [String!]
        $source: [String!]
        $twitter: String
        $linkedin: String
        $facebook: String
        $status: Int
        $updatedby: ID
        $phonecountryid: ID
        $subscriptionstatus: Int,
        $emailsubscriptiondate: String,
        $emailunsubscriptiondate: String
        $attribute1: String
        $attribute2: String
        $attribute3: String
        $attribute4: String
        $attribute5: String
        $attribute6: String
        $attribute7: String
        $attribute8: String
        $attribute9: String
        $attribute10: String
        $attribute11: String
        $attribute12: String
        $attribute13: String
        $attribute14: String
        $attribute15: String
        $attribute16: String
        $attribute17: String
        $attribute18: String
        $attribute19: String
        $attribute20: String
        $attribute21: String
        $attribute22: String
        $attribute23: String
        $attribute24: String
        $attribute25: String
        $attribute26: String
        $attribute27: String
        $attribute28: String
        $attribute29: String
        $attribute30: String
        $attribute31: String
        $attribute32: String
        $attribute33: String
        $attribute34: String
        $attribute35: String
        $attribute36: String
        $attribute37: String
        $attribute38: String
        $attribute39: String
        $attribute40: String
        $attribute41: String
        $attribute42: String
        $attribute43: String
        $attribute44: String
        $attribute45: String
        $attribute46: String
        $attribute47: String
        $attribute48: String
        $attribute49: String
        $attribute50: String
    ){
        updateContact(
            contactid:$contactid,
            primaryemail: $primaryemail,
            secondaryemail: $secondaryemail,
            firstname: $firstname,
            lastname: $lastname,
            locationid: $locationid,
            officephonenumber: $officephonenumber,
            phonenumber: $phonenumber,
            lifecyclestage: $lifecyclestage,
            jobid: $jobid,
            tags: $tags,
            status: $status,
            updatedby: $updatedby,
            phonecountryid: $phonecountryid
            subscriptionstatus: $subscriptionstatus,
emailsubscriptiondate: $emailsubscriptiondate,
emailunsubscriptiondate: $emailunsubscriptiondate
            source: $source
            twitter:$twitter
            linkedin: $linkedin
            facebook: $facebook
            attribute1:$attribute1
            attribute2:$attribute2
            attribute3:$attribute3
            attribute4:$attribute4
            attribute5:$attribute5
            attribute6:$attribute6
            attribute7:$attribute7
            attribute8:$attribute8
            attribute9:$attribute9
            attribute10:$attribute10
            attribute11:$attribute11
            attribute12:$attribute12
            attribute13:$attribute13
            attribute14:$attribute14
            attribute15:$attribute15
            attribute16:$attribute16
            attribute17:$attribute17
            attribute18:$attribute18
            attribute19:$attribute19
            attribute20:$attribute20
            attribute21:$attribute21
            attribute22:$attribute22
            attribute23:$attribute23
            attribute24:$attribute24
            attribute25:$attribute25
            attribute26:$attribute26
            attribute27:$attribute27
            attribute28:$attribute28
            attribute29:$attribute29
            attribute30:$attribute30
            attribute31:$attribute31
            attribute32:$attribute32
            attribute33:$attribute33
            attribute34:$attribute34
            attribute35:$attribute35
            attribute36:$attribute36
            attribute37:$attribute37
            attribute38:$attribute38
            attribute39:$attribute39
            attribute40:$attribute40
            attribute41:$attribute41
            attribute42:$attribute42
            attribute43:$attribute43
            attribute44:$attribute44
            attribute45:$attribute45
            attribute46:$attribute46
            attribute47:$attribute47
            attribute48:$attribute48
            attribute49:$attribute49
            attribute50:$attribute50
        )
    }`;

export const CRMcontactByData = `
query contact($contactid: ID!){
    contact(contactid: $contactid){
        contactid,primaryemail,secondaryemail,firstname,lastname,officephonenumber,phonenumber,homephonenumber,lifecyclestage,locationid,
        createdby,updatedby,deleted,jobid,tags,ownerid,source,twitter,address{addressid,street1,street2,city,county,stateid,countryid,addresstype,zip,country{countryid,name},state{stateid,name}},
        createdAt,updatedAt,phonecountryid,linkedin,facebook,status,attribute1,attribute2,attribute3,attribute4,attribute5,attribute6,attribute7,attribute8,
        attribute9,attribute10,attribute11,attribute12,attribute13,attribute14,attribute15,attribute16,attribute17,attribute18,attribute19,attribute20,attribute21,attribute22,attribute23,
        attribute24,attribute25,attribute26,attribute27,attribute28,attribute29,attribute30,attribute31,attribute32,attribute33,attribute34,attribute35,attribute36,
        attribute37,attribute38,attribute39,attribute40,attribute41,attribute42,attribute43,attribute44,attribute45,attribute46,attribute47,
        attribute48, attribute49,attribute50,emailstatus, phonenostatus,phoneverifydata, subscriptionstatus, emailunsubscriptiondate, emailsubscriptiondate
    }
}`;

export const Updatecontacts = `mutation updatecontacts($contactids: [ID!]!, $ownerid: ID, $lifecyclestage: Int, $status: Int, $tags: [String!], $source: [String!], $teams: [ID!], $subscriptionstatus: Int, $emailsubscriptiondate: String, $emailunsubscriptiondate: String) {
  updatecontacts(contactids: $contactids, ownerid: $ownerid, lifecyclestage: $lifecyclestage, status: $status, tags: $tags, source: $source, teams: $teams, subscriptionstatus: $subscriptionstatus, emailsubscriptiondate: $emailsubscriptiondate, emailunsubscriptiondate: $emailunsubscriptiondate)
}`;

export const ContactsSources = `query contactssources($businessid:ID){
  contactssources(businessid:$businessid)
  }`;

export const ContactsTags = `query contactsTags($businessid:ID){
    contactsTags(businessid:$businessid)
}`;
