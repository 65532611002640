import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  Paper: {
    backgroundColor: '#e8e8ee',
    color: '#000000',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#e8e8ee',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  btn: {
    fontSize: 12,
    color: '#fff',
    float: 'right',
    backgroundColor: '#005EB2',
  },
  field: {
    width: '100%',
    fontFamily: 'Open sans',
  },
  paymentBox:{
    marginLeft:40,
    [theme.breakpoints.down('sm')]:{
      marginLeft:0,
    },
  },
  actionIcon: {
    color: '#005EB2',
  },
  Dropzone: {
    height: '150px',
    textAlign: 'center',
    padding: '17px',
    backgroundColor: 'lightGray',
    color: 'gray',
  },
  placeholderIcon: {
    fontSize: 50,
    [theme.breakpoints.down('sm')]: {
      fontSize: 45,
    },
  },
  previewImage: {
    width: 150,
    aspectRatio: 'auto',
  },
  root: {
    maxWidth: 330,
    margin: 'auto',
    transition: '0.3s',
    borderRadius: '1rem',
    borderColor: theme.palette.primary.main,
    boxShadow:'0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    '&:hover': {
      cursor: 'pointer',
      transform: 'translateY(-10px)',
      boxShadow: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      transition: '0.3s',
      border: '1px solid #005eb2',
    },
  },
  media: {
    height: 200,
  },
  mediaDetails: {
    maxWidth: 400,
    maxHeight: 400,
    minWidth: 400,
    minHeight: 400,
    margin: 'auto',
    aspectRatio: 'auto',
    objectFit: 'contain',
    borderRadius: '4px',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 245,
      maxHeight: 'auto',
      minWidth: 245,
      minHeight: 'auto',
      margin: 'auto',
      aspectRatio: 'auto',
      objectFit: 'contain',
    },
  },
  productCard: {
    padding: theme.spacing(5),
  },
  action: {
    padding: '5px 10px 10px 16px',
  },
  rootDetails: {
    maxWidth: '80%',
    margin: 'auto',
    border:'1px solid black',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 'auto',
    },
  },
  productCardDetails: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    padding: 6,
  },
  Thankyou: {
    textAlign: 'center',
    padding: '20px',
    marginTop: '20px',
    fontSize: '40px',
    fontWeight: 600,
    textShadow: '2px 2px #FF0000',
  },
  paper1: {
    padding: theme.spacing(3),
    marginTop: 10,
    maxWidth: 1000,
    maxHeight: 500,
  },
  margin: {
    marginBottom: 10,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  reportCount: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    position: 'relative',
    height: 60,
  },
  businessLogo: {
    width: 50,
    height: 'auto',
    aspectRatio: 'auto',
    objectFit: 'contain',
  },
  details: {
    padding: 15,
    borderBottom: '2px solid black',
  },
  background: {
    border: '1px solid black',
  },
  prod: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subtext: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 400,
  },
  cart:{
    color:'#005eb2',
  },
}));
