import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  Rating: {
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
  },
  details: {
    display: "flex",
    alignItems: "center",
  },
  address: {
    marginTop: 13,
  },
  personDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16
  },
  person: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    maxHeight: 34,
    maxWidth: 34,
    aspectRatio: 'auto',
  },
  businessDetails: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  root: {
    maxWidth: 350,
    margin: 'auto',
    transition: '0.3s',
    borderRadius: '1rem',
    borderColor: theme.palette.primary.main,
    boxShadow:'0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    '&:hover': {
      cursor: 'pointer',
      transform: 'translateY(-10px)',
      boxShadow: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      transition: '0.3s',
      border:'1px solid #005eb2',
      borderRadius: '0rem 2rem 0rem 2rem',
    },
  },
  chip: {
    '&:hover': {
        cursor: 'pointer',
        border:'1px solid #005eb2',
        backgroundColor:'#005eb2',
        color: 'white',
        borderRadius: '2rem',
      },
  },
  heading: {
    '&:hover': {
      cursor: 'pointer',
      color: '#005eb2',
    },
  },
  subtext: {
    display: 'block',
    color: '#6d7e9c',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 400,
  },

  subtextDetails: {
    display: 'block',
    fontWeight: 400,
    fontSize: 12,
    color: '#6d7e9c',
  },
  contact: {
    margin: 0,
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  subhead:{
    display: 'block',
    color: '#6d7e9c',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  }
}));
