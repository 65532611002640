/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import React, { useEffect } from 'react';
import { postDataRequestPublic, handleUndefined } from '../../../common/commonfunctions';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import { paymentServiceProvider } from '../../../common/const';
import config from '../../../config';
import Widget from '../../../components/Widget/Widget';

export default function Paypal({
  amount, setPaymentId, setActiveTabId, appointment, contactData, event, organizationData,
  userId, businessId, locationId, eventId, objectId, formId, inquiryform,
}) {
  const style = { layout: 'vertical' };
  const bId = JSON.parse(localStorage.getItem('business_Id'));
  const uId = JSON.parse(localStorage.getItem('userid'));
  const lId = JSON.parse(localStorage.getItem('location_Id'));
  const clientId = 'AcUKdbtAPys4oAiNRcFZemWF4geVoucnkL0wLABT2hkF4U9iPeO2qhKkvfQUoc1YqTYtV3qjB9fX8Ugl';
  function ButtonWrapper({ currency, showSpinner }) {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
        type: 'resetOptions',
        value: {
          ...options,
          currency,
        },
      });
    }, [currency, showSpinner]);

    return (
      <>
        {(showSpinner && isPending) && <div className="spinner" />}
        <PayPalButtons
          style={style}
          disabled={false}
          forceReRender={[amount, currency, style]}
          fundingSource={undefined}
          createOrder={(data, actions) => actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => orderId)}
          // eslint-disable-next-line react/jsx-no-bind, func-names
          onApprove={function (data, actions) {
            actions.order.capture();
            if (data?.orderID) {
              postDataRequestPublic(`${config.payment}v1/payment-settings/create-payment`, {
                serviceproviderid: data.orderID,
                paymentid: data?.paymentID ? data?.paymentID : data?.payerID,
                businessid: businessId || bId,
                userid: userId || uId,
                locationid: locationId || lId,
                contactid: contactData?.contactid,
                organizationid: organizationData?.organizationid,
                objectid: objectId || eventId || formId,
                email: contactData?.primaryemail ? contactData?.primaryemail
                  : organizationData?.orgemail,
                payername: `${handleUndefined(contactData?.firstname)} ${handleUndefined(contactData?.lastname)} ${handleUndefined(organizationData?.name)}`,
                currency: 'usd',
                totalamount: amount,
                type: appointment ? 'APPOINTMENT' : event ? 'EVENT' : inquiryform ? 'INQUIRYFORM' :  'OTHER',
                description: appointment ? 'Appointment' : event ? 'Event' : inquiryform ? 'Inquiryform' : 'Other',
                status: 'success',
                additionaldata: JSON.stringify(data),
                serviceprovider: paymentServiceProvider?.paypal,
              }).then((data) => {
                if (data.data) {
                  setPaymentId(data?.data?.businesspaymentid);
                  if (setActiveTabId) {
                    setActiveTabId(3);
                  }
                  showNotification('Payment Successfully Done', 'success');
                }
              }).catch((err) => {
                showNotification(err?.response?.data?.message, 'error');
              });
            }
          }}
        />
      </>
    );
  }

  return (
    <div>
      <Widget>
        <PayPalScriptProvider options={{
          'client-id': clientId,
        }}
        >
          <ButtonWrapper
            style={{ layout: 'horizontal' }}
          />
        </PayPalScriptProvider>
      </Widget>
    </div>
  );
}
