import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    container: {
        width: '100vw !important',
        height:'100vh !important',
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        backgroundColor: '#005eb2 !important',
        position: 'absolute !important',
        top: 0,
        left: 0,
        [theme.breakpoints.down('sm')] : {
        backgroundColor: 'white !important',
        },
    },
    pageContainer: {
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'space-around !important',
        gap: '15rem !important',
        [theme.breakpoints.down('lg')]: {
            gap: '0 !important',
        },
    },
    errorLogo: {
        display: 'block !important',
        [theme.breakpoints.down('lg')]: {
            display: 'none !important',
        },
    },
    logoImg: {
        width: '350px !important',
        [theme.breakpoints.down('lg')]: {
            display: 'none !important',
        },
    },
    paperContainer: {
        height: '98vh !important',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'space-around !important',
        flexDirection: 'column !important',
        width: '420px !important',
    },
    errorType: {
        fontSize: '3rem !important',
        fontWeight: '400 !important',
    },
    errorMsg: {
        fontSize: '1.5rem !important',
        fontWeight: '300 !important',
        textAlign: 'center !important',
    },
    commonFont: {
        fontSize: '1.5rem !important',
        fontWeight: '300 !important',
        textAlign: 'center !important',
    },
    bixMsg: {
        fontSize: '1.5rem !important',
        color: '#b9b9b9 !important',
        fontWeight: '400 !important',
        textAlign: 'center !important',
    },
    backToBtn: {
        width: '180px !important',
        height: '55px !important',
        backgroundColor: '#005eb2 !important',
        color: 'white !important',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        fontSize: '1.3rem !important',
        fontWeight: '400 !important',
        borderRadius: '5px !important',
    }
}));
