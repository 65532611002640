import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  rootDialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  socialSvgContent: {
    borderRadius: '50%',
    top: 0,
    left: 0,
    margin: 1,
  },
  actionIcon: {
    color: '#005EB2',
  },
  BottomBut: {
    Color: theme.palette.success.main,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  socialIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    '&:hover': {
      fill: 'black',
      WebkitTransition: 'fill 170ms ease-in-out',
    },
  },
  margin: {
    marginBottom: 24,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  componentHeading: {
    fontSize: 20,
    paddingTop: 4,
    fontFamily: 'Open sans',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  backArrow: {
    fontSize: '35px !important',
    fontFamily: 'Open sans',
  },
  frame: {
    height: '100vh',
    width: '100%',
    border: '3px solid',
    borderColor: '#6e6e6e',
    borderRadius: '10px',
    overflow: 'auto',
  },
  iframe: {
    height: '95vh',
    width: '100%',
  },
  framePreview: {
    border: '2px solid',
    borderTop: '20px solid',
    borderColor: '#6e6e6e',
    borderRadius: '10px',
  },
  dotRed: {
    background: 'rgb(237, 89, 74)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotYellow: {
    background: 'rgb(253, 216, 0)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotGreen: {
    background: 'rgb(90, 192, 90)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '15px 15px 15px',
    zIndex: '10',
    alignItems: 'center',
    backgroundColor: '#6e6e6e',
    padding: '10px',
    paddingLeft: '1.5rem',
  },
  backMenu: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  mainDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '30px 0',
  },
  chilDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '85%',
    marginBottom: '10px',
  },
  contentImg: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    aspectRatio: 'auto',
    marginRight: '30px',
  },
  contentImgs: {
    flexShrink: '0',
    width: '60px',
    color: '#005EB2',
    height: '60px',
    backgroundColor: 'white',
    border: '3px solid #005EB2',
    aspectRatio: 'auto',
    marginRight: '30px',
  },
  button: {
    marginRight: '20px',
    borderRadius: '50px',
    color: 'white',
  },
  tabs: {
    minWidth: 170,
    borderBottom: '2px solid #e8e8e8',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '20px',
      fontSize: 10,
    },
  },
  tab: {
    minWidth: 110,
    fontSize: 14,
    fontFamily: 'Open sans',
    [theme.breakpoints.down('xs')]: {
      minWidth: 8,
      fontSize: 10,
    },
    '&:hover': {
      backgroundColor: '#f6f7ff',
      color: 'black',
    },
  },
  backButton: {
    height: 'fit-content',
    alignSelf: 'center',
  },
  nextButton: {
    marginLeft: 'auto',
    height: 'fit-content',
    alignSelf: 'center',
  },
  navPaddingLocation: {
    height: 'calc( 111vh - 82px )',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  score: {
    backgroundColor: 'white',
    fontSize: 14,
    color: '#005EB2',
    border: 'solid 2px #005EB2',
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginLeft: '8%',
    marginTop: '3%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
    },
  },
  cal: {
    width: '90%',
    height: 'auto',
    padding: 37,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 15,
      width: '100%',
      height: 'auto',
    },
  },
  brandLogoSize: {
    width: theme.spacing(22),
    height: 'auto',
    aspectRatio: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(18),
      height: 'auto',
      aspectRatio: 'auto',
    },
  },
  root1: {
    backgroundColor: theme.palette.background.paper,
    width: 650,
    [theme.breakpoints.down('sm')]: {
      width: 380,
    },
  },
  card: {
    marginTop: 20,
  },
  inquiryBox: {
    width: '100%',
    height: 45,
    border: '1px dashed lightgrey',
    paddingLeft: 10,
    paddingTop: 14,
    marginBottom: 10,
  },
  selectedBox: {
    width: '100%',
    height: 45,
    border: '1px dashed #005EB2',
    paddingTop: 14,
    paddingLeft: 10,
    marginBottom: 10,
    background: '#005EB2',
    color: '#fff',
  },
  accordionDetails: {
    display: 'block',
  },
  field: {
    width: '100%',
    fontFamily: 'Open sans',
  },
  fieldLink:{
    fontSize:12,
  },
  elementBox: {
    display: 'flex',
    alignItems: 'center',
  },
  requiredField: {
    color: 'red',
    position: 'absolute',
    bottom: 30,
    right: 0,
  },
  required: {
    color: 'red',
    marginLeft: 4,
  },
  submitBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectrows: {
    float: 'right',
    marginRight: 15,
    marginBottom: 15,
  },
  reportLink: {
    wordBreak: 'break-all',
  },
  widgetForm: {
    padding: 5,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
  },
  redio: {
    marginLeft: 15,
  },
  WidgetLayout: {
    textAlign: 'center',
  },
  WidgetLayoutAlign: {
    textAlign: 'left',
    padding: 10,
  },
  contents: {
    display: 'flex',
    padding: 10,
  },
  contentsText: {
    padding: 10,
  },
  img: {
    width: 250,
    height: 150,
    aspectRatio: 'auto',
    objectFit: 'fill',
    border: '1px solid black',
  },
  paper1: {
    padding: theme.spacing(3),
    marginTop: 10,
    maxWidth: 1000,
    maxHeight: 500,
  },
  widgetField: {
    marginTop: 10,
  },
}));
