/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Form } from 'react-final-form';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import './inquiry.css';
import './formstyle.css';
import Dropzone from 'react-dropzone';
import DateFnsUtils from '@date-io/date-fns';
import config from '../../config';
import { useGlobalState } from '../../context/GlobalContext';
import useStyles from './FormStyle';
import {
  Component, Country, State, timeFormat,
} from '../../common/const';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import {
  Organizationbylocation,
} from '../../Api Mutation/Contact';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { UploadImage, WorkflowAutomationsByLocation } from '../../Api Mutation/InquiryForm';
import { emailValidate, phoneCountryDialCodeValidate } from '../../common/FormValidation';
import { ContactByEmail } from '../../Api Mutation/TicketApi';
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, createFilterOptions, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, Radio, RadioGroup, Rating, Select, styled, TextField, Tooltip, Typography } from '@mui/material';
import { Attachment, Close, CloudUpload, ConfirmationNumber, Description, Save } from '@mui/icons-material';
import RichTextEditor from 'react-rte';
import TicketAttchments from './TicketAttchments';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';

const httpLink = createUploadLink({
  uri: config.contactApi,
  headers: {
    authorization: config.staticToken || null,
    'Apollo-Require-Preflight': 'true',
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: config.staticToken || null,
      'Apollo-Require-Preflight': 'true',
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
});

const TicketFormGenerator = ({
  content, onFormSubmit, btnName, colorCode,
  locationId,
  backgroundColorCode,
  foreColor,
  fontColorCode,
  btnPos,
  radioPos,
  checkBoxPos,
  btnThemeColor,
  btnTextColor,
  formWidth,
  termDesc,
  enableTerm,
  visible,
  setVisible,
  suggestContact,
  defaultCountryPhoneCode,
  contact,
  setContact,
  formData,
  isAppointment,
  isAppointmentPublic,
  eventType,
  isFromContact,
  isSaveContact,
  isFromDeal,
  isFromTicket,
  handleClose,
  typeList,
  topicList,
  priorityList,
  editorValue,
  setSubject,
  setDescription,
  setEditorValue,
  setValue,
}) => {
  const globalContext = useGlobalState();
  const filter = createFilterOptions();
  const classes = useStyles({
    color: colorCode,
    background: backgroundColorCode,
    textColor: foreColor,
    btnPosition: btnPos,
    btnColor: btnThemeColor,
    btnText: btnTextColor,
    formwidth: formWidth,
    customTextField: {
      '& input::placeholder': {
        fontSize: '20px',
      },
    },
  });

  const StyledButton = styled(Button)(() => ({
    backgroundColor: isFromTicket ? '#005eb2' : btnThemeColor,
    color: isFromTicket ? 'white' : btnTextColor,
    textTransform: 'capitalize',
    fontSize: '12px',
    fontFamily: 'Open sans',
    fontWeight: isFromTicket ? 600 : 500,
  }));

  const StyledBox = styled(Box)(() => ({
    textAlign: btnPos,
    paddingTop: '10px',
    paddingLeft: '20px',
  }));

  const StyledBtnBox = styled(Box)(() => ({
    textAlign: isFromTicket ? 'end' : btnPos,
    paddingTop: '10px',
    paddingLeft: '20px',
  }));

  const CheckBoxTypo = styled(Typography)(() => ({
    fontFamily: 'Open sans',
    fontWeight: 600,
    fontSize: 14,
  }));

  const LabelTypo = styled(Typography)(() => ({
    fontFamily: 'Open sans',
  }));

  const LabelBox = styled(Typography)(() => ({
    paddingBottom: 5,
    borderBottom: '1px solid grey',
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 500,
    fontfamily: 'Open Sans',
  }));

  const ScheduleButton = styled(Button)(() => ({
    backgroundColor: btnThemeColor,
    color: btnTextColor,
    textTransform: 'capitalize',
    fontSize: '12px',
    fontFamily: 'Open sans',
    fontWeight: 500,
    width: '100%',
    borderRadius: 15,
  }));

  const GuestButton = styled(Button)(() => ({
    textTransform: 'capitalize',
    fontSize: '12px',
    fontFamily: 'Open sans',
    fontWeight: 500,
    width: '100%',
    borderRadius: 15,
    marginTop: 10,
    marginBottom: 15,
  }));

  const dobObj = content?.find((con) => con?.name?.toLowerCase()?.includes('dob'));
  const [dob, setDOB] = useState(
    dobObj?.objecttype ? {
      [`${dobObj?.objecttype}_${dobObj?.name}`]: moment(new Date()).format('YYYY-MM-DD'),
    } : '',
  );
  const startDateObj = content?.find((con) => con?.name?.toLowerCase()?.includes('business start'));
  const [businessStartDate, setBusinessStartDate] = useState(
    startDateObj?.objecttype ? {
      [`${startDateObj?.objecttype}_${startDateObj?.name}`]: moment(new Date()).format('YYYY-MM-DD'),
    } : '',
  );
  const birthDateObj = content?.find((con) => con?.name?.toLowerCase()?.includes('birth'));
  const [dateOfBirth, setDateOfBirth] = useState(
    birthDateObj?.objecttype ? {
      [`${birthDateObj?.objecttype}_${birthDateObj?.name}`]: moment(new Date()).format('YYYY-MM-DD'),
    } : '',
  );
  const [activeTabId, setActiveTabId] = useState(1);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [steps, setSteps] = useState([{
    label: 1,
  }]);
  const [countryArr, setCountryArr] = useState([]);
  const rtEditor = useRef(null);
  const countryFilterArr = eventType === 'multi-page'
    ? content?.[0]?.[`Page${activeTabId}`]?.filter((con) => con?.name?.toLowerCase()?.includes('country'))
    : content?.filter((con) => con?.name?.toLowerCase()?.includes('country'));
  const conCountryObj = countryFilterArr?.find((con) => con?.objecttype?.includes('contact'));
  const [contactCountryid, setContactCountryId] = useState({ [`${conCountryObj?.objecttype ? conCountryObj?.objecttype : ''}${conCountryObj?.name ? `_${conCountryObj?.name}` : ''}`]: Country.USA });
  const orgCountryObj = countryFilterArr?.find((con) => con?.objecttype?.includes('organization'));
  const [orgCountryid, setOrgCountryId] = useState({ [`${orgCountryObj?.objecttype ? orgCountryObj?.objecttype : ''}${orgCountryObj?.name ? `_${orgCountryObj?.name}` : ''}`]: Country.USA });
  const [contactStateArr, setContactStateArr] = useState([]);
  const [orgStateArr, setOrgStateArr] = useState([]);
  const stateFilterArr = eventType === 'multi-page'
    ? content?.[0]?.[`Page${activeTabId}`]?.filter((con) => con?.name?.toLowerCase()?.includes('state'))
    : content?.filter((con) => con?.name?.toLowerCase()?.includes('state'));
  const conStateObj = stateFilterArr?.find((con) => con?.objecttype?.includes('contact'));
  const [contactStateid, setContactStateId] = useState({ [`${conStateObj?.objecttype ? conStateObj?.objecttype : ''}${conStateObj?.name ? `_${conStateObj?.name}` : ''}`]: State.Alabama });
  const orgStateObj = stateFilterArr?.find((con) => con?.objecttype?.includes('organization'));
  const [orgStateid, setOrgStateId] = useState({ [`${orgStateObj?.objecttype ? orgStateObj?.objecttype : ''}${orgStateObj?.name ? `_${orgStateObj?.name}` : ''}`]: State.Alabama });
  const [dropdownSel, setDropDownSel] = useState('');
  const [multiConArr, setMultiConArr] = useState([]);
  const [multiOrgArr, setMultiOrgArr] = useState([]);
  const [updatedVal, setUpdatedVal] = useState([]);
  const [fileLoader, setFileLoader] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [images, setImages] = useState([]);
  const [businessLogo, setBusinessLogo] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [day, setDays] = React.useState({
    SUN: false,
    MON: true,
    TUE: true,
    WED: true,
    THU: true,
    FRI: true,
    SAT: false,
  });
  const [sunTimeStart, setSunTimeStart] = useState(moment().format('YYYY-MM-DD 09:00:00 Z'));
  const [monTimeStart, setMonTimeStart] = useState(moment().format('YYYY-MM-DD 09:00:00 Z'));
  const [tueTimeStart, setTueTimeStart] = useState(moment().format('YYYY-MM-DD 09:00:00 Z'));
  const [wenTimeStart, setWenTimeStart] = useState(moment().format('YYYY-MM-DD 09:00:00 Z'));
  const [thuTimeStart, setThuTimeStart] = useState(moment().format('YYYY-MM-DD 09:00:00 Z'));
  const [friTimeStart, setFriTimeStart] = useState(moment().format('YYYY-MM-DD 09:00:00 Z'));
  const [satTimeStart, setSatTimeStart] = useState(moment().format('YYYY-MM-DD 09:00:00 Z'));
  const [sunTimeEnd, setSunTimeEnd] = useState(moment().format('YYYY-MM-DD 17:00:00 Z'));
  const [monTimeEnd, setMonTimeEnd] = useState(moment().format('YYYY-MM-DD 17:00:00 Z'));
  const [tueTimeEnd, setTueTimeEnd] = useState(moment().format('YYYY-MM-DD 17:00:00 Z'));
  const [wenTimeEnd, setWenTimeEnd] = useState(moment().format('YYYY-MM-DD 17:00:00 Z'));
  const [thuTimeEnd, setThuTimeEnd] = useState(moment().format('YYYY-MM-DD 17:00:00 Z'));
  const [friTimeEnd, setFriTimeEnd] = useState(moment().format('YYYY-MM-DD 17:00:00 Z'));
  const [satTimeEnd, setSatTimeEnd] = useState(moment().format('YYYY-MM-DD 17:00:00 Z'));
  const {
    SUN, MON, TUE, WED, THU, FRI, SAT,
  } = day;
  const [pageNumber, setPageNumber] = useState(1);
  const [getOrganizationData, setGetOrganizationData] = useState([]);
  const [workflowData, setWorkFlowData] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [enteredEmail, setEnteredEmail] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setSubject('');
    setDescription('');
  }, []);

  const getWorkFlowData = async (props) => {
    if (globalContext.globalState.currentLocation.entityid) {
      postDataRequestPublic(config.baseURLApi, {
        query: WorkflowAutomationsByLocation,
        variables: {
          locationid: globalContext.globalState.currentLocation.entityid,
          pageNumber: props?.pageNo || pageNumber,
          pageSize: 25,
        },
      }).then((res) => {
        const result = res.data.workflowAutomationsByLocation?.rows;
        if (result?.length > 0) {
          setIsDataAvailable(true);
          const data = [...workflowData, ...result];
          const uniqueArray = [
            ...new Set(
              data.map((item) => JSON.stringify(item)),
            ),
          ].map((item) => JSON.parse(item));
          setWorkFlowData(uniqueArray);
        } else {
          setIsDataAvailable(false);
        }
      });
    }
  };

  const loadNextWorkFlow = async (event) => {
    const listBoxNode = event.currentTarget;
    const numberOfPages = pageNumber + 1;
    const position = listBoxNode.scrollTop + listBoxNode.clientHeight;
    if (listBoxNode.scrollHeight - position <= 1 && isDataAvailable) {
      setPageNumber(numberOfPages);
      await getWorkFlowData({ pageNo: numberOfPages });
    }
  };

  const getOrganizationByLocation = (props) => {
    if (globalContext.globalState.currentLocation.entityid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Organizationbylocation,
        variables: {
          locationid: globalContext.globalState.currentLocation.entityid,
          pagenumber: props?.searchtext ? 1 : props?.pagenumber || pageNumber,
          pagesize: 20,
          searchtext: props?.searchtext,
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.organizationbylocation?.rows?.length > 0) {
          const data = getOrganizationData.concat(
            res?.data?.organizationbylocation?.rows,
          );
          const uniqueArray = [...new Set(
            data.map((item) => JSON.stringify(item)),
          )].map((item) => JSON.parse(item));
          setGetOrganizationData(uniqueArray);
        }
      });
    }
  };

  const loadNextpage = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      setPageNumber(pageNumber + 1);
      getOrganizationByLocation({ pagenumber: pageNumber + 1 });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getOrganizationByLocation();
    }
    return () => {
      isMounted = false;
    };
  }, [globalContext.globalState.currentLocation.entityid]);

  const handleChangeTime = (event) => {
    setDays({ ...day, [event.target.name]: event.target.checked });
  };

  const handlePrev = () => {
    if (activeTabId > 0) {
      setActiveTabId(activeTabId - 1);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (!formData && typeList?.length > 0 && isMounted) {
      const submittedData = [
        {
          name: 'ticket_Type',
          custom_name: 'ticket_Type',
          value: Number(typeList[0]?.internal),
          dbfieldName: 'type',
        },
      ];
      const val = [...updatedVal];
      submittedData.map((form) => {
        const data = {};
        data[form.name] = form?.value;
        val.map((v) => {
          if (Object.keys(v)[0] === form.name) {
            const index = val.indexOf(v);
            val.splice(index, 1);
          }
        });
        val.push(data);
        setUpdatedVal(val);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [typeList]);
  useEffect(() => {
    let isMounted = true;
    if (!formData && priorityList?.length > 0 && isMounted) {
      const submittedData = [
        {
          name: 'ticket_Priority',
          custom_name: 'ticket_Priority',
          value: Number(priorityList[0]?.internal),
          dbfieldName: 'type',
        },
      ];
      const val = [...updatedVal];
      submittedData.map((form) => {
        const data = {};
        data[form.name] = form?.value;
        val.map((v) => {
          if (Object.keys(v)[0] === form.name) {
            const index = val.indexOf(v);
            val.splice(index, 1);
          }
        });
        val.push(data);
        setUpdatedVal(val);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [priorityList]);
  useEffect(() => {
    let isMounted = true;
    if (!formData && topicList?.length > 0 && isMounted) {
      const submittedData = [
        {
          name: 'ticket_Topic',
          custom_name: 'ticket_Topic',
          value: Number(topicList[0]?.internal),
          dbfieldName: 'type',
        },
      ];
      const val = [...updatedVal];
      submittedData.map((form) => {
        const data = {};
        data[form.name] = form?.value;
        val.map((v) => {
          if (Object.keys(v)[0] === form.name) {
            const index = val.indexOf(v);
            val.splice(index, 1);
          }
        });
        val.push(data);
        setUpdatedVal(val);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [topicList]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && eventType === 'multi-page') {
      if (content && content[0]) {
        const arr = Object.keys(content[0]);
        if (arr.length > 0) {
          const newStep = [...steps];
          // eslint-disable-next-line no-plusplus
          for (let i = 2; i <= arr.length; i++) {
            const step = {};
            step.label = i;
            newStep.push(step);
            setSteps(newStep);
          }
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [eventType]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: '{countries{countryid,name}}',
      })
        .then((res) => {
          setCountryArr(res.data.countries);
        });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: 'query statebycountry($countryId:Int!){statesbycountry(countryid:$countryId){stateid,name,code}}',
        variables: {
          countryId: parseInt(Object.keys(contactCountryid)?.[0] !== ''
            && contactCountryid[Object.keys(contactCountryid)]
            ? contactCountryid[Object.keys(contactCountryid)]
            : Country.USA, 10),
        },
      })
        .then((res) => {
          setContactStateArr(res?.data?.statesbycountry);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [contactCountryid]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: 'query statebycountry($countryId:Int!){statesbycountry(countryid:$countryId){stateid,name,code}}',
        variables: {
          countryId: parseInt(Object.keys(orgCountryid)?.[0] !== ''
            && orgCountryid[Object.keys(orgCountryid)]
            ? orgCountryid[Object.keys(orgCountryid)] : Country.USA, 10),
        },
      })
        .then((res) => {
          setOrgStateArr(res?.data?.statesbycountry);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [orgCountryid]);

  const getElement = (elem) => {
    switch (Component[elem.fieldtype]) {
      case 'Textbox':
        return (
          <FormControl fullWidth size="small" variant="outlined" style={{ display: (elem?.dbfieldname === 'firstname' || elem?.dbfieldname === 'lastname' || elem?.dbfieldname === 'phonenumber') ? (showFields ? 'block' : 'none') : 'block', }}>
            <Box className={classes.elementBox}>
              {isFromContact && elem?.dbfieldname === 'name' ? (
                <Autocomplete
                  fullWidth
                  onChange={(event, newValue) => {
                    const obj = {};
                    const val = [...updatedVal];
                    if (typeof newValue === 'string') {
                      obj[`${elem?.objecttype}_${elem?.name}`] = newValue;
                    } else if (newValue && newValue?.inputValue) {
                      obj[`${elem?.objecttype}_${elem?.name}`] = newValue?.inputValue;
                    } else {
                      obj[`${elem?.objecttype}_${elem?.name}`] = newValue?.name;
                    }
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(obj);
                    setUpdatedVal(val);
                  }}
                  ListboxProps={{
                    onScroll: loadNextpage,
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={getOrganizationData}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option?.inputValue) {
                      return option?.inputValue;
                    }
                    return option?.name || '';
                  }}
                  renderOption={(option) => option?.name}
                  size="small"
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Organization Name"
                      variant="outlined"
                      className={classes.autocomplete}
                      placeholder="Name"
                      onChange={(e) => {
                        getOrganizationByLocation({ searchtext: e?.target?.value });
                      }}
                    />
                  )}
                />
              )
                : isFromContact && elem?.dbfieldname === 'workflowid' ? (
                  <Autocomplete
                    fullWidth
                    id="value"
                    options={workflowData || []}
                    getOptionLabel={(option) => option?.name}
                    ListboxProps={{
                      onScroll: (event) => loadNextWorkFlow(event),
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const obj = {};
                        const val = [...updatedVal];
                        obj[`${elem?.objecttype}_${elem?.name}`] = newValue.workflowid;
                        val.map((v) => {
                          if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                            const index = val.indexOf(v);
                            val.splice(index, 1);
                          }
                        });
                        val.push(obj);
                        setUpdatedVal(val);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={elem.name}
                        size="small"
                        variant="outlined"
                        className={classes.textLabel}
                      />
                    )}
                  />
                )
                  : isFromTicket && elem?.dbfieldname === 'primaryemail' ? (
                    <Box className={classes.emailBox}>
                      <TextField
                        placeholder="Enter email and press button"
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                          style: { 
                            color: fontColorCode, 
                            foreColor: fontColorCode,
                            width: '100%',
                            fontSize: 12,
                            fontFamily: 'Open sans',
                          },
                        }}
                        InputProps={{
                          classes: {
                            input: classes.input,
                          },
                          style: { 
                            color: fontColorCode, 
                            foreColor: fontColorCode,
                            width: '100%',
                            fontSize: 12,
                            fontFamily: 'Open sans',
                          },
                        }}
                        style={{
                          background: colorCode,
                          color: fontColorCode,
                          foreColor: fontColorCode,
                          width: '100%',
                          fontSize: 12,
                          fontFamily: 'Open sans',
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        label={elem?.name}
                        value={isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : ''}
                        onChange={(e) => {
                          const data = {};
                          const val = [...updatedVal];
                          data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          val.map((v) => {
                            if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                              const index = val.indexOf(v);
                              val.splice(index, 1);
                            }
                          });
                          val.push(data);
                          setUpdatedVal(val);
                          setEnteredEmail(e.target.value);
                        }}
                      />
                      <Button
                        size="small"
                        style={{
                          height: 35,
                          fontWeight: 600,
                          fontSize: 13,
                        }}
                        onClick={() => {
                          if (locationId && enteredEmail) {
                            setLoader(true);
                            postDataRequestPublic(config.baseURLApi, {
                              query: ContactByEmail,
                              variables: {
                                locationid: locationId,
                                primaryemail: enteredEmail || '',
                              },
                            }).then((res) => {
                              if (res?.data?.contactbyemail) {
                                localStorage.setItem('ticketFirstName', res?.data?.contactbyemail?.firstname);
                                localStorage.setItem('ticketLastName', res?.data?.contactbyemail?.lastname);
                                localStorage.setItem('ticketPhoneNumber', res?.data?.contactbyemail?.phonenumber);
                                const submittedData = [
                                  {
                                    name: 'ticket_First Name',
                                    custom_name: 'ticket_First Name',
                                    value: handleUndefined(res?.data?.contactbyemail?.firstname) || '',
                                    dbfieldName: 'firstname',
                                  },
                                  {
                                    name: 'ticket_Last Name',
                                    custom_name: 'ticket_Last Name',
                                    value: handleUndefined(res?.data?.contactbyemail?.lastname) || '',
                                    dbfieldName: 'lastname',
                                  },
                                  {
                                    name: 'ticket_Phone Number',
                                    custom_name: 'ticket_Phone Number',
                                    value: handleUndefined(res?.data?.contactbyemail?.phonenumber) || '',
                                    dbfieldName: 'phonenumber',
                                  },
                                ];
                                const val = [...updatedVal];
                                submittedData.map((form) => {
                                  const data = {};
                                  data[form.name] = form.value;
                                  val.map((v) => {
                                    if (Object.keys(v)[0] === form.name) {
                                      const index = val.indexOf(v);
                                      val.splice(index, 1);
                                    }
                                  });
                                  val.push(data);
                                  setUpdatedVal(val);
                                });
                              }
                              setLoader(false);
                              setShowFields(true);
                            });
                          }
                        }}
                        color="primary"
                        variant="contained"
                      >
                        {loader ? <CircularProgress size={15} color="inherit" /> : 'Enter'}
                      </Button>
                    </Box>
                  )
                    : (
                      <TextField
                        InputLabelProps={{
                          classes: {
                            root: classes.label,
                          },
                          style:{
                            color: fontColorCode,
                            foreColor: fontColorCode,
                            width: '100%',
                            fontSize: 12,
                            fontFamily: 'Open sans',
                          },
                        }}
                        InputProps={{
                          classes: {
                            input: classes.input,
                          },
                          style:{
                            color: fontColorCode,
                            foreColor: fontColorCode,
                            width: '100%',
                            fontSize: 12,
                            fontFamily: 'Open sans',
                          },
                        }}
                        style={{
                          background: colorCode,
                          color: fontColorCode,
                          foreColor: fontColorCode,
                          width: '100%',
                          fontSize: 12,
                          fontFamily: 'Open sans',
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        label={elem?.name}
                        value={(isFromTicket && elem?.name === 'First Name') ? ((isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : '') || handleUndefined(localStorage.getItem('ticketFirstName')) || '') : (isFromTicket && elem?.name === 'Last Name') ? ((isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : '') || handleUndefined(localStorage.getItem('ticketLastName')) || '') : (isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : '')}
                        onChange={(e) => {
                          const data = {};
                          const val = [...updatedVal];
                          data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          val.map((v) => {
                            if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                              const index = val.indexOf(v);
                              val.splice(index, 1);
                            }
                          });
                          val.push(data);
                          setUpdatedVal(val);
                        }}
                      />
                    )}
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Multiline':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={(elem?.dbfieldname === 'ticketdescription') ? classes.editorBox : classes.elementBox}>
              {(elem?.dbfieldname === 'ticketdescription')
                ? (
                  <div className={classes.descriptionBox}>
                    <RichTextEditor
                      value={editorValue}
                      placeholder="Write a Email..."
                      multiline
                      id="body-text"
                      type="string"
                      ref={rtEditor}
                      onChange={(values) => {
                        setEditorValue(values);
                        setValue(values.toString('html'));
                        const data = {};
                        const val = [...updatedVal];
                        data[`${elem?.objecttype}_${elem?.name}`] = values.toString('html');
                        val.map((v) => {
                          if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                            const index = val.indexOf(v);
                            val.splice(index, 1);
                          }
                        });
                        val.push(data);
                        setUpdatedVal(val);
                      }}
                      style={{ border: 'none' }}
                      editorStyle={{ minHeight: '160px', fontSize: '14px', border: 'none' }}
                    />
                    <div className={classes.alignContant}>
                      <Tooltip title="Add Attachment">
                        <IconButton
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setDropOpen(true);
                            localStorage.removeItem('attachedFiles');
                          }}
                        >
                          <Attachment className={classes.themeIcon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {attachedFiles?.length > 0 ? (
                      <TicketAttchments
                        attchedFiles={attachedFiles}
                        setAttachedFiles={setAttachedFiles}
                        updateValues={updatedVal}
                        setUpdatedValues={setUpdatedVal}
                      />
                    ) : ''}
                    <Dialog open={dropOpen} onClose={() => setDropOpen(false)} aria-labelledby="form-dialog-title">
                      <DialogTitle id="form-dialog-title">Add Attachment</DialogTitle>
                      <DialogContent>
                        <Box className={classes.Dropzone}>
                          <Dropzone
                            disabled={fileLoader}
                            onDrop={async (acceptedFiles) => {
                              if (acceptedFiles.length > 0) {
                                const uploadedFileUrls = [];

                                for (const file of acceptedFiles) {
                                  try {
                                    setFileLoader(true);
                                    const res = await client.mutate({
                                      mutation: UploadImage,
                                      variables: { images: file },
                                    });
                                    const fileUrl = res?.data?.uploadimg?.images;
                                    if (fileUrl) {
                                      uploadedFileUrls.push(fileUrl);
                                      setFileLoader(false);
                                    }
                                  } catch (error) {
                                    setFileLoader(false);
                                    showNotification('Error uploading file', 'error');
                                  }
                                }

                                if (uploadedFileUrls.length > 0) {
                                  const data = {};
                                  const val = [...updatedVal];
                                  const existingEntry = val.find((v) => Object.keys(v)[0] === `${elem?.objecttype}_attachment`);
                                  const existingUrls = existingEntry ? JSON.parse(existingEntry[`${elem?.objecttype}_attachment`]) : [];
                                  const combinedUrls = [...existingUrls, ...uploadedFileUrls];
                                  setAttachedFiles(combinedUrls);
                                  data[`${elem?.objecttype}_attachment`] = JSON.stringify(combinedUrls);
                                  const updatedValWithoutOldEntry = val.filter(v => Object.keys(v)[0] !== `${elem?.objecttype}_attachment`);
                                  updatedValWithoutOldEntry.push(data);

                                  setUpdatedVal(updatedValWithoutOldEntry);
                                  showNotification('Files uploaded successfully', 'success');
                                  setDropOpen(false);
                                }
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} disabled={loader} />
                                <CloudUpload className={classes.placeholderIcon} />
                                <br />
                                {fileLoader ? (<p style={{ marginTop: 10 }}>
                                  Uploading...
                                  {elem?.isrequired ? <label className={classes.error}>*</label> : ''}
                                </p>) :
                                  (<p style={{ marginTop: 10 }}>
                                    Upload Picture
                                    {elem?.isrequired ? <label className={classes.error}>*</label> : ''}
                                  </p>)}
                              </div>
                            )}
                          </Dropzone>
                          <FormHelperText>
                            <Typography variant="body2">
                              <center>
                                {' '}
                                *File size should not
                                greater than 5MB!
                              </center>
                            </Typography>
                          </FormHelperText>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button variant="contained" size="small" startIcon={<Close />} onClick={() => setDropOpen(false)} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )
                : (
                  <TextField
                    className={classes.field}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                      style: { fontSize: 12, color: fontColorCode, foreColor: fontColorCode },
                    }}
                    style={{
                      background: colorCode,
                      color: fontColorCode,
                      foreColor: fontColorCode,
                    }}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    label={elem?.name}
                    multiline
                    minRows={5}
                    value={isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : ''}
                    onChange={(e) => {
                      const data = {};
                      const val = [...updatedVal];
                      data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                      val.map((v) => {
                        if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                          const index = val.indexOf(v);
                          val.splice(index, 1);
                        }
                      });
                      val.push(data);
                      setUpdatedVal(val);
                    }}
                  />
                )}
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Single checkbox':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox required={elem?.isrequired} checked={isSubmitted === false && updatedVal?.length > 0 && updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]} />}
                  label={(
                    <Box className={classes.elementBox}>
                      <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
                      {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
                    </Box>
                  )}
                  onChange={(e) => {
                    const data = {};
                    const val = [...updatedVal];
                    data[`${elem?.objecttype}_${elem?.name}`] = e.target.checked;
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(data);
                    setUpdatedVal(val);
                  }}
                />
              </FormGroup>
            </Box>
          </FormControl>
        );
      case 'Multiple checkbox':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
              {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
            </Box>
            <Box className={classes.elementBox}>
              <FormGroup
                style={{ flexDirection: checkBoxPos === 'horizontal' ? 'row' : 'column' }}
              >
                {elem?.options?.length > 0 && elem?.options?.map((el) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={isSubmitted === false && updatedVal?.length > 0
                          && updatedVal?.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]?.length > 0
                          && updatedVal?.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]?.find((res) => res === el.label)}
                      />
                    )}
                    sx={{ fontFamily: 'Open sans', fontSize: '14px' }}
                    label={<CheckBoxTypo>{el.label}</CheckBoxTypo>}
                    value={el.label}
                    onChange={(e) => {
                      const data = {};
                      const val = [...updatedVal];
                      if (elem?.objecttype === 'contact') {
                        const arr = [...multiConArr];
                        if (e.target.checked) {
                          arr.push(e.target.value);
                        } else {
                          const index = arr.findIndex((mui) => mui === e.target.value);
                          if (index > -1) {
                            arr.splice(index, 1);
                          }
                        }
                        setMultiConArr(arr);
                        data[`${elem?.objecttype}_${elem?.name}`] = arr;
                      } else {
                        const arr = [...multiOrgArr];
                        if (e.target.checked) {
                          arr.push(e.target.value);
                        } else {
                          const index = arr.findIndex((mui) => mui === e.target.value);
                          if (index > -1) {
                            arr.splice(index, 1);
                          }
                        }
                        setMultiOrgArr(arr);
                        data[`${elem?.objecttype}_${elem?.name}`] = arr;
                      }

                      val.map((v) => {
                        if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                          const index = val.indexOf(v);
                          val.splice(index, 1);
                        }
                      });
                      val.push(data);
                      setUpdatedVal(val);
                      setIsRequired(false);
                    }}
                  />
                ))}
                {elem?.isrequired && isRequired && multiConArr.length === 0 && elem?.objecttype === 'contact' && (<Typography className={classes.required} variant="body1">Please select any one</Typography>)}
                {elem?.isrequired && isRequired && multiOrgArr.length === 0 && elem?.objecttype === 'organization' && (<Typography className={classes.required} variant="body1">Please select any one</Typography>)}
              </FormGroup>
            </Box>
          </FormControl>
        );
      case 'Dropdown':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              {(((elem?.name?.toLowerCase()?.includes('country') && countryArr?.length > 0)
                || (elem?.name?.toLowerCase()?.includes('state')
                  && elem?.objecttype?.includes('contact') && contactStateArr?.length > 0)
                || (elem?.name?.toLowerCase()?.includes('state') && elem?.objecttype?.includes('organization')
                  && orgStateArr?.length > 0) || (elem?.options?.length > 0)) && !isFromTicket) ? (
                <>
                  <InputLabel
                    style={{ background: colorCode }}
                    id={`${elem?.objecttype}_${elem?.name}`}
                    sx={{ fontSize: '12px', fontFamily: 'open sans', fontWeight: 500 }}
                  >
                    {elem?.name}
                  </InputLabel>
                  <Select
                    labelId={`${elem?.objecttype}_${elem?.name}`}
                    id="demo-simple-select"
                    label={elem?.name}
                    sx={{
                      fontSize: 12,
                      fontFamily: 'Open sans',
                    }}
                    fullWidth
                    required={elem?.isrequired}
                    value={
                      updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]
                        ? updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]
                        : elem?.name?.toLowerCase()?.includes('country')
                          ? Country.USA
                          : elem?.name?.toLowerCase()?.includes('state')
                            ? State.Alabama
                            : dropdownSel[`${elem?.objecttype}_${elem?.name}`]
                    }
                    onChange={(e) => {
                      const obj = {};
                      const val = [...updatedVal];
                      if (elem?.name?.toLowerCase()?.includes('country')) {
                        if (elem?.objecttype?.includes('contact')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setContactCountryId(obj);
                        }
                        if (elem?.objecttype?.includes('organization')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setOrgCountryId(obj);
                        }
                      } else if (elem?.name?.toLowerCase()?.includes('state')) {
                        if (elem?.objecttype?.includes('contact')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setContactStateId(obj);
                        }
                        if (elem?.objecttype?.includes('organization')) {
                          obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                          setOrgStateId(obj);
                        }
                      }
                      obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                      setDropDownSel(obj);
                      val.map((v) => {
                        if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                          const index = val.indexOf(v);
                          val.splice(index, 1);
                        }
                      });
                      val.push(obj);
                      setUpdatedVal(val);
                    }}
                  >
                    {elem?.name?.toLowerCase()?.includes('country') && (
                      countryArr.length > 0 && countryArr.map((country) => (
                        <MenuItem value={country.countryid} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{country.name}</MenuItem>
                      ))
                    )}
                    {elem?.name?.toLowerCase()?.includes('state') && elem?.objecttype?.includes('contact') && (
                      contactStateArr?.length > 0 && contactStateArr?.map((state) => (
                        <MenuItem value={state.stateid} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{state.name}</MenuItem>
                      ))
                    )}
                    {elem?.name?.toLowerCase()?.includes('state') && elem?.objecttype?.includes('organization') && (
                      orgStateArr?.length > 0 && orgStateArr?.map((state) => (
                        <MenuItem value={state.stateid} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{state.name}</MenuItem>
                      ))
                    )}
                    {elem?.options?.length > 0 && elem?.options?.map((el) => (
                      <MenuItem value={el.label}>{el.label}</MenuItem>
                    ))}
                  </Select>
                </>
              ) : isFromTicket && ['type', 'priority', 'topic']?.includes(elem?.dbfieldname) ? (
                <>
                  <InputLabel
                    style={{ background: colorCode }}
                    id={`${elem?.objecttype}_${elem?.name}`}
                    sx={{ fontSize: '12px', fontFamily: 'open sans', fontWeight: 500 }}
                  >
                    {elem?.name}
                  </InputLabel>
                  <Select
                    labelId={`${elem?.objecttype}_${elem?.name}`}
                    id="demo-simple-select"
                    label={elem?.name}
                    sx={{
                      fontSize: 12,
                      fontFamily: 'Open sans',
                    }}
                    fullWidth
                    required={elem?.isrequired}
                    value={updatedVal?.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]
                      ? updatedVal?.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]
                      : ''}
                    onChange={(e) => {
                      const obj = {};
                      const val = [...updatedVal];
                      obj[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                      val.map((v) => {
                        if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                          const index = val.indexOf(v);
                          val.splice(index, 1);
                        }
                      });
                      val.push(obj);
                      setUpdatedVal(val);
                    }}
                  >
                    {elem?.dbfieldname?.toLowerCase()?.includes('type') && (
                      typeList?.length > 0 && typeList.map((country) => (
                        <MenuItem value={country?.internal} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{country?.label}</MenuItem>
                      ))
                    )}
                    {elem?.dbfieldname?.toLowerCase()?.includes('priority') && (
                      priorityList?.length > 0 && priorityList.map((country) => (
                        <MenuItem value={country?.internal} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{country?.label}</MenuItem>
                      ))
                    )}
                    {elem?.dbfieldname?.toLowerCase()?.includes('topic') && (
                      topicList?.length > 0 && topicList.map((country) => (
                        <MenuItem value={country?.internal} sx={{ fontFamily: 'Open sans', fontSize: 14 }}>{country?.label}</MenuItem>
                      ))
                    )}
                  </Select>
                </>
              ) : (
                <TextField
                  fullWidth
                  className={classes.statefield}
                  variant="outlined"
                  size="small"
                  type="text"
                  label={elem?.name}
                  value={isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}_text`)?.[`${elem?.objecttype}_${elem?.name}_text`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}_text`)?.[`${elem?.objecttype}_${elem?.name}_text`]) : '' : ''}
                  onChange={(e) => {
                    const obj = {};
                    const val = [...updatedVal];
                    if (elem?.name?.toLowerCase()?.includes('country')) {
                      if (elem?.objecttype?.includes('contact')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setContactCountryId(obj);
                      }
                      if (elem?.objecttype?.includes('organization')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setOrgCountryId(obj);
                      }
                    } else if (elem?.name?.toLowerCase()?.includes('state')) {
                      if (elem?.objecttype?.includes('contact')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setContactStateId(obj);
                      }
                      if (elem?.objecttype?.includes('organization')) {
                        obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                        setOrgStateId(obj);
                      }
                    }
                    obj[`${elem?.objecttype}_${elem?.name}_text`] = e.target.value;
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}_text`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(obj);
                    setUpdatedVal(val);
                  }}
                />
              )}
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Radio':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <FormControl>
                <Box className={classes.elementBox}>
                  <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
                  {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
                </Box>
                <RadioGroup
                  style={{
                    flexDirection: radioPos === 'horizontal' ? 'row' : 'column',
                  }}
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name={`${elem?.objecttype}_${elem?.name}`}
                  onChange={(e) => {
                    const data = {};
                    const val = [...updatedVal];
                    data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(data);
                    setUpdatedVal(val);
                  }}
                >
                  {elem?.options?.length > 0 && elem?.options?.map((el) => (
                    <FormControlLabel
                      value={el.label}
                      control={<Radio required={elem?.isrequired} />}
                      label={<CheckBoxTypo>{el.label}</CheckBoxTypo>}
                      checked={isSubmitted === false && updatedVal?.length > 0 && updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`] === el.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </FormControl>
        );
      case 'Date picker':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
              >
                <DateTimePicker
                  InputLabelProps={{ style: { fontSize: 12, color: fontColorCode } }}
                  style={{ background: colorCode, color: colorCode }}
                  className={classes.dateTimePicker}
                  value={
                    new Date(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`])
                      ? new Date(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`])
                      : elem?.name?.toLowerCase()?.includes('dob')
                        ? (dob[`${elem?.objecttype}_${elem?.name}`] ? new Date(dob[`${elem?.objecttype}_${elem?.name}`]) : null)
                        : elem?.name?.toLowerCase()?.includes('birth')
                          ? (dateOfBirth[`${elem?.objecttype}_${elem?.name}`] ? new Date(dateOfBirth[`${elem?.objecttype}_${elem?.name}`]) : null)
                          : elem?.name.toLowerCase()?.includes('business start')
                            ? (businessStartDate[`${elem?.objecttype}_${elem?.name}`] ? new Date(businessStartDate[`${elem?.objecttype}_${elem?.name}`]) : null)
                            : null
                  }
                  label={elem?.name}
                  format="yyyy/MM/dd"
                  ampm={false}
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const obj = {};
                    const val = [...updatedVal];
                    if (elem?.name?.toLowerCase()?.includes('dob')) {
                      obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                      setDOB(obj);
                    } else if (elem?.name?.toLowerCase()?.includes('birth')) {
                      obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                      setDateOfBirth(obj);
                    } else if (elem?.name?.toLowerCase()?.includes('business start')) {
                      obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                      setBusinessStartDate(obj);
                    }
                    obj[`${elem?.objecttype}_${elem?.name}`] = moment(e).format('YYYY-MM-DD');
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(obj);
                    setUpdatedVal(val);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Number':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                  style:{
                    color: fontColorCode,
                    foreColor: fontColorCode,
                    width: '100%',
                    fontSize: 12,
                    fontFamily: 'Open sans',
                  }
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  style:{
                    color: fontColorCode,
                    foreColor: fontColorCode,
                    width: '100%',
                    fontSize: 12,
                    fontFamily: 'Open sans',
                  }
                }}
                style={{ 
                  background: colorCode, 
                  color: fontColorCode, 
                  foreColor: fontColorCode,
                  width: '100%',
                  fontSize: 12,
                  fontFamily: 'Open sans',
                }}
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                label={elem?.name}
                value={isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : ''}
                onChange={(e) => {
                  const data = {};
                  const val = [...updatedVal];
                  data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Phone number':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                  style:{
                    color: fontColorCode,
                    foreColor: fontColorCode,
                    width: '100%',
                    fontSize: 12,
                    fontFamily: 'Open sans',
                  },
                  shrink: handleUndefined(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) || handleUndefined(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]),
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                  style:{
                    color: fontColorCode,
                    foreColor: fontColorCode,
                    width: '100%',
                    fontSize: 12,
                    fontFamily: 'Open sans',
                  },
                }}
                style={{ 
                  background: colorCode, 
                  color: fontColorCode, 
                  foreColor: fontColorCode,
                  width: '100%',
                  fontSize: 12,
                  fontFamily: 'Open sans',
                }}
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                label={elem?.name}
                value={isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : ''}
                onChange={(e) => {
                  const data = {};
                  const val = [...updatedVal];
                  data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            {elem?.isrequired && (<Typography className={classes.requiredField} variant="body1">*</Typography>)}
          </FormControl>
        );
      case 'Rating':
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
              {elem?.isrequired && (<Typography className={classes.required} variant="body1">*</Typography>)}
            </Box>
            <Rating
              name={`${elem?.objecttype}_${elem?.name}`}
              value={isSubmitted === false ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) ? (updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]) : '' : ''}
              precision={0.1}
              onChange={(e) => {
                const data = {};
                const val = [...updatedVal];
                data[`${elem?.objecttype}_${elem?.name}`] = e.target.value;
                val.map((v) => {
                  if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                    const index = val.indexOf(v);
                    val.splice(index, 1);
                  }
                });
                val.push(data);
                setUpdatedVal(val);
                setIsRequired(false);
              }}
            />
            {elem?.isrequired && isRequired && elem?.objecttype === 'contact' && (<Typography className={classes.required} variant="body1">This field is required</Typography>)}
            {elem?.isrequired && isRequired && elem?.objecttype === 'organization' && (<Typography className={classes.required} variant="body1">This field is required</Typography>)}
          </FormControl>
        );
      case 'Paragraph':
        return (
          <Box className={classes.elementBox}>
            <CheckBoxTypo>{elem?.name}</CheckBoxTypo>
          </Box>
        );
      case 'Divider':
        return (
          <Divider />
        );
      case 'Label':
        return (
          <Box>
            <LabelTypo dangerouslySetInnerHTML={{ __html: elem?.value }} />
          </Box>
        );
      case 'Image':
        if (elem.propertyname === 'Business Logo') {
          setBusinessLogo(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]);
        }
        if (elem.propertyname === 'Images') {
          setImages(updatedVal.find((val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`)?.[`${elem?.objecttype}_${elem?.name}`]);
        }
        return (
          <FormControl fullWidth size="small" variant="outlined">
            {elem.objecttype === 'organization'
              ? (
                <>
                  <Box>
                    <LabelBox>{`Upload ${elem?.name}`}</LabelBox>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box className="mb-4">
                        <Box className={classes.Dropzone}>
                          <Dropzone
                            accept="image/*"
                            onDrop={async (acceptedFiles) => {
                              if (elem.propertyname === 'Business Logo') {
                                setBusinessLogo(acceptedFiles);
                              } else {
                                setImages(acceptedFiles);
                              }
                              const res = await client.mutate({
                                mutation: UploadImage,
                                variables: {
                                  images: acceptedFiles,
                                },
                              });
                              if (res?.data?.uploadimg?.images) {
                                const data = {};
                                const val = [...updatedVal];
                                data[`${elem?.objecttype}_${elem?.name}`] = res?.data?.uploadimg?.images;
                                val.map((v) => {
                                  if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                                    const index = val.indexOf(v);
                                    val.splice(index, 1);
                                  }
                                });
                                val.push(data);
                                setUpdatedVal(val);
                                showNotification('Image uploaded successfully', 'success');
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <CloudUpload style={{ fontSize: 50 }} />
                                <br />
                                <p style={{ fontSize: 14 }}>
                                  Upload Picture
                                  {elem?.isrequired ? <label className={classes.error}>*</label> : ''}
                                </p>
                              </div>
                            )}
                          </Dropzone>
                          <center>
                            <Typography variant="body2" style={{ fontSize: 14, fontFamily: 'Open sans' }}>
                              {' '}
                              File size should not
                              greater than 3MB!
                            </Typography>
                          </center>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {elem.propertyname === 'Business Logo' && (typeof businessLogo !== 'string'
                        ? businessLogo?.length > 0 && [...businessLogo]?.map((f) => (
                          <center>
                            <img src={URL.createObjectURL(f)} alt="preview" className={classes.images} />
                          </center>
                        ))
                        : (
                          <center>
                            <img src={businessLogo || ''} alt="preview" className={classes.images} />
                          </center>
                        ))}
                      {elem.propertyname === 'Images' && (typeof images !== 'string'
                        ? images?.length > 0 && [...images]?.map((f) => (
                          <center>
                            <img src={URL.createObjectURL(f)} alt="preview" className={classes.images} />
                          </center>
                        ))
                        : (
                          <center>
                            <img src={images || ''} alt="preview" className={classes.images} />
                          </center>
                        ))}
                    </Grid>
                  </Grid>
                </>
              )
              : (
                elem.value && (
                  <center>
                    <img src={elem.value} alt="preview" className={classes.previewImage} />
                  </center>
                )
              )}
          </FormControl>
        );
      case 'Section':
        return (
          <>
            <Box className={classes.elementBox}>
              <Typography variant="h6" style={{ fontFamily: 'Open sans' }}>{elem?.value}</Typography>
            </Box>
            <Divider />
          </>
        );
      case 'availablehours': {
        return (
          <Box className={classes.elementBox}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Box>
                  <Typography variant="body1">
                    Working Hours
                  </Typography>
                  <Typography variant="caption">
                    Add Working hours of your business place
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={SUN} onChange={handleChangeTime} name="SUN" />}
                  label="SUN"
                />
              </Grid>
              <Grid item xs={6}>
                {day?.SUN === true ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex">
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="Start Time"
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        minDate={moment().format('YYYY-MM-DD 09:00:00 Z')}
                        minDateMessage={
                          moment(sunTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            >= moment(sunTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'Start time should be earlier than End time' : ''
                        }
                        value={moment(sunTimeStart, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setSunTimeStart(date)}
                      />
                      &nbsp;
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="End Time"
                        minDate={moment().format('YYYY-MM-DD 17:00:00 Z')}
                        minDateMessage={
                          moment(sunTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            <= moment(sunTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'End time should be later than Start time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        value={moment(sunTimeEnd, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setSunTimeEnd(date)}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                )
                  : (
                    <Typography className={classes.timeText} variant="body1">
                      Unavailable
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={MON} onChange={handleChangeTime} name="MON" />}
                  label="MON"
                />
              </Grid>
              <Grid item xs={6}>
                {day?.MON === true ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex">
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="Start Time"
                        minDate={moment().format('YYYY-MM-DD 09:00:00 Z')}
                        minDateMessage={
                          moment(monTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            >= moment(monTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'Start time should be earlier than End time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        value={moment(monTimeStart, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setMonTimeStart(date)}
                      />
                      &nbsp;
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="End Time"
                        minDate={moment().format('YYYY-MM-DD 17:00:00 Z')}
                        minDateMessage={
                          moment(monTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            <= moment(monTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'End time should be later than Start time' : ''
                        }
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        value={moment(monTimeEnd, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setMonTimeEnd(date)}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                )
                  : (
                    <Typography className={classes.timeText} variant="body1">
                      Unavailable
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={TUE} onChange={handleChangeTime} name="TUE" />}
                  label="TUE"
                />
              </Grid>
              <Grid item xs={6}>
                {day?.TUE === true ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex">
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        format="HH:mm:ss"
                        minDate={moment().format('YYYY-MM-DD 09:00:00 Z')}
                        minDateMessage={
                          moment(tueTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            >= moment(tueTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'Start time should be earlier than End time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        label="Start Time"
                        value={moment(tueTimeStart, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setTueTimeStart(date)}
                      />
                      &nbsp;
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="End Time"
                        minDate={moment().format('YYYY-MM-DD 17:00:00 Z')}
                        minDateMessage={
                          moment(tueTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            <= moment(tueTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'End time should be later than Start time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        value={moment(tueTimeEnd, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setTueTimeEnd(date)}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                )
                  : (
                    <Typography className={classes.timeText} variant="body1">
                      Unavailable
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={WED} onChange={handleChangeTime} name="WED" />}
                  label="WED"
                />
              </Grid>
              <Grid item xs={6}>
                {day?.WED === true ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex">
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="Start Time"
                        minDate={moment().format('YYYY-MM-DD 09:00:00 Z')}
                        minDateMessage={
                          moment(wenTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            >= moment(wenTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'Start time should be earlier than End time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        value={moment(wenTimeStart, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setWenTimeStart(date)}
                      />
                      &nbsp;
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="End Time"
                        minDate={moment().format('YYYY-MM-DD 17:00:00 Z')}
                        minDateMessage={
                          moment(wenTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            <= moment(wenTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'End time should be later than Start time' : ''
                        }
                        format="HH:mm:ss"
                        views={['hours', 'minutes', 'seconds']}
                        value={moment(wenTimeEnd, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setWenTimeEnd(date)}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                )
                  : (
                    <Typography className={classes.timeText} variant="body1">
                      Unavailable
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={THU} onChange={handleChangeTime} name="THU" />}
                  label="THU"
                />
              </Grid>
              <Grid item xs={6}>
                {day?.THU === true ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex">
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        format="HH:mm:ss"
                        minDate={moment().format('YYYY-MM-DD 09:00:00 Z')}
                        minDateMessage={
                          moment(thuTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            >= moment(thuTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'Start time should be earlier than End time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        label="Start Time"
                        value={moment(thuTimeStart, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setThuTimeStart(date)}
                      />
                      &nbsp;
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="End Time"
                        minDate={moment().format('YYYY-MM-DD 17:00:00 Z')}
                        minDateMessage={
                          moment(thuTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            <= moment(thuTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'End time should be later than Start time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        value={moment(thuTimeEnd, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setThuTimeEnd(date)}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                )
                  : (
                    <Typography className={classes.timeText} variant="body1">
                      Unavailable
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={FRI} onChange={handleChangeTime} name="FRI" />}
                  label="FRI"
                />
              </Grid>
              <Grid item xs={6}>
                {day?.FRI === true ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex">
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="Start Time"
                        format="HH:mm:ss"
                        minDate={moment().format('YYYY-MM-DD 09:00:00 Z')}
                        minDateMessage={
                          moment(friTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            >= moment(friTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'Start time should be earlier than End time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        value={moment(friTimeStart, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setFriTimeStart(date)}
                      />
                      &nbsp;
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        label="End Time"
                        minDate={moment().format('YYYY-MM-DD 17:00:00 Z')}
                        minDateMessage={
                          moment(friTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            <= moment(friTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'End time should be later than Start time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        format="HH:mm:ss"
                        value={moment(friTimeEnd, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setFriTimeEnd(date)}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                )
                  : (
                    <Typography className={classes.timeText} variant="body1">
                      Unavailable
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={SAT} onChange={handleChangeTime} name="SAT" />}
                  label="SAT"
                />
              </Grid>
              <Grid item xs={6}>
                {day?.SAT === true ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box display="flex">
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        format="HH:mm:ss"
                        minDate={moment().format('YYYY-MM-DD 09:00:00 Z')}
                        minDateMessage={
                          moment(satTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            >= moment(satTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'Start time should be earlier than End time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        label="Start Time"
                        value={moment(satTimeStart, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setSatTimeStart(date)}
                      />
                      &nbsp;
                      <TimePicker
                        ampm={false}
                        variant="inline"
                        format="HH:mm:ss"
                        minDate={moment().format('YYYY-MM-DD 17:00:00 Z')}
                        minDateMessage={
                          moment(satTimeEnd, 'YYYY-MMM-DD HH:mm A').format()
                            <= moment(satTimeStart, 'YYYY-MMM-DD HH:mm A').format()
                            ? 'End time should be later than Start time' : ''
                        }
                        views={['hours', 'minutes', 'seconds']}
                        label="End Time"
                        value={moment(satTimeEnd, 'YYYY-MMM-DD HH:mm A').format()}
                        onChange={(date) => setSatTimeEnd(date)}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                )
                  : (
                    <Typography className={classes.timeText} variant="body1">
                      Unavailable
                    </Typography>
                  )}
              </Grid>
            </Grid>
          </Box>
        );
      }
      case 'Docs': {
        const docs = updatedVal?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) || updatedVal?.find((v) => v[elem?.value]);
        const documents = docs?.[`${elem?.objecttype}_${elem?.name}`]?.documentLink || docs?.[elem?.value]?.documentLink;
        const documentfile = documents?.split('/');
        return (
          <FormControl fullWidth size="small" variant="outlined">
            <Box className={classes.elementBox}>
              <Typography variant="h6" style={{ fontFamily: 'Open sans' }}>{elem?.value}</Typography>
            </Box>
            <Divider />
            <Box className={`${classes.elementBox} mt-2`}>
              <TextField
                className={classes.field}
                size="small"
                variant="outlined"
                name={elem.name}
                type="text"
                label="Title"
                value={isSubmitted === false
                  ? (updatedVal.find(
                    (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                  )?.[`${elem?.objecttype}_${elem?.name}`]?.title)
                    ? (updatedVal.find(
                      (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                    )?.[`${elem?.objecttype}_${elem?.name}`]?.title)
                    : updatedVal.find(
                      (val) => Object.keys(val)[0] === elem?.value,
                    )?.[elem?.value]?.title
                      ? updatedVal.find(
                        (val) => Object.keys(val)[0] === elem?.value,
                      )?.[elem?.value]?.title
                      : '' : ''}
                onChange={(e) => {
                  const val = [...updatedVal];
                  const data = val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) ? val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) : val?.find((v) => v[elem?.value]) ? val?.find((v) => v[elem?.value]) : {};
                  const docObj = data[`${elem?.objecttype}_${elem?.name}`] ? data[`${elem?.objecttype}_${elem?.name}`] : data[elem?.value] ? data[elem?.value] : {};
                  docObj.title = e.target.value;
                  if (data[elem?.value]) {
                    delete data[elem?.value];
                  }
                  data[`${elem?.objecttype}_${elem?.name}`] = docObj;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    } else if (Object.keys(v)[0] === elem.value) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            <Box className={`${classes.elementBox} mt-2`}>
              <TextField
                className={classes.field}
                size="small"
                variant="outlined"
                name={elem.name}
                type="text"
                label="Description"
                value={isSubmitted === false
                  ? (updatedVal.find(
                    (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                  )?.[`${elem?.objecttype}_${elem?.name}`]?.description)
                    ? (updatedVal.find(
                      (val) => Object.keys(val)[0] === `${elem?.objecttype}_${elem?.name}`,
                    )?.[`${elem?.objecttype}_${elem?.name}`]?.description)
                    : updatedVal.find(
                      (val) => Object.keys(val)[0] === elem?.value,
                    )?.[elem?.value]?.description
                      ? updatedVal.find(
                        (val) => Object.keys(val)[0] === elem?.value,
                      )?.[elem?.value]?.description
                      : '' : ''}
                onChange={(e) => {
                  const val = [...updatedVal];
                  const data = val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) ? val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) : val?.find((v) => v[elem?.value]) ? val?.find((v) => v[elem?.value]) : {};
                  const docObj = data[`${elem?.objecttype}_${elem?.name}`] ? data[`${elem?.objecttype}_${elem?.name}`] : data[elem?.value] ? data[elem?.value] : {};
                  docObj.description = e.target.value;
                  if (data[elem?.value]) {
                    delete data[elem?.value];
                  }
                  data[`${elem?.objecttype}_${elem?.name}`] = docObj;
                  val.map((v) => {
                    if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    } else if (Object.keys(v)[0] === elem.value) {
                      const index = val.indexOf(v);
                      val.splice(index, 1);
                    }
                  });
                  val.push(data);
                  setUpdatedVal(val);
                }}
              />
            </Box>
            <Box className={`${classes.UploadDrop} mt-2 mb-4`}>
              <Dropzone
                onDrop={async (acceptedFiles) => {
                  const res = await client.mutate({
                    mutation: UploadImage,
                    variables: {
                      images: acceptedFiles,
                    },
                  });
                  if (res?.data?.uploadimg?.images) {
                    const val = [...updatedVal];
                    const data = val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) ? val?.find((v) => v[`${elem?.objecttype}_${elem?.name}`]) : val?.find((v) => v[elem?.value]) ? val?.find((v) => v[elem?.value]) : {};
                    const docObj = data[`${elem?.objecttype}_${elem?.name}`] ? data[`${elem?.objecttype}_${elem?.name}`] : data[elem?.value] ? data[elem?.value] : {};
                    docObj.documentLink = res?.data?.uploadimg?.images;
                    if (data[elem?.value]) {
                      delete data[elem?.value];
                    }
                    data[`${elem?.objecttype}_${elem?.name}`] = docObj;
                    val.map((v) => {
                      if (Object.keys(v)[0] === `${elem?.objecttype}_${elem?.name}`) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      } else if (Object.keys(v)[0] === elem.value) {
                        const index = val.indexOf(v);
                        val.splice(index, 1);
                      }
                    });
                    val.push(data);
                    setUpdatedVal(val);
                    showNotification('Image uploaded successfully', 'success');
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <CloudUpload className={classes.placeholderIcon} />
                    <Typography variant="body1">
                      Select Files
                    </Typography>
                    <Typography variant="body2">
                      *File size should not
                      greater than 5MB!
                    </Typography>
                  </div>
                )}
              </Dropzone>
              {documents && (
                <Link href={documents} target="_blank" download>
                  <br />
                  <Box className={classes.note}>
                    <Typography variant="body2" className={classes.docTitle}>
                      <Description className={classes.docs} />
                      &nbsp;&nbsp;
                      {documentfile[documentfile?.length - 1]}
                    </Typography>
                  </Box>
                </Link>
              )}
            </Box>
          </FormControl>
        );
      }
      default:
        return '';
    }
  };

  const getSelectedValue = (values, obj) => {
    if (Object.keys(obj).length > 0) {
      values[Object.keys(obj)] = obj[Object.keys(obj)];
    }
    return values;
  };

  useEffect(() => {
    let isMounted = true;
    if (formData && formData.length > 0 && isMounted) {
      const val = [...updatedVal];
      formData.map((form) => {
        const data = {};
        data[form.name] = form.value;
        val.map((v) => {
          if (Object.keys(v)[0] === form.name) {
            const index = val.indexOf(v);
            val.splice(index, 1);
          }
        });
        val.push(data);
        setUpdatedVal(val);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [formData]);

  const validateSubmit = (values, formType) => {
    let isValid = true;
    const errors = [];
    getSelectedValue(values, dob);
    getSelectedValue(values, dateOfBirth);
    getSelectedValue(values, businessStartDate);
    getSelectedValue(values, contactCountryid);
    getSelectedValue(values, contactStateid);
    getSelectedValue(values, orgCountryid);
    getSelectedValue(values, orgStateid);

    updatedVal.map((val) => {
      getSelectedValue(values, val);
    });

    const data = [];
    const keys = Object.keys(values);
    if (keys.length > 0) {
      keys.map((key) => {
        if (key) {
          data.push({
            name: key,
            custom_name: key,
            value: values[key],
            dbfieldName: key === "ticket_attachment" ? "attachments" : content?.find((con) => con.name === key?.split('_')?.[1])?.dbfieldname,
          });
        }
      });
    }

    if (formType === 'multi-page') {
      data.map((d) => {
        if (content.length > 0) {
          for (let i = 1; i < activeTabId; i++) {
            if (content[0][`Page${i}`]?.find((con) => con.name === d.name?.split('_')?.[1])?.dbfieldname) {
              d.dbfieldName = content[0][`Page${i}`]?.find((con) => con.name === d.name?.split('_')?.[1])?.dbfieldname;
            }
          }
        }
      });
    }

    const arr = formType === 'multi-page' ? content[0][`Page${activeTabId}`] : content;
    if (onFormSubmit) {
      if (arr.length > 0) {
        arr.map((con) => {
          if (Component[con.fieldtype] === 'Multiple checkbox' && con.isrequired) {
            keys.map((key) => {
              if (key !== `${con.objecttype}_${con.name}`) {
                if (!(values[`${con.objecttype}_${con.name}`] && values[`${con.objecttype}_${con.name}`].length > 0)) {
                  isValid = false;
                  setIsRequired(true);
                } else {
                  isValid = true;
                }
              }
            });
          }

          if (Component[con.fieldtype] === 'Rating' && con.isrequired) {
            keys.map((key) => {
              if (key !== `${con.objecttype}_${con.name}`) {
                if (!(values[`${con.objecttype}_${con.name}`])) {
                  isValid = false;
                  setIsRequired(true);
                } else {
                  isValid = true;
                }
              }
            });
          }

          if (Component[con.fieldtype] === 'Docs') {
            if (!values[`${con.objecttype}_${con.name}`]?.title && values[`${con.objecttype}_${con.name}`]?.documentLink) {
              isValid = false;
              errors.push('Document title is required');
            } else {
              if (data && data.find((d) => d.name === `${con.objecttype}_${con.name}`)) {
                data.find((d) => d.name === `${con.objecttype}_${con.name}`).name = con.value;
              } else if (data && data.find((d) => d.name === con.value)) {
                data.find((d) => d.name === con.value).custom_name = `${con.objecttype}_${con.name}`;
              }
              isValid = true;
            }
          }

          if (Component[con?.fieldtype] === 'Textbox' && con?.isrequired) {
            if (!(values[`${con.objecttype}_${con.name}`])) {
              isValid = false;
              errors.push(`${con?.name} is required`);
            } else if (con?.dbfieldname?.toLowerCase() === 'primaryemail') {
              if (!emailValidate(values[`${con.objecttype}_${con?.name}`])) {
                isValid = true;
              } else {
                errors.push(emailValidate(values[`${con?.objecttype}_${con?.name}`]));
              }
            }
          } else if (Component[con.fieldtype] === 'Textbox' && con?.dbfieldname?.toLowerCase() === 'primaryemail') {
            if (((con.dbfieldname === 'primaryemail' && !con.isrequired) || (con.dbfieldname === 'phonenumber' && !con.isrequired)) && isFromContact) {
              if (!values[`${con?.objecttype}_${arr.find((a) => a.dbfieldname === 'phonenumber').name}`]
                && !values[`${con?.objecttype}_${arr.find((a) => a.dbfieldname === 'primaryemail').name}`]) {
                isValid = false;
                errors.push('Please enter email address or phone number');
              }
            } else if (values[`${con.objecttype}_${con.name}`]) {
              if (!emailValidate(values[`${con.objecttype}_${con.name}`])) {
                isValid = true;
              } else {
                errors.push(emailValidate(values[`${con.objecttype}_${con.name}`]));
              }
            }
          }

          if (Component[con.fieldtype] === 'Multiline' && con.isrequired) {
            // eslint-disable-next-line no-underscore-dangle
            if (con?.dbfieldname === 'description' && !editorValue?._cache?.html) {
              errors.push('Description is required');
            }
            if (!(values[`${con.objecttype}_${con.name}`]) && (con?.dbfieldname !== 'description')) {
              isValid = false;
              errors.push(`${con.name} is required`);
            } else {
              isValid = true;
            }
          }

          if (Component[con.fieldtype] === 'Phone number' && con.isrequired) {
            if (!(values[`${con.objecttype}_${con.name}`])) {
              isValid = false;

              errors.push(`${con.name} is required`);
            } else if (!phoneCountryDialCodeValidate(values[`${con.objecttype}_${con.name}`])) {
              isValid = true;
            } else if ((values[`${con.objecttype}_${con.name}`]).length === 10) {
              isValid = true;
              const findData = data.find((e) => e.custom_name === `${con.objecttype}_${con.name}`);
              findData.value = `${handleUndefined(defaultCountryPhoneCode)}${values[`${con.objecttype}_${con.name}`]}`;
            } else {
              isValid = false;
              errors.push('Kindly input a valid phone number containing a minimum of 10 digits.');
            }
          } else if (Component[con.fieldtype] === 'Phone number') {
            if (!phoneCountryDialCodeValidate(values[`${con.objecttype}_${con.name}`])) {
              isValid = true;
            } else if ((values[`${con.objecttype}_${con.name}`]).length === 10) {
              isValid = true;
              const findData = data.find((e) => e.custom_name === `${con.objecttype}_${con.name}`);
              findData.value = `${handleUndefined(defaultCountryPhoneCode)}${values[`${con.objecttype}_${con.name}`]}`;
            } else {
              isValid = false;
              errors.push('Kindly input a valid phone number containing a minimum of 10 digits.');
            }
          }

          if (Component[con.fieldtype] === 'Number' && con.isrequired) {
            if (!(values[`${con.objecttype}_${con.name}`])) {
              isValid = false;
              errors.push(`${con.name} is required`);
            } else {
              isValid = true;
            }
          }

          if (con.propertyname === 'Business Logo' && con.isrequired) {
            if (!(values[`${con.objecttype}_${con.name}`])) {
              isValid = false;
              errors.push(`${con.name} is required`);
            } else {
              isValid = true;
            }
          }

          if (con.propertyname === 'Images' && con.isrequired) {
            if (!(values[`${con.objecttype}_${con.name}`])) {
              isValid = false;
              errors.push(`${con.name} is required`);
            } else {
              isValid = true;
            }
          }
        });
      }

      errors.map((err) => {
        showNotification(err, 'error');
      });
    } else if (activeTabId < steps.length && formType === 'multi-page') {
      setActiveTabId(activeTabId + 1);
    }

    const timeavailabilityArray = [];
    if (!(moment(sunTimeStart, 'YYYY-MMM-DD HH:mm A').format() >= moment(sunTimeEnd, 'YYYY-MMM-DD HH:mm A').format())
      && !(moment(monTimeStart, 'YYYY-MMM-DD HH:mm A').format() >= moment(monTimeEnd, 'YYYY-MMM-DD HH:mm A').format())
      && !(moment(tueTimeStart, 'YYYY-MMM-DD HH:mm A').format() >= moment(tueTimeEnd, 'YYYY-MMM-DD HH:mm A').format())
      && !(moment(wenTimeStart, 'YYYY-MMM-DD HH:mm A').format() >= moment(wenTimeEnd, 'YYYY-MMM-DD HH:mm A').format())
      && !(moment(thuTimeStart, 'YYYY-MMM-DD HH:mm A').format() >= moment(thuTimeEnd, 'YYYY-MMM-DD HH:mm A').format())
      && !(moment(friTimeStart, 'YYYY-MMM-DD HH:mm A').format() >= moment(friTimeEnd, 'YYYY-MMM-DD HH:mm A').format())
      && !(moment(satTimeStart, 'YYYY-MMM-DD HH:mm A').format() >= moment(satTimeEnd, 'YYYY-MMM-DD HH:mm A').format())
    ) {
      if (day?.SUN === true) timeavailabilityArray.push({ day: 'SUN', starttime: moment(sunTimeStart, timeFormat?.times).format(timeFormat?.time), endtime: moment(sunTimeEnd, timeFormat?.times).format(timeFormat?.time) });
      if (day?.MON === true) timeavailabilityArray.push({ day: 'MON', starttime: moment(monTimeStart, timeFormat?.times).format(timeFormat?.time), endtime: moment(monTimeEnd, timeFormat?.times).format(timeFormat?.time) });
      if (day?.TUE === true) timeavailabilityArray.push({ day: 'TUE', starttime: moment(tueTimeStart, timeFormat?.times).format(timeFormat?.time), endtime: moment(tueTimeEnd, timeFormat?.times).format(timeFormat?.time) });
      if (day?.WED === true) timeavailabilityArray.push({ day: 'WED', starttime: moment(wenTimeStart, timeFormat?.times).format(timeFormat?.time), endtime: moment(wenTimeEnd, timeFormat?.times).format(timeFormat?.time) });
      if (day?.THU === true) timeavailabilityArray.push({ day: 'THU', starttime: moment(thuTimeStart, timeFormat?.times).format(timeFormat?.time), endtime: moment(thuTimeEnd, timeFormat?.times).format(timeFormat?.time) });
      if (day?.FRI === true) timeavailabilityArray.push({ day: 'FRI', starttime: moment(friTimeStart, timeFormat?.times).format(timeFormat?.time), endtime: moment(friTimeEnd, timeFormat?.times).format(timeFormat?.time) });
      if (day?.SAT === true) timeavailabilityArray.push({ day: 'SAT', starttime: moment(satTimeStart, timeFormat?.times).format(timeFormat?.time), endtime: moment(satTimeEnd, timeFormat?.times).format(timeFormat?.time) });
    }

    const timeavailability = arr.find((con) => Component[con.fieldtype] === 'availablehours');
    if (timeavailability) {
      data.push({
        name: `${timeavailability?.objecttype}_${timeavailability?.name}`,
        custom_name: `${timeavailability?.objecttype}_${timeavailability?.name}`,
        value: timeavailabilityArray,
      });
    }

    if (onFormSubmit && isValid && errors.length === 0 && formType === 'single-page') {
      if (!isFromContact && !isFromDeal) {
        setUpdatedVal([]);
        setIsSubmitted(true);
      }
      onFormSubmit(data);
    } else if (isValid && errors.length === 0 && activeTabId < steps.length && formType === 'multi-page') {
      setActiveTabId(activeTabId + 1);
    } else if (onFormSubmit && isValid && errors.length === 0 && formType === 'multi-page') {
      setUpdatedVal([]);
      setIsSubmitted(true);
      onFormSubmit(data);
    }
  };

  const onSubmitData = async (values) => {
    validateSubmit(values, eventType);
  };

  const onMultiSubmitData = (values) => {
    validateSubmit(values, eventType);
  };

  return (
    eventType === 'single-page'
      ? (
        <Form
          onSubmit={onSubmitData}
          render={({
            handleSubmit,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} className={isAppointmentPublic ? 'pl-4 pt-2 pr-4' : (!isFromContact && !isFromDeal) ? 'pl-4 pt-2 pr-4' : 'pt-2'}>
                  {content?.length > 0 ? (content.map((con) => (
                    <Grid item lg={12} xs={12} md={12} sm={12} className="mt-2" style={{ display: (con?.dbfieldname === 'firstname' || con?.dbfieldname === 'lastname' || con?.dbfieldname === 'phonenumber') ? (showFields ? 'block' : 'none') : 'block', }}>
                      {getElement(con)}
                    </Grid>
                  ))) : (<Typography className={classes.emptyMsg}>
                    <center>Please Create a Form</center>
                  </Typography>)}
                  {!isFromTicket && visible === true
                    ? (
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          value={contact}
                          onChange={(event, newValue) => { setContact(newValue); }}
                          options={suggestContact}
                          freeSolo
                          renderTags={(values, getTagProps) => values.map(
                            (option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ),
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label="Guest Email(s)"
                              placeholder="Add email and press enter"
                            />
                          )}
                        />
                      </Grid>
                    )
                    : ''}
                  {(!isFromContact && !isFromDeal) && (
                    <Grid item md={12} sm={12} xs={12}>
                      {enableTerm && (
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: termDesc }} className={classes.termDescription} />
                      )}
                      <StyledBtnBox>
                        {isAppointment
                          ? (
                            <>
                              <GuestButton
                                size="small"
                                variant="outlined"
                                color="primary"
                                className={classes.guestBtn}
                                onClick={() => {
                                  if (setVisible) {
                                    setVisible(true);
                                  }
                                }}
                              >
                                Add Guests
                              </GuestButton>
                              <ScheduleButton
                                size="small"
                                variant="contained"
                                type="submit"
                                onClick={() => { }}
                              >
                                {btnName}
                              </ScheduleButton>
                            </>
                          )
                          : (
                            <>
                              {content?.length > 0 ? <StyledButton
                                size="small"
                                variant="contained"
                                type={isFromTicket ? 'button' : 'submit'}
                                onClick={() => handleSubmit()}
                                endIcon={isFromTicket ? <ConfirmationNumber /> : ''}
                              >
                                <span>{btnName}</span>
                              </StyledButton> : ''}
                            </>
                          )}
                      </StyledBtnBox>
                    </Grid>
                  )}
                  {(isSaveContact || isFromDeal) && (
                    <Box style={{
                      position: 'absolute', bottom: 12, right: 27, paddingBottom: 10,
                    }}
                    >
                      <Button
                        startIcon={<Close />}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleClose()}
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        startIcon={<Save />}
                        size="small"
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginLeft: 10 }}
                        onClick={() => { }}
                      >
                        <span>Save</span>
                      </Button>
                    </Box>
                  )}
                </Grid>
              </form>
            </>
          )}
        />
      ) : (
        <Form
          onSubmit={onMultiSubmitData}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} className={isAppointmentPublic ? 'pl-4 pt-2' : 'pl-4 pt-2 pr-4'}>
                {content?.length > 0 && content.map((con) => (
                  con[`Page${activeTabId}`] && con[`Page${activeTabId}`].length > 0
                    ? con[`Page${activeTabId}`].map((fi) => (
                      fi.objecttype !== 'Designing' ? (
                        <Grid
                          item
                          lg={
                            12
                          }
                          md={
                            12
                          }
                          xs={12}
                          sm={12}
                          className="pl-4 pt-2 pr-4"
                          style={{ backgroundColor: backgroundColorCode }}
                        >
                          <Box className={classes.elementBox}>
                            {getElement(fi)}
                          </Box>
                        </Grid>
                      ) : (
                        <Grid item md={12} xs={12} sm={12} className="pl-4 pt-2 pr-4">
                          <Grid container spacing={2}>
                            <Grid item xs={11} md={11} sm={11}>
                              {getElement(fi)}
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    ))
                    : null
                ))}
                <Grid item md={12} xs={12} sm={12} style={{ paddingTop: '30px' }}>
                  {enableTerm && activeTabId === steps.length && (
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: termDesc }} />
                  )}
                  <StyledBox>
                    {activeTabId > 1 && (
                      <Button
                        style={{ float: eventType === 'single-page' ? 'center' : 'left' }}
                        className={classes.prevBtn}
                        onClick={handlePrev}
                      >
                        Prev
                      </Button>
                    )}
                    {activeTabId >= 1 && activeTabId < steps.length
                      && (
                        <Button
                          style={{ float: eventType === 'single-page' ? 'center' : 'left' }}
                          className={classes.nextBtn}
                          type="submit"
                          onClick={() => { }}
                        >
                          Next
                        </Button>
                      )}
                    {activeTabId === steps.length && (
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        style={{ float: eventType === 'single-page' ? 'center' : 'left' }}
                        className={classes.CalloutButton}
                        onClick={() => { }}
                      >
                        <b>{btnName}</b>
                      </Button>
                    )}
                  </StyledBox>
                </Grid>
              </Grid>
            </form>
          )}
        />
      )
  );
};
export default TicketFormGenerator;
