export const OrganizationsWithPagination = `query organizationswithpagination($locationid: ID!, $pagenumber: Int, $pagesize: Int, $isverified: Boolean,  $typeofentity: String,  $searchtext: String) {
    organizationswithpagination(locationid: $locationid, pagenumber: $pagenumber, pagesize: $pagesize, isverified: $isverified,  typeofentity: $typeofentity, searchtext: $searchtext) {
      count
      rows {
        organizationid
        name
        locationid
        orgphonenumber
        orgemail
        orgownerid
        createdby
        updatedby
        address {
          addressid
          street1
          street2
          city
          county
          stateid
          countryid
          zip
          createdby
          updatedby
          locationid
          organizationid
          addresstype
          state {
            name
            stateid
          }
          country {
            countryid
            name
          }
        }
        contact {
            contactid
            primaryemail
            contactavatar
            firstname
            lastname
          }
        createdAt
        updatedAt
        shortdesc
        longdesc
        businesslogo
        images
        isverified
        availablehours {
          day
          starttime
          endtime
        }
      }
    }
  }`;

export const Organization = `query organization($organizationid: ID!) {
    organization(organizationid: $organizationid) {
      organizationid
      name
      locationid
      orgphonenumber
      orgemail
      categories
      employeesize
      yearsinbusiness
      revenue
      businessstartdate
      typeofentity
      orgmap
      orgwebsite
      orgtwitter
      orgfacebook
      orgstatus
      orgtags
      orgsource
      orgownerid
      orglinkedin
      orggmb
      orgyelp
      orgtripadvisor
      createdby
      isverified
      updatedby
      address {
        addressid
        street1
        street2
        city
        county
        stateid
        countryid
        zip
        createdby
        updatedby
        locationid
        organizationid
        addresstype
        country {
          countryid
          name
        }
        state {
          stateid
          name
        }
      }
      phonecountryid
      createdAt
      updatedAt
      contact {
        contactid
        primaryemail
        firstname
        lastname
        contactavatar
        phonenumber
      }
      contactcount
      shortdesc
      longdesc
      businesslogo
      images
      availablehours {
        day
        starttime
        endtime
      }
    }
  }`;
