import './Toast.css';
import { Flip, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
export const showNotification = (message, type, timeOut) => {

  if (type === 'error') {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      transition:Flip
    });
  } else if (type === 'success') {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      transition:Flip
    });
  } else if (type === 'info') {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
      transition:Flip
    });
  }

};
