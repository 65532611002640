export const UploadEntityPhoto = `mutation uploadEntityPhoto($entityid: ID!, $logo: Upload!) {
    uploadEntityPhoto(entityid: $entityid, logo: $logo)
  }`;

export const Entity = `query entity($entityid: ID!) {
  entity(entityid: $entityid) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    defaultcountrycode
    defaultphonecountrycode
    address {
      addressid
      street1
      street2
      city
      stateid
      state
      countryid
      zip
      createdby
      updatedby
    }
    ownerid
    totalreview
    nps
    website
    subdomain
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    publiclogotype
    favicon
    whitelabeltype
    whitelabeldomain
  }
}`;

export const BrandSettingByLocation = `query brandSettingByLocation($locationid:ID!)
{brandSettingByLocation(locationid:$locationid){color,dateformat,timeformat,logourl}}
`;
export const Entitybysubdomain = `query entitybysubdomain($subdomain: String) {
  entitybysubdomain(subdomain: $subdomain) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    address {
      addressid
      street1
      street2
      city
      stateid
      state
      countryid
      zip
      createdby
      updatedby
    }
    ownerid
    totalreview
    nps
    website
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    subdomain
  }
}`;

export const Entitiesbyparentid = `query entitiesbyparentid($parententityid: ID!) {
  entitiesbyparentid(parententityid: $parententityid) {
    entityid
    businessname
    brandname
    firstname
    lastname
    email
    phonenumber
    entitytypeid
    parententityid
    businesstypeid
    createdby
    updatedby
    deleted
    jobid
    tags
    ownerid
    totalreview
    nps
    website
    shortdesc
    fulldesc
    createdAt
    updatedAt
    phonecountryid
    policy
    terms
    displaylocationdoncampaign
    publicpageviewcount
    customercount
    businesscount
    locationcount
    reviewscount
    status
    trialplanid
    paidplanid
    isfirstsubmitted
    iscreatedagency
    isfreebusiness
    trialplanenddate
    coverpageurl
    iscallrecording
    isbusinessverified
    logourl
    subdomain
  }
}`;
