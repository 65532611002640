import React from 'react';
import Bix from '../../images/bix.png';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import useStyles from './LoadingPageStyle';

const LoadingPage = () => {
  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open>
        <Grid
          container
          spacing={0}
          className={classes.gridWrapper}
        >
          <Grid item xs={3} className={classes.loadingWrapper}>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {Bix ? (
                <img
                  src={Bix}
                  className={classes.loadingLogo}
                  alt="bix logo"
                />
                ) : ''}
                <CircularProgress
                  size={78}
                  className={classes.fabProgress}
                />
              </div>
            </div>
            <span>Loading...</span>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
};
// eslint-disable-next-line import/prefer-default-export
export { LoadingPage };
