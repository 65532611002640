/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Link, MobileStepper, Button, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  postDataRequestPublic,
  handleUndefined,
} from "../../common/commonfunctions";
import PoweredBy from "../../common/PoweredBy";
import { useTheme } from "@mui/material/styles";
import { OrganizationsWithPagination } from "../../Api Mutation/OrganizationApi";
import { Entity } from "../../Api Mutation/EntityApi";
import config from "../../config";
import { showNotification } from "../../components/Notification/ToastifyNotifications";
import BusinessSearch from "./BusinessSearch";
import useStyle from "./styles";
import "./businessdetails.css";

export default function Businesslist() {
  const classes = useStyle();
  const { lid } = useParams([]);
  const [businessData, setBusinessData] = useState([]);
  const [locationData, setLocationData] = useState();
  const [searchText, setSearchText] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const [count, setCount] = useState();
  const maxSteps = Math.ceil(count?.count / 9);

  const getInitialData = (props) => {
    if (lid) {
      postDataRequestPublic(config.baseURLApi, {
        query: OrganizationsWithPagination,
        variables: {
          locationid: lid,
          pagenumber: props?.pagenumber + 1,
          pagesize: 9,
          isverified: true,
          searchtext: searchText,
        },
      }).then((response) => {
        const data = response?.data?.organizationswithpagination;
        if (data) {
          setBusinessData(data);
          setCount(data);
        } else if (response?.errors[0]?.message) {
          showNotification(
            response?.errors && response?.errors[0]?.message,
            "error"
          );
        }
      });
    }
  };

  const getLocationByData = () => {
    if (lid) {
      const ENTITYDATA = Entity;
      postDataRequestPublic(config.baseURLApi, {
        query: ENTITYDATA,
        variables: { entityid: lid },
      }).then((res) => {
        if (res?.data?.entity) {
          setLocationData(res?.data?.entity);
        }
      });
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    getInitialData({
      pagenumber: activeStep,
    });
  }, [lid, activeStep]);

  useEffect(() => {
    getLocationByData();
  }, [lid]);

  return (
    <div>
      <br />
      <BusinessSearch
        setSearchText={setSearchText}
        searchText={searchText}
        getInitialData={getInitialData}
        activeStep={activeStep}
      />
      <section class="gray">
        <div class="container">
          <Grid container spacing={1}>
            {businessData?.rows?.length <= 0 ? (
                <Grid
                style={{ background: "none" }}
                item
                xs={12}
                md={12}
                sm={12}
              >
                <Typography variant="h5" textAlign="center">
                    No Data found
                </Typography>
              </Grid>
            ) : (
              <>
                {businessData &&
                  businessData?.rows?.map((i) => (
                    <Grid
                      style={{ background: "none" }}
                      item
                      xs={12}
                      md={4}
                      sm={4}
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        href={
                          locationData?.subdomain
                            ? `https://${locationData.subdomain}${config.subDomainUrl}/bd/${i?.organizationid}/${locationData?.entityid}/${locationData?.parententityid}`
                            : `${config.BixUrl}/bd/${i?.organizationid}/${locationData?.entityid}/${locationData?.parententityid}`
                        }
                      >
                        <div class="Reveal-grid-wrapper">
                          {/* <div class="list-badge now-open">Now Open</div> */}
                          <div class="Reveal-grid-thumb">
                            {/* <div class="Reveal-listing-price-info">
                    <span class="pricetag">$45 - $70</span>
                  </div> */}
                            {/* <a href="#" class="tag_t">
                    <i class="ti-heart"></i>Save
                  </a> */}
                            {/* <div class="lup-category">
                    <a href="search-with-sidebar.html">Hotel</a>
                  </div> */}
                            <a href class="lup-box">
                              <img
                                style={{
                                  height: 230,
                                  objectFit: "contain",
                                  width: "100%",
                                }}
                                src={
                                  i?.businesslogo
                                    ? i?.businesslogo
                                    : config?.businessListLogo
                                }
                                class="img-fluid"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="Reveal-grid-caption">
                            <div class="Reveal-grid-caption-header">
                              <h4
                                class="Reveal-header-title"
                                className={classes.subtext}
                              >
                                <a href>{i?.name}</a>
                              </h4>
                              {/* <div class="Reveal-grid-reviews">
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star"></i>
                            <span class="overall-reviews">(42 Reviews)</span>
                          </div> */}
                            </div>
                            <div class="Reveal-grid-caption-body">
                              <ul class="Reveal-contact-list">
                                <li className={classes.subtext}>
                                  {i?.address?.state?.name ||
                                  i?.address?.country?.name ? (
                                    <>
                                      <img
                                        src="https://themezhub.net/reveal-live/reveal/assets/img/add.svg"
                                        class=""
                                        alt=""
                                      />
                                      {i?.address?.state?.name},
                                      {i?.address?.country?.name},{" "}
                                      {i?.address?.zip}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </li>

                                <li className={classes.subtext}>
                                  {i?.orgphonenumber ? (
                                    <>
                                      <img
                                        src="https://themezhub.net/reveal-live/reveal/assets/img/call.svg"
                                        class=""
                                        alt=""
                                      />
                                      {i?.orgphonenumber}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="Reveal-grid-footer">
                            <div class="Reveal-grid-footer-flex">
                              <div class="Reveal-author-caption">
                                <div class="Reveal-author-thumb">
                                  {handleUndefined(
                                    i?.contact?.contactavatar
                                  ) ? (
                                    <img
                                      src={handleUndefined(
                                        i?.contact?.contactavatar
                                      )}
                                      class="img-fluid"
                                      alt=""
                                    />
                                  ) : (
                                    <h1>
                                      {handleUndefined(
                                        i?.contact?.firstname?.charAt(0)
                                      )}
                                    </h1>
                                  )}
                                </div>
                                <div class="Reveal-author-header">
                                  <h4>
                                    {handleUndefined(i?.contact?.firstname)}{" "}
                                    {handleUndefined(i?.contact?.lastname)}
                                    <span className={classes.subhead}>
                                      {handleUndefined(
                                        i?.contact?.primaryemail
                                      )}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div class="Reveal-grid-footer-last">
                              <a href class="Reveal-view-btn">
                                View
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
          {count?.count > 0 ? (
            <MobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{ flexGrow: 1 }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === 5}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          ) : (
            ""
          )}
        </div>
      </section>
      <PoweredBy id={locationData?.entityid} />
    </div>
  );
}
